define("clarify/adapters/rest-layer", ["exports", "ember-data/adapters/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  _rest.default.reopen({
    useFetch: true
  });

  class RestLayerAdapter extends _rest.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "includeAll", false);

      _defineProperty(this, "removeDeleted", false);
    }

    fields(_requestType) {
      return [];
    }

    buildURL(modelName, id, snapshot, requestType, query) {
      let url = super.buildURL(modelName, id, snapshot, requestType, query);

      if (!requestType) {
        return url;
      }

      let queryParameters = new URLSearchParams();
      let fields = this.fields(requestType).join(',');

      if (fields) {
        queryParameters.append('fields', fields);
      }

      if (this.includeAll) {
        queryParameters.append('includeAll', 'true');
      }

      if (this.removeDeleted) {
        queryParameters.append('removeDeleted', 'true');
      }

      switch (requestType) {
        case 'query':
          if (query && query.filter) {
            query.filter = JSON.stringify(query.filter);
          }

          break;

        default:
          break;
      }

      let queryString = queryParameters.toString();

      if (queryString) {
        let conjuction = url.indexOf('?') === -1 ? '?' : '&';
        return `${url}${conjuction}${queryString}`;
      }

      return url;
    }

    updateRecord(store, type, snapshot) {
      // @ts-ignore
      let modelName = type.modelName;
      const serializer = store.serializerFor(modelName);
      const data = serializer.serialize(snapshot);
      const url = this.buildURL(modelName, snapshot.id, snapshot, 'updateRecord');
      return this.ajax(url, 'PATCH', {
        data: data
      });
    }

    handleResponse(status, headers, payload, requestData) {
      let data = super.handleResponse(status, headers, payload, requestData);

      if (status === 204) {
        return data;
      }

      let meta = {};
      let total = headers['x-total'];
      let offset = headers['x-offset'];

      if (total || offset) {
        meta.pagination = {
          total: total ? parseInt(total, 10) : Infinity,
          offset: offset ? parseInt(offset, 10) : 0
        };
      }

      return {
        data,
        meta
      };
    }

  }

  _exports.default = RestLayerAdapter;
});