define("clarify/components/x-canvas", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let XCanvas = (_dec = (0, _component.tagName)('canvas'), _dec2 = (0, _component.attribute)('width'), _dec3 = Ember.computed('width', 'devicePixelRatio'), _dec4 = (0, _component.attribute)('height'), _dec5 = Ember.computed('height', 'devicePixelRatio'), _dec6 = (0, _component.attribute)('style'), _dec7 = Ember.computed('width', 'height'), _dec(_class = (_class2 = (_temp = class XCanvas extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "width", 100);

      _defineProperty(this, "height", 100);

      _defineProperty(this, "devicePixelRatio", 1);
    }

    get innerWidth() {
      return this.width * this.devicePixelRatio;
    }

    get innerHeight() {
      return this.height * this.devicePixelRatio;
    }

    get style() {
      return Ember.String.htmlSafe(`width: ${this.width}px; height: ${this.height}px`);
    }

    didRender() {
      super.didRender();

      if (!this.isDestroyed && !this.isDestroying) {
        this.prepare();
      }
    }

    prepare() {
      const context = this.element.getContext('2d');

      if (context) {
        context.setTransform(this.devicePixelRatio, 0, 0, this.devicePixelRatio, 0, 0);
        this.paint(context);
      }
    }

    paint(_context) {}

  }, _temp), (_applyDecoratedDescriptor(_class2.prototype, "innerWidth", [_dec2, _dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "innerWidth"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "innerHeight", [_dec4, _dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "innerHeight"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "style", [_dec6, _dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "style"), _class2.prototype)), _class2)) || _class);
  _exports.default = XCanvas;
});