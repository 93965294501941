define("clarify/components/subject-auto-complete/index", ["exports", "@glimmer/component", "clarify/utilities/compact-map", "clarify/utilities/score"], function (_exports, _component, _compactMap, _score) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#let (uuid) as |id|}}
    <AutoComplete
      type="text"
      class="subject-auto-complete-input"
      placeholder={{t "subject-auto-complete.placeholder"}}
      aria-controls={{concat id "-popover-list"}}
      aria-autocomplete="list"
      ...attributes
      @items={{this.sections}}
      @filter={{fn this.filter}}
      @selectItem={{fn this.handleSuggestion}} as |auto|
    >
      <div class="subject-auto-complete" tabindex="-1">
        {{#unless auto.query}}
          <h2 class="subject-auto-complete-header">
            {{t "subject-auto-complete.popover.header"}}
          </h2>
        {{/unless}}
        <SubjectAutoComplete::List
          id={{concat id "-popover-list"}}
          @items={{auto.items}}
          @highlightedIndexPath={{auto.highlightedIndex}}
          @selectItem={{fn auto.selectItem}}
        />
      </div>
    </AutoComplete>
  {{/let}}
  */
  {"id":"L+hVbv8Z","block":"{\"symbols\":[\"id\",\"auto\",\"&attrs\"],\"statements\":[[6,[37,5],[[30,[36,4],null,null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"auto-complete\",[[24,0,\"subject-auto-complete-input\"],[16,\"placeholder\",[30,[36,0],[\"subject-auto-complete.placeholder\"],null]],[16,\"aria-controls\",[30,[36,1],[[32,1],\"-popover-list\"],null]],[24,\"aria-autocomplete\",\"list\"],[17,3],[24,4,\"text\"]],[[\"@items\",\"@filter\",\"@selectItem\"],[[32,0,[\"sections\"]],[30,[36,2],[[32,0,[\"filter\"]]],null],[30,[36,2],[[32,0,[\"handleSuggestion\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"subject-auto-complete\"],[14,\"tabindex\",\"-1\"],[12],[2,\"\\n\"],[6,[37,3],[[32,2,[\"query\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"h2\"],[14,0,\"subject-auto-complete-header\"],[12],[2,\"\\n          \"],[1,[30,[36,0],[\"subject-auto-complete.popover.header\"],null]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[8,\"subject-auto-complete/list\",[[16,1,[30,[36,1],[[32,1],\"-popover-list\"],null]]],[[\"@items\",\"@highlightedIndexPath\",\"@selectItem\"],[[32,2,[\"items\"]],[32,2,[\"highlightedIndex\"]],[30,[36,2],[[32,2,[\"selectItem\"]]],null]]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"concat\",\"fn\",\"unless\",\"uuid\",\"let\"]}","meta":{"moduleName":"clarify/components/subject-auto-complete/index.hbs"}});

  let SubjectAutoComplete = (_dec = Ember._action, _dec2 = Ember.computed.sort('args.timeline.organization.groups', 'groupSortingKeys'), _dec3 = Ember.computed.sort('args.timeline.organization.activeUsers', 'userSortingKeys'), _dec4 = Ember._action, (_class = (_temp = class SubjectAutoComplete extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "groupSortingKeys", ['isPrimaryGroup:desc', 'name:asc']);

      _initializerDefineProperty(this, "groupSuggestions", _descriptor, this);

      _defineProperty(this, "userSortingKeys", ['name:asc']);

      _initializerDefineProperty(this, "userSuggestions", _descriptor2, this);
    }

    handleSuggestion(item) {
      this.args.didSelectSubject(item.subject);
    }

    filter(items, query) {
      if (!query) {
        let section = items[0];
        let sectionItem = section.items.filter(item => !item.disabled);
        return {
          type: 'visible',
          sections: [{ ...section,
            items: sectionItem
          }]
        };
      }

      let sections = (0, _compactMap.default)(items, section => {
        let scored = section.items.map(item => {
          switch (item.subject.type) {
            case 'user':
              return {
                item,
                score: (0, _score.score)(item.subject.user.name, query)
              };

            case 'group':
              return {
                item,
                score: (0, _score.score)(item.subject.group.name, query)
              };

            default:
              throw new Error('Unknown subject type');
          }
        });
        let items = scored.filter(item => item.score > 0.25).sortBy('score').reverse().map(item => item.item);

        if (items.length) {
          return { ...section,
            items
          };
        }

        return null;
      });
      return {
        type: 'visible',
        sections
      };
    }

    get sections() {
      let existingMembers = this.args.timeline.validPermissions.reduce((permissionSet, permission) => {
        permissionSet.add(permission.identifier);
        return permissionSet;
      }, new Set());
      let groups = this.groupSuggestions.map(group => {
        return {
          subject: {
            type: 'group',
            group
          },
          disabled: existingMembers.has(group.id)
        };
      });
      let users = this.userSuggestions.map(user => {
        return {
          subject: {
            type: 'user',
            user
          },
          disabled: existingMembers.has(user.id)
        };
      });
      return [{
        title: null,
        id: 'id',
        items: [...groups, ...users]
      }];
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "handleSuggestion", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "handleSuggestion"), _class.prototype), _descriptor = _applyDecoratedDescriptor(_class.prototype, "groupSuggestions", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "userSuggestions", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "filter", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "filter"), _class.prototype)), _class));
  _exports.default = SubjectAutoComplete;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SubjectAutoComplete);
});