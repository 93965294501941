define("clarify/routes/organizations/show/timelines/show/stats/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TimelinesShowStatsIndexRoute extends Ember.Route {
    redirect() {
      let model = this.modelFor('organizations/show/timelines/show/stats');
      let visualization = model.layout.visualizations.toArray()[0];

      if (visualization) {
        this.transitionTo('organizations.show.timelines.show.stats.show', visualization.id);
      }
    }

  }

  _exports.default = TimelinesShowStatsIndexRoute;
});