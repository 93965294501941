define("clarify/serializers/activity-group", ["exports", "clarify/serializers/rest-layer"], function (_exports, _restLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class ActivityGroup extends _restLayer.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "root", 'activity-groups');
    }

    modelNameFromPayloadKey(key) {
      switch (key) {
        case 'comment':
          return 'activity-group-comment';

        case 'reply':
          return 'activity-group-reply';

        default:
          return super.modelNameFromPayloadKey(key);
      }
    }

  }

  _exports.default = ActivityGroup;
});