define("clarify/services/thread", ["exports", "clarify/environment", "clarify/services/uploader"], function (_exports, _environment, _uploader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ComposerData = _exports.AttachmentData = void 0;

  var _dec, _dec2, _class, _temp, _dec3, _dec4, _dec5, _dec6, _class3, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let AttachmentData = (_dec = Ember.computed('status'), _dec2 = Ember.computed('uploader.progress.{loaded,total}'), (_class = (_temp = class AttachmentData extends Ember.Object {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "file", void 0);

      _defineProperty(this, "name", void 0);

      _defineProperty(this, "url", void 0);

      _defineProperty(this, "type", void 0);

      _defineProperty(this, "status", {
        type: 'pending'
      });

      _defineProperty(this, "uploader", _uploader.default.create());
    }

    get uploading() {
      return this.status.type === 'uploading';
    }

    get progress() {
      return ~~(this.uploader.progress.loaded / this.uploader.progress.total * 100);
    }

    get isImage() {
      return this.type.startsWith('image/');
    }

    init() {
      super.init();
      this.uploader.promise.then(() => {
        Ember.set(this, 'status', {
          type: 'uploaded'
        });
      }, error => {
        Ember.set(this, 'status', {
          type: 'failed',
          error
        });
      });
    }

    cancel() {
      this.uploader.cancel();
    }

    upload(uri, token) {
      if (this.status.type === 'pending') {
        let headers = {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json'
        };
        Ember.set(this.uploader, 'file', this.file);
        this.uploader.upload(uri, headers);
        Ember.set(this, 'status', {
          type: 'uploading'
        });
      }

      return this.uploader.promise;
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "uploading", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "uploading"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "progress", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "progress"), _class.prototype)), _class));
  _exports.AttachmentData = AttachmentData;
  let ComposerData = (_dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.computed.alias('authentication.accessToken'), (_class3 = (_temp2 = class ComposerData extends Ember.Object {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "attachments", []);

      _defineProperty(this, "thread", void 0);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "currentUser", _descriptor2, this);

      _initializerDefineProperty(this, "authentication", _descriptor3, this);

      _initializerDefineProperty(this, "token", _descriptor4, this);
    }

    addAttachment(attachment) {
      this.attachments.addObject(attachment);

      if (Ember.get(this.thread, 'isNew') === false) {
        this.uploadAttachment(attachment);
      }
    }

    removeAttachment(attachment) {
      attachment.cancel();
      this.attachments.removeObject(attachment);
    }

    async uploadAttachment(attachment) {
      let name = attachment.name.replace(/\/|\\/g, '_');
      const uri = `${_environment.default.APP.API_HOST}/api/files/threads/${this.thread.get('id')}/${encodeURIComponent(name)}`;
      return await attachment.upload(uri, this.token);
    }

    async postComment(text, contentReferences) {
      const comment = this.store.createRecord('comment', {
        textContent: text,
        contentReferences: contentReferences,
        timeline: this.thread.get('timeline'),
        thread: this.thread,
        organization: this.thread.organization,
        attachments: [],
        createdBy: this.currentUser.user,
        createdAt: new Date()
      });
      let isNew = Ember.get(this.thread, 'isNew');

      if (isNew) {
        Ember.set(this.thread, 'comment', comment);
        await this.thread.save();
      }

      let attachments = this.attachments.map(attachment => {
        return this.uploadAttachment(attachment);
      });
      let attachmentsObjects = await Promise.all(attachments);
      Ember.set(comment, 'attachments', attachmentsObjects);

      try {
        await comment.save();
      } catch (error) {
        this.store.deleteRecord(comment);
        return Promise.reject(error);
      }
    }

  }, _temp2), (_descriptor = _applyDecoratedDescriptor(_class3.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class3.prototype, "currentUser", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class3.prototype, "authentication", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class3.prototype, "token", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class3));
  _exports.ComposerData = ComposerData;

  class ThreadService extends Ember.Service {
    createComposerDataForThread(thread) {
      let owner = Ember.getOwner(this);
      let composerData = owner.lookup('service:composer-data');
      Ember.set(composerData, 'thread', thread);
      return composerData;
    }

  }

  _exports.default = ThreadService;
});