define("clarify/adapters/user", ["exports", "clarify/adapters/meta"], function (_exports, _meta) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const defaultLimit = 200;

  class User extends _meta.default {
    fields(_requestType) {
      return ['id', 'name', 'email', 'picture'];
    }

    urlForQuery(query, _modelName) {
      let path = this.buildURL('organization', query.organization);
      return `${path}/users?limit=${defaultLimit}`;
    }

  }

  _exports.default = User;
});