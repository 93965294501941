define("clarify/components/a-tag/-private", ["exports", "ui/components/a-tag/-private"], function (_exports, _private) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _private.default;
    }
  });
});