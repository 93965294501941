define("clarify/components/card/activity/activity-group-comment/index", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Card::Activity::Default @activity={{@activity}} ...attributes>
    <:title>
      {{t "activity-feed.activity-group-comment.title" name=@activity.comment.createdBy.name}}
    </:title>
    <:content>
      <Card::Activity::Comment
        @comment={{@activity.comment}}
        @showAttachments={{true}}
        @maxImageSize={{this.maxImageSize}}
        @preview={{true}}
      />
    </:content>
  </Card::Activity::Default>
  */
  {"id":"XMgk4rya","block":"{\"symbols\":[\"__arg0\",\"@activity\",\"&attrs\"],\"statements\":[[8,\"card/activity/default\",[[17,3]],[[\"@activity\",\"@namedBlocksInfo\"],[[32,2],[30,[36,3],null,[[\"title\",\"content\"],[0,0]]]]],[[\"default\"],[{\"statements\":[[6,[37,1],[[30,[36,0],[[32,1],\"title\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,2],[\"activity-feed.activity-group-comment.title\"],[[\"name\"],[[32,2,[\"comment\",\"createdBy\",\"name\"]]]]]],[2,\"\\n  \"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,0],[[32,1],\"content\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"card/activity/comment\",[],[[\"@comment\",\"@showAttachments\",\"@maxImageSize\",\"@preview\"],[[32,2,[\"comment\"]],true,[32,0,[\"maxImageSize\"]],true]],null],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"-is-named-block-invocation\",\"if\",\"t\",\"hash\"]}","meta":{"moduleName":"clarify/components/card/activity/activity-group-comment/index.hbs"}});

  class CommentAndReplyCard extends _component.default {
    get maxImageSize() {
      return this.args.maxImageSize || {
        width: 300,
        height: 240
      };
    }

  }

  _exports.default = CommentAndReplyCard;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CommentAndReplyCard);
});