define("clarify/utilities/fetch-stats", ["exports", "unionize", "clarify/utilities/rpc/time-series-rollup"], function (_exports, _unionize, _timeSeriesRollup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fetchStats = fetchStats;
  _exports.StatDefinition = _exports.VisualizationStatStatus = void 0;
  const VisualizationStatStatus = (0, _unionize.unionize)({
    hidden: {},
    loading: {},
    loaded: (0, _unionize.ofType)()
  }, {
    tag: 'type',
    value: 'payload'
  });
  _exports.VisualizationStatStatus = VisualizationStatStatus;
  const StatDefinition = (0, _unionize.unionize)({
    value: (0, _unionize.ofType)(),
    error: (0, _unionize.ofType)()
  }, {
    tag: 'type',
    value: 'payload'
  });
  _exports.StatDefinition = StatDefinition;

  async function fetchStats(items, from, to, network, abortSignal) {
    let returnResult = new Map();
    let requests = items.map(item => {
      return _timeSeriesRollup.TimeSeriesRollupRequestInfo.create(item.id, {
        window: {
          from: from.toISOString(),
          to: to.toISOString()
        },
        bucket: null,
        rollups: [{
          id: item.id,
          methods: ['min', 'max', 'avg', 'sum', 'count']
        }]
      });
    });

    try {
      let result = await network.rpc('timeseries/rpc', {
        signal: abortSignal
      }, ...requests);
      result.forEach((payload, index) => {
        let signal = items[index];

        if (payload.type === 'result') {
          let section = payload.result.sections[0];

          if (section && section[0]) {
            let stats = {
              minimum: section[0][1],
              maximum: section[0][2],
              average: section[0][3],
              sum: section[0][4],
              count: section[0][5]
            };
            returnResult.set(signal.id, StatDefinition.value(stats));
          } else {
            let stats = {
              minimum: null,
              maximum: null,
              average: null,
              sum: null,
              count: null
            };
            returnResult.set(signal.id, StatDefinition.value(stats));
          }
        } else {
          items.forEach(item => {
            returnResult.set(item.id, StatDefinition.error(payload.error));
          });
        }
      });
    } catch (error) {
      if (error.name === 'AbortError') {
        return Promise.reject(error);
      }

      items.forEach(item => {
        returnResult.set(item.id, StatDefinition.error(error));
      });
    }

    return returnResult;
  }
});