define("clarify/templates/organizations/show/timelines/show/settings/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nE5rDoLM",
    "block": "{\"symbols\":[],\"statements\":[[8,\"off-click\",[],[[\"@offClick\",\"@triggerOnInteractiveElements\"],[[30,[36,0],[[32,0],\"settingsMenuClosed\"],null],false]],[[\"default\"],[{\"statements\":[[8,\"timeline-settings\",[],[[\"@timeline\",\"@layoutModel\",\"@colorDidChange\",\"@insertVisualizationItems\",\"@onClose\",\"@openVisualizationSettings\",\"@removeVisualization\",\"@removeVisualizationItem\",\"@sortItems\",\"@sortVisualizations\",\"@timelineNameDidChange\",\"@visibilityDidChange\",\"@visualizationNameDidChange\"],[[32,0,[\"timeline\"]],[32,0,[\"layout\"]],[30,[36,0],[[32,0],\"colorDidChange\"],null],[30,[36,0],[[32,0],\"insertVisualizationItems\"],null],[30,[36,0],[[32,0],\"settingsMenuClosed\"],null],[30,[36,0],[[32,0],\"openVisualizationSettings\"],null],[30,[36,0],[[32,0],\"removeVisualization\"],null],[30,[36,0],[[32,0],\"removeVisualizationItem\"],null],[30,[36,0],[[32,0],\"sortItems\"],null],[30,[36,0],[[32,0],\"sortVisualizations\"],null],[30,[36,0],[[32,0],\"timelineNameDidChange\"],null],[30,[36,0],[[32,0],\"visibilityDidChange\"],null],[30,[36,0],[[32,0],\"visualizationNameDidChange\"],null]]],null]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "clarify/templates/organizations/show/timelines/show/settings/index.hbs"
    }
  });

  _exports.default = _default;
});