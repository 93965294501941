define("clarify/utilities/compact-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = compactMap;

  function compactMap(array, callback) {
    let data = new Array();
    let count = 0;
    array.forEach((item, index, array) => {
      let transformed = callback(item, index, array);

      if (transformed) {
        count = count + 1;
        data.push(transformed);
      }
    });
    return data;
  }
});