define("clarify/helpers/render-content", ["exports", "clarify/utilities/content-reference-formatter", "clarify/utilities/content-reference-parser"], function (_exports, _contentReferenceFormatter, _contentReferenceParser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.renderContent = renderContent;
  _exports.default = void 0;

  function renderContent([content, contentReferences]) {
    let tree = (0, _contentReferenceParser.createTree)(content, contentReferences);
    return Ember.String.htmlSafe((0, _contentReferenceFormatter.toHTML)(tree));
  }

  var _default = Ember.Helper.helper(renderContent);

  _exports.default = _default;
});