define("clarify/components/routes/organizations/new/index", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ember-concurrency-ts", "form/fields/-form", "form/fields/input", "clarify/utilities/rpc/organization/new", "clarify/environment"], function (_exports, _component, _emberConcurrencyDecorators, _emberConcurrencyTs, _form, _input, _new, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.OrganizationCreateForm = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (eq @step "organization")}}
    <OnboardingLayout>
      <Routes::Organizations::New::-organization @onSubmit={{fn this.onSubmit}} @form={{this.form.fields.organization}} />
    </OnboardingLayout>
  {{else if (eq @step "demo-data")}}
    <OnboardingLayout data-size="extra-wide">
      <Routes::Organizations::New::-demoData
        @datasets={{@datasets}}
        @isLoading={{this.createOrganization.isRunning}}
        @form={{this.form}}
        @onSubmit={{fn this.submitForm}}
      />
    </OnboardingLayout>
  {{/if}}
  */
  {"id":"IdF/H03T","block":"{\"symbols\":[\"@datasets\",\"@step\"],\"statements\":[[6,[37,2],[[30,[36,1],[[32,2],\"organization\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"onboarding-layout\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"routes/organizations/new/-organization\",[],[[\"@onSubmit\",\"@form\"],[[30,[36,0],[[32,0,[\"onSubmit\"]]],null],[32,0,[\"form\",\"fields\",\"organization\"]]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[30,[36,1],[[32,2],\"demo-data\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"onboarding-layout\",[[24,\"data-size\",\"extra-wide\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"routes/organizations/new/-demo-data\",[],[[\"@datasets\",\"@isLoading\",\"@form\",\"@onSubmit\"],[[32,1],[32,0,[\"createOrganization\",\"isRunning\"]],[32,0,[\"form\"]],[30,[36,0],[[32,0,[\"submitForm\"]]],null]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"fn\",\"eq\",\"if\"]}","meta":{"moduleName":"clarify/components/routes/organizations/new/index.hbs"}});

  let OrganizationCreateForm = _form.default.define({
    organization: _form.default.define({
      name: _input.InputField.required()
    }),
    demoData: _input.InputField.required()
  });

  _exports.OrganizationCreateForm = OrganizationCreateForm;
  let OrganizationNewModal = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember._action, _dec9 = Ember._action, (_class = (_temp = class OrganizationNewModal extends _component.default {
    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "alert", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _initializerDefineProperty(this, "network", _descriptor4, this);

      _initializerDefineProperty(this, "analytics", _descriptor5, this);

      _initializerDefineProperty(this, "authentication", _descriptor6, this);

      _initializerDefineProperty(this, "intl", _descriptor7, this);

      _defineProperty(this, "form", new OrganizationCreateForm());

      let dataset = args.datasets.find(dataset => Ember.get(dataset, 'annotations')['clarify/industry-type'] === 'other');

      if (dataset) {
        this.form.populate({
          demoData: dataset.id
        });
      }
    }

    *createOrganization(entry) {
      try {
        let request = _new.OrganizationNewRequestInfo.create({
          name: entry.organization.name,
          price: this.args.price || _environment.default.APP.DEFAULT_STRIPE_PRICE,
          dataset: entry.demoData
        });

        let [result] = yield this.network.rpc('meta/rpc', {}, request);

        if (result.type === 'result') {
          yield this.authentication.checkSession(true);
          let campaign = this.analytics.campaign || this.analytics.popCampaign();

          if (campaign) {
            this.analytics.storeCampaign(campaign);
          }

          this.router.transitionTo('organizations.show.creating', result.result.organization.id, {
            queryParams: campaign ? {
              utm_source: campaign.source,
              utm_campaign: campaign.campaign,
              utm_medium: campaign.medium,
              utm_content: campaign.content,
              utm_term: campaign.term
            } : undefined
          });
        } else {
          throw result.error;
        }
      } catch (error) {
        console.error('Failed creating record!', error);
        this.alert.show({
          title: this.intl.t('organizations-new.errors.submit.title'),
          message: this.intl.t('organizations-new.errors.submit.message'),
          actions: [{
            title: this.intl.t('organizations-new.errors.submit.dismiss'),
            defaultButton: true,
            action: () => {
              this.alert.dismissAlert();
            }
          }]
        });
      }

      return undefined;
    }

    submitForm(event) {
      event.preventDefault();
      let data = this.form.serialize();

      if (data.type === 'success') {
        (0, _emberConcurrencyTs.taskFor)(this.createOrganization).perform(data.value);
      }
    }

    onSubmit(event) {
      event.preventDefault();
      let data = this.form.fields.organization.serialize();

      if (data.type === 'success') {
        this.router.transitionTo('organizations.new', {
          queryParams: {
            step: 'demo-data'
          }
        });
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "alert", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "network", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "analytics", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "authentication", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "createOrganization", [_emberConcurrencyDecorators.enqueueTask], Object.getOwnPropertyDescriptor(_class.prototype, "createOrganization"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "submitForm", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "submitForm"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSubmit", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmit"), _class.prototype)), _class));
  _exports.default = OrganizationNewModal;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, OrganizationNewModal);
});