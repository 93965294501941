define("clarify/controllers/organizations/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.OrganizationNewStep = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  let OrganizationNewStep;
  _exports.OrganizationNewStep = OrganizationNewStep;

  (function (OrganizationNewStep) {
    OrganizationNewStep["organization"] = "organization";
    OrganizationNewStep["demoData"] = "demo-data";
  })(OrganizationNewStep || (_exports.OrganizationNewStep = OrganizationNewStep = {}));

  class OrganizationNewController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", ['step', 'price']);

      _defineProperty(this, "step", OrganizationNewStep.organization);

      _defineProperty(this, "price", undefined);

      _defineProperty(this, "datasets", void 0);
    }

  }

  _exports.default = OrganizationNewController;
});