define("clarify/modifiers/scroll-into-view", ["exports", "ember-modifier", "scroll-into-view-if-needed"], function (_exports, _emberModifier, _scrollIntoViewIfNeeded) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _emberModifier.modifier)((element, [shouldScrollIntoView], hash) => {
    if (shouldScrollIntoView) {
      (0, _scrollIntoViewIfNeeded.default)(element, hash);
    }
  });

  _exports.default = _default;
});