define("clarify/templates/callback-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tVinUSSW",
    "block": "{\"symbols\":[],\"statements\":[[8,\"app-header\",[],[[\"@style\"],[\"dark\"]],null],[2,\" \"],[8,\"error\",[],[[\"@title\",\"@description\"],[[30,[36,0],[\"callback.title\"],null],[30,[36,1],[[32,0,[\"model\"]]],null]]],null]],\"hasEval\":false,\"upvars\":[\"t\",\"localizable-error-description\"]}",
    "meta": {
      "moduleName": "clarify/templates/callback-error.hbs"
    }
  });

  _exports.default = _default;
});