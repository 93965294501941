define("clarify/templates/invitations/show-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F/AJjb1J",
    "block": "{\"symbols\":[],\"statements\":[[8,\"app-header\",[],[[\"@style\"],[\"dark\"]],null],[2,\" \"],[8,\"error\",[],[[\"@title\",\"@description\"],[[30,[36,0],[\"invitations-show-error.title\"],[[\"name\",\"type\"],[[32,0,[\"model\",\"name\"]],[32,0,[\"model\",\"type\"]]]]],[30,[36,0],[\"invitations-show-error.description\"],[[\"name\",\"type\"],[[32,0,[\"model\",\"name\"]],[32,0,[\"model\",\"type\"]]]]]]],null]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "clarify/templates/invitations/show-error.hbs"
    }
  });

  _exports.default = _default;
});