define("clarify/components/timeline-canvas/present-timestamp-ruler/index", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="present-timestamp-ruler" {{style this.style}}></div>
  */
  {"id":"rtdUxQnv","block":"{\"symbols\":[],\"statements\":[[11,\"div\"],[24,0,\"present-timestamp-ruler\"],[4,[38,0],[[32,0,[\"style\"]]],null],[12],[13]],\"hasEval\":false,\"upvars\":[\"style\"]}","meta":{"moduleName":"clarify/components/timeline-canvas/present-timestamp-ruler/index.hbs"}});

  class PresentTimestampRuler extends _component.default {
    get style() {
      let offset = this.args.scale(this.args.now);

      if (offset > this.args.width) {
        return {
          display: 'none'
        };
      }

      return {
        width: `${Math.min(this.args.width, this.args.width - offset)}px`,
        transform: `translateX(${Math.max(0, offset)}px)`,
        display: 'block'
      };
    }

  }

  _exports.default = PresentTimestampRuler;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PresentTimestampRuler);
});