define("clarify/components/timeline-filter/empty-filter-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <section class="timelines-content-empty-filter">
    <i class="timelines-content-empty-filter-icon">
      {{svg-jar "#design-system-search-64" role="image" width=64 height=64}}
    </i>
    <h3 class="timelines-content-empty-title">
      {{@title}}
    </h3>
  </section>
  */
  {"id":"Sy9/c52d","block":"{\"symbols\":[\"@title\"],\"statements\":[[10,\"section\"],[14,0,\"timelines-content-empty-filter\"],[12],[2,\"\\n  \"],[10,\"i\"],[14,0,\"timelines-content-empty-filter-icon\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"#design-system-search-64\"],[[\"role\",\"width\",\"height\"],[\"image\",64,64]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"h3\"],[14,0,\"timelines-content-empty-title\"],[12],[2,\"\\n    \"],[1,[32,1]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"svg-jar\"]}","meta":{"moduleName":"clarify/components/timeline-filter/empty-filter-state.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});