define("clarify/locales/nb-no", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    presentTime: {
      timestamp: 'H:mm',
      date: 'do LLL'
    },
    ruler: {
      millisecond: 'H:mm:ss.SSS',
      seconds: 'H:mm:ss',
      minutes: 'H:mm',
      datetime: 'do LLL H:mm',
      date: 'do LLL'
    },
    threads: {
      header: 'LLLL do, yyyy – HH:mm',
      dateHeader: 'iiii, LLLL do, yyyy',
      avatarHeader: 'HH:mm'
    },
    timeRangeTooltip: {
      date: 'iii LLL d, yyyy',
      time: 'HH:mm',
      timeWithSeconds: 'HH:mm:ss'
    },
    ticks: {
      time: {
        full: 'HH:mm:ss',
        short: 'HH:mm'
      },
      date: {
        full: 'EEE d LLL yyyy',
        medium: 'EEE d LLL',
        short: 'd LLL'
      },
      tokens: {
        month: 'LLLL',
        shortMonth: 'LLL',
        year: 'yyyy'
      }
    },
    numbers: {
      decimalPoint: ',',
      groupIndicator: ' '
    }
  };
  _exports.default = _default;
});