define("clarify/components/m-visualization-settings/menu/type-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{svg-jar @iconName class="m-visualization-settings-display-type-control-item-icon" width=24 height=24}}
  <div class="m-visualization-settings-control-display-type-title">
    {{@title}}
  </div>
  */
  {"id":"9PeS9lkP","block":"{\"symbols\":[\"@iconName\",\"@title\"],\"statements\":[[1,[30,[36,0],[[32,1]],[[\"class\",\"width\",\"height\"],[\"m-visualization-settings-display-type-control-item-icon\",24,24]]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"m-visualization-settings-control-display-type-title\"],[12],[2,\"\\n  \"],[1,[32,2]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"svg-jar\"]}","meta":{"moduleName":"clarify/components/m-visualization-settings/menu/type-content.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});