define("clarify/serializers/thread", ["exports", "clarify/serializers/rest-layer"], function (_exports, _restLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Thread extends _restLayer.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "root", 'threads');

      _defineProperty(this, "attrs", {
        comments: {
          deserialize: 'records'
        },
        comment: {
          deserialize: 'records'
        }
      });
    }

    normalize(modelClass, resourceHash, prop) {
      const payload = { ...resourceHash,
        comment: resourceHash.comment && resourceHash.comment[0] || null,
        comments: resourceHash.comments || []
      };
      return super.normalize(modelClass, payload, prop);
    }

    serialize(snapshot, options) {
      const payload = super.serialize(snapshot, options);
      return {
        timeline: payload.timeline,
        timestamp: payload.timestamp,
        organization: payload.organization
      };
    }

  }

  _exports.default = Thread;
});