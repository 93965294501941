define("clarify/components/m-user-tag/-private", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="m-user-tag-default" ...attributes>
    <div class="m-user-tag-content">
      <Avatar src={{@icon}} width="26" height="26" />
      <div class="m-user-tag-p-user-info">
        {{@user}}
      </div>
      <button class="m-user-tag-icon-p-remove-cross" {{on "click" (prevent-default (stop-propagation @onRemove))}}>
        {{svg-jar "#design-system-p-remove-cross-18" width=18 height=18}}
      </button>
    </div>
  </div>
  */
  {"id":"zn9XDSFA","block":"{\"symbols\":[\"&attrs\",\"@icon\",\"@user\",\"@onRemove\"],\"statements\":[[11,\"div\"],[24,0,\"m-user-tag-default\"],[17,1],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"m-user-tag-content\"],[12],[2,\"\\n    \"],[8,\"avatar\",[[16,\"src\",[32,2]],[24,\"width\",\"26\"],[24,\"height\",\"26\"]],[[],[]],null],[2,\"\\n    \"],[10,\"div\"],[14,0,\"m-user-tag-p-user-info\"],[12],[2,\"\\n      \"],[1,[32,3]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[11,\"button\"],[24,0,\"m-user-tag-icon-p-remove-cross\"],[4,[38,2],[\"click\",[30,[36,1],[[30,[36,0],[[32,4]],null]],null]],null],[12],[2,\"\\n      \"],[1,[30,[36,3],[\"#design-system-p-remove-cross-18\"],[[\"width\",\"height\"],[18,18]]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"stop-propagation\",\"prevent-default\",\"on\",\"svg-jar\"]}","meta":{"moduleName":"clarify/components/m-user-tag/-private.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});