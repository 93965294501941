define("clarify/components/timeline-canvas/visualizations/layers/line-charts", ["exports", "canvas/components/layer", "d3-scale", "clarify/utilities/bisect", "d3-shape", "clarify/colors"], function (_exports, _layer, _d3Scale, _bisect, _d3Shape, _colors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.between = between;
  _exports.isVisible = isVisible;
  _exports.default = void 0;
  const PADDING_INSET = {
    top: 8.5,
    bottom: 8.5
  };

  class LineChart extends _layer.default {
    get rangeY() {
      return (0, _d3Scale.scaleLinear)().domain([this.args.rangeMin, this.args.rangeMax]).range([this.args.height - PADDING_INSET.bottom, PADDING_INSET.top]);
    }

    render() {
      let rangeY = this.rangeY;
      let rangeX = (0, _d3Scale.scaleLinear)().domain([this.args.from.getTime() * 1000, this.args.to.getTime() * 1000]).range([0, this.args.width]);
      let context = this.context;
      this.paintDotBackgrounds(context, rangeX, rangeY);
      this.paintLineChart(context, rangeX, rangeY);
      this.paintDots(context, rangeX, rangeY);
    }

    paintLineChart(context, rangeX, rangeY) {
      let lineGenerator = (0, _d3Shape.line)().context(context).x(d => rangeX(d[0])).y(d => rangeY(d[1]));
      this.args.segments.forEach(plot => {
        if (plot.visible === false) {
          return;
        }

        if (plot.dataPoints && plot.dataPoints.length > 0) {
          let subSegments = [];

          if (this.args.timeRange) {
            let gratestFrom = Math.max(this.args.timeRange.from.getTime(), this.args.from.getTime());
            let smallestTo = Math.min(this.args.timeRange.to.getTime(), this.args.to.getTime());
            let fadedRanges = [[this.args.from.getTime(), gratestFrom], [smallestTo, this.args.to.getTime()]];
            fadedRanges.forEach(([from, to]) => {
              let first = plot.dataPoints[0][0];
              let last = plot.dataPoints[plot.dataPoints.length - 1][0];

              if (first > to * 1000 || last < from * 1000) {
                return;
              }

              let dataPoints = (0, _bisect.limit)(plot.dataPoints, dataPoint => dataPoint[0], from * 1000, to * 1000);
              subSegments.push({
                color: _colors.darkGraphColors.get(plot.color),
                dataPoints: dataPoints
              });
            });

            if (gratestFrom >= this.args.from.getTime() || smallestTo <= this.args.to.getTime()) {
              let dataPoints = (0, _bisect.limit)(plot.dataPoints, dataPoint => dataPoint[0], gratestFrom * 1000, smallestTo * 1000, _bisect.LimitCap.noCap);
              subSegments.push({
                color: _colors.graphColors.get(plot.color),
                dataPoints: dataPoints
              });
            }
          } else {
            subSegments.push({
              color: _colors.graphColors.get(plot.color),
              dataPoints: (0, _bisect.limit)(plot.dataPoints, dataPoint => dataPoint[0], this.args.from.getTime() * 1000, this.args.to.getTime() * 1000)
            });
          }

          context.save();

          for (let value of subSegments) {
            if (value.dataPoints.length === 1) {
              let point = value.dataPoints[0];
              let x = rangeX(point[0]);
              let y = rangeY(point[1]);
              context.beginPath();
              context.arc(x, y, 4, 0, Math.PI * 2);
              context.fillStyle = value.color;
              context.fill();
            } else if (value.dataPoints.length > 1) {
              context.beginPath();
              lineGenerator(value.dataPoints);
              context.lineWidth = 2;
              context.strokeStyle = value.color;
              context.stroke();
            }
          }

          context.restore();

          if (plot.filtered === false) {
            context.save();

            for (let value of subSegments) {
              if (value.dataPoints.length > 1) {
                this.paintUnfilteredDots(context, value.dataPoints, rangeX, rangeY, value.color);
              }
            }

            context.restore();
          }
        }
      });
    }

    paintDotBackgrounds(context, rangeX, rangeY) {
      this.args.dots.forEach(dot => {
        let [xValue, yValue] = dot.dataPoint;
        let x = rangeX(xValue);
        let y = rangeY(yValue);
        let inTimeRange = !this.args.timeRange || between(this.args.timeRange, xValue / 1000);
        context.beginPath();
        context.arc(x, y, 8, 0, Math.PI * 2);
        context.fillStyle = inTimeRange ? _colors.tooltip.get(dot.plot.color) : _colors.tooltipDark.get(dot.plot.color);
        context.fill();
      });
    }

    paintDots(context, rangeX, rangeY) {
      this.args.dots.forEach(dot => {
        let [xValue, yValue] = dot.dataPoint;
        let x = rangeX(xValue);
        let y = rangeY(yValue);
        let inTimeRange = !this.args.timeRange || between(this.args.timeRange, xValue / 1000);
        context.beginPath();
        context.arc(x, y, 4, 0, Math.PI * 2);

        if (inTimeRange) {
          context.fillStyle = _colors.graphColors.get(dot.plot.color);
        } else {
          context.fillStyle = _colors.darkGraphColors.get(dot.plot.color);
        }

        context.fill();
      });
    }

    paintUnfilteredDots(context, dataPoints, rangeX, rangeY, fill) {
      let lastX = null;
      let values = [];

      for (let [xValue, yValue] of dataPoints) {
        let x = rangeX(xValue);
        let y = rangeY(yValue);

        if (lastX && x - lastX < 64) {
          return;
        }

        lastX = x;
        values.push({
          x,
          y
        });
      }

      values.forEach(({
        x,
        y
      }) => {
        context.beginPath();
        context.fillStyle = fill;
        context.arc(x, y, 4, 0, Math.PI * 2);
        context.fill();
      });
    }

  }

  _exports.default = LineChart;

  function between(timeRange, position) {
    return position >= timeRange.from.valueOf() && position <= timeRange.to.valueOf();
  }

  function isVisible(viewport, element) {
    return viewport.from.getTime() <= element.to.getTime() && viewport.to.getTime() >= element.from.getTime();
  }
});