define("clarify/components/timeline-canvas-header/sharing-info/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <ul class="sharing-info" ...attributes>
    <li class="sharing-info-entry">
      {{svg-jar "#design-system-p-share-shared-12" width=12 height=12}}
      <span class="sharing-info-entry-text">
        {{#if (eq @timeline.mostSignificantPermissionType "timelines:guest")}}
          {{t "sharing-info.timeline.guest"}}
        {{else}}
          {{t "sharing-info.timeline.collaborator"}}
        {{/if}}
      </span>
    </li>
    <li class="sharing-info-entry">
      {{#if @timeline.isPrivate}}
        {{svg-jar "#design-system-p-share-personal-12" width=12 height=12}}
        <span class="sharing-info-entry-text">
          {{t "sharing-info.user.private"}}
        </span>
      {{else}}
        {{svg-jar "#design-system-p-share-shared-12" width=12 height=12}}
        <span class="sharing-info-entry-text">
          {{t "sharing-info.user.shared"}}
        </span>
      {{/if}}
    </li>
  </ul>
  */
  {"id":"cw9ZXG0W","block":"{\"symbols\":[\"&attrs\",\"@timeline\"],\"statements\":[[11,\"ul\"],[24,0,\"sharing-info\"],[17,1],[12],[2,\"\\n  \"],[10,\"li\"],[14,0,\"sharing-info-entry\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"#design-system-p-share-shared-12\"],[[\"width\",\"height\"],[12,12]]]],[2,\"\\n    \"],[10,\"span\"],[14,0,\"sharing-info-entry-text\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[32,2,[\"mostSignificantPermissionType\"]],\"timelines:guest\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,1],[\"sharing-info.timeline.guest\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[30,[36,1],[\"sharing-info.timeline.collaborator\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"li\"],[14,0,\"sharing-info-entry\"],[12],[2,\"\\n\"],[6,[37,3],[[32,2,[\"isPrivate\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,0],[\"#design-system-p-share-personal-12\"],[[\"width\",\"height\"],[12,12]]]],[2,\"\\n      \"],[10,\"span\"],[14,0,\"sharing-info-entry-text\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[\"sharing-info.user.private\"],null]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[30,[36,0],[\"#design-system-p-share-shared-12\"],[[\"width\",\"height\"],[12,12]]]],[2,\"\\n      \"],[10,\"span\"],[14,0,\"sharing-info-entry-text\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[\"sharing-info.user.shared\"],null]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"svg-jar\",\"t\",\"eq\",\"if\"]}","meta":{"moduleName":"clarify/components/timeline-canvas-header/sharing-info/index.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});