define("clarify/transitions/slide-in", ["exports", "liquid-fire"], function (_exports, _liquidFire) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = slideIn;

  function slideIn(direction, opts) {
    let newElements = this.newElement ? this.newElement.toArray() : [];
    newElements.forEach(element => {
      element.style.zIndex = '1000';
      element.style.left = 'inherit';
      element.style.right = '0';
    });
    let oldElements = this.oldElement ? this.oldElement.toArray() : [];
    oldElements.forEach(element => {
      element.style.zIndex = '900';
      element.style.left = 'inherit';
      element.style.right = '0';
    });
    let newParams = {
      translateX: ['0%', -1 * 100 * direction + '%']
    };
    return (0, _liquidFire.animate)(this.newElement, newParams, opts, 'sliding-over');
  }
});