define("clarify/routes/organizations/show/timelines/show/visualizations/show", ["exports", "clarify/models/permission", "clarify/errors/route"], function (_exports, _permission, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let TimelinesShowVisualizationsShow = (_dec = Ember._action, (_class = class TimelinesShowVisualizationsShow extends Ember.Route {
    model(params) {
      let model = this.modelFor('organizations/show/timelines/show');

      if (model.timeline.mostSignificantPermissionType === _permission.PermissionType.guest) {
        return Promise.reject(new _route.default(_route.RouteErrorType.noAccess));
      }

      let visualization = model.layout.visualizations.find(visualization => {
        return visualization.id === params.visualizationId;
      });

      if (visualization) {
        return Promise.resolve({
          visualization,
          layout: model.layout
        });
      }

      return Promise.reject(new _route.default(_route.RouteErrorType.notFound));
    }

    loading() {
      return false;
    }

    setupController(controller, model) {
      Ember.set(controller, 'visualization', model.visualization);
      Ember.set(controller, 'layout', model.layout);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "loading", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "loading"), _class.prototype)), _class));
  _exports.default = TimelinesShowVisualizationsShow;
});