define("clarify/controllers/organizations/show/creating", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class TimelinesShowCreating extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", [{
        source: {
          as: 'utm_source'
        },
        campaign: {
          as: 'utm_campaign'
        },
        medium: {
          as: 'utm_medium'
        },
        term: {
          as: 'utm_term'
        },
        content: {
          as: 'utm_content'
        }
      }]);

      _defineProperty(this, "source", void 0);

      _defineProperty(this, "campaign", void 0);

      _defineProperty(this, "medium", void 0);

      _defineProperty(this, "term", void 0);

      _defineProperty(this, "content", void 0);
    }

  }

  _exports.default = TimelinesShowCreating;
});