define("clarify/routes/redirect-timelines-show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TimelinesShow extends Ember.Route {
    model(params) {
      return this.store.findRecord('timeline', params.id);
    }

    redirect(timeline) {
      let organization = timeline.belongsTo('organization');
      this.transitionTo('organizations.show.timelines.show', organization.id(), timeline.id);
    }

  }

  _exports.default = TimelinesShow;
});