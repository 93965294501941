define("clarify/routes/index", ["exports", "clarify/routes/protected"], function (_exports, _protected) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Index extends _protected.default {
    model() {
      return this.store.findAll('organization');
    }

    redirect(models) {
      if (models.length) {
        this.transitionTo('organizations.show.timelines.index', models.toArray()[0].id);
      } else {
        this.transitionTo('organizations.new');
      }

      throw new Error('User doesn’t belong to any organization');
    }

  }

  _exports.default = Index;
});