define("clarify/helpers/uuid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let identifier = 0;

  var _default = Ember.Helper.helper(() => {
    return `clarify-${++identifier}`;
  });

  _exports.default = _default;
});