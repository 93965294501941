define("clarify/components/timeline-canvas-legend-settings/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="timeline-canvas-legend-settings">
    {{#if (has-block "header")}}
      <div class="timeline-canvas-legend-settings--header">
        <header class="timeline-canvas-legend-settings--title">
          {{@title}}
        </header>
        <div class="timeline-canvas-legend-settings--actions">
          {{yield to="header"}}
        </div>
      </div>
    {{/if}}
    <div class="timeline-canvas-legend-settings--content">
      {{yield to="content"}}
    </div>
  </div>
  */
  {"id":"+uKCzRVP","block":"{\"symbols\":[\"@title\",\"&default\",\"@namedBlocksInfo\"],\"statements\":[[10,\"div\"],[14,0,\"timeline-canvas-legend-settings\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,3],\"header\",false],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"timeline-canvas-legend-settings--header\"],[12],[2,\"\\n      \"],[10,\"header\"],[14,0,\"timeline-canvas-legend-settings--title\"],[12],[2,\"\\n        \"],[1,[32,1]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"timeline-canvas-legend-settings--actions\"],[12],[2,\"\\n        \"],[18,2,[[30,[36,0],[\"header\"],null]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"div\"],[14,0,\"timeline-canvas-legend-settings--content\"],[12],[2,\"\\n    \"],[18,2,[[30,[36,0],[\"content\"],null]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"-named-block-invocation\",\"-has-block\",\"if\"]}","meta":{"moduleName":"clarify/components/timeline-canvas-legend-settings/index.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});