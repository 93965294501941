define("clarify/components/organizations/show/items-quest/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#each @items as |item|}}
    <LinkTo class="items-quest-list-link" @route={{item-modal}} @model={{item.id}}>
      <AItemCard class="m-item-card-container" @item={{item}} />
    </LinkTo>
  {{/each}}
  */
  {"id":"Yg3XPQwP","block":"{\"symbols\":[\"item\",\"@items\"],\"statements\":[[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"link-to\",[[24,0,\"items-quest-list-link\"]],[[\"@route\",\"@model\"],[[34,0],[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"a-item-card\",[[24,0,\"m-item-card-container\"]],[[\"@item\"],[[32,1]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"item-modal\",\"-track-array\",\"each\"]}","meta":{"moduleName":"clarify/components/organizations/show/items-quest/list.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});