define("clarify/components/settings-header/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="settings-header" data-theme={{@theme}}>
    <span class="settings-header-icon">
      {{svg-jar @icon width=20 height=20}}
    </span>
    <span class="settings-header-title">
      {{@title}}
    </span>
    <Button
      class="settings-header-hide-button"
      @rightIcon={{svg-jar "#settings-header-icon" width=20 height=20}}
      @size="normal"
      @color={{if (eq @theme "light") "blue" "white"}}
      @style="icon"
      @action={{action @onClose}}
      title={{t "timeline-settings.tooltip.close-edit"}}
    />
  </div>
  */
  {"id":"lu+/hTpA","block":"{\"symbols\":[\"@theme\",\"@icon\",\"@title\",\"@onClose\"],\"statements\":[[10,\"div\"],[14,0,\"settings-header\"],[15,\"data-theme\",[32,1]],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"settings-header-icon\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[[32,2]],[[\"width\",\"height\"],[20,20]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"span\"],[14,0,\"settings-header-title\"],[12],[2,\"\\n    \"],[1,[32,3]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[8,\"button\",[[24,0,\"settings-header-hide-button\"],[16,\"title\",[30,[36,4],[\"timeline-settings.tooltip.close-edit\"],null]]],[[\"@rightIcon\",\"@size\",\"@color\",\"@style\",\"@action\"],[[30,[36,0],[\"#settings-header-icon\"],[[\"width\",\"height\"],[20,20]]],\"normal\",[30,[36,2],[[30,[36,1],[[32,1],\"light\"],null],\"blue\",\"white\"],null],\"icon\",[30,[36,3],[[32,0],[32,4]],null]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"svg-jar\",\"eq\",\"if\",\"action\",\"t\"]}","meta":{"moduleName":"clarify/components/settings-header/index.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});