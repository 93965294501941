define("clarify/components/timeline-canvas/visualizations/comment/index", ["exports", "@glimmer/component", "d3-scale", "clarify/utilities/partition-threads", "clarify/utilities/bisect"], function (_exports, _component, _d3Scale, _partitionThreads, _bisect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <TimelineCanvas::Visualizations::Comment::Canvas
    @visibleDomainFrom={{@domain.from}}
    @visibleDomainTo={{@domain.to}}
    @loading={{this.loadingSlots}}
    @threads={{this.segmentedThreads.threads}}
    @highlightedEntity={{this.segmentedThreads.hightlighted}}
    @devicePixelRatio={{this.devicePixelRatio}}
    @width={{this.width}}
    @height={{this.height}}
    @setHighlightedThread={{action @setHighlightedThread}}
    @setPinnedThread={{action @setPinnedThread}}
    @timeRange={{@timeRange}}
    @rulers={{@rulers}}
    data-intercom-target="Comment visualization"
    {{did-update this.commentCanvasDidUpdate @slotIds @interactions}}
  />
  */
  {"id":"IwRzXzRX","block":"{\"symbols\":[\"@domain\",\"@setHighlightedThread\",\"@setPinnedThread\",\"@timeRange\",\"@rulers\",\"@interactions\",\"@slotIds\"],\"statements\":[[8,\"timeline-canvas/visualizations/comment/canvas\",[[24,\"data-intercom-target\",\"Comment visualization\"],[4,[38,1],[[32,0,[\"commentCanvasDidUpdate\"]],[32,7],[32,6]],null]],[[\"@visibleDomainFrom\",\"@visibleDomainTo\",\"@loading\",\"@threads\",\"@highlightedEntity\",\"@devicePixelRatio\",\"@width\",\"@height\",\"@setHighlightedThread\",\"@setPinnedThread\",\"@timeRange\",\"@rulers\"],[[32,1,[\"from\"]],[32,1,[\"to\"]],[32,0,[\"loadingSlots\"]],[32,0,[\"segmentedThreads\",\"threads\"]],[32,0,[\"segmentedThreads\",\"hightlighted\"]],[32,0,[\"devicePixelRatio\"]],[32,0,[\"width\"]],[32,0,[\"height\"]],[30,[36,0],[[32,0],[32,2]],null],[30,[36,0],[[32,0],[32,3]],null],[32,4],[32,5]]],null]],\"hasEval\":false,\"upvars\":[\"action\",\"did-update\"]}","meta":{"moduleName":"clarify/components/timeline-canvas/visualizations/comment/index.hbs"}});

  let VisualizationComments = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed.alias('screen.devicePixelRatio'), _dec4 = Ember.computed.alias('screen.width'), _dec5 = Ember.computed('args.domain.{from,to}', 'width'), _dec6 = Ember.computed('args.threadClusterLimit', 'filteredThreads'), _dec7 = Ember.computed('allThreads.[]', 'allThreads.@each.{comment,isReady}', 'args.timeline', 'args.slotIds.[]'), _dec8 = Ember.computed('args.ruler', 'threads'), _dec9 = Ember.computed('slotIds.[]', 'slots', 'loadedSlots'), _dec10 = Ember.computed(), _dec11 = Ember._action, (_class = (_temp = class VisualizationComments extends _component.default {
    // Services
    // Computed properties
    get rangeX() {
      return (0, _d3Scale.scaleTime)().domain([this.args.domain.from, this.args.domain.to]).range([0, this.width]);
    }

    get threads() {
      return (0, _partitionThreads.partitionThreads)(this.filteredThreads, this.args.threadClusterLimit);
    }

    get filteredThreads() {
      if (this.args.slotIds.length) {
        let {
          from
        } = this.args.slotIds[0];
        let {
          to
        } = this.args.slotIds[this.args.slotIds.length - 1];
        return this.allThreads.filter(thread => {
          if (thread.comment && thread.timeline === this.args.timeline) {
            return thread.isReady && thread.timestamp > from && thread.timestamp < to;
          }

          return false;
        }).sortBy('timestamp');
      }

      return [];
    }

    get segmentedThreads() {
      if (this.args.ruler) {
        let index = (0, _bisect.closestDataPointWithAccessor)(this.threads.comments, comment => comment.thread.timestamp.getTime(), this.args.ruler.date.getTime());

        if (index != null) {
          let hightlighted = this.threads.comments[index];
          let rulerPosition = this.rangeX(this.args.ruler.date);
          let comment = this.rangeX(hightlighted.thread.timestamp);
          let distance = Math.abs(rulerPosition - comment);

          if (distance < 60) {
            let threads = [...this.threads.comments.slice(0, index), ...this.threads.comments.slice(index + 1), ...this.threads.dots, ...this.threads.aggregated];
            return {
              threads,
              hightlighted: {
                threadEntry: this.threads.comments[index],
                style: this.args.ruler.type
              }
            };
          }
        }
      }

      return {
        threads: [...this.threads.comments, ...this.threads.dots, ...this.threads.aggregated],
        hightlighted: null
      };
    }

    get loadingSlots() {
      if (this.slots.state === 'loading') {
        return this.args.slotIds.filter(slot => {
          return !this.loadedSlots.find(loadedSlot => loadedSlot.isEqual(slot));
        });
      }

      return [];
    } // Properties


    get allThreads() {
      return this.store.peekAll('thread');
    }

    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "screen", _descriptor2, this);

      _initializerDefineProperty(this, "devicePixelRatio", _descriptor3, this);

      _initializerDefineProperty(this, "width", _descriptor4, this);

      _defineProperty(this, "height", 80);

      _defineProperty(this, "slots", {
        state: 'uninitialized'
      });

      _defineProperty(this, "loadedSlots", []);

      _defineProperty(this, "range", {
        from: 0,
        to: 0
      });

      this.fetchData();
    }

    commentCanvasDidUpdate() {
      let hasInteractions = this.args.interactions.size !== 0;

      if (this.args.slotIds.length) {
        let {
          from
        } = this.args.slotIds[0];
        let {
          to
        } = this.args.slotIds[this.args.slotIds.length - 1];

        if (this.range.from !== from.valueOf() || this.range.to !== to.valueOf()) {
          if (hasInteractions === false) {
            this.range = {
              from: from.valueOf(),
              to: to.valueOf()
            };
            this.fetchData();
          } else {
            this.abort();
          }
        }
      }
    }

    async fetchData() {
      let abortController = new AbortController();
      Ember.set(this, 'slots', {
        state: 'loading',
        abortController
      });
      let slots = [...this.args.slotIds];

      try {
        let {
          from
        } = this.args.slotIds[0];
        let {
          to
        } = this.args.slotIds[this.args.slotIds.length - 1];
        await this.store.query('thread', {
          limit: 1000,
          filter: {
            $and: [{
              timeline: this.args.timeline.id
            }, {
              timestamp: {
                $gte: from.toISOString()
              }
            }, {
              timestamp: {
                $lt: to.toISOString()
              }
            }]
          }
        });
        Ember.set(this, 'loadedSlots', slots);
        Ember.set(this, 'slots', {
          state: 'loaded'
        });
      } catch (error) {
        switch (error.name) {
          case 'AbortError':
            break;

          default:
            Ember.set(this, 'slots', {
              state: 'failed',
              error,
              slots
            });
            break;
        }
      }
    }

    abort() {
      if (this.slots.state === 'loading') {
        this.slots.abortController.abort();
        Ember.set(this, 'slots', this.loadedSlots.length ? {
          state: 'loaded'
        } : {
          state: 'uninitialized'
        });
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "screen", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "devicePixelRatio", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "width", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "rangeX", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "rangeX"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "threads", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "threads"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filteredThreads", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "filteredThreads"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "segmentedThreads", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "segmentedThreads"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadingSlots", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "loadingSlots"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "allThreads", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "allThreads"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "commentCanvasDidUpdate", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "commentCanvasDidUpdate"), _class.prototype)), _class));
  _exports.default = VisualizationComments;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, VisualizationComments);
});