define("clarify/helpers/format-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const defaultNumberFormatter = new Intl.NumberFormat([], {
    maximumFractionDigits: 2
  });

  var _default = Ember.Helper.helper((_params, hash) => {
    if (hash.value == null) {
      return hash.emptyString || '';
    }

    let precision = hash.item.displayOptions.precision;
    let formatter = defaultNumberFormatter;
    let showEnumValue = hash.showEnumValue != null ? hash.showEnumValue : false;

    if (showEnumValue && hash.item.type === 'enum' && hash.item.enumValues) {
      return hash.item.enumValues[`${hash.value}`];
    }

    if (precision !== null && precision !== undefined) {
      formatter = new Intl.NumberFormat([], {
        maximumFractionDigits: precision
      });
    }

    let formattedValue = formatter.format(hash.value);

    if (hash.includeEngUnit) {
      return `${formattedValue} ${hash.item.engUnit}`;
    }

    return formattedValue;
  });

  _exports.default = _default;
});