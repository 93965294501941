define("clarify/templates/application-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GMlsilqy",
    "block": "{\"symbols\":[],\"statements\":[[8,\"loading\",[[24,\"data-without-app-header\",\"\"]],[[\"@title\"],[[30,[36,0],[\"application-loading.loading\"],null]]],null]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "clarify/templates/application-loading.hbs"
    }
  });

  _exports.default = _default;
});