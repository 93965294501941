define("clarify/components/a-privacy/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="a-privacy" ...attributes>
    <span class="a-privacy-title">
      {{#if @timeline.isPublic}}
        {{t "privacy.public" organizationName=@timeline.organization.name}}
      {{else if @timeline.isPrivate}}
        {{t "privacy.private"}}
      {{else}}
        {{t "privacy.mixed" users=@timeline.members.length}}
      {{/if}}
    </span>
  </div>
  */
  {"id":"WbEDKRec","block":"{\"symbols\":[\"@timeline\",\"&attrs\"],\"statements\":[[11,\"div\"],[24,0,\"a-privacy\"],[17,2],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"a-privacy-title\"],[12],[2,\"\\n\"],[6,[37,1],[[32,1,[\"isPublic\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,0],[\"privacy.public\"],[[\"organizationName\"],[[32,1,[\"organization\",\"name\"]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[32,1,[\"isPrivate\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,0],[\"privacy.private\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[30,[36,0],[\"privacy.mixed\"],[[\"users\"],[[32,1,[\"members\",\"length\"]]]]]],[2,\"\\n    \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"if\"]}","meta":{"moduleName":"clarify/components/a-privacy/index.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});