define("clarify/components/keyword-filter/category", ["exports", "@glimmer/component", "clarify/utilities/create-tree"], function (_exports, _component, _createTree) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <h3 class="keyword-filter-category-header">
    {{@category.name}}
  </h3>
  <ul class="keyword-filter-items">
    {{#each this.treeItems key="id" as |item|}}
      <KeywordFilter::Item @item={{item}} @isEnabled={{fn @isEnabled}} @setEnabled={{fn @setEnabled}} />
    {{else}}
      <li class="keyword-filter-empty">
        {{svg-jar "#icon-label-24" width=24 height=24}}
        <span class="keyword-filter-empty--text">
          {{t "keyword-filter.empty" type=@category.type}}
        </span>
      </li>
    {{/each}}
  </ul>
  */
  {"id":"fZMSE1y6","block":"{\"symbols\":[\"item\",\"@category\",\"@isEnabled\",\"@setEnabled\"],\"statements\":[[10,\"h3\"],[14,0,\"keyword-filter-category-header\"],[12],[2,\"\\n  \"],[1,[32,2,[\"name\"]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"ul\"],[14,0,\"keyword-filter-items\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,0,[\"treeItems\"]]],null]],null]],[[\"key\"],[\"id\"]],[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"keyword-filter/item\",[],[[\"@item\",\"@isEnabled\",\"@setEnabled\"],[[32,1],[30,[36,2],[[32,3]],null],[30,[36,2],[[32,4]],null]]],null],[2,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[2,\"    \"],[10,\"li\"],[14,0,\"keyword-filter-empty\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"#icon-label-24\"],[[\"width\",\"height\"],[24,24]]]],[2,\"\\n      \"],[10,\"span\"],[14,0,\"keyword-filter-empty--text\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[\"keyword-filter.empty\"],[[\"type\"],[[32,2,[\"type\"]]]]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"svg-jar\",\"t\",\"fn\",\"-track-array\",\"each\"]}","meta":{"moduleName":"clarify/components/keyword-filter/category.hbs"}});

  class CategoryComponent extends _component.default {
    get treeItems() {
      return (0, _createTree.createTree)(this.args.category.items, item => item.id, item => item.parentId);
    }

  }

  _exports.default = CategoryComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CategoryComponent);
});