define("clarify/components/timeline-canvas/visualizations/layers/time-range", ["exports", "canvas/components/layer", "ui/helpers/design-system-particles"], function (_exports, _layer, _designSystemParticles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const RULER_INSET = {
    top: 5,
    bottom: 5
  };
  const TIME_RANGE_FIXED_BORDER = _designSystemParticles.darkActionActionPrimaryBg;
  const TIME_RANGE_TEMP_BORDER = _designSystemParticles.darkNeutralTxtNeutralStrongest;

  class TimeRangeLayer extends _layer.default {
    render() {
      let height = this.args.height - RULER_INSET.top - RULER_INSET.bottom;
      let y = RULER_INSET.top;
      let context = this.context;
      let timeRange = this.args.timeRange;

      if (timeRange) {
        let from = this.args.rangeX(timeRange.from);
        let to = this.args.rangeX(timeRange.to);
        context.beginPath();
        context.fillStyle = timeRange.temporarily === 'from' || timeRange.temporarily === 'moving' ? TIME_RANGE_TEMP_BORDER : TIME_RANGE_FIXED_BORDER;
        context.rect(Math.round(from), y, 1, height);
        context.fill();
        context.beginPath();
        context.fillStyle = timeRange.temporarily === 'to' || timeRange.temporarily === 'moving' ? TIME_RANGE_TEMP_BORDER : TIME_RANGE_FIXED_BORDER;
        context.rect(Math.round(to) - 1, y, 1, height);
        context.fill();
      }
    }

  }

  _exports.default = TimeRangeLayer;
});