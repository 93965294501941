define("clarify/services/mobile-upsell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Platform = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _class2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Platform;
  _exports.Platform = Platform;

  (function (Platform) {
    Platform["iOS"] = "iOS";
    Platform["android"] = "android";
    Platform["desktop"] = "desktop";
  })(Platform || (_exports.Platform = Platform = {}));

  function getParameters(routeInfo) {
    let allParameters = [];
    let current = routeInfo;

    do {
      let {
        params,
        paramNames
      } = current;
      let currentParameters = paramNames.map(n => params[n]);
      allParameters = [...currentParameters, ...allParameters];
    } while (current = current.parent);

    return allParameters;
  }

  let MobileUpsellService = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, (_class = (_temp = _class2 = class MobileUpsellService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "dismissed", _descriptor2, this);

      _initializerDefineProperty(this, "transition", _descriptor3, this);
    }

    shouldDisplay(transition) {
      if (this.platform() === Platform.iOS && !this.dismissed) {
        var _transition$to;

        let to = (_transition$to = transition.to) === null || _transition$to === void 0 ? void 0 : _transition$to.name;
        return MobileUpsellService.mobileReadyRoutes.has(to) === false;
      }

      return false;
    }

    dismiss() {
      this.dismissed = true;
      let url = this.url();

      if (url) {
        this.router.transitionTo(url);
      }
    }

    platform() {
      let userAgent = navigator.userAgent;

      if (/iPad|iPhone|iPod/.test(userAgent)) {
        return Platform.iOS;
      }

      if (/android/i.test(userAgent)) {
        return Platform.android;
      }

      return Platform.desktop;
    }

    openApp() {
      let url = this.url();
      location.href = `clarify://${location.host}${url}`;
    }

    url() {
      let routeInfo = this.transition.to;

      if (routeInfo) {
        let {
          name,
          queryParams
        } = routeInfo;
        let orderedParameters = getParameters(routeInfo); // @ts-expect-error

        return this.router.urlFor(name, ...orderedParameters, {
          queryParams
        });
      }

      return null;
    }

  }, _defineProperty(_class2, "mobileReadyRoutes", new Set(['index', 'invitations.show', 'callback', 'login', 'logout', 'setup', 'organizations.new', 'resend', 'organizations.show.creating'])), _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dismissed", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "transition", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = MobileUpsellService;
});