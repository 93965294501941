define("clarify/initializers/quill", ["exports", "quill", "clarify/components/text-engine"], function (_exports, _quill, _textEngine) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    _quill.default.register('formats/auto-complete', _textEngine.AutoCompleteTokenModule);
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});