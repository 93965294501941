define("clarify/components/scroll-view", ["exports", "clarify/utilities/scrollable"], function (_exports, _scrollable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ScrollView = (_dec = Ember.inject.service, (_class = (_temp = class ScrollView extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "gesture", _descriptor, this);

      _defineProperty(this, "allowHorizontalScrolling", true);

      _defineProperty(this, "allowVerticalScrolling", true);

      _defineProperty(this, "handleScroll", void 0);

      _defineProperty(this, "handleEndDecelerating", void 0);

      _defineProperty(this, "handleWillBeginDragging", void 0);

      _defineProperty(this, "handleDidEndDragging", void 0);

      _defineProperty(this, "scroller", null);
    }

    didInsertElement() {
      let scroller = new _scrollable.default(this.element);

      Ember._associateDestroyableChild(this, scroller);

      scroller.delegate = this;
      this.scroller = scroller;
      this.gesture.install(this.element, scroller.gestureRecognizers);
    }

    willDestroyElement() {
      this.gesture.uninstall(this.element);
    }

    contentOffsetDidChange(contentOffset, delta) {
      if (this.allowHorizontalScrolling) {
        this.element.scrollLeft = contentOffset.x;
      }

      if (this.allowVerticalScrolling) {
        this.element.scrollTop = contentOffset.y;
      }

      if (this.handleScroll) {
        this.handleScroll(contentOffset, delta);
      }
    }

    didEndDecelerating() {
      if (this.handleEndDecelerating) {
        this.handleEndDecelerating();
      }
    }

    willBeginDragging() {
      if (this.handleWillBeginDragging) {
        this.handleWillBeginDragging();
      }
    }

    didEndDragging(willDecelerate) {
      if (this.handleDidEndDragging) {
        this.handleDidEndDragging(willDecelerate);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "gesture", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ScrollView;
});