define("clarify/utilities/rpc/organization/send-invite", ["exports", "clarify/utilities/uuid"], function (_exports, _uuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Role = _exports.SendInviteRequestInfo = void 0;

  class SendInviteRequestInfo {
    static create(parameters) {
      let id = (0, _uuid.default)();
      return {
        method: 'organization.SendInvite',
        version: '0.6',
        id,
        parameters
      };
    }

  }

  _exports.SendInviteRequestInfo = SendInviteRequestInfo;
  let Role;
  _exports.Role = Role;

  (function (Role) {
    Role["admin"] = "admin";
    Role["member"] = "member";
  })(Role || (_exports.Role = Role = {}));
});