define("clarify/serializers/organization", ["exports", "clarify/serializers/rest-layer", "clarify/environment"], function (_exports, _restLayer, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Organization extends _restLayer.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "root", 'organizations');
    }

    normalize(modelClass, resourceHash, prop) {
      let payload = super.normalize(modelClass, resourceHash, prop);
      payload.data.relationships.groups = {
        links: {
          related: `${_environment.default.APP.API_HOST}/api/meta/organizations/${payload.data.id}/groups`
        }
      };
      payload.data.relationships.users = {
        links: {
          related: `${_environment.default.APP.API_HOST}/api/meta/organizations/${payload.data.id}/users?limit=200`
        }
      };
      return payload;
    }

  }

  _exports.default = Organization;
});