define("clarify/components/visualization-item-icon/index", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (eq @visualizationItem.visualization.type "status")}}
    <VisualizationItemIcon::Status
      @visualizationItem={{@visualizationItem}}
      class="visualization-item-status-icon"
      ...attributes
    />
  {{else}}
    <div
      class="visualization-item-line-chart-icon"
      data-color={{@visualizationItem.color}}
      data-size={{this.size}}
      ...attributes
    ></div>
  {{/if}}
  */
  {"id":"7RtboEox","block":"{\"symbols\":[\"@visualizationItem\",\"&attrs\"],\"statements\":[[6,[37,1],[[30,[36,0],[[32,1,[\"visualization\",\"type\"]],\"status\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"visualization-item-icon/status\",[[24,0,\"visualization-item-status-icon\"],[17,2]],[[\"@visualizationItem\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[11,\"div\"],[24,0,\"visualization-item-line-chart-icon\"],[16,\"data-color\",[32,1,[\"color\"]]],[16,\"data-size\",[32,0,[\"size\"]]],[17,2],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\"]}","meta":{"moduleName":"clarify/components/visualization-item-icon/index.hbs"}});

  class VisualizationItemIcon extends _component.default {
    get size() {
      return this.args.size || 'normal';
    }

  }

  _exports.default = VisualizationItemIcon;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, VisualizationItemIcon);
});