define("clarify/adapters/item", ["exports", "clarify/adapters/meta"], function (_exports, _meta) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const defaultLimit = 200;

  class Item extends _meta.default {
    fields(_requestType) {
      return ['createdAt', 'createdBy', 'deltaInterval', 'description', 'engUnit', 'enumValues', 'id', 'labels', 'name', 'visible', 'type', 'permissions', 'updatedAt', 'updatedBy', 'displayOptions'];
    }

    urlForFindAll(modelName, snapshot) {
      let url = super.urlForFindAll(modelName, snapshot);
      return `${url}?limit=${defaultLimit}`;
    }

  }

  _exports.default = Item;
});