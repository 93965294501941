define("clarify/controllers/organizations/show/timelines/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let TimelinesShow = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, (_class = (_temp = class TimelinesShow extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", ['from', 'to', 'signals', {
        new: {
          as: 'onboarding-new'
        },
        demo: {
          as: 'onboarding-demo'
        }
      }]);

      _defineProperty(this, "timeline", void 0);

      _defineProperty(this, "timeRange", void 0);

      _defineProperty(this, "layout", void 0);

      _defineProperty(this, "from", void 0);

      _defineProperty(this, "to", void 0);

      _defineProperty(this, "signals", false);

      _defineProperty(this, "new", false);

      _defineProperty(this, "demo", false);
    }

    updateTimeRange(timeRange) {
      Ember.set(this, 'timeRange', timeRange);
    }

    visibleDomainChanged(from, to) {
      Ember.set(this, 'from', from.toISOString());
      Ember.set(this, 'to', to.toISOString());
    }

    setItemMenuVisible(itemMenuVisible) {
      Ember.set(this, 'signals', itemMenuVisible);
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "updateTimeRange", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "updateTimeRange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "visibleDomainChanged", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "visibleDomainChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setItemMenuVisible", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "setItemMenuVisible"), _class.prototype)), _class));
  _exports.default = TimelinesShow;
});