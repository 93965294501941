define("clarify/modifiers/setup-menu", ["exports", "design-library/modifiers/setup-menu"], function (_exports, _setupMenu) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _setupMenu.default;
    }
  });
});