define("clarify/components/wizard-dialog/index", ["exports", "design-library/components/wizard-dialog"], function (_exports, _wizardDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _wizardDialog.default;
    }
  });
});