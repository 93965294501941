define("clarify/services/settings-store", ["exports", "ember-m3/services/store"], function (_exports, _store) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SettingsStore extends _store.default {}

  _exports.default = SettingsStore;
});