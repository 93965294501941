define("clarify/components/timeline-canvas/visualizations/drop-zone/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <DropZone class="visualization-drop-zone" @allow={{array "clarify/item"}} @dropAction={{@dropAction}} ...attributes>
    <div class="visualization-drop-zone-more">
      {{yield}}
    </div>
  </DropZone>
  */
  {"id":"f4e/9jVH","block":"{\"symbols\":[\"@dropAction\",\"&attrs\",\"&default\"],\"statements\":[[8,\"drop-zone\",[[24,0,\"visualization-drop-zone\"],[17,2]],[[\"@allow\",\"@dropAction\"],[[30,[36,0],[\"clarify/item\"],null],[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"visualization-drop-zone-more\"],[12],[2,\"\\n    \"],[18,3,null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"array\"]}","meta":{"moduleName":"clarify/components/timeline-canvas/visualizations/drop-zone/index.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});