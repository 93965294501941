define("clarify/controllers/organizations/show/timelines/show/settings/index", ["exports", "clarify/models/layout"], function (_exports, _layout) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TimelinesShowSettingsIndexController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, (_class = (_temp = class TimelinesShowSettingsIndexController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "timeline", void 0);

      _defineProperty(this, "layout", void 0);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "layoutService", _descriptor2, this);
    }

    async removeVisualizationItem(item) {
      this.layoutService.removeItem(this.layout, item);
      await (0, _layout.saveLayout)(this.layout);
    }

    settingsMenuClosed() {
      this.router.transitionTo('organizations.show.timelines.show');
    }

    async visibilityDidChange(visualizationItem, visibility) {
      Ember.set(visualizationItem, 'visible', visibility);
      await (0, _layout.saveLayout)(this.layout);
    }

    async sortVisualizations(visualizations) {
      this.layout.visualizations.setObjects(visualizations);
      await (0, _layout.saveLayout)(this.layout);
    }

    async insertVisualizationItems(visualization, items) {
      let cache = new Set(items);
      let existing = visualization.visualizationItems.toArray();
      let visualizationItems = [...existing.filter(item => !cache.has(item)), ...items];
      this.layoutService.setVisualizationItems(visualizationItems, visualization);
      await (0, _layout.saveLayout)(this.layout);
    }

    async sortItems(visualization, visualizationItems) {
      this.layoutService.setVisualizationItems(visualizationItems, visualization);
      await (0, _layout.saveLayout)(this.layout);
    }

    async colorDidChange(visualizationItem, color) {
      visualizationItem.color = color;
      await (0, _layout.saveLayout)(this.layout);
    }

    async removeVisualization(visualization) {
      this.layoutService.removeVisualization(visualization, this.layout);
      await (0, _layout.saveLayout)(this.layout);
    }

    async timelineNameDidChange(name) {
      this.timeline.set('name', name);
      await this.timeline.save();
    }

    async visualizationNameDidChange(visualization, name) {
      visualization.set('name', name);
      await (0, _layout.saveLayout)(this.layout);
    }

    openVisualizationSettings(visualization) {
      this.router.transitionTo('organizations.show.timelines.show.visualizations.show', visualization.id);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "layoutService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "removeVisualizationItem", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "removeVisualizationItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "settingsMenuClosed", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "settingsMenuClosed"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "visibilityDidChange", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "visibilityDidChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sortVisualizations", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "sortVisualizations"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "insertVisualizationItems", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "insertVisualizationItems"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sortItems", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "sortItems"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "colorDidChange", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "colorDidChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeVisualization", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "removeVisualization"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "timelineNameDidChange", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "timelineNameDidChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "visualizationNameDidChange", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "visualizationNameDidChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openVisualizationSettings", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "openVisualizationSettings"), _class.prototype)), _class));
  _exports.default = TimelinesShowSettingsIndexController;
});