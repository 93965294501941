define("clarify/components/wizard-dialog/text-content", ["exports", "design-library/components/wizard-dialog/text-content"], function (_exports, _textContent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _textContent.default;
    }
  });
});