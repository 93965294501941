define("clarify/utilities/scrollable", ["exports", "gesture-recognizer/gestures/pan", "gesture-recognizer/gestures/tap", "gesture-recognizer/gestures/hold", "gesture-recognizer/gestures/-base"], function (_exports, _pan, _tap, _hold, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.MouseDownGestureRecognizer = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const MIN_VELOCITY_FOR_DECELERATION = 250;
  const DECELERATION_FRICTION_FACTOR = 0.998;
  const MINIMUM_VELOCITY = 10;
  const zero = {
    x: 0,
    y: 0
  };
  const zeroSize = {
    width: 0,
    height: 0
  };
  var ScrollState;

  (function (ScrollState) {
    ScrollState[ScrollState["idle"] = 0] = "idle";
    ScrollState[ScrollState["tracking"] = 1] = "tracking";
    ScrollState[ScrollState["decelerating"] = 2] = "decelerating";
  })(ScrollState || (ScrollState = {}));

  class MouseDownGestureRecognizer extends _base.default {
    pointerDown() {
      Ember.set(this, 'state', _base.GestureRecognizerState.ended);
    }

  }

  _exports.MouseDownGestureRecognizer = MouseDownGestureRecognizer;

  class Scrollable {
    get decelerating() {
      return this.state === ScrollState.decelerating;
    }

    constructor(element) {
      _defineProperty(this, "delegate", null);

      _defineProperty(this, "element", void 0);

      _defineProperty(this, "state", ScrollState.idle);

      _defineProperty(this, "panGestureRecognizer", _pan.default.create());

      _defineProperty(this, "tapGestureRecognizer", MouseDownGestureRecognizer.create());

      _defineProperty(this, "decelerationVelocity", zero);

      _defineProperty(this, "adjustedDecelerationFactor", zeroSize);

      _defineProperty(this, "animatedContentOffset", zero);

      _defineProperty(this, "contentOffset", zero);

      _defineProperty(this, "decelerationTimer", null);

      _defineProperty(this, "previousDecelerationFrame", null);

      _defineProperty(this, "stepThroughDecelerationAnimation", timestamp => {
        if (!this.decelerating || !this.previousDecelerationFrame) {
          return;
        }

        const delta = timestamp - this.previousDecelerationFrame;
        let animatedContentOffset = {
          x: this.element.scrollLeft,
          y: this.element.scrollTop
        };
        const adjustedDecelerationFactor = this.adjustedDecelerationFactor;
        const n = {
          width: Math.exp(Math.log(adjustedDecelerationFactor.width) * delta),
          height: Math.exp(Math.log(adjustedDecelerationFactor.height) * delta)
        };
        var l = {
          width: adjustedDecelerationFactor.width * ((1 - n.width) / (1 - adjustedDecelerationFactor.width)),
          height: adjustedDecelerationFactor.height * ((1 - n.height) / (1 - adjustedDecelerationFactor.height))
        };
        animatedContentOffset.x += this.decelerationVelocity.x / 1000 * l.width;
        animatedContentOffset.y += this.decelerationVelocity.y / 1000 * l.height;
        let deltaX = this.decelerationVelocity.x / 1000 * l.width;
        let deltaY = this.decelerationVelocity.y / 1000 * l.width;
        this.decelerationVelocity.x *= n.width;
        this.decelerationVelocity.y *= n.height;
        this.animatedContentOffset = animatedContentOffset;
        this.contentOffset = animatedContentOffset;
        this.delegate && this.delegate.contentOffsetDidChange(this.contentOffset, {
          x: deltaX,
          y: deltaY
        }, this);
        let velocityX = Math.abs(this.decelerationVelocity.x);
        let velocityY = Math.abs(this.decelerationVelocity.y);
        let done = velocityX <= MINIMUM_VELOCITY && velocityY <= MINIMUM_VELOCITY;

        if (done) {
          this.decelerationAnimationCompleted();
          return;
        }

        this.decelerationTimer = requestAnimationFrame(this.stepThroughDecelerationAnimation);
        this.previousDecelerationFrame = timestamp;
      });

      _defineProperty(this, "tapGestureRecognizerDidChange", _gestureRecognizer => {
        if (this.state === ScrollState.decelerating) {
          this.state = ScrollState.idle;
          this.decelerationTimer && cancelAnimationFrame(this.decelerationTimer);
          this.delegate && this.delegate.didEndDecelerating(this);
        }
      });

      _defineProperty(this, "panGestureRecognizerDidChange", gestureRecognizer => {
        let state = gestureRecognizer.state;

        switch (state) {
          case _base.GestureRecognizerState.ended:
            this.startDecelerationAnimation(gestureRecognizer);
            break;

          case _base.GestureRecognizerState.began:
          case _base.GestureRecognizerState.changed:
            if (this.state === ScrollState.idle) {
              this.delegate && this.delegate.willBeginDragging(this);
              this.state = ScrollState.tracking;
            }

            let contentOffset = {
              x: this.element.scrollLeft - gestureRecognizer.delta.x,
              y: this.element.scrollTop - gestureRecognizer.delta.y
            };
            let delta = {
              x: -gestureRecognizer.delta.x,
              y: -gestureRecognizer.delta.y
            };
            this.delegate && this.delegate.contentOffsetDidChange(contentOffset, delta, this);
        }
      });

      this.element = element;
      this.tapGestureRecognizer.delegate = this;
      this.tapGestureRecognizer.didChange = this.tapGestureRecognizerDidChange;
      this.panGestureRecognizer.delegate = this;
      this.panGestureRecognizer.didChange = this.panGestureRecognizerDidChange;

      Ember._registerDestructor(this, () => {
        this.destroy();
      });
    }

    get gestureRecognizers() {
      return [this.panGestureRecognizer, this.tapGestureRecognizer];
    }

    destroy() {
      if (this.decelerationTimer) {
        cancelAnimationFrame(this.decelerationTimer);
      }

      if (this.previousDecelerationFrame) {
        cancelAnimationFrame(this.previousDecelerationFrame);
      }

      this.delegate = null;
    } // Private


    startDecelerationAnimation(recognizer) {
      this.decelerationVelocity = recognizer.velocity();
      this.decelerationVelocity.x = -this.decelerationVelocity.x;
      this.decelerationVelocity.y = -this.decelerationVelocity.y;
      this.adjustedDecelerationFactor = {
        width: DECELERATION_FRICTION_FACTOR,
        height: DECELERATION_FRICTION_FACTOR
      };
      let willDecelerate = Math.abs(this.decelerationVelocity.x) > MIN_VELOCITY_FOR_DECELERATION || Math.abs(this.decelerationVelocity.y) > MIN_VELOCITY_FOR_DECELERATION;

      if (willDecelerate) {
        this.state = ScrollState.decelerating;
        this.animatedContentOffset = {
          x: this.element.scrollLeft,
          y: this.element.scrollTop
        };
        this.decelerationTimer = requestAnimationFrame(this.stepThroughDecelerationAnimation);
        this.previousDecelerationFrame = performance.now();
      }

      this.state = willDecelerate ? ScrollState.decelerating : ScrollState.idle;
      this.delegate && this.delegate.didEndDragging(willDecelerate, this);
    }

    decelerationAnimationCompleted() {
      this.state = ScrollState.idle;
      this.delegate && this.delegate.didEndDecelerating(this);
    }

    shouldBeRequiredToFailBy(gestureRecognizer, _requiredToFailBy) {
      if (gestureRecognizer === this.tapGestureRecognizer) {
        return true;
      }

      return false; // return requiredToFailBy instanceof PanGestureRecognizer;
    }

    shouldRequireFailureOf(gestureRecognizer, requireFailureOf) {
      console.error('shouldBeRequiredToFailBy');

      if (gestureRecognizer === this.panGestureRecognizer) {
        return requireFailureOf instanceof _tap.default || requireFailureOf instanceof _hold.default;
      }

      return false;
    }

    shouldRecognizeSimultaneously(gestureRecognizer, otherGestureRecognizer) {
      return gestureRecognizer === this.tapGestureRecognizer && otherGestureRecognizer === this.panGestureRecognizer;
    }

    shouldBegin(gestureRecognizer) {
      if (gestureRecognizer === this.tapGestureRecognizer) {
        return this.state === ScrollState.decelerating;
      }

      return true;
    }

  }

  _exports.default = Scrollable;
});