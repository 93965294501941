define("clarify/components/m-user-tag/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <MUserTag::-private @icon={{@user.picture}} @user={{@user.name}} @onRemove={{@removeTag}} />
  */
  {"id":"qIo87hLI","block":"{\"symbols\":[\"@user\",\"@removeTag\"],\"statements\":[[8,\"m-user-tag/-private\",[],[[\"@icon\",\"@user\",\"@onRemove\"],[[32,1,[\"picture\"]],[32,1,[\"name\"]],[32,2]]],null]],\"hasEval\":false,\"upvars\":[]}","meta":{"moduleName":"clarify/components/m-user-tag/user.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});