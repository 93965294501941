define("clarify/components/file-upload/index", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <input
    type="file"
    multiple="true"
    hidden="true"
    {{did-insert this.handleSetElement}}
    {{will-destroy this.handleSetElement}}
    {{on "change" (fn this.addFiles)}}
  />
  {{yield (hash openFileDialog=(fn this.openFileDialog))}}
  */
  {"id":"X8KMuM6h","block":"{\"symbols\":[\"&default\"],\"statements\":[[11,\"input\"],[24,\"multiple\",\"true\"],[24,\"hidden\",\"true\"],[24,4,\"file\"],[4,[38,0],[[32,0,[\"handleSetElement\"]]],null],[4,[38,1],[[32,0,[\"handleSetElement\"]]],null],[4,[38,3],[\"change\",[30,[36,2],[[32,0,[\"addFiles\"]]],null]],null],[12],[13],[2,\"\\n\"],[18,1,[[30,[36,4],null,[[\"openFileDialog\"],[[30,[36,2],[[32,0,[\"openFileDialog\"]]],null]]]]]]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"will-destroy\",\"fn\",\"on\",\"hash\"]}","meta":{"moduleName":"clarify/components/file-upload/index.hbs"}});

  let FileUpload = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, (_class = (_temp = class FileUpload extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "fileElement", null);
    }

    openFileDialog() {
      if (this.fileElement) {
        this.fileElement.click();
      }
    }

    addFiles(event) {
      let element = event.target;

      if (element.files) {
        let files = Array.from(element.files);
        this.args.filesSelected(files);
      }
    }

    handleSetElement(element = null) {
      this.fileElement = element;
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "openFileDialog", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "openFileDialog"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addFiles", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "addFiles"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleSetElement", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "handleSetElement"), _class.prototype)), _class));
  _exports.default = FileUpload;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FileUpload);
});