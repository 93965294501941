define("clarify/components/card/activity/empty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <InitialState
    @title={{t "activity-feed.state.empty.title"}}
    @description={{t "activity-feed.state.empty.description"}}
    @image="/assets/empty-activity-state.png"
    @retinaImage="/assets/empty-activity-state@2x.png"
    @width="284"
    @height="180"
  />
  */
  {"id":"g6XOajgB","block":"{\"symbols\":[],\"statements\":[[8,\"initial-state\",[],[[\"@title\",\"@description\",\"@image\",\"@retinaImage\",\"@width\",\"@height\"],[[30,[36,0],[\"activity-feed.state.empty.title\"],null],[30,[36,0],[\"activity-feed.state.empty.description\"],null],\"/assets/empty-activity-state.png\",\"/assets/empty-activity-state@2x.png\",\"284\",\"180\"]],null]],\"hasEval\":false,\"upvars\":[\"t\"]}","meta":{"moduleName":"clarify/components/card/activity/empty.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});