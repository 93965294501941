define("clarify/helpers/is-visualization-item-accessible", ["exports", "clarify/models/visualization-item"], function (_exports, _visualizationItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class IsVisualizationItemAccessibleHelper extends Ember.Helper {
    compute([visualizationItem], _hash) {
      return _visualizationItem.default.isAccessible(visualizationItem);
    }

  }

  _exports.default = IsVisualizationItemAccessibleHelper;
});