define("clarify/helpers/export-modal-exit-alert", ["exports", "clarify/helpers/alert"], function (_exports, _alert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ExportModalExitAlert extends _alert.default {
    compute(_parameters, hash) {
      return () => {
        let {
          action
        } = hash;
        let exitButton = this.intl.t('export-modal-exit-alert.exit-button');
        this.showAlert({
          title: this.intl.t('export-modal-exit-alert.title'),
          message: this.intl.t('export-modal-exit-alert.message'),
          primaryButton: {
            title: exitButton,
            action: action
          }
        });
      };
    }

  }

  _exports.default = ExportModalExitAlert;
});