define("clarify/draggable/tests/templates.template.lint-test", [], function () {
  "use strict";

  QUnit.module('TemplateLint');
  QUnit.test('addon/templates/components/drop-zone.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/drop-zone.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/sort-interaction.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/sort-interaction.hbs should pass TemplateLint.\n\n');
  });
});