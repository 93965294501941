define("clarify/initializers/images", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = _exports.data = _exports.dragImageVisualization = void 0;
  const dragImageVisualization = new Image();
  _exports.dragImageVisualization = dragImageVisualization;
  dragImageVisualization.src = '/legacy/drag-image-visualization.svg';
  const data = new Image();
  _exports.data = data;
  data.src = '/selection-bg-dark@2x.png';

  function initialize() {// no-op
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});