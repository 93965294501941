define("clarify/components/thresholds/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="m-thresholds">
    <header class="m-thresholds-header">
      <div class="m-thresholds-header-icon">
        {{svg-jar "#design-system-icon-info-circle-16" width=16 height=16}}
      </div>
      <p class="m-thresholds-header-text">
        {{t "thresholds.description"}}
      </p>
    </header>
    <Thresholds::TableForm
      class="m-thresholds-form-container"
      @layout={{@layout}}
      @timeline={{@timeline}}
      @source={{@source}}
      @thresholds={{@thresholds}}
    />
  </div>
  */
  {"id":"gcjwQZYT","block":"{\"symbols\":[\"@layout\",\"@timeline\",\"@source\",\"@thresholds\"],\"statements\":[[10,\"div\"],[14,0,\"m-thresholds\"],[12],[2,\"\\n  \"],[10,\"header\"],[14,0,\"m-thresholds-header\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"m-thresholds-header-icon\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"#design-system-icon-info-circle-16\"],[[\"width\",\"height\"],[16,16]]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"p\"],[14,0,\"m-thresholds-header-text\"],[12],[2,\"\\n      \"],[1,[30,[36,1],[\"thresholds.description\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[8,\"thresholds/table-form\",[[24,0,\"m-thresholds-form-container\"]],[[\"@layout\",\"@timeline\",\"@source\",\"@thresholds\"],[[32,1],[32,2],[32,3],[32,4]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"svg-jar\",\"t\"]}","meta":{"moduleName":"clarify/components/thresholds/index.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});