define("clarify/components/timeline-canvas-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="timeline-canvas-heading">
    <InlineEditable
      data-intercom-target="Timeline name"
      class="timeline-canvas-heading-timeline-name"
      @disabled={{not (can-edit @timeline)}}
      @value={{@timeline.name}}
      @placeholder={{t "timeline-canvas.untitled"}}
      @onChange={{action @timelineNameChanged}}
    />
    <div class="timeline-canvas-header-actions">
      <TimelineCanvasHeader::SharingInfo class="timeline-canvas-header-sharing-info" @timeline={{@timeline}} />
      <TimelinePermissionPopover @timeline={{@timeline}} @theme="dark" as |args|>
        <ButtonShare
          class="timeline-canvas-share-button"
          data-intercom-target="Share timeline"
          data-marketing-landmark="timelines.show.share"
          @title={{t "timeline-canvas.timeline-share"}}
          @style="dark"
          @size="normal"
          {{on "click" args.show}}
        />
      </TimelinePermissionPopover>
      <AButtonHiddenbox
        class="timeline-canvas-header-activity"
        title={{t "timelines-index.activity"}}
        @icon={{svg-jar "#design-system-icon-comment-24" width=24 height=24}}
        {{on "click" (fn this.openActivityMenu)}}
      />
    </div>
  </div>
  */
  {"id":"woElxgdE","block":"{\"symbols\":[\"args\",\"@timeline\",\"@timelineNameChanged\"],\"statements\":[[10,\"div\"],[14,0,\"timeline-canvas-heading\"],[12],[2,\"\\n  \"],[8,\"inline-editable\",[[24,\"data-intercom-target\",\"Timeline name\"],[24,0,\"timeline-canvas-heading-timeline-name\"]],[[\"@disabled\",\"@value\",\"@placeholder\",\"@onChange\"],[[30,[36,1],[[30,[36,0],[[32,2]],null]],null],[32,2,[\"name\"]],[30,[36,2],[\"timeline-canvas.untitled\"],null],[30,[36,3],[[32,0],[32,3]],null]]],null],[2,\"\\n  \"],[10,\"div\"],[14,0,\"timeline-canvas-header-actions\"],[12],[2,\"\\n    \"],[8,\"timeline-canvas-header/sharing-info\",[[24,0,\"timeline-canvas-header-sharing-info\"]],[[\"@timeline\"],[[32,2]]],null],[2,\"\\n    \"],[8,\"timeline-permission-popover\",[],[[\"@timeline\",\"@theme\"],[[32,2],\"dark\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"button-share\",[[24,0,\"timeline-canvas-share-button\"],[24,\"data-intercom-target\",\"Share timeline\"],[24,\"data-marketing-landmark\",\"timelines.show.share\"],[4,[38,4],[\"click\",[32,1,[\"show\"]]],null]],[[\"@title\",\"@style\",\"@size\"],[[30,[36,2],[\"timeline-canvas.timeline-share\"],null],\"dark\",\"normal\"]],null],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n    \"],[8,\"a-button-hiddenbox\",[[24,0,\"timeline-canvas-header-activity\"],[16,\"title\",[30,[36,2],[\"timelines-index.activity\"],null]],[4,[38,4],[\"click\",[30,[36,6],[[32,0,[\"openActivityMenu\"]]],null]],null]],[[\"@icon\"],[[30,[36,5],[\"#design-system-icon-comment-24\"],[[\"width\",\"height\"],[24,24]]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"can-edit\",\"not\",\"t\",\"action\",\"on\",\"svg-jar\",\"fn\"]}","meta":{"moduleName":"clarify/components/timeline-canvas-header.hbs"}});

  let TimelineCanvasHeader = (_dec = Ember.inject.service, _dec2 = Ember._action, (_class = (_temp = class TimelineCanvasHeader extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);
    }

    openActivityMenu() {
      this.router.transitionTo('organizations.show.timelines.show.activity');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "openActivityMenu", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "openActivityMenu"), _class.prototype)), _class));
  _exports.default = TimelineCanvasHeader;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TimelineCanvasHeader);
});