define("clarify/templates/organizations/show/timelines/show/groups/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ieK7OzZ5",
    "block": "{\"symbols\":[],\"statements\":[[8,\"modal\",[],[[],[]],[[\"default\"],[{\"statements\":[[8,\"group-members-modal\",[],[[\"@group\"],[[32,0,[\"group\"]]]],null]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "clarify/templates/organizations/show/timelines/show/groups/show.hbs"
    }
  });

  _exports.default = _default;
});