define("clarify/helpers/map-get", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(([map, key]) => {
    return map.get(key);
  });

  _exports.default = _default;
});