define("clarify/serializers/permission", ["exports", "clarify/serializers/rest-layer", "clarify/utilities/flat-map"], function (_exports, _restLayer, _flatMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Permission extends _restLayer.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "root", 'permissions');
    }

    static id(timelineId, model) {
      return `${timelineId}||${model.id}`;
    }

    static fromProprietaryFormat(timelineId, permissions) {
      return (0, _flatMap.flatMap)(Object.entries(permissions), ([key, value]) => {
        return value.map(entity => {
          let model = entity.includes('|') ? 'user' : 'group';
          return {
            id: `${timelineId}||${entity}`,
            permission: key,
            [model]: entity,
            type: model === 'user' ? 'PermissionUser' : 'PermissionGroup'
          };
        });
      });
    }

    static toProprietaryFormat(permissions) {
      let permissionsIds = {};

      for (let permission of permissions) {
        let id;

        if (permission.modelName === 'permission-user') {
          let userPermission = permission;
          id = userPermission.belongsTo('user', {
            id: true
          });
        } else if (permission.modelName === 'permission-group') {
          let groupPermission = permission;
          id = groupPermission.belongsTo('group', {
            id: true
          });
        }

        if (!id) {
          continue;
        }

        let typed = permission;
        let type = typed.attr('permission');
        let ids = permissionsIds[type];

        if (ids) {
          ids.push(id);
        } else {
          permissionsIds[type] = [id];
        }
      }

      return permissionsIds;
    }

  }

  _exports.default = Permission;
});