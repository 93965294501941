define("clarify/templates/organizations/show/timelines/show/export/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v529zo1x",
    "block": "{\"symbols\":[],\"statements\":[[8,\"export-modal\",[],[[\"@title\",\"@layoutModel\",\"@timeRange\"],[[30,[36,0],[\"export-modal.selected-period.export\"],null],[32,0,[\"layout\"]],[32,0,[\"timeRange\"]]]],null]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "clarify/templates/organizations/show/timelines/show/export/index.hbs"
    }
  });

  _exports.default = _default;
});