define("clarify/components/timeline-canvas/visualizations/comment/canvas", ["exports", "d3-scale", "clarify/components/x-canvas", "clarify/drag-images/item", "clarify/utilities/hit-region", "clarify/components/timeline-canvas", "clarify/helpers/resize-attachment", "clarify/utilities/colors", "@ember-decorators/component", "clarify/components/timeline-canvas/visualizations/line-chart", "ui/helpers/design-system-particles"], function (_exports, _d3Scale, _xCanvas, _item, _hitRegion, _timelineCanvas, _resizeAttachment, _colors, _component, _lineChart, _designSystemParticles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.renderRuler = renderRuler;
  _exports.renderTimeRange = renderTimeRange;
  _exports.default = _exports.ThreadStyle = _exports.THREAD_BACKGROUND = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _class2, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var DisplayStyle;

  (function (DisplayStyle) {
    DisplayStyle[DisplayStyle["full"] = 0] = "full";
    DisplayStyle[DisplayStyle["imageOnly"] = 1] = "imageOnly";
    DisplayStyle[DisplayStyle["fileOnly"] = 2] = "fileOnly";
  })(DisplayStyle || (DisplayStyle = {}));

  const DEBUG = false;
  const DEBUG_TIME = false || DEBUG;
  const AGGREAGATED_RECT_TOP = 20;
  const DOT_SIZE = 7;
  const DOT_SIZE_2 = DOT_SIZE / 2;
  const DOT_CENTER = AGGREAGATED_RECT_TOP + DOT_SIZE_2;
  const AGGREAGATED_TEXT_TOP = 6;
  const COMMENT_WIDTH = 232;
  const COMMENT_TOP = 40;
  const AVATAR_SIZE = 24;
  const DOT_SHINE_HIGHLIGHTED_COLOR = 'rgba(207, 212, 232, 0.23)';
  const DOT_SHINE_HIGHLIGHTED_SIZE_2 = 19 / 2;
  const DOT_SHINE_PINNED_COLOR = 'rgba(207, 212, 232, 0.23)';
  const DOT_COLOR = _designSystemParticles.darkNeutralTxtNeutralMediumstrong;
  const DOT_PINNED_BACKGROUND_COLOR = _designSystemParticles.darkNeutralTxtNeutralStrongest;
  const DOT_HIGHLIGHTED_BACKGROUND_COLOR = _designSystemParticles.darkNeutralTxtNeutralStrong;
  const COMMENT_BACKGROUND_COLOR = _designSystemParticles.darkNeutralTxtNeutralMediumstrong;
  const COMMENT_BACKGROUND_PINNED_STROKE = _designSystemParticles.darkActionActionPrimaryBg;
  const COMMENT_BACKGROUND_SHADOW = 'rgba(0, 0, 0, 0.4)';
  const COMMENT_HIGHLIGHTED_BACKGROUND_COLOR = _designSystemParticles.darkNeutralTxtNeutralStrong;
  const COMMENT_PINNED_BACKGROUND_COLOR = _designSystemParticles.darkNeutralTxtNeutralStrongest;
  const COMMENT_TEXT_CONTENT_COLOR = _designSystemParticles.darkNeutralTxtNeutralDisabledBg;
  const COMMENT_INSET = 8;
  const COMMENT_HEIGHT_MEDIUM = 32;
  const COMMENT_IMAGE_WIDTH = 55;
  const COMMENT_IMAGE_HEIGHT_MEDIUM = 30;
  const THREAD_BACKGROUND = _designSystemParticles.darkSystemCanvasBgPrimary;
  _exports.THREAD_BACKGROUND = THREAD_BACKGROUND;
  const TIME_RANGE_BACKGROUND = (0, _colors.toHex)((0, _colors.blend)(0x4564fd14, 0x152233ff));
  const TIME_RANGE_BACKGROUND_TRANSPARENT = `rgba(69, 100, 253, 0.08)`;
  const TIME_RANGE_FIXED_BORDER = '#567fe3';
  const TIME_RANGE_TEMP_BORDER = 'white';
  let ThreadStyle;
  _exports.ThreadStyle = ThreadStyle;

  (function (ThreadStyle) {
    ThreadStyle[ThreadStyle["normal"] = 0] = "normal";
    ThreadStyle[ThreadStyle["highlighted"] = 1] = "highlighted";
    ThreadStyle[ThreadStyle["pinned"] = 2] = "pinned";
  })(ThreadStyle || (_exports.ThreadStyle = ThreadStyle = {}));

  const THREAD_IMAGE_SIZE = {
    width: 28,
    height: 28
  };

  function tooltipPath(x, y, width, height, nib = 23) {
    let path = new Path2D();
    path.moveTo(x, y);
    path.lineTo(x + nib, y);
    path.lineTo(x + nib + 5.5, y - 5);
    path.lineTo(x + nib + 11, y);
    path.lineTo(x + width, y);
    path.lineTo(x + width, y + height);
    path.lineTo(x, y + height);
    path.closePath();
    return path;
  }

  function renderRuler(ruler, context, rangeX, height, y = 0) {
    context.beginPath();

    switch (ruler.style) {
      case _timelineCanvas.TimelineRulerStyle.scrubbing:
        context.fillStyle = '#57678c';
        break;

      case _timelineCanvas.TimelineRulerStyle.pinned:
        context.fillStyle = '#4b71ce';
        break;

      case _timelineCanvas.TimelineRulerStyle.highlighted:
        context.fillStyle = '#4b71ce';
        break;
    }

    let position = rangeX(ruler.date);
    context.rect(Math.round(position) - 1, y, 1, height);
    context.fill();
  }

  function renderTimeRange(timeRange, scale, height, context, opaque = true, y = 0) {
    context.beginPath();
    context.fillStyle = opaque ? TIME_RANGE_BACKGROUND : TIME_RANGE_BACKGROUND_TRANSPARENT;
    const from = scale(timeRange.from);
    const to = scale(timeRange.to);
    context.rect(Math.round(from), y, Math.round(to) - Math.round(from), height);
    context.fill();
    context.beginPath();
    context.fillStyle = timeRange.temporarily === 'from' || timeRange.temporarily === 'moving' ? TIME_RANGE_TEMP_BORDER : TIME_RANGE_FIXED_BORDER;
    context.rect(Math.round(from), y, 1, height);
    context.fill();
    context.beginPath();
    context.fillStyle = timeRange.temporarily === 'to' || timeRange.temporarily === 'moving' ? TIME_RANGE_TEMP_BORDER : TIME_RANGE_FIXED_BORDER;
    context.rect(Math.round(to) - 1, y, 1, height);
    context.fill();
  }

  function debugRect(x, y, width, height, context) {
    if (DEBUG) {
      context.save();
      context.beginPath();
      context.fillStyle = 'rgba(255, 0, 0, 0.1)';
      context.fillRect(x, y, width, height);
      context.restore();
    }
  }

  let VisualizationCommentsCanvas = (_dec = (0, _component.classNames)('visualization-comments-canvas'), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed('context'), _dec5 = Ember.computed('context'), _dec6 = Ember.computed('context'), _dec7 = Ember.computed('visibleDomainFrom', 'visibleDomainTo', 'width'), _dec8 = Ember.computed('threads', 'highlightedEntity'), _dec(_class = (_class2 = (_temp = class VisualizationCommentsCanvas extends _xCanvas.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "visibleDomainFrom", void 0);

      _defineProperty(this, "visibleDomainTo", void 0);

      _defineProperty(this, "threads", void 0);

      _defineProperty(this, "highlightedEntity", void 0);

      _defineProperty(this, "setHighlightedThread", void 0);

      _defineProperty(this, "setPinnedThread", void 0);

      _defineProperty(this, "timeRange", void 0);

      _defineProperty(this, "rulers", void 0);

      _defineProperty(this, "loading", void 0);

      _defineProperty(this, "context", void 0);

      _defineProperty(this, "cache", new Map());

      _initializerDefineProperty(this, "network", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);
    }

    get hitRegions() {
      return new _hitRegion.default(this.context);
    }

    get aggregatedTypesetter() {
      return (0, _item.createTypesetter)(this.context, {
        fill: DOT_COLOR,
        font: '11px roboto'
      });
    }

    get textContentTypesetter() {
      let typesetter = (0, _item.createTypesetter)(this.context, {
        fill: COMMENT_TEXT_CONTENT_COLOR,
        font: '13px roboto'
      });
      typesetter.wrapper.maxLines(1);
      typesetter.wrapper.textTrimming('ellipsis');
      return typesetter;
    }

    get rangeX() {
      return (0, _d3Scale.scaleTime)().domain([this.visibleDomainFrom.getTime(), this.visibleDomainTo.getTime()]).range([0, this.width]);
    }

    get threadIdCache() {
      let map = new Map();
      this.threads.forEach(thread => {
        if (thread.type === 'thread') {
          map.set(thread.thread.id, thread.thread);
        }
      });

      if (this.highlightedEntity) {
        map.set(this.highlightedEntity.threadEntry.thread.id, this.highlightedEntity.threadEntry.thread);
      }

      return map;
    }

    get commentMediumImageSize() {
      return {
        width: COMMENT_IMAGE_WIDTH * this.devicePixelRatio,
        height: COMMENT_IMAGE_HEIGHT_MEDIUM * this.devicePixelRatio
      };
    }

    paintLoading(context, rangeX) {
      let viewport = {
        from: this.visibleDomainFrom,
        to: this.visibleDomainTo
      };
      context.save();
      context.fillStyle = '#343c5b';

      for (let slot of this.loading) {
        if ((0, _lineChart.isVisible)(viewport, {
          from: slot.from,
          to: slot.to
        })) {
          let from = Math.max(rangeX(slot.from), 0);
          let to = Math.min(rangeX(slot.to), this.width);
          context.fillRect(from, AGGREAGATED_RECT_TOP, to - from, 8);
        }
      }

      context.restore();
    }

    mouseLeave() {
      this.setHighlightedThread(null);
    }

    click(event) {
      let thread = this.highlightedEntity ? this.highlightedEntity.threadEntry.thread : this.getThread(event);

      if (thread) {
        this.setPinnedThread(thread);
      }
    }

    mouseMove(event) {
      DEBUG_TIME && console.time('VisualizationCommentsCanvas#mouseMove');
      let thread = this.getThread(event);

      if (thread) {
        this.setHighlightedThread(thread);
      } else {
        this.setHighlightedThread(null);
      }

      DEBUG_TIME && console.timeEnd('VisualizationCommentsCanvas#mouseMove');
    }

    getThread(event) {
      let regionId = this.hitRegions.getRegion(event.offsetX * this.devicePixelRatio, event.offsetY * this.devicePixelRatio);

      if (regionId) {
        let thread = this.threadIdCache.get(regionId);

        if (thread) {
          return thread;
        }
      }

      return null;
    }

    paint(context) {
      DEBUG_TIME && console.time('VisualizationCommentsCanvas#paint');
      Ember.set(this, 'context', context);
      context.fillStyle = THREAD_BACKGROUND;
      context.fillRect(0, 0, this.width, this.height);
      this.hitRegions.clearHitRegions();
      this.paintLoading(context, this.rangeX);
      this.threads.forEach(thread => {
        switch (thread.type) {
          case 'thread':
            this.renderThread(thread, context);
            break;

          case 'aggregated_threads':
            this.renderAggregatedThreads(thread, this.aggregatedTypesetter, context);
            break;
        }
      });

      if (this.highlightedEntity) {
        this.renderThread(this.highlightedEntity.threadEntry, context, this.highlightedEntity.style === _timelineCanvas.RulerType.pinnedThread ? ThreadStyle.pinned : ThreadStyle.highlighted);
      }

      DEBUG_TIME && console.timeEnd('VisualizationCommentsCanvas#paint');
    }

    async fetchImage(attachment, size) {
      let fetchURL = (0, _resizeAttachment.urlForAttachment)(attachment, size, _resizeAttachment.ContentMode.cover);
      await this.fetchImageURL(fetchURL, true);
    }

    async fetchImageURL(fetchURL, withAuthentication) {
      this.cache.set(fetchURL, {
        type: 'loading'
      });

      try {
        let response = withAuthentication ? await this.network.fetch(fetchURL) : await fetch(fetchURL);
        let blob = await response.blob();
        let url = window.URL.createObjectURL(blob);
        let image = new Image();
        image.src = url;
        image.addEventListener('load', () => {
          URL.revokeObjectURL(url);
          this.cache.set(fetchURL, {
            type: 'loaded',
            image
          });
          this.prepare();
        }, {
          once: true
        });
      } catch (error) {
        console.log('failed: ', error);
        this.cache.set(fetchURL, {
          type: 'failed'
        });
      }
    }

    renderThread(thread, context, style = ThreadStyle.normal) {
      let from = Math.round(this.rangeX(thread.thread.timestamp.getTime()));

      if (-COMMENT_WIDTH < from && from < this.width + COMMENT_WIDTH) {
        if (thread.thread.comment.images.length > 0) {
          if (style !== ThreadStyle.normal) {
            context.beginPath();
            (0, _item.roundedRect)(context, from - 8 - 4, DOT_CENTER - 8 - 4, 24, 24, 6);
            context.fillStyle = 'rgba(230, 234, 252, 0.23)';
            context.fill();
          }

          context.beginPath();
          (0, _item.roundedRect)(context, from - 8, DOT_CENTER - 8, 16, 16, 3);
          context.fillStyle = this.dotBackground(style);
          context.fill();
          let attachment = thread.thread.comment.images[0];
          let entry = this.cache.get((0, _resizeAttachment.urlForAttachment)(attachment, THREAD_IMAGE_SIZE, _resizeAttachment.ContentMode.cover));

          if (!entry) {
            this.fetchImage(attachment, THREAD_IMAGE_SIZE);
          } else if (entry.type === 'loaded') {
            context.save();
            context.beginPath();
            (0, _item.roundedRect)(context, from - 7, DOT_CENTER - 7, 14, 14, 2);
            context.closePath();
            context.clip();
            context.drawImage(entry.image, from - 7, DOT_CENTER - 7, 14, 14);
            context.restore();
          }
        } else {
          let threadDotGlow = this.threadDotGlow(style);

          if (threadDotGlow) {
            context.beginPath();
            context.arc(from + 0.5, DOT_CENTER, threadDotGlow.size, 0, Math.PI * 2);
            context.fillStyle = threadDotGlow.color;
            context.fill();
          }

          let size = DOT_SIZE_2;
          context.beginPath();
          context.arc(from + 0.5, DOT_CENTER, size, 0, Math.PI * 2);
          context.fillStyle = this.dotBackground(style);
          context.fill();
        }

        if (thread.previewComment) {
          this.renderComment(thread, context, style);
        }
      }
    }

    renderCommentAuthorAvatar(context, user) {
      let entry = this.cache.get(user.picture);

      if (!entry) {
        this.fetchImageURL(user.picture, false);
      } else if (entry.type === 'loaded') {
        context.save();
        context.beginPath();
        context.arc(12, 12, 12, 0, Math.PI * 2);
        context.closePath();
        context.clip();
        context.drawImage(entry.image, 0, 0, AVATAR_SIZE, AVATAR_SIZE);
        context.restore();
        return;
      }

      context.save();
      context.beginPath();
      context.arc(12, 12, 12, 0, Math.PI * 2);
      context.fillStyle = _designSystemParticles.lightSystemSystemPlaceholderBgMedium;
      context.fill();
      context.restore();
    }

    renderAggregatedThreads(aggregation, typesetter, context) {
      let viewport = {
        from: this.visibleDomainFrom,
        to: this.visibleDomainTo
      };

      if ((0, _lineChart.isVisible)(viewport, {
        from: aggregation.from,
        to: aggregation.to
      })) {
        let from = Math.round(this.rangeX(aggregation.from));
        let to = Math.round(this.rangeX(aggregation.to));
        let width = Math.max(to - from, DOT_SIZE);
        context.save();
        context.translate(from, AGGREAGATED_TEXT_TOP);
        typesetter.write(`${aggregation.threads.length}`, width, 11, {
          xAlign: 'center'
        });
        context.restore();

        if (DEBUG) {
          context.beginPath();
          context.fillStyle = 'rgba(255, 0, 0, 0.1)';
          context.fillRect(from, AGGREAGATED_TEXT_TOP, width, 11);
        }

        context.beginPath();
        (0, _item.roundedRect)(context, from, AGGREAGATED_RECT_TOP, width, DOT_SIZE, DOT_SIZE_2);
        context.fillStyle = DOT_COLOR;
        context.fill();
      }
    }

    renderComment(thread, context, style) {
      let from = Math.round(this.rangeX(thread.thread.timestamp.getTime()));
      let hasTextContent = thread.thread.comment.textContent && !!thread.thread.comment.textContent.trim();
      let displayStyle = DisplayStyle.full;

      if (!hasTextContent) {
        if (thread.thread.comment.images.length) {
          displayStyle = DisplayStyle.imageOnly;
        } else if (thread.thread.comment.files.length) {
          displayStyle = DisplayStyle.fileOnly;
        } else {
          return;
        }
      }

      let size = {
        width: displayStyle == DisplayStyle.imageOnly ? COMMENT_IMAGE_WIDTH + 1 : COMMENT_WIDTH,
        height: COMMENT_HEIGHT_MEDIUM
      };
      let storke = this.threadBorder(style);
      context.save();
      let path = tooltipPath(from - 23 - 5, COMMENT_TOP, size.width, size.height, 23);
      context.fillStyle = this.commentBackgroundColor(style);
      this.hitRegions.addHitRegion({
        path,
        id: thread.thread.id
      });
      context.save();

      if (storke) {
        context.save();
        context.strokeStyle = storke;
        context.lineWidth = 4;
        context.shadowColor = COMMENT_BACKGROUND_SHADOW;
        context.shadowBlur = 8;
        context.stroke(path);
        context.restore();
      } else {
        context.shadowColor = COMMENT_BACKGROUND_SHADOW;
        context.shadowBlur = 8;
      }

      context.fill(path);
      context.restore();
      context.save();
      context.translate(from - 23 - 5, COMMENT_TOP);
      let attachment = thread.thread.comment.images[0];

      if (attachment) {
        this.renderImage(thread, attachment, context);
        context.translate(COMMENT_IMAGE_WIDTH, 0);
      }

      if (displayStyle !== DisplayStyle.imageOnly) {
        let availableWidth = attachment ? COMMENT_WIDTH - COMMENT_IMAGE_WIDTH : COMMENT_WIDTH;
        this.renderCommentBody(thread, context, displayStyle, availableWidth);
      }

      context.restore();
    }

    renderImage(_thread, attachment, context) {
      let entry = this.cache.get((0, _resizeAttachment.urlForAttachment)(attachment, this.commentMediumImageSize, _resizeAttachment.ContentMode.cover));

      if (!entry) {
        context.beginPath();
        context.rect(1, 1, COMMENT_IMAGE_WIDTH, COMMENT_IMAGE_HEIGHT_MEDIUM);
        context.fillStyle = '#d8dcf0';
        context.fill();
        this.fetchImage(attachment, this.commentMediumImageSize);
      } else if (entry.type === 'loaded') {
        let {
          size,
          offset
        } = (0, _resizeAttachment.previewSize)(attachment.dimensions, {
          width: COMMENT_IMAGE_WIDTH,
          height: COMMENT_IMAGE_HEIGHT_MEDIUM
        }, _resizeAttachment.ContentMode.cover);
        context.save();
        context.beginPath();
        context.rect(1, 1, COMMENT_IMAGE_WIDTH, COMMENT_IMAGE_HEIGHT_MEDIUM);
        context.clip();
        context.drawImage(entry.image, offset.x, offset.y, size.width, size.height);
        context.restore();
      }
    }

    renderCommentBody(thread, context, displayStyle, availableWidth) {
      let availableContentWidth = availableWidth - COMMENT_INSET - COMMENT_INSET - AVATAR_SIZE - COMMENT_INSET;
      let comment = thread.thread.comment;
      let textContent = displayStyle == DisplayStyle.fileOnly ? this.intl.t('visualization-comments.uploaded-file') : comment.textContent.trim();
      context.translate(8, 4);
      this.renderCommentAuthorAvatar(context, thread.thread.createdBy);
      context.translate(8 + 24, 3);
      let [text] = textContent.trim().split('\n');
      this.textContentTypesetter.write(text, availableContentWidth, 20, {
        yAlign: 'center'
      });
      debugRect(0, 0, availableContentWidth, 20, context);
      context.restore();
    }

    commentBackgroundColor(style) {
      switch (style) {
        case ThreadStyle.highlighted:
          return COMMENT_HIGHLIGHTED_BACKGROUND_COLOR;

        case ThreadStyle.normal:
          return COMMENT_BACKGROUND_COLOR;

        case ThreadStyle.pinned:
          return COMMENT_PINNED_BACKGROUND_COLOR;
      }
    }

    dotBackground(style) {
      switch (style) {
        case ThreadStyle.highlighted:
          return DOT_HIGHLIGHTED_BACKGROUND_COLOR;

        case ThreadStyle.normal:
          return DOT_COLOR;

        case ThreadStyle.pinned:
          return DOT_PINNED_BACKGROUND_COLOR;
      }
    }

    threadDotGlow(style) {
      switch (style) {
        case ThreadStyle.highlighted:
          return {
            size: DOT_SHINE_HIGHLIGHTED_SIZE_2,
            color: DOT_SHINE_HIGHLIGHTED_COLOR
          };

        case ThreadStyle.pinned:
          return {
            size: DOT_SHINE_HIGHLIGHTED_SIZE_2,
            color: DOT_SHINE_PINNED_COLOR
          };

        case ThreadStyle.normal:
          return null;
      }
    }

    threadBorder(style) {
      switch (style) {
        case ThreadStyle.pinned:
          return COMMENT_BACKGROUND_PINNED_STROKE;

        case ThreadStyle.highlighted:
        case ThreadStyle.normal:
          return null;
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "network", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "hitRegions", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "hitRegions"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "aggregatedTypesetter", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "aggregatedTypesetter"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "textContentTypesetter", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "textContentTypesetter"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "rangeX", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "rangeX"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "threadIdCache", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "threadIdCache"), _class2.prototype)), _class2)) || _class);
  _exports.default = VisualizationCommentsCanvas;
});