define("clarify/components/text-engine/index", ["exports", "@glimmer/component", "quill", "ui/helpers/normalize-key-codes", "clarify/models/item"], function (_exports, _component, _quill, _normalizeKeyCodes, _item) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.AutoCompleteTokenModule = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _temp;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Karl @placement="top-end" @offclick={{false}} @visible={{if this.autoCompleteSession true false}} as |karl|>
    <div {{did-insert (fn karl.setTarget)}} {{will-destroy (fn karl.setTarget)}}>
      {{yield
        (hash
          open=(action "open")
          autoComplete=this.autoCompleteSession
          editor=(component
            "text-engine/editor"
            setAPI=(action "setAPI")
            modules=(hash keyboard=this.keyboard)
            handleFocus=(action "handleFocus")
            handleBlur=(action "handleBlur")
          )
        )
      }}
    </div>
    <karl.container>
      <DropdownList
        class="text-engine-auto-complete-dropdown-list"
        @emptyState={{component
          "empty-state"
          style="normal"
          text=(t (concat @autoCompleteEmptyStateKey "." this.autoCompleteSession.source.type))
        }}
        @emptyStateClassName="text-engine-auto-complete-empty-state"
        @select={{action "selectDropdownItem"}}
        @highlightedIndex={{this.selectedIndex}}
        @items={{this.result}}
      />
    </karl.container>
  </Karl>
  */
  {"id":"sJxDlJj/","block":"{\"symbols\":[\"karl\",\"&default\",\"@autoCompleteEmptyStateKey\"],\"statements\":[[8,\"karl\",[],[[\"@placement\",\"@offclick\",\"@visible\"],[\"top-end\",false,[30,[36,0],[[32,0,[\"autoCompleteSession\"]],true,false],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[11,\"div\"],[4,[38,2],[[30,[36,1],[[32,1,[\"setTarget\"]]],null]],null],[4,[38,3],[[30,[36,1],[[32,1,[\"setTarget\"]]],null]],null],[12],[2,\"\\n    \"],[18,2,[[30,[36,5],null,[[\"open\",\"autoComplete\",\"editor\"],[[30,[36,4],[[32,0],\"open\"],null],[32,0,[\"autoCompleteSession\"]],[30,[36,6],[\"text-engine/editor\"],[[\"setAPI\",\"modules\",\"handleFocus\",\"handleBlur\"],[[30,[36,4],[[32,0],\"setAPI\"],null],[30,[36,5],null,[[\"keyboard\"],[[32,0,[\"keyboard\"]]]]],[30,[36,4],[[32,0],\"handleFocus\"],null],[30,[36,4],[[32,0],\"handleBlur\"],null]]]]]]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[8,[32,1,[\"container\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"dropdown-list\",[[24,0,\"text-engine-auto-complete-dropdown-list\"]],[[\"@emptyState\",\"@emptyStateClassName\",\"@select\",\"@highlightedIndex\",\"@items\"],[[30,[36,6],[\"empty-state\"],[[\"style\",\"text\"],[\"normal\",[30,[36,8],[[30,[36,7],[[32,3],\".\",[32,0,[\"autoCompleteSession\",\"source\",\"type\"]]],null]],null]]]],\"text-engine-auto-complete-empty-state\",[30,[36,4],[[32,0],\"selectDropdownItem\"],null],[32,0,[\"selectedIndex\"]],[32,0,[\"result\"]]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"fn\",\"did-insert\",\"will-destroy\",\"action\",\"hash\",\"component\",\"concat\",\"t\"]}","meta":{"moduleName":"clarify/components/text-engine/index.hbs"}});

  const Embed = _quill.default.import('blots/embed');

  class AutoCompleteTokenModule extends Embed {
    static create(item) {
      let node = super.create();
      node.dataset.id = item.id;
      node.dataset.type = item.source.type;
      node.innerText = item.token;
      return node;
    }

    static value(node) {
      return {
        id: node.dataset.id,
        type: node.dataset.type,
        token: node.innerText.trim()
      };
    }

  }

  _exports.AutoCompleteTokenModule = AutoCompleteTokenModule;

  _defineProperty(AutoCompleteTokenModule, "blotName", 'auto-complete');

  _defineProperty(AutoCompleteTokenModule, "className", 'ql-auto-complete-token');

  _defineProperty(AutoCompleteTokenModule, "tagName", 'span');

  const isSpaceRegEx = /\s/;

  const isSpace = c => isSpaceRegEx.exec(c);

  function getWordAt(string, position) {
    let start = position - 1;
    let end = position;

    while (start >= 0 && !isSpace(string[start])) {
      start -= 1;
    }

    start = Math.max(0, start + 1);

    while (end < string.length && !isSpace(string[end])) {
      end += 1;
    }

    end = Math.max(start, end);
    return [start, string.substring(start, end)];
  }

  let TextEngine = (_dec = Ember.computed('autoCompleteSession'), _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember.computed('autoCompleteSession.results'), _dec7 = Ember._action, (_class = (_temp = class TextEngine extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "quill", null);

      _defineProperty(this, "selectedIndex", null);

      _defineProperty(this, "autoCompleteSession", null);

      _defineProperty(this, "escapeHandler", () => {
        Ember.set(this, 'autoCompleteSession', null);
        Ember.set(this, 'selectedIndex', null);
        return false;
      });

      _defineProperty(this, "enterHandler", () => {
        if (this.autoCompleteSession && this.selectedIndex != null) {
          let model = this.autoCompleteSession.results[this.selectedIndex];

          if (model) {
            this.insert(model);
            return false;
          }
        }

        return true;
      });

      _defineProperty(this, "upHandler", () => {
        if (this.autoCompleteSession && this.selectedIndex != null) {
          let index = this.selectedIndex <= 0 ? this.autoCompleteSession.results.length - 1 : this.selectedIndex - 1;
          Ember.set(this, 'selectedIndex', index);
          return false;
        }

        return true;
      });

      _defineProperty(this, "downHandler", () => {
        if (this.autoCompleteSession && this.selectedIndex != null) {
          let index = this.selectedIndex === this.autoCompleteSession.results.length - 1 ? 0 : this.selectedIndex + 1;
          Ember.set(this, 'selectedIndex', index);
          return false;
        }

        return true;
      });

      _defineProperty(this, "handleEditorChange", (_event, _delta, _old, source) => {
        if (!this.quill) {
          return;
        }

        if (source === 'user') {
          let selection = this.quill.getSelection();

          if (selection) {
            let cursorIndex = selection.index;
            let [leaf, offset] = this.quill.getLeaf(cursorIndex);

            if (leaf && leaf.text) {
              let [wordOffset, word] = getWordAt(leaf.text, offset);
              let prefix = word[0];
              let token = word.substr(1);
              let source = this.args.sources.find(source => source.trigger === prefix);

              if (source) {
                let results = source.filter(token || null);
                let leafOffset = leaf.offset(this.quill.scroll);
                Ember.set(this, 'autoCompleteSession', {
                  source,
                  query: word,
                  startIndex: leafOffset + wordOffset,
                  results
                });
                Ember.set(this, 'selectedIndex', results.length ? 0 : null);
                return;
              }
            }

            Ember.set(this, 'autoCompleteSession', null);
            Ember.set(this, 'selectedIndex', null);
          }
        }
      });
    }

    get show() {
      return !!this.autoCompleteSession;
    }

    willDestroy() {
      super.willDestroy();
      this.setAPI(null);
    }

    setAPI(quill) {
      if (this.quill) {
        this.quill.off('editor-change', this.handleEditorChange);
      }

      Ember.set(this, 'quill', quill);
      this.args.setAPI(quill);

      if (this.quill) {
        this.quill.on('editor-change', this.handleEditorChange);
      }
    }

    insert(autoCompleteItem) {
      if (!this.quill) {
        return;
      }

      let session = this.autoCompleteSession;

      if (session) {
        if (session.query) {
          this.quill.deleteText(session.startIndex, session.query.length);
          this.quill.insertEmbed(session.startIndex, 'auto-complete', autoCompleteItem, 'user');
          this.quill.setSelection(session.startIndex + 1, 0, 'user');
          return;
        }
      }
    }

    handleFocus() {
      console.log('focus!');
    }

    handleBlur() {
      console.log('blur!');
    }

    selectDropdownItem(_dropdownItem, index) {
      if (this.autoCompleteSession) {
        let item = this.autoCompleteSession.results[index];
        this.insert(item);
      }
    }

    get result() {
      if (!this.autoCompleteSession) {
        return null;
      }

      return this.autoCompleteSession.results.map(result => {
        return {
          title: result.title,
          subtitle: result.description || '',
          accessory: result.value instanceof _item.default ? result.value.engUnit : '',
          type: result.description ? 'subtitle' : 'user',
          value: result.value
        };
      });
    }

    get keyboard() {
      return {
        bindings: {
          completionEscape: {
            key: 'Escape',
            handler: this.escapeHandler
          },
          completionEnter: {
            key: 'Enter',
            handler: this.enterHandler
          },
          mentionUpHandler: {
            key: _normalizeKeyCodes.KeyCodes.up,
            handler: this.upHandler
          },
          mentionDownHandler: {
            key: _normalizeKeyCodes.KeyCodes.down,
            handler: this.downHandler
          },
          tab: {
            key: 'Tab',
            handler: this.enterHandler
          }
        }
      };
    }

    open(type) {
      let source = this.args.sources.find(source => source.type === type);

      if (source) {
        this.openSource(source);
      }
    }

    openSource(source) {
      if (!this.quill) {
        return;
      }

      let selection = this.quill.getSelection(true);
      this.quill.insertText(selection.index, source.trigger, 'user');
      this.quill.setSelection(selection.index + 1, 0, 'user');
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "show", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "show"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setAPI", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "setAPI"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleFocus", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "handleFocus"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleBlur", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "handleBlur"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectDropdownItem", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "selectDropdownItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "result", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "result"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "open", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "open"), _class.prototype)), _class));
  _exports.default = TextEngine;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TextEngine);
});