define("clarify/services/drag-coordinator", ["exports", "draggable/services/drag-coordinator"], function (_exports, _dragCoordinator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _dragCoordinator.default;
    }
  });
});