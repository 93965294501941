define("clarify/components/timeline-canvas/visualizations/status/index", ["exports", "@glimmer/component", "clarify/models/visualization-item", "clarify/models/item"], function (_exports, _component, _visualizationItem, _item) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <TimelineCanvas::Visualizations::Status::Source
    @slotIds={{@slotIds}}
    @visible={{@visible}}
    @source={{hash type="visualization" visualization=@visualization}}
    @interactions={{@interactions}} as |source|
  >
    <div class="visualization-status-visualization-items">
      {{#each this.visibleVisualizationItems as |visualizationItem|}}
        <TimelineCanvas::Visualizations::Status::Item
          @loading={{source.loadingSlots}}
          @ruler={{@ruler}}
          @rulers={{@rulers}}
          @timeRange={{@timeRange}}
          @visible={{@visible}}
          @domain={{@domain}}
          @verticalGridLines={{@verticalGridLines}}
          @width={{@width}}
          @plot={{map-get source.plots visualizationItem.id}}
          @rangeX={{@visibleRangeX}}
          @now={{@now}}
          @devicePixelRatio={{@devicePixelRatio}}
        />
      {{/each}}
    </div>
  </TimelineCanvas::Visualizations::Status::Source>
  */
  {"id":"zbGioaCo","block":"{\"symbols\":[\"source\",\"visualizationItem\",\"@ruler\",\"@rulers\",\"@timeRange\",\"@visible\",\"@domain\",\"@verticalGridLines\",\"@width\",\"@visibleRangeX\",\"@now\",\"@devicePixelRatio\",\"@slotIds\",\"@visualization\",\"@interactions\"],\"statements\":[[8,\"timeline-canvas/visualizations/status/source\",[],[[\"@slotIds\",\"@visible\",\"@source\",\"@interactions\"],[[32,13],[32,6],[30,[36,1],null,[[\"type\",\"visualization\"],[\"visualization\",[32,14]]]],[32,15]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"visualization-status-visualization-items\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"visibleVisualizationItems\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"timeline-canvas/visualizations/status/item\",[],[[\"@loading\",\"@ruler\",\"@rulers\",\"@timeRange\",\"@visible\",\"@domain\",\"@verticalGridLines\",\"@width\",\"@plot\",\"@rangeX\",\"@now\",\"@devicePixelRatio\"],[[32,1,[\"loadingSlots\"]],[32,3],[32,4],[32,5],[32,6],[32,7],[32,8],[32,9],[30,[36,0],[[32,1,[\"plots\"]],[32,2,[\"id\"]]],null],[32,10],[32,11],[32,12]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"map-get\",\"hash\",\"-track-array\",\"each\"]}","meta":{"moduleName":"clarify/components/timeline-canvas/visualizations/status/index.hbs"}});

  let VisualizationStatus = (_dec = Ember.computed('args.visualization.visualizationItems.@each.{visible}'), (_class = class VisualizationStatus extends _component.default {
    get visibleVisualizationItems() {
      return this.args.visualization.visualizationItems.filter(visualizationItem => visualizationItem.visible !== false && _visualizationItem.default.isAccessible(visualizationItem) && visualizationItem.item.type === _item.ItemType.enum);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "visibleVisualizationItems", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "visibleVisualizationItems"), _class.prototype)), _class));
  _exports.default = VisualizationStatus;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, VisualizationStatus);
});