define("clarify/utilities/locale", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.bestMatch = bestMatch;

  function parse(string) {
    let [language, subLanguage] = string.split('-');
    return {
      language,
      subLanguage
    };
  }

  function bestMatch(supported, userLanguages = navigator.languages) {
    supported = supported.map(lang => lang.toLowerCase());
    userLanguages = userLanguages.map(lang => lang.toLowerCase());
    let map = new Set(supported);

    for (let userLocale of userLanguages) {
      if (map.has(userLocale)) {
        return userLocale;
      }
    }

    for (let localeString of userLanguages) {
      let local = parse(localeString);

      for (let lang of supported) {
        let supportedLocale = parse(lang);

        if (local.language === supportedLocale.language) {
          return lang;
        }
      }
    }

    return supported[0];
  }
});