define("clarify/serializers/visualization-item", ["exports", "clarify/serializers/rest-layer"], function (_exports, _restLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class VisualizationItem extends _restLayer.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "root", 'visualizationItems');

      _defineProperty(this, "attrs", {
        item: {
          serialize: 'id',
          deserialize: 'records'
        }
      });
    }

    normalize(modelClass, resourceHash, prop) {
      const payload = { ...resourceHash,
        item: VisualizationItem.parse(resourceHash.item),
        displayOptions: resourceHash.displayOptions || {},
        thresholdOptions: resourceHash.thresholdOptions || {
          enable: false
        }
      };
      return super.normalize(modelClass, payload, prop);
    }

    serialize(snapshot, _options) {
      let payload = super.serialize(snapshot, {
        includeId: true
      });
      return {
        id: payload.id,
        item: payload.item,
        displayOptions: payload.displayOptions,
        thresholdOptions: payload.thresholdOptions
      };
    }

    static parse(attributes) {
      return { ...attributes,
        itemType: attributes.type,
        type: attributes.error ? 'ItemInaccessible' : 'Item'
      };
    }

  }

  _exports.default = VisualizationItem;
});