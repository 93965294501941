define("clarify/models/item-abstract", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Item extends _emberData.default.Model {}

  _exports.default = Item;
});