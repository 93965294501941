define("clarify/utilities/animator", ["exports", "clarify/utilities/spring"], function (_exports, _spring) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Animator extends Ember.Object {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "velocity", 0);

      _defineProperty(this, "value", null);

      _defineProperty(this, "desiredValue", null);

      _defineProperty(this, "isPaused", false);

      _defineProperty(this, "animation", null);

      _defineProperty(this, "lastFrame", null);

      _defineProperty(this, "accumulatedTime", 0);

      _defineProperty(this, "startValue", null);

      _defineProperty(this, "spring", (0, _spring.spring)(169, 26));

      _defineProperty(this, "step", time => {
        if (this.value === null || this.desiredValue === null || this.startValue === null || this.lastFrame === null) {
          this.animation = null;
          this.accumulatedTime = 0;
          return;
        }

        this.accumulatedTime += time - this.lastFrame;
        const elapsedTimeInSeconds = this.accumulatedTime / 1000;

        if (elapsedTimeInSeconds >= this.spring.duration) {
          Ember.set(this, 'value', this.desiredValue);
          this.accumulatedTime = 0;
          this.animation = null;
        } else {
          const displacement = this.spring.ease(elapsedTimeInSeconds);
          const delta = this.startValue - this.desiredValue;
          const currentValue = this.desiredValue - delta * displacement;
          Ember.set(this, 'value', currentValue);
          this.animation = requestAnimationFrame(this.step);
          this.lastFrame = time;
        }
      });
    }

    update(value) {
      if (Number.isFinite(value) === false) {
        return;
      }

      if (this.value === null) {
        Ember.set(this, 'value', value);
        Ember.set(this, 'desiredValue', value);
        return;
      }

      Ember.set(this, 'desiredValue', value);
      this.start();
    }

    start() {
      if (this.desiredValue !== this.value && this.animation === null && this.isPaused === false) {
        this.animation = requestAnimationFrame(this.step);
        this.accumulatedTime = 0;
        this.startValue = this.value;
        this.lastFrame = performance.now();
      }
    }

    setIsPaused(paused) {
      if (paused === true) {
        this.isPaused = paused;
        this.animation && cancelAnimationFrame(this.animation);
        this.animation = null;
        this.velocity = 0;
      } else if (this.isPaused) {
        this.isPaused = paused;
        this.start();
      }
    }

  }

  _exports.default = Animator;
});