define("clarify/utilities/rpc/time-series-rollup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TimeSeriesRollupRequestInfo = void 0;

  class TimeSeriesRollupRequestInfo {
    static create(id, parameters) {
      return {
        method: 'timeseries.Rollup',
        version: '0.5',
        id,
        parameters
      };
    }

  }

  _exports.TimeSeriesRollupRequestInfo = TimeSeriesRollupRequestInfo;
});