define("clarify/utilities/content-reference-parser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createTree = createTree;
  _exports.NodeType = void 0;
  let NodeType;
  _exports.NodeType = NodeType;

  (function (NodeType) {
    NodeType["text"] = "text";
    NodeType["contentReference"] = "content-reference";
    NodeType["root"] = "root";
  })(NodeType || (_exports.NodeType = NodeType = {}));

  function createTree(content, contentReferences) {
    let tree = {
      type: NodeType.root,
      children: []
    };
    let list = [];
    contentReferences.forEach(reference => {
      // Finish any nodes before the current tree
      let index = list.findIndex(entry => inside(reference, entry.contentReference));
      let parent = list[index] || tree;
      let finalizedNodes = list.splice(0, index !== -1 ? index : list.length);
      finalizedNodes.forEach(node => {
        finalizeNode(content, node);
      }); // Insert any text from the previous node, till the current content reference

      insertText(content, reference.start, parent); // Insert node for the current content reference

      let node = {
        children: [],
        contentReference: reference,
        type: NodeType.contentReference
      };
      parent.children.push(node);
      list.unshift(node);
    }); // Finish all started elements

    list.forEach(entry => {
      finalizeNode(content, entry);
    }); // Insert any text from the last node till the end of the content

    insertText(content, content.length, tree);
    return tree;
  }

  function inside(node, parent) {
    let nodeEnd = node.start + node.length;
    let parentEnd = parent.start + parent.length;
    return node.start >= parent.start && nodeEnd <= parentEnd;
  }

  function insertText(content, end, node) {
    let start = 0;

    if (node.children.length === 0) {
      start = node.type === NodeType.contentReference ? node.contentReference.start : 0;
    } else {
      let last = node.children[node.children.length - 1];

      if (last.type === NodeType.contentReference) {
        start = last.contentReference.start + last.contentReference.length;
      }
    }

    let textNode = createTextNode(content, start, end);

    if (textNode) {
      node.children.push(textNode);
    }
  }

  function createTextNode(content, start, end) {
    if (start === end) {
      return null;
    }

    let subContent = content.substring(start, end ? end : undefined);

    if (subContent.length === 0) {
      return null;
    }

    let textNode = {
      type: NodeType.text,
      content: subContent
    };
    return textNode;
  }

  function finalizeNode(content, node) {
    let end = node.contentReference.start + node.contentReference.length;
    insertText(content, end, node);
  }
});