define("clarify/components/organizations/show/timelines/state/failed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <section class="timeline-failed" data-placement={{@placement}}>
    <p class="timeline-failed-text">
      {{@content}}
    </p>
    <div class="timeline-failed-button">
      <Button @size="normal" @color="blue" @title={{t "timeline-card.timeline-state.retry"}} {{on "click" @action}} />
    </div>
  </section>
  */
  {"id":"e/QmsxDh","block":"{\"symbols\":[\"@placement\",\"@content\",\"@action\"],\"statements\":[[10,\"section\"],[14,0,\"timeline-failed\"],[15,\"data-placement\",[32,1]],[12],[2,\"\\n  \"],[10,\"p\"],[14,0,\"timeline-failed-text\"],[12],[2,\"\\n    \"],[1,[32,2]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"timeline-failed-button\"],[12],[2,\"\\n    \"],[8,\"button\",[[4,[38,1],[\"click\",[32,3]],null]],[[\"@size\",\"@color\",\"@title\"],[\"normal\",\"blue\",[30,[36,0],[\"timeline-card.timeline-state.retry\"],null]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"on\"]}","meta":{"moduleName":"clarify/components/organizations/show/timelines/state/failed.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});