define("clarify/helpers/delete-visualization-alert", ["exports", "clarify/helpers/alert"], function (_exports, _alert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DeleteVisualizationAlert extends _alert.default {
    compute(_parameters, hash) {
      return event => {
        let {
          visualization,
          action
        } = hash;
        let deleteButton = visualization.name ? this.intl.t('delete-visualization-alert.delete-button.named', {
          name: visualization.name
        }) : this.intl.t('delete-visualization-alert.delete-button.untitled');
        this.showAlert({
          title: this.intl.t('delete-visualization-alert.title', visualization),
          message: this.intl.t('delete-visualization-alert.message'),
          primaryButton: {
            title: deleteButton,
            style: 'destructive',
            action: action
          }
        }, event === null || event === void 0 ? void 0 : event.currentTarget);
      };
    }

  }

  _exports.default = DeleteVisualizationAlert;
});