define("clarify/helpers/localizable-error-description", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class LocalizableErrorDescription extends Ember.Helper {
    compute([error]) {
      Ember.setOwner(error, Ember.getOwner(this));
      return error.description;
    }

  }

  _exports.default = LocalizableErrorDescription;
});