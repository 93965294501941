define("clarify/modifiers/quill", ["exports", "ember-modifier", "quill"], function (_exports, _emberModifier, _quill) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _emberModifier.modifier)((element, _positional, hash) => {
    let options = { ...hash
    };
    let quill = new _quill.default(element, options);

    if (hash.onInit) {
      hash.onInit(quill);
    }
  });

  _exports.default = _default;
});