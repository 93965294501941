define("clarify/helpers/is-item-accessible", ["exports", "clarify/models/item"], function (_exports, _item) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class IsItemAccessibleHelper extends Ember.Helper {
    compute([item], _hash) {
      return _item.default.isAccessible(item);
    }

  }

  _exports.default = IsItemAccessibleHelper;
});