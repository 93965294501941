define("clarify/transforms/display-options", ["exports", "ember-data", "clarify/models/display-options"], function (_exports, _emberData, _displayOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DisplayOptionsTransform extends _emberData.default.Transform {
    deserialize(serialized) {
      return _displayOptions.default.create(serialized);
    }

    serialize(deserialized) {
      // @ts-ignore
      let payload = deserialized.getProperties(Object.keys(deserialized));
      return payload;
    }

  }

  _exports.default = DisplayOptionsTransform;
});