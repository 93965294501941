define("clarify/initializers/svg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'svg',
    initialize: async function (_register) {
      let response = await fetch('/assets/symbols.svg');

      if (response.status === 200) {
        let imageData = await response.text();
        var div = document.createElement('div');
        div.innerHTML = imageData;
        document.body.insertBefore(div, document.body.childNodes[0]);
      }
    }
  };
  _exports.default = _default;
});