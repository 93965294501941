define("clarify/utilities/rpc/label-integration-labels", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.LabelIntegrationLablesRequestInfo = void 0;

  class LabelIntegrationLablesRequestInfo {
    static create(id, parameters) {
      return {
        method: 'label.IntegrationLabels',
        version: '0.6',
        id,
        parameters
      };
    }

  }

  _exports.LabelIntegrationLablesRequestInfo = LabelIntegrationLablesRequestInfo;
});