define("clarify/components/timeline-canvas/tooltips/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div ...attributes class="tooltip-container" {{transform @left center=false}}>
    {{#each @tooltips key="id" as |tooltip|}}
      <TimelineCanvas::Tooltips::Item @tooltip={{tooltip}} />
    {{/each}}
  </div>
  */
  {"id":"XtVGpPEA","block":"{\"symbols\":[\"tooltip\",\"&attrs\",\"@left\",\"@tooltips\"],\"statements\":[[11,\"div\"],[17,2],[24,0,\"tooltip-container\"],[4,[38,0],[[32,3]],[[\"center\"],[false]]],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,4]],null]],null]],[[\"key\"],[\"id\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"timeline-canvas/tooltips/item\",[],[[\"@tooltip\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"transform\",\"-track-array\",\"each\"]}","meta":{"moduleName":"clarify/components/timeline-canvas/tooltips/index.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});