define("clarify/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cT4AkyqP",
    "block": "{\"symbols\":[],\"statements\":[[8,\"alert/provider\",[],[[],[]],null],[2,\" \"],[10,\"div\"],[14,0,\"application\"],[12],[10,\"div\"],[14,0,\"main-application-container\"],[12],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[13],[13],[2,\" \"],[1,[30,[36,1],[[30,[36,0],[\"modal\"],null]],null]]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "clarify/templates/application.hbs"
    }
  });

  _exports.default = _default;
});