define("clarify/components/keyword-filter/item", ["exports", "@glimmer/component", "ember-animated/transitions/fade"], function (_exports, _component, _fade) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <li class="keyword-filter-item">
    <Checkbox
      @disabled={{eq this.item.count 0}}
      @onChange={{fn @setEnabled this.item}}
      @checked={{this.isChecked}}
      @size="normal"
      @style="light" as |id|
    >
      <label
        for={{id}}
        class="keyword-filter-item-title"
        data-checked={{this.isChecked}}
        data-disabled={{eq this.item.count 0}}
      >
        {{this.item.name}}
        <span class="keyword-filter-item-title-count">
          {{t "keyword-filter.item.count" count=this.item.count}}
        </span>
      </label>
    </Checkbox>
  </li>
  <AnimatedContainer>
    {{#animated-if (and this.isChecked (gt @item.children.length 0)) duration=150 use=transition}}
      <ul class="keyword-filter-item-children">
        {{#each @item.children key="id" as |item|}}
          <KeywordFilter::Item @item={{item}} @isEnabled={{fn @isEnabled}} @setEnabled={{fn @setEnabled}} />
        {{/each}}
      </ul>
    {{/animated-if}}
  </AnimatedContainer>
  */
  {"id":"vhT8jbY+","block":"{\"symbols\":[\"item\",\"id\",\"@isEnabled\",\"@setEnabled\",\"@item\"],\"statements\":[[10,\"li\"],[14,0,\"keyword-filter-item\"],[12],[2,\"\\n  \"],[8,\"checkbox\",[],[[\"@disabled\",\"@onChange\",\"@checked\",\"@size\",\"@style\"],[[30,[36,3],[[32,0,[\"item\",\"count\"]],0],null],[30,[36,0],[[32,4],[32,0,[\"item\"]]],null],[32,0,[\"isChecked\"]],\"normal\",\"light\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"label\"],[15,\"for\",[32,2]],[14,0,\"keyword-filter-item-title\"],[15,\"data-checked\",[32,0,[\"isChecked\"]]],[15,\"data-disabled\",[30,[36,3],[[32,0,[\"item\",\"count\"]],0],null]],[12],[2,\"\\n      \"],[1,[32,0,[\"item\",\"name\"]]],[2,\"\\n      \"],[10,\"span\"],[14,0,\"keyword-filter-item-title-count\"],[12],[2,\"\\n        \"],[1,[30,[36,4],[\"keyword-filter.item.count\"],[[\"count\"],[[32,0,[\"item\",\"count\"]]]]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"],[13],[2,\"\\n\"],[8,\"animated-container\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,8],[[30,[36,7],[[32,0,[\"isChecked\"]],[30,[36,6],[[32,5,[\"children\",\"length\"]],0],null]],null]],[[\"duration\",\"use\"],[150,[35,5]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"ul\"],[14,0,\"keyword-filter-item-children\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,5,[\"children\"]]],null]],null]],[[\"key\"],[\"id\"]],[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"keyword-filter/item\",[],[[\"@item\",\"@isEnabled\",\"@setEnabled\"],[[32,1],[30,[36,0],[[32,3]],null],[30,[36,0],[[32,4]],null]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"fn\",\"-track-array\",\"each\",\"eq\",\"t\",\"transition\",\"gt\",\"and\",\"animated-if\"]}","meta":{"moduleName":"clarify/components/keyword-filter/item.hbs"}});

  class ItemComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "transition", _fade.default);
    }

    get item() {
      return this.args.item.item;
    }

    get isChecked() {
      return this.args.isEnabled(this.item);
    }

  }

  _exports.default = ItemComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ItemComponent);
});