define("clarify/utilities/rpc/keyword-labels", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.KeywordLabelsRequestInfo = void 0;

  class KeywordLabelsRequestInfo {
    static create(id, parameters) {
      return {
        method: 'keyword.Labels',
        version: '0.6',
        id,
        parameters
      };
    }

  }

  _exports.KeywordLabelsRequestInfo = KeywordLabelsRequestInfo;
});