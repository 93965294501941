define("clarify/components/organizations/show/timelines/state/empty-match", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="timeline-empty-match" data-placement={{@placement}}>
    <div class="timeline-empty-match-icon">
      {{svg-jar "#design-system-p-fig-search" width=64 height=64}}
    </div>
    <p class="timeline-empty-match-text">
      {{t "timeline-card.timeline-state.no-matches" htmlSafe=true}}
    </p>
  </div>
  */
  {"id":"bnFOklMN","block":"{\"symbols\":[\"@placement\"],\"statements\":[[10,\"div\"],[14,0,\"timeline-empty-match\"],[15,\"data-placement\",[32,1]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"timeline-empty-match-icon\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"#design-system-p-fig-search\"],[[\"width\",\"height\"],[64,64]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"p\"],[14,0,\"timeline-empty-match-text\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[\"timeline-card.timeline-state.no-matches\"],[[\"htmlSafe\"],[true]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"svg-jar\",\"t\"]}","meta":{"moduleName":"clarify/components/organizations/show/timelines/state/empty-match.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});