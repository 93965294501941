define("clarify/components/organizations/show/timelines/state/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="timeline-loading" ...attributes>
    <div class="timeline-loading-text">
      {{t "timeline-card.timeline-state.loading"}}
    </div>
    <div class="timeline-loading-spinner">
      {{svg-jar "design-system-spinner" width=44 height=44}}
    </div>
  </div>
  */
  {"id":"Wt+FBjim","block":"{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"div\"],[24,0,\"timeline-loading\"],[17,1],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"timeline-loading-text\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"timeline-card.timeline-state.loading\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"timeline-loading-spinner\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[\"design-system-spinner\"],[[\"width\",\"height\"],[44,44]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"svg-jar\"]}","meta":{"moduleName":"clarify/components/organizations/show/timelines/state/loading.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});