define("clarify/serializers/activity-group-reply", ["exports", "clarify/serializers/rest-layer"], function (_exports, _restLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class ActivityReply extends _restLayer.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "root", 'groups');

      _defineProperty(this, "attrs", {
        comments: {
          serialize: 'ids',
          deserialize: 'records'
        },
        timeline: {
          serialize: 'ids',
          deserialize: 'records'
        },
        thread: {
          serialize: 'ids',
          deserialize: 'records'
        }
      });
    }

  }

  _exports.default = ActivityReply;
});