define("clarify/components/button-share/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @route}}
    <LinkTo
      @route={{@route}}
      @model={{@model}}
      class="ac-button-share"
      data-style={{or @style "light"}}
      data-size={{or @size "normal"}}
      ...attributes
    >
      {{@title}}
    </LinkTo>
  {{else}}
    <button class="ac-button-share" data-style={{or @style "light"}} data-size={{or @size "normal"}} ...attributes>
      {{@title}}
    </button>
  {{/if}}
  */
  {"id":"yH9sw1kM","block":"{\"symbols\":[\"@style\",\"@size\",\"&attrs\",\"@title\",\"@route\",\"@model\"],\"statements\":[[6,[37,1],[[32,5]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"link-to\",[[24,0,\"ac-button-share\"],[16,\"data-style\",[30,[36,0],[[32,1],\"light\"],null]],[16,\"data-size\",[30,[36,0],[[32,2],\"normal\"],null]],[17,3]],[[\"@route\",\"@model\"],[[32,5],[32,6]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[32,4]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[11,\"button\"],[24,0,\"ac-button-share\"],[16,\"data-style\",[30,[36,0],[[32,1],\"light\"],null]],[16,\"data-size\",[30,[36,0],[[32,2],\"normal\"],null]],[17,3],[12],[2,\"\\n    \"],[1,[32,4]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"or\",\"if\"]}","meta":{"moduleName":"clarify/components/button-share/index.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});