define("clarify/modifiers/style", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _emberModifier.modifier)((element, [styles]) => {
    for (let [key, value] of Object.entries(styles)) {
      element.style[key] = value;
    }
  });

  _exports.default = _default;
});