define("clarify/transforms/ember-objects", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = emberObjectsTransform;

  function emberObjectsTransform(type) {
    class EmberObjectsTransform extends _emberData.default.Transform {
      deserialize(serialized) {
        return serialized.map(model => {
          return type.create(model);
        });
      }

      serialize(deserialized) {
        return deserialized.map(model => {
          // @ts-ignore
          return model.getProperties(Object.keys(model));
        });
      }

    }

    return EmberObjectsTransform;
  }
});