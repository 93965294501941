define("clarify/components/timeline-canvas/header/time-selection-bar", ["exports", "canvas/components/layer", "clarify/components/timeline-canvas/visualizations/comment/canvas", "ui/helpers/design-system-particles"], function (_exports, _layer, _canvas, _designSystemParticles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const backgroundColor = _designSystemParticles.darkSystemCanvasBgPrimary;
  const rulerHeight = 14;
  const majorTickWidth = 1;

  class TimeSelectionBarLayer extends _layer.default {
    render() {
      let context = this.context;
      context.fillStyle = backgroundColor;
      context.fillRect(0, 0, this.args.width, this.args.height);
      this.paintTimeRange(context);
      this.paintTicks(context, this.args.scale);
      this.args.rulers.forEach(ruler => {
        (0, _canvas.renderRuler)(ruler, context, this.args.scale, rulerHeight, this.args.height - rulerHeight);
      });
    }

    paintTicks(context, scale) {
      context.lineWidth = majorTickWidth;
      this.args.mainTicks.forEach(tick => {
        context.beginPath();

        if (this.args.timeRange && tick.value.valueOf() >= this.args.timeRange.from.valueOf() && tick.value.valueOf() <= this.args.timeRange.to.valueOf()) {
          context.strokeStyle = _designSystemParticles.darkNeutralTxtNeutralMediumstrong;
        } else {
          context.strokeStyle = _designSystemParticles.darkNeutralTxtNeutralFaded1up;
        }

        const position = scale(tick.value);
        const offsetPosition = Math.floor(position) + 0.5;
        context.moveTo(offsetPosition, 3);
        context.lineTo(offsetPosition, 3 + 9);
        context.stroke();
      });
    }

    paintTimeRange(context) {
      if (this.args.timeRange) {
        context.beginPath();
        context.fillStyle = _designSystemParticles.darkActionActionPrimaryBg;
        const from = this.args.scale(this.args.timeRange.from);
        const to = this.args.scale(this.args.timeRange.to);
        context.rect(Math.round(from), 0, Math.round(to) - Math.round(from), this.args.height - 1);
        context.fill();
      }
    }

  }

  _exports.default = TimeSelectionBarLayer;
});