define("clarify/templates/logout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "riqzAwoY",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[12],[1,[30,[36,0],[\"logout.logOutInProgress\"],null]],[13]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "clarify/templates/logout.hbs"
    }
  });

  _exports.default = _default;
});