define("clarify/components/timeline-canvas/visualizations/layers/status", ["exports", "canvas/components/layer", "clarify/utilities/bisect", "clarify/colors", "clarify/drag-images/item", "clarify/utilities/colors"], function (_exports, _layer, _bisect, _colors, _item, _colors2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const CONTENT_INSET = {
    top: 8,
    bottom: 8
  };
  const STATUS_LAYER_MINIMUM_TEXT_WIDTH = 28;
  let StatusLayer = (_dec = Ember.inject.service, _dec2 = Ember.computed('context'), _dec3 = Ember.computed('context'), (_class = (_temp = class StatusLayer extends _layer.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);
    }

    // Computed property
    get lightTextColorTypesetter() {
      let typesetter = (0, _item.createTypesetter)(this.context, {
        fill: 'white',
        font: '13px roboto'
      });
      typesetter.wrapper.textTrimming('none');
      return typesetter;
    }

    get darkTextColorTypesetter() {
      let typesetter = (0, _item.createTypesetter)(this.context, {
        fill: 'black',
        font: '13px roboto'
      });
      typesetter.wrapper.textTrimming('none');
      return typesetter;
    }

    render() {
      let context = this.context;

      if (this.args.plot) {
        this.paintPlot(this.args.plot, context, this.args.rangeX);
      }
    }

    paintPlot(segment, context, rangeX) {
      let dataPoints = (0, _bisect.limit)(segment.dataPoints, dataPoint => dataPoint.from.getTime(), this.args.from.getTime(), this.args.to.getTime());
      dataPoints.forEach(dataPoint => {
        let fromPosition = rangeX(dataPoint.from);
        let toPosition = rangeX(dataPoint.to);
        let length = toPosition - fromPosition;
        context.beginPath();
        context.fillStyle = _colors.graphColors.get(dataPoint.color);
        context.rect(fromPosition, CONTENT_INSET.top, length, this.args.height - CONTENT_INSET.top - CONTENT_INSET.bottom);
        context.fill(); // Print text

        if (length >= STATUS_LAYER_MINIMUM_TEXT_WIDTH) {
          context.save();
          let text = dataPoint.text || this.intl.t('status.unknown-case');
          let dimensions = this.lightTextColorTypesetter.measurer.measure(text);
          let displayFull = length - 4 - 4 > dimensions.width;

          if (displayFull) {
            context.translate(fromPosition + (length - dimensions.width) / 2, CONTENT_INSET.top);
          } else {
            context.clip();
            context.translate(fromPosition + 4, CONTENT_INSET.top);
          }

          let textColor = _colors.textColors.get(dataPoint.color) || _colors2.TextColor.dark;

          let typesetter = textColor === _colors2.TextColor.light ? this.lightTextColorTypesetter : this.darkTextColorTypesetter;
          typesetter.write(text, dimensions.width, this.args.height - CONTENT_INSET.top - CONTENT_INSET.bottom, {
            yAlign: 'center'
          });
          context.restore();
        }

        context.closePath();
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "lightTextColorTypesetter", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "lightTextColorTypesetter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "darkTextColorTypesetter", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "darkTextColorTypesetter"), _class.prototype)), _class));
  _exports.default = StatusLayer;
});