define("clarify/components/off-click", ["exports", "gesture-recognizer/gestures/tap"], function (_exports, _tap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let OffClickGesture = (_dec = Ember.inject.service, (_class = (_temp = class OffClickGesture extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "offClick", void 0);

      _defineProperty(this, "enabled", true);

      _defineProperty(this, "triggerOnInteractiveElements", true);

      _defineProperty(this, "tapGestureRecognizer", _tap.default.create());

      _initializerDefineProperty(this, "gesture", _descriptor, this);

      _defineProperty(this, "tapGestureRecognizerDidChange", () => {
        this.offClick();
      });
    }

    didInsertElement() {
      super.didInsertElement();
      this.tapGestureRecognizer.delegate = this;
      this.tapGestureRecognizer.didChange = this.tapGestureRecognizerDidChange;
      this.gesture.add([this.tapGestureRecognizer], document.body);
    }

    willDestroyElement() {
      super.willDestroyElement();
      this.gesture.delete([this.tapGestureRecognizer], document.body);
    }

    shouldBegin(_gestureRecognizer, event) {
      if (!this.enabled) {
        return false;
      }

      let target = event.target;

      if (!this.triggerOnInteractiveElements) {
        if (target.closest('a, button')) {
          return false;
        }
      }

      return !this.element.contains(target) && !!target.closest('.main-application-container');
    }

    shouldBeRequiredToFailBy(_gestureRecognizer, _requiredToFailBy) {
      return true;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "gesture", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = OffClickGesture;
});