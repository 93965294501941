define("clarify/components/routes/organizations/new/-organization", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="onboarding-content">
    <Routes::Organizations::New::-header
      @progress={{t "organizations-new.organization.header.progress"}}
      @title={{t "organizations-new.organization.header.title"}}
    />
    <p class="organization-new-form--description">
      {{t "organizations-new.organization.description"}}
    </p>
    <form class="organization-new-form" {{on "submit" @onSubmit}}>
      <fieldset class="onboarding-fiedset">
        <label class="onboarding-fiedset--label">
          {{t "organizations-new.organization.form.name.label"}}
        </label>
        <InputText
          placeholder={{t "organizations-new.organization.form.name.placeholder"}}
          @size="extra-large"
          data-validity={{if @form.fields.name.isInvalid "invalid"}}
          {{set-focus true}}
          {{setup-field @form.fields.name}}
        />
      </fieldset>
      <FlatButton
        class="organization-new-form--button"
        @size="extra-large"
        @text={{t "organizations-new.organization.form.submit"}}
        @type="submit"
      />
    </form>
  </div>
  */
  {"id":"rPAOsb+g","block":"{\"symbols\":[\"@onSubmit\",\"@form\"],\"statements\":[[10,\"div\"],[14,0,\"onboarding-content\"],[12],[2,\"\\n  \"],[8,\"routes/organizations/new/-header\",[],[[\"@progress\",\"@title\"],[[30,[36,0],[\"organizations-new.organization.header.progress\"],null],[30,[36,0],[\"organizations-new.organization.header.title\"],null]]],null],[2,\"\\n  \"],[10,\"p\"],[14,0,\"organization-new-form--description\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"organizations-new.organization.description\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[11,\"form\"],[24,0,\"organization-new-form\"],[4,[38,1],[\"submit\",[32,1]],null],[12],[2,\"\\n    \"],[10,\"fieldset\"],[14,0,\"onboarding-fiedset\"],[12],[2,\"\\n      \"],[10,\"label\"],[14,0,\"onboarding-fiedset--label\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"organizations-new.organization.form.name.label\"],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[8,\"input-text\",[[16,\"placeholder\",[30,[36,0],[\"organizations-new.organization.form.name.placeholder\"],null]],[16,\"data-validity\",[30,[36,2],[[32,2,[\"fields\",\"name\",\"isInvalid\"]],\"invalid\"],null]],[4,[38,3],[true],null],[4,[38,4],[[32,2,[\"fields\",\"name\"]]],null]],[[\"@size\"],[\"extra-large\"]],null],[2,\"\\n    \"],[13],[2,\"\\n    \"],[8,\"flat-button\",[[24,0,\"organization-new-form--button\"]],[[\"@size\",\"@text\",\"@type\"],[\"extra-large\",[30,[36,0],[\"organizations-new.organization.form.submit\"],null],\"submit\"]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"on\",\"if\",\"set-focus\",\"setup-field\"]}","meta":{"moduleName":"clarify/components/routes/organizations/new/-organization.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});