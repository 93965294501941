define("clarify/components/timeline-canvas/visualizations/status/source", ["exports", "@glimmer/component", "clarify/services/timeseries-data-provider", "clarify/models/visualization-item", "clarify/utilities/compact-map", "clarify/utilities/merge-time-series"], function (_exports, _component, _timeseriesDataProvider, _visualizationItem, _compactMap, _mergeTimeSeries) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    class="visualization-line-chart visualization-status"
    {{did-update this.didUpdateVisualization @interactions @slotIds @visible}}
  >
    {{yield this}}
  </div>
  */
  {"id":"UG6JX4F1","block":"{\"symbols\":[\"@visible\",\"@slotIds\",\"@interactions\",\"&default\"],\"statements\":[[11,\"div\"],[24,0,\"visualization-line-chart visualization-status\"],[4,[38,0],[[32,0,[\"didUpdateVisualization\"]],[32,3],[32,2],[32,1]],null],[12],[2,\"\\n  \"],[18,4,[[32,0]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"did-update\"]}","meta":{"moduleName":"clarify/components/timeline-canvas/visualizations/status/source.hbs"}});

  let VisualizationStatus = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember.inject.service('timeseries-data-provider'), _dec6 = Ember.computed('args.slotIds.[]', 'slots', 'loadedSlots'), _dec7 = Ember._action, (_class = (_temp = class VisualizationStatus extends _component.default {
    // Properties
    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "segments", _descriptor, this);

      _initializerDefineProperty(this, "plots", _descriptor2, this);

      _initializerDefineProperty(this, "loadedSlots", _descriptor3, this);

      _initializerDefineProperty(this, "slots", _descriptor4, this);

      _defineProperty(this, "range", {
        from: 0,
        to: 0
      });

      _initializerDefineProperty(this, "dataProvider", _descriptor5, this);

      this.didUpdateVisualization();

      Ember._registerDestructor(this, () => {
        this.abort();
      });
    }

    get loadingSlots() {
      if (this.slots.state === 'loading') {
        return this.args.slotIds.filter(slot => {
          return !this.loadedSlots.find(loadedSlot => loadedSlot.isEqual(slot));
        });
      }

      return [];
    }

    abort() {
      if (this.slots.state === 'loading') {
        this.slots.abortController.abort();
        Ember.set(this, 'slots', this.loadedSlots.length ? {
          state: 'loaded'
        } : {
          state: 'uninitialized'
        });
      }
    }

    didUpdateVisualization() {
      if (this.args.visible === false) {
        this.abort();
        return;
      }

      let hasInteractions = this.args.interactions.size !== 0;

      if (this.args.slotIds.length) {
        let {
          from
        } = this.args.slotIds[0];
        let {
          to
        } = this.args.slotIds[this.args.slotIds.length - 1];

        if (this.range.from !== from.valueOf() || this.range.to !== to.valueOf()) {
          if (hasInteractions === false) {
            this.range = {
              from: from.valueOf(),
              to: to.valueOf()
            };
            this.fetchData();
          } else {
            this.abort();
          }
        }
      }
    }

    async fetchData() {
      let abortController = new AbortController();
      Ember.set(this, 'slots', {
        state: 'loading',
        abortController
      });
      let slots = [...this.args.slotIds];
      let promises;

      switch (this.args.source.type) {
        case 'visualization':
          promises = (0, _compactMap.default)(this.args.source.visualization.visualizationItems.toArray(), visualizationItem => {
            if (_visualizationItem.default.isAccessible(visualizationItem) && visualizationItem.item.type === 'enum') {
              return this.fetchVisualizationItem(this.args.slotIds, visualizationItem, abortController.signal);
            }

            return null;
          });
          break;

        case 'item':
          promises = [this.fetchItem(this.args.slotIds, this.args.source.item, abortController.signal)];
          break;
      }

      try {
        let data = await Promise.all(promises);
        let segments = (0, _compactMap.default)(data, plot => plot);
        this.plots = segments.reduce((plots, plot) => {
          plots.set(plot.identifier, plot);
          return plots;
        }, new Map());
        this.segments = segments;
        this.loadedSlots = slots;
        this.slots = {
          state: 'loaded'
        };
      } catch (error) {
        switch (error.name) {
          case 'AbortError':
            break;

          default:
            console.error('Failed fetching status block: ', error);
            Ember.set(this, 'slots', {
              state: 'failed',
              error,
              slots
            });
            break;
        }
      }
    }

    async fetchVisualizationItem(slots, visualizationItem, signal) {
      let values = slots.map(slot => {
        return this.dataProvider.fetchLineChart(visualizationItem.item, slot.from, slot.to, signal);
      });
      let result = await Promise.all(values);
      let data = (0, _mergeTimeSeries.mergeTimeSeries)(result);

      if (data) {
        return _timeseriesDataProvider.default.parsePayloadIntoStatusPlot(visualizationItem, data);
      }

      return null;
    }

    async fetchItem(slots, item, signal) {
      let values = slots.map(slot => {
        return this.dataProvider.fetchLineChart(item, slot.from, slot.to, signal);
      });
      let result = await Promise.all(values);
      let data = (0, _mergeTimeSeries.mergeTimeSeries)(result);

      if (data) {
        return _timeseriesDataProvider.default.parsePayloadIntoStatusPlot(item, data);
      }

      return null;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "segments", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "plots", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new Map();
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "loadedSlots", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "slots", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        state: 'uninitialized'
      };
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "dataProvider", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loadingSlots", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "loadingSlots"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "didUpdateVisualization", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "didUpdateVisualization"), _class.prototype)), _class));
  _exports.default = VisualizationStatus;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, VisualizationStatus);
});