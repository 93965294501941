define("clarify/components/routes/organizations/new/-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div ...attributes class="onboarding-step-header">
    {{#if @progress}}
      <span class="onboarding-step-header--progress">
        {{@progress}}
      </span>
    {{/if}}
    <h2 class="onboarding-step-header--title">
      {{@title}}
    </h2>
  </div>
  */
  {"id":"QHesBwsd","block":"{\"symbols\":[\"@progress\",\"&attrs\",\"@title\"],\"statements\":[[11,\"div\"],[17,2],[24,0,\"onboarding-step-header\"],[12],[2,\"\\n\"],[6,[37,0],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"onboarding-step-header--progress\"],[12],[2,\"\\n      \"],[1,[32,1]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"h2\"],[14,0,\"onboarding-step-header--title\"],[12],[2,\"\\n    \"],[1,[32,3]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}","meta":{"moduleName":"clarify/components/routes/organizations/new/-header.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});