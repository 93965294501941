define("clarify/templates/organizations/show/timelines/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/WXwYnfD",
    "block": "{\"symbols\":[],\"statements\":[[8,\"app-header\",[],[[\"@style\"],[\"light\"]],null],[2,\" \"],[8,\"app-sub-header\",[],[[\"@style\"],[\"light\"]],null],[2,\" \"],[8,\"organizations/show/timelines\",[],[[\"@timelines\",\"@keywords\",\"@organization\"],[[32,0,[\"timelines\"]],[32,0,[\"keywords\"]],[32,0,[\"organization\"]]]],null],[2,\" \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "clarify/templates/organizations/show/timelines/index.hbs"
    }
  });

  _exports.default = _default;
});