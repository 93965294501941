define("clarify/components/alert/provider", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.activeAlert}}
    <Alert
      @title={{this.activeAlert.title}}
      @message={{this.activeAlert.message}}
      @alertActions={{this.activeAlert.actions}}
      @dismiss={{fn this.dismissAlert}}
    />
  {{/if}}
  */
  {"id":"zxuUXol2","block":"{\"symbols\":[],\"statements\":[[6,[37,1],[[32,0,[\"activeAlert\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"alert\",[],[[\"@title\",\"@message\",\"@alertActions\",\"@dismiss\"],[[32,0,[\"activeAlert\",\"title\"]],[32,0,[\"activeAlert\",\"message\"]],[32,0,[\"activeAlert\",\"actions\"]],[30,[36,0],[[32,0,[\"dismissAlert\"]]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"fn\",\"if\"]}","meta":{"moduleName":"clarify/components/alert/provider.hbs"}});

  let AlertProvider = (_dec = Ember.inject.service, _dec2 = Ember._action, (_class = (_temp = class AlertProvider extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "alert", _descriptor, this);
    }

    get activeAlert() {
      return this.alert.activeAlert;
    }

    dismissAlert() {
      this.alert.dismissAlert();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "alert", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "dismissAlert", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "dismissAlert"), _class.prototype)), _class));
  _exports.default = AlertProvider;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, AlertProvider);
});