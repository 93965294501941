define("clarify/components/organizations/show/timelines/state/loading-epilogue", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="timeline-epilogue-loading" ...attributes>
    <div class="timeline-epilogue-loading-text">
      {{@content}}
    </div>
    <div class="timeline-epilogue-loading-spinner">
      {{svg-jar "design-system-spinner" width=44 height=44}}
    </div>
  </div>
  */
  {"id":"5Mfero3K","block":"{\"symbols\":[\"&attrs\",\"@content\"],\"statements\":[[11,\"div\"],[24,0,\"timeline-epilogue-loading\"],[17,1],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"timeline-epilogue-loading-text\"],[12],[2,\"\\n    \"],[1,[32,2]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"timeline-epilogue-loading-spinner\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"design-system-spinner\"],[[\"width\",\"height\"],[44,44]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"svg-jar\"]}","meta":{"moduleName":"clarify/components/organizations/show/timelines/state/loading-epilogue.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});