define("clarify/components/timeline-canvas-legend-settings/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="timeline-canvas-legend-settings-header">
    <span class="timeline-canvas-legend-settings-header--icon">
      {{yield to="icon"}}
    </span>
    <div class="timeline-canvas-legend-settings-header--input">
      {{yield to="input"}}
    </div>
    <div class="timeline-canvas-legend-settings-header--buttons">
      {{yield to="buttons"}}
    </div>
  </div>
  */
  {"id":"nHC9VQuX","block":"{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"timeline-canvas-legend-settings-header\"],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"timeline-canvas-legend-settings-header--icon\"],[12],[2,\"\\n    \"],[18,1,[[30,[36,0],[\"icon\"],null]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"timeline-canvas-legend-settings-header--input\"],[12],[2,\"\\n    \"],[18,1,[[30,[36,0],[\"input\"],null]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"timeline-canvas-legend-settings-header--buttons\"],[12],[2,\"\\n    \"],[18,1,[[30,[36,0],[\"buttons\"],null]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"-named-block-invocation\"]}","meta":{"moduleName":"clarify/components/timeline-canvas-legend-settings/header.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});