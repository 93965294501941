define("clarify/utilities/associated-models", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  /// Keeps and updates an assosiation between a view model and a model. Allows the underlaying model
  /// to change and be replaced without loosing the data in the view model.
  let AssociatedModelDiffer = (_dec = Ember.computed('models.[]'), (_class = (_temp = class AssociatedModelDiffer {
    constructor(models, viewModelConstructor) {
      _defineProperty(this, "modelViewModelMap", new Map());

      _defineProperty(this, "models", void 0);

      _defineProperty(this, "viewModelConstructor", void 0);

      this.models = models;
      this.viewModelConstructor = viewModelConstructor;
    }

    get viewModels() {
      let removed = new Map(this.modelViewModelMap);
      let viewModels = this.models.map(model => {
        removed.delete(model);
        let viewModel = this.modelViewModelMap.get(model);

        if (viewModel) {
          return viewModel;
        }

        viewModel = new this.viewModelConstructor(model);
        this.modelViewModelMap.set(model, viewModel);
        return viewModel;
      });

      for (let [model] of removed) {
        this.modelViewModelMap.delete(model);
      }

      return viewModels;
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "viewModels", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "viewModels"), _class.prototype)), _class));
  _exports.default = AssociatedModelDiffer;
});