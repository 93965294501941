define("clarify/controllers/organizations/show/activities", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TimelinesShowActivitiesIndex extends Ember.Controller {}

  _exports.default = TimelinesShowActivitiesIndex;
});