define("clarify/utilities/resource", ["exports", "@ember-decorators/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let Resource = (_dec = Ember.computed('status'), _dec2 = Ember.computed('status'), _dec3 = Ember.computed('pagination'), _dec4 = (0, _object.observes)('models.@each.dirtyType'), _dec5 = Ember._action, (_class = (_temp = class Resource extends Ember.Object {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "store", void 0);

      _defineProperty(this, "query", void 0);

      _defineProperty(this, "type", void 0);

      _defineProperty(this, "limit", 10);

      _defineProperty(this, "cacheKey", void 0);

      _defineProperty(this, "includeTotal", true);

      _defineProperty(this, "adapterOptions", null);

      _defineProperty(this, "models", []);

      _defineProperty(this, "status", {
        type: 'initial'
      });

      _defineProperty(this, "pagination", null);
    }

    get loading() {
      return this.status.type === 'loading';
    }

    get failed() {
      return this.status.type === 'failed';
    }

    get hasMoreContent() {
      if (this.pagination) {
        return this.pagination.offset + this.limit < this.pagination.total;
      }

      return true;
    }

    insert(model) {
      if (this.models.includes(model) === false) {
        this.models.insertAt(0, model);
      }
    } /// This is by no mean ideal, but to get the rest of the stack to be reactive,
    /// we rely on the array being one instance.


    removeDestroyedObjects() {
      for (let model of this.models) {
        if (Ember.get(model, 'dirtyType') === 'deleted') {
          this.models.removeObject(model);
        }
      }
    }

    async load() {
      if (!this.hasMoreContent) {
        return Promise.reject(new Error('Trying to load an already loaded collection'));
      }

      if (this.status.type === 'loading') {
        return Promise.reject(new Error('Resource is already loading'));
      }

      try {
        Ember.set(this, 'status', {
          type: 'loading'
        });
        let query = { ...this.query,
          ...this.paginationQuery
        };

        if (this.includeTotal) {
          query['total'] = '1';
        }

        let models = await this.store.query(this.type, query, {
          adapterOptions: this.adapterOptions
        });
        Ember.set(this, 'status', {
          type: 'loaded'
        }); // @ts-ignore

        let pagination = Ember.get(models, 'meta.pagination');
        Ember.set(this, 'pagination', pagination);
        this.models.addObjects(models);
        return this.models.toArray();
      } catch (error) {
        Ember.set(this, 'status', {
          type: 'failed',
          error
        });
        return Promise.reject(error);
      }
    }

    get paginationQuery() {
      if (this.pagination) {
        return {
          skip: this.pagination.offset + this.limit,
          limit: this.limit
        };
      }

      return {
        limit: this.limit
      };
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "loading", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "loading"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "failed", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "failed"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hasMoreContent", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "hasMoreContent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeDestroyedObjects", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "removeDestroyedObjects"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "load", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "load"), _class.prototype)), _class));
  _exports.default = Resource;
});