define("clarify/initializers/gestures", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'gestures',
    initialize: function (register) {
      register.registerOptionsForType('gesture', {
        singleton: false
      });
    }
  };
  _exports.default = _default;
});