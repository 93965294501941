define("clarify/templates/organizations/show/timelines/show/stats/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jOG49LU6",
    "block": "{\"symbols\":[\"visualization\"],\"statements\":[[6,[37,2],[[30,[36,1],[[30,[36,1],[[30,[36,0],[[32,0,[\"visualization\"]]],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[8,\"m-table\",[],[[\"@timeRange\",\"@visualization\"],[[32,0,[\"timeRange\"]],[32,1]]],null]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"array\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "clarify/templates/organizations/show/timelines/show/stats/show.hbs"
    }
  });

  _exports.default = _default;
});