define("clarify/helpers/delete-comment-alert", ["exports", "clarify/helpers/alert"], function (_exports, _alert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DeleteCommentAlert extends _alert.default {
    compute(_parameters, hash) {
      return event => {
        this.showAlert({
          title: this.intl.t('delete-comment-alert.title'),
          message: this.intl.t('delete-comment-alert.message'),
          primaryButton: {
            title: this.intl.t('delete-comment-alert.delete-button.name'),
            style: 'destructive',
            action: hash.action
          }
        }, event === null || event === void 0 ? void 0 : event.currentTarget);
      };
    }

  }

  _exports.default = DeleteCommentAlert;
});