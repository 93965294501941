define("clarify/externals/segment", ["clarify/environment"], function (_environment) {
  "use strict";

  var analytics = window.analytics = window.analytics || [];

  if (!analytics.initialize) {
    if (analytics.invoked) {
      window.console && console.error && console.error('Segment snippet included twice.');
    } else {
      analytics.invoked = true;
      analytics.methods = ['trackSubmit', 'trackClick', 'trackLink', 'trackForm', 'pageview', 'identify', 'reset', 'group', 'track', 'ready', 'alias', 'debug', 'page', 'once', 'off', 'on'];

      analytics.factory = function (t) {
        return function () {
          var e = Array.prototype.slice.call(arguments);
          e.unshift(t);
          analytics.push(e);
          return analytics;
        };
      };

      analytics.methods.forEach(function (name) {
        analytics[name] = analytics.factory(name);
      });

      analytics.load = function (appId) {
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.defer = true;
        script.src = `https://cdn.segment.com/analytics.js/v1/${appId}/analytics.min.js`;
        document.body.appendChild(script);
      };

      analytics.SNIPPET_VERSION = '4.1.0';

      if (_environment.default.APP.SEGMENT_PUBLIC_KEY) {
        analytics.load(_environment.default.APP.SEGMENT_PUBLIC_KEY);
      }
    }
  }
});