define("clarify/components/organizations/show/timelines/state/empty-timeline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <InitialState
    class="timeline-initial-state"
    @title={{t "timeline-card.timeline-state.no-timelines.title"}}
    @description={{t "timeline-card.timeline-state.no-timelines.description"}}
    @image="/assets/empty-timelines-state.png"
    @retinaImage="/assets/empty-timelines-state@2x.png"
    @width="272"
    @height="240"
  >
    <:button>
      <FlatButton::Link
        @style="primary"
        @size="medium"
        type="button"
        @text={{t "timeline-card.timeline-state.no-timelines.action"}}
        @route={{route "organizations.show.timelines.new"}}
      />
    </:button>
  </InitialState>
  */
  {"id":"S+Pkupil","block":"{\"symbols\":[\"__arg0\"],\"statements\":[[8,\"initial-state\",[[24,0,\"timeline-initial-state\"]],[[\"@title\",\"@description\",\"@image\",\"@retinaImage\",\"@width\",\"@height\",\"@namedBlocksInfo\"],[[30,[36,0],[\"timeline-card.timeline-state.no-timelines.title\"],null],[30,[36,0],[\"timeline-card.timeline-state.no-timelines.description\"],null],\"/assets/empty-timelines-state.png\",\"/assets/empty-timelines-state@2x.png\",\"272\",\"240\",[30,[36,2],null,[[\"button\"],[0]]]]],[[\"default\"],[{\"statements\":[[6,[37,4],[[30,[36,3],[[32,1],\"button\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"flat-button/link\",[[24,4,\"button\"]],[[\"@style\",\"@size\",\"@text\",\"@route\"],[\"primary\",\"medium\",[30,[36,0],[\"timeline-card.timeline-state.no-timelines.action\"],null],[30,[36,1],[\"organizations.show.timelines.new\"],null]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"route\",\"hash\",\"-is-named-block-invocation\",\"if\"]}","meta":{"moduleName":"clarify/components/organizations/show/timelines/state/empty-timeline.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});