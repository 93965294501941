define("clarify/adapters/thread", ["exports", "clarify/adapters/meta"], function (_exports, _meta) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Thread extends _meta.default {
    fields(_requestType) {
      return ['id', 'createdAt', 'createdBy', 'updatedAt', 'updatedBy', 'timestamp', 'timeline', 'replies', 'organization', 'comment:comments(limit:1,sort:"createdAt")', 'comments:comments(limit:2,sort:"createdAt")'];
    }

  }

  _exports.default = Thread;
});