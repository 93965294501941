define("clarify/transitions/slide-to-left", ["exports", "clarify/transitions/slide-in"], function (_exports, _slideIn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(opts) {
    return _slideIn.default.call(this, -1, opts);
  }
});