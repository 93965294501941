define("clarify/models/filter-auto-complete-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.FilterType = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class FilterAutoCompleteViewModel extends Ember.Object {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "data", void 0);
    }

    scoreForQuery(query) {
      switch (this.data.type) {
        case FilterType.query:
          return 1;

        case FilterType.keyword:
          return this.data.title.toLowerCase().indexOf(query.toLowerCase()) === -1 ? 0 : 1;
      }
    }

  }

  let FilterType;
  _exports.FilterType = FilterType;

  (function (FilterType) {
    FilterType["query"] = "query";
    FilterType["keyword"] = "keyword";
  })(FilterType || (_exports.FilterType = FilterType = {}));

  var _default = FilterAutoCompleteViewModel;
  _exports.default = _default;
});