define("clarify/routes/organizations/show/timelines/show/groups/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class GroupShowRoute extends Ember.Route {
    async model(params) {
      let group = await this.store.findRecord('group', params.groupId);
      return group;
    }

    setupController(controller, model, transition) {
      super.setupController(controller, model, transition);
      controller.set('group', model);
    }

    serialize(model) {
      return {
        groupId: model.id
      };
    }

  }

  _exports.default = GroupShowRoute;
});