define("clarify/templates/organizations/show/timelines/show/visualizations/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MTQpt7Pt",
    "block": "{\"symbols\":[],\"statements\":[[8,\"off-click\",[],[[\"@offClick\",\"@triggerOnInteractiveElements\"],[[30,[36,0],[[32,0],\"settingsMenuClosed\"],null],false]],[[\"default\"],[{\"statements\":[[8,\"visualization-settings\",[],[[\"@layoutModel\",\"@visualization\",\"@close\",\"@colorDidChange\",\"@deleteVisualization\",\"@lineChartRangeDidChange\",\"@removeVisualizationItem\",\"@settingsMenuClosed\",\"@sortItems\",\"@visibilityDidChange\",\"@visualizationNameDidChange\",\"@visualizationTypeDidChange\"],[[32,0,[\"layout\"]],[32,0,[\"visualization\"]],[30,[36,0],[[32,0],\"settingsMenuClosed\"],null],[30,[36,0],[[32,0],\"colorDidChange\"],null],[30,[36,0],[[32,0],\"deleteVisualization\"],null],[30,[36,0],[[32,0],\"lineChartRangeDidChange\"],null],[30,[36,0],[[32,0],\"removeVisualizationItem\"],null],[30,[36,0],[[32,0],\"settingsMenuClosed\"],null],[30,[36,0],[[32,0],\"sortItems\"],null],[30,[36,0],[[32,0],\"visibilityDidChange\"],null],[30,[36,0],[[32,0],\"visualizationNameDidChange\"],null],[30,[36,0],[[32,0],\"visualizationTypeDidChange\"],null]]],null]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "clarify/templates/organizations/show/timelines/show/visualizations/show.hbs"
    }
  });

  _exports.default = _default;
});