define("clarify/components/card/activity/comment/attachments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#each @comment.images key="name" as |attachment|}}
    <Comment::Image class="comment-comment-image" @maxImageSize={{@maxImageSize}} @attachment={{attachment}} />
  {{/each}}
  {{#each @comment.files key="name" as |attachment|}}
    <Comment::Attachment
      class="comment-comment-attachment m-card-activity-attachment"
      @icon={{false}}
      @attachment={{attachment}}
    />
  {{/each}}
  */
  {"id":"2a55sMMk","block":"{\"symbols\":[\"attachment\",\"attachment\",\"@maxImageSize\",\"@comment\"],\"statements\":[[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,4,[\"images\"]]],null]],null]],[[\"key\"],[\"name\"]],[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"comment/image\",[[24,0,\"comment-comment-image\"]],[[\"@maxImageSize\",\"@attachment\"],[[32,3],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,4,[\"files\"]]],null]],null]],[[\"key\"],[\"name\"]],[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"comment/attachment\",[[24,0,\"comment-comment-attachment m-card-activity-attachment\"]],[[\"@icon\",\"@attachment\"],[false,[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}","meta":{"moduleName":"clarify/components/card/activity/comment/attachments.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});