define("clarify/drag-images/visualization-item", ["exports", "clarify/drag-images/item", "clarify/models/item"], function (_exports, _item, _item2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ItemDragImage extends Ember.Object {
    draw(canvasConstructor, models, event) {
      let model = models[0];

      if (!model) {
        return null;
      }

      if (_item2.default.isAccessible(model.item)) {
        return (0, _item.drawItem)(model.item, canvasConstructor, event);
      }

      return null;
    }

  }

  _exports.default = ItemDragImage;
});