define("clarify/serializers/item", ["exports", "clarify/serializers/rest-layer", "clarify/serializers/permission"], function (_exports, _restLayer, _permission) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Item extends _restLayer.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "root", 'items');

      _defineProperty(this, "attrs", {
        permissions: {
          serialize: 'records',
          deserialize: 'records'
        }
      });
    }

    normalize(modelClass, resourceHash, prop) {
      let permissions = resourceHash.permissions ? _permission.default.fromProprietaryFormat(resourceHash.id, resourceHash.permissions) : undefined;
      let payload = { ...resourceHash,
        type: resourceHash.itemType || resourceHash.type,
        itemType: undefined,
        permissions
      };
      return super.normalize(modelClass, payload, prop);
    }

    serializeHasMany(snapshot, json, relationship) {
      let typedSnapshot = snapshot;
      let key = relationship.key;

      switch (key) {
        case 'permissions':
          let permissions = typedSnapshot.hasMany('permissions'); // @ts-ignore

          json.permissions = _permission.default.toProprietaryFormat(permissions);
          break;

        default:
          super.serializeHasMany(snapshot, json, relationship);
      }

      return;
    }

  }

  _exports.default = Item;
});