define("clarify/components/thread-pane/index", ["exports", "@glimmer/component", "clarify/models/visualization-item", "clarify/utilities/compact-map"], function (_exports, _component, _visualizationItem, _compactMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="thread-pane">
    <ThreadPane::Header @timestamp={{@thread.timestamp}} @close={{fn this.close}} />
    {{#if @thread.isNew}}
      <EmptyState @text={{t "thread.emptyState"}} @style="none" />
    {{else}}
      <div class="thread-content">
        <Thread
          @maxImageSize={{this.maxImageSize}}
          @headerStyle="normal"
          @thread={{@thread}}
          @enableDelete={{true}}
          @deleteThread={{fn this.deleteThread}}
        />
      </div>
    {{/if}}
    <div class="thread-composer-spacer"></div>
    <div class="thread-composer">
      <TextEditor
        @active={{readonly @thread.isNew}}
        @items={{this.items}}
        @users={{@users}}
        @composerData={{this.data}}
        @onPostComment={{fn this.postComment}}
        @uploadAttachments={{fn this.uploadAttachments}}
        @removeAttachment={{fn this.removeAttachment}}
      />
    </div>
  </div>
  */
  {"id":"ZOolIaoJ","block":"{\"symbols\":[\"@thread\",\"@users\"],\"statements\":[[10,\"div\"],[14,0,\"thread-pane\"],[12],[2,\"\\n  \"],[8,\"thread-pane/header\",[],[[\"@timestamp\",\"@close\"],[[32,1,[\"timestamp\"]],[30,[36,0],[[32,0,[\"close\"]]],null]]],null],[2,\"\\n\"],[6,[37,2],[[32,1,[\"isNew\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"empty-state\",[],[[\"@text\",\"@style\"],[[30,[36,1],[\"thread.emptyState\"],null],\"none\"]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"thread-content\"],[12],[2,\"\\n      \"],[8,\"thread\",[],[[\"@maxImageSize\",\"@headerStyle\",\"@thread\",\"@enableDelete\",\"@deleteThread\"],[[32,0,[\"maxImageSize\"]],\"normal\",[32,1],true,[30,[36,0],[[32,0,[\"deleteThread\"]]],null]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"div\"],[14,0,\"thread-composer-spacer\"],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"thread-composer\"],[12],[2,\"\\n    \"],[8,\"text-editor\",[],[[\"@active\",\"@items\",\"@users\",\"@composerData\",\"@onPostComment\",\"@uploadAttachments\",\"@removeAttachment\"],[[30,[36,3],[[32,1,[\"isNew\"]]],null],[32,0,[\"items\"]],[32,2],[32,0,[\"data\"]],[30,[36,0],[[32,0,[\"postComment\"]]],null],[30,[36,0],[[32,0,[\"uploadAttachments\"]]],null],[30,[36,0],[[32,0,[\"removeAttachment\"]]],null]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"t\",\"if\",\"readonly\"]}","meta":{"moduleName":"clarify/components/thread-pane/index.hbs"}});

  let ThreadContentPane = (_dec = Ember._tracked, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service('thread'), _dec8 = Ember.computed.alias('currentUser.user'), _dec9 = Ember.computed('layout.visualization.@each.items'), _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, (_class = (_temp = class ThreadContentPane extends _component.default {
    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "data", _descriptor, this);

      _initializerDefineProperty(this, "currentUser", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);

      _initializerDefineProperty(this, "alert", _descriptor5, this);

      _initializerDefineProperty(this, "intl", _descriptor6, this);

      _initializerDefineProperty(this, "threadService", _descriptor7, this);

      _initializerDefineProperty(this, "user", _descriptor8, this);

      this.data = this.threadService.createComposerDataForThread(args.thread);
    }

    get maxImageSize() {
      return {
        width: 460,
        height: 300
      };
    }

    get items() {
      let items = [];
      this.args.layoutModel.visualizations.forEach(visualization => {
        let visualizationItemsItems = (0, _compactMap.default)(visualization.visualizationItems.toArray(), visualizationItem => {
          if (_visualizationItem.default.isAccessible(visualizationItem)) {
            return visualizationItem.item;
          }

          return null;
        });
        items.push(...visualizationItemsItems);
      });
      let set = new Set(items);
      return Array.from(set).sortBy('name');
    }

    close() {
      this.router.transitionTo('organizations.show.timelines.show');
    }

    uploadAttachments(attachments) {
      attachments.forEach(attachment => {
        this.data.addAttachment(attachment);
      });
    }

    removeAttachment(attachment) {
      this.data.removeAttachment(attachment);
    }

    async deleteThread() {
      try {
        await this.args.thread.destroyRecord();
        this.router.transitionTo('organizations.show.timelines.show');
      } catch (error) {
        console.log('error', error);
      }
    }

    async postComment(text, references) {
      if (text || this.data.attachments.length) {
        let isNew = Ember.get(this.args.thread, 'isNew');

        try {
          await this.data.postComment(text, references);

          if (isNew) {
            this.router.replaceWith('organizations.show.timelines.show.threads.show', {
              thread: this.args.thread
            });
          } else {
            this.data = this.threadService.createComposerDataForThread(this.args.thread);
            let content = document.querySelector('.thread-content');

            if (content) {
              content.scrollTop = content.scrollHeight - content.offsetHeight;
            }
          }
        } catch (error) {
          this.alert.show({
            title: this.intl.t('thread.errors.title'),
            message: this.intl.t('thread.errors.failed'),
            actions: [{
              action: () => {
                this.alert.dismissAlert();
              },
              title: this.intl.t('thread.errors.dismiss')
            }]
          });
          console.error('PostComment#performUpload failed with', error);
        }
      } else {
        this.alert.show({
          title: this.intl.t('thread.errors.title'),
          message: this.intl.t('thread.errors.empty'),
          actions: [{
            title: this.intl.t('thread.errors.dismiss'),
            action: () => {
              this.alert.dismissAlert();
            }
          }]
        });
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "data", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "alert", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "threadService", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "items", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "items"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "close", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "uploadAttachments", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "uploadAttachments"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeAttachment", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "removeAttachment"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteThread", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "deleteThread"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "postComment", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "postComment"), _class.prototype)), _class));
  _exports.default = ThreadContentPane;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ThreadContentPane);
});