define("clarify/components/m-list-item/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="m-list-item-container" data-style={{or @style "default"}} ...attributes>
    {{yield}}
  </div>
  */
  {"id":"88Dvoy1L","block":"{\"symbols\":[\"@style\",\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[24,0,\"m-list-item-container\"],[16,\"data-style\",[30,[36,0],[[32,1],\"default\"],null]],[17,2],[12],[2,\"\\n  \"],[18,3,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"or\"]}","meta":{"moduleName":"clarify/components/m-list-item/index.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});