define("clarify/components/organizations/show/items/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div ...attributes>
    <div class="items-details-entries">
      <Organizations::Show::Items::DetailsEntry
        @title={{t "item-dialog.fields.engUnit"}}
        @value={{@item.engUnit}}
        @size="large"
      />
      <Organizations::Show::Items::DetailsEntry
        @title={{t "item-dialog.fields.sampleInterval"}}
        @value={{if @item.sampleInterval (format-duration @item.sampleInterval) ""}}
        @size="large"
      />
      <Organizations::Show::Items::DetailsEntry
        @title={{t "item-dialog.fields.sourceType"}}
        @value={{@item.sourceType}}
        @size="large"
      />
      <Organizations::Show::Items::DetailsEntry
        @title={{t "item-dialog.fields.dataType"}}
        @value={{@item.type}}
        @size="large"
      />
      <Organizations::Show::Items::DetailsEntry @title={{t "item-dialog.fields.id"}} @value={{@item.id}} />
      <Organizations::Show::Items::DetailsEntry
        @title={{t "item-dialog.fields.createdAt"}}
        @value={{format-date
          @item.createdAt
          year="numeric"
          month="numeric"
          day="numeric"
          hour="numeric"
          minute="numeric"
          second="numeric"
        }}
      />
    </div>
  </div>
  */
  {"id":"PfKXll5I","block":"{\"symbols\":[\"&attrs\",\"@item\"],\"statements\":[[11,\"div\"],[17,1],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"items-details-entries\"],[12],[2,\"\\n    \"],[8,\"organizations/show/items/details-entry\",[],[[\"@title\",\"@value\",\"@size\"],[[30,[36,0],[\"item-dialog.fields.engUnit\"],null],[32,2,[\"engUnit\"]],\"large\"]],null],[2,\"\\n    \"],[8,\"organizations/show/items/details-entry\",[],[[\"@title\",\"@value\",\"@size\"],[[30,[36,0],[\"item-dialog.fields.sampleInterval\"],null],[30,[36,2],[[32,2,[\"sampleInterval\"]],[30,[36,1],[[32,2,[\"sampleInterval\"]]],null],\"\"],null],\"large\"]],null],[2,\"\\n    \"],[8,\"organizations/show/items/details-entry\",[],[[\"@title\",\"@value\",\"@size\"],[[30,[36,0],[\"item-dialog.fields.sourceType\"],null],[32,2,[\"sourceType\"]],\"large\"]],null],[2,\"\\n    \"],[8,\"organizations/show/items/details-entry\",[],[[\"@title\",\"@value\",\"@size\"],[[30,[36,0],[\"item-dialog.fields.dataType\"],null],[32,2,[\"type\"]],\"large\"]],null],[2,\"\\n    \"],[8,\"organizations/show/items/details-entry\",[],[[\"@title\",\"@value\"],[[30,[36,0],[\"item-dialog.fields.id\"],null],[32,2,[\"id\"]]]],null],[2,\"\\n    \"],[8,\"organizations/show/items/details-entry\",[],[[\"@title\",\"@value\"],[[30,[36,0],[\"item-dialog.fields.createdAt\"],null],[30,[36,3],[[32,2,[\"createdAt\"]]],[[\"year\",\"month\",\"day\",\"hour\",\"minute\",\"second\"],[\"numeric\",\"numeric\",\"numeric\",\"numeric\",\"numeric\",\"numeric\"]]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"format-duration\",\"if\",\"format-date\"]}","meta":{"moduleName":"clarify/components/organizations/show/items/details.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});