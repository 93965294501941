define("clarify/components/p-user-info/group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PUserInfo::-private
    @icon="/design-system/design-system-profilepic-group-32.svg"
    @user={{@group.name}}
    @disabled={{@disabled}}
  >
    <LinkTo
      class="p-user-info-group-link"
      data-style={{@style}}
      @route="organizations.show.timelines.show.groups.show"
      @model={{@group}}
      ...attributes
      title={{t "timeline-sharing.group-members.title"}}
    >
      {{t "access-control.profile-group.members" count=@group.loadedUsers.length}}
    </LinkTo>
  </PUserInfo::-private>
  */
  {"id":"6v8uRIVj","block":"{\"symbols\":[\"@group\",\"@disabled\",\"@style\",\"&attrs\"],\"statements\":[[8,\"p-user-info/-private\",[],[[\"@icon\",\"@user\",\"@disabled\"],[\"/design-system/design-system-profilepic-group-32.svg\",[32,1,[\"name\"]],[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"link-to\",[[24,0,\"p-user-info-group-link\"],[16,\"data-style\",[32,3]],[17,4],[16,\"title\",[30,[36,0],[\"timeline-sharing.group-members.title\"],null]]],[[\"@route\",\"@model\"],[\"organizations.show.timelines.show.groups.show\",[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"access-control.profile-group.members\"],[[\"count\"],[[32,1,[\"loadedUsers\",\"length\"]]]]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\"]}","meta":{"moduleName":"clarify/components/p-user-info/group.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});