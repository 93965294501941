define("clarify/templates/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xmW06iwX",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"login\"],[12],[10,\"div\"],[14,0,\"login-content\"],[12],[1,[30,[36,0],[\"#clarify-logo-white\"],[[\"class\",\"width\",\"height\"],[\"login-logo\",270,68]]]],[2,\" \"],[10,\"div\"],[14,0,\"login-description\"],[12],[1,[30,[36,1],[\"login.description\"],null]],[13],[2,\" \"],[10,\"a\"],[14,6,\"https://www.clarify.us\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener\"],[14,0,\"login-learn-more\"],[12],[1,[30,[36,1],[\"login.learn-more\"],null]],[13],[2,\" \"],[8,\"button\",[[24,0,\"login-button\"]],[[\"@size\",\"@color\",\"@action\",\"@rightIcon\",\"@title\"],[\"large\",\"white\",[30,[36,2],[[32,0],\"login\"],null],[30,[36,0],[\"#x-button-right-arrow-round\"],[[\"width\",\"height\"],[24,17]]],[30,[36,1],[\"login.logIn\"],null]]],null],[13],[13]],\"hasEval\":false,\"upvars\":[\"svg-jar\",\"t\",\"action\"]}",
    "meta": {
      "moduleName": "clarify/templates/login.hbs"
    }
  });

  _exports.default = _default;
});