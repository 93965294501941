define("clarify/utilities/flat-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.flatMap = flatMap;

  function flatMap(array, func) {
    return Array.prototype.concat.apply([], array.map(func));
  }
});