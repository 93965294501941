define("clarify/helpers/delete-visualization-item-alert", ["exports", "clarify/helpers/alert", "clarify/models/visualization-item"], function (_exports, _alert, _visualizationItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DeleteVisualizationItemAlert extends _alert.default {
    compute(_parameters, hash) {
      // TODO figure this out;
      let name = '';

      if (_visualizationItem.default.isAccessible(hash.visualizationItem)) {
        name = hash.visualizationItem.item.name;
      }

      return event => {
        this.showAlert({
          title: this.intl.t('delete-visualization-item-alert.title', hash.visualizationItem),
          message: this.intl.t('delete-visualization-item-alert.message'),
          primaryButton: {
            title: this.intl.t('delete-visualization-item-alert.delete-button', {
              name
            }),
            style: 'destructive',
            action: hash.action
          }
        }, event === null || event === void 0 ? void 0 : event.currentTarget);
      };
    }

  }

  _exports.default = DeleteVisualizationItemAlert;
});