define("clarify/initializers/composer-data-service", ["exports", "clarify/services/thread"], function (_exports, _thread) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'composer-data-service',
    initialize: function (register) {
      register.register('service:composer-data', _thread.ComposerData, {
        singleton: false
      });
    }
  };
  _exports.default = _default;
});