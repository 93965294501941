define("clarify/helpers/can-edit", ["exports", "clarify/models/permission"], function (_exports, _permission) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CanEditHelper extends Ember.Helper {
    compute([timeline], _hash) {
      switch (timeline.mostSignificantPermissionType) {
        case _permission.PermissionType.collaborator:
        case _permission.PermissionType.owner:
          return true;

        default:
          return false;
      }
    }

  }

  _exports.default = CanEditHelper;
});