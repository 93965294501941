define("clarify/services/locale", ["exports", "clarify/locales/en-us", "clarify/locales/nb-no", "date-fns", "date-fns/locale", "clarify/utilities/number-parser"], function (_exports, _enUs, _nbNo, _dateFns, _locale, _numberParser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let LocaleService = (_dec = Ember.inject.service, (_class = (_temp = class LocaleService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);
    }

    get current() {
      switch (this.intl.primaryLocale) {
        case 'nb-no':
          return _nbNo.default;

        case 'en-us':
          return _enUs.default;

        default:
          throw new Error(`Can't find a matching locale`);
      }
    }

    get decimalPoint() {
      return this.current.numbers.decimalPoint;
    }

    format(date, formatString) {
      return (0, _dateFns.format)(date, formatString, {
        locale: this.dateFnsLocale
      });
    }

    formatDistanceToNow(date) {
      return (0, _dateFns.formatDistanceToNow)(date, {
        locale: this.dateFnsLocale
      });
    }

    normalizeNumber(value) {
      return (0, _numberParser.normalize)(value, this.current.numbers.decimalPoint, this.current.numbers.groupIndicator);
    }

    localizeNumberString(value) {
      return value.replace('.', this.current.numbers.decimalPoint);
    }

    get dateFnsLocale() {
      switch (this.intl.primaryLocale) {
        case 'nb-no':
          return _locale.nb;

        case 'en-us':
          return _locale.enUS;

        default:
          throw new Error(`Can't find a matching locale`);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = LocaleService;
});