define("clarify/templates/invitations/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "48Wtg7vt",
    "block": "{\"symbols\":[],\"statements\":[[8,\"routes/invitations/show\",[],[[\"@invitation\"],[[32,0,[\"model\",\"invitation\"]]]],null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "clarify/templates/invitations/show.hbs"
    }
  });

  _exports.default = _default;
});