define("clarify/routes/organizations/show/items/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class OrganizationsShowSignalsIndex extends Ember.Route {
    setupController(controller) {
      let {
        organization
      } = this.modelFor('organizations/show');
      Ember.set(controller, 'organization', organization);
    }

  }

  _exports.default = OrganizationsShowSignalsIndex;
});