define("clarify/components/color-popover/index", ["exports", "@glimmer/component", "clarify/colors"], function (_exports, _component, _colors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="color-popover">
    <div class="color-popover-header">
      {{t "color-popover.header"}}
    </div>
    <div class="color-popover-content">
      {{#each this.colors as |colorSet|}}
        <div class="color-popover-row">
          {{#each colorSet as |color|}}
            <div
              onclick={{action @colorDidChange color}}
              role="button"
              class="color-popover-item"
              data-color={{color}}
              data-selected={{eq this.color color}}
            ></div>
          {{/each}}
        </div>
      {{/each}}
    </div>
  </div>
  */
  {"id":"p6Msl6s8","block":"{\"symbols\":[\"colorSet\",\"color\",\"@colorDidChange\"],\"statements\":[[10,\"div\"],[14,0,\"color-popover\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"color-popover-header\"],[12],[2,\"\\n    \"],[1,[30,[36,4],[\"color-popover.header\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"color-popover-content\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"colors\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"color-popover-row\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"div\"],[15,\"onclick\",[30,[36,0],[[32,0],[32,3],[32,2]],null]],[14,\"role\",\"button\"],[14,0,\"color-popover-item\"],[15,\"data-color\",[32,2]],[15,\"data-selected\",[30,[36,1],[[32,0,[\"color\"]],[32,2]],null]],[12],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"eq\",\"-track-array\",\"each\",\"t\"]}","meta":{"moduleName":"clarify/components/color-popover/index.hbs"}});

  class ColorPopover extends _component.default {
    get colors() {
      var _this$args$colorSet;

      let colorSet = (_this$args$colorSet = this.args.colorSet) !== null && _this$args$colorSet !== void 0 ? _this$args$colorSet : 'graph';

      switch (colorSet) {
        case 'graph':
          return _colors.colorSets;

        case 'thresholds':
          return _colors.thresholdColorSet;

        default:
          throw new Error('ColorPopover.color: Unsupported color set');
      }
    }

  }

  _exports.default = ColorPopover;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ColorPopover);
});