define("clarify/components/organizations/show/timelines/state/searching", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="timeline-searching" data-placement={{@placement}}>
    <div class="timeline-searching-text">
      {{t "timeline-card.timeline-state.searching"}}
    </div>
    <div class="timeline-searching-spinner">
      {{svg-jar "design-system-spinner" width=48 height=48}}
    </div>
  </div>
  */
  {"id":"ofAJvZ8y","block":"{\"symbols\":[\"@placement\"],\"statements\":[[10,\"div\"],[14,0,\"timeline-searching\"],[15,\"data-placement\",[32,1]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"timeline-searching-text\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"timeline-card.timeline-state.searching\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"timeline-searching-spinner\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[\"design-system-spinner\"],[[\"width\",\"height\"],[48,48]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"svg-jar\"]}","meta":{"moduleName":"clarify/components/organizations/show/timelines/state/searching.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});