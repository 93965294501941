define("clarify/components/timelines-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="timeline-list" ...attributes>
    {{#each @timelines as |timeline|}}
      <TimelineCard @categories={{@categories}} @timeline={{timeline}} />
    {{/each}}
  </div>
  */
  {"id":"K0JYfZto","block":"{\"symbols\":[\"timeline\",\"@categories\",\"&attrs\",\"@timelines\"],\"statements\":[[11,\"div\"],[24,0,\"timeline-list\"],[17,3],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"timeline-card\",[],[[\"@categories\",\"@timeline\"],[[32,2],[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}","meta":{"moduleName":"clarify/components/timelines-list.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});