define("clarify/components/comment/attachment", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="comment-attachment" ...attributes>
    {{#if @icon}}
      <div class="comment-attachment-icon">
        {{svg-jar "#comment-feed-attachment" width=20 height=20}}
      </div>
    {{/if}}
    <div class="comment-attachment-content">
      {{@attachment.displayName}}
      <a
        href={{@attachment.downloadURL}}
        download={{@attachment.displayName}}
        class="comment-attachment-download"
        target="_blank"
        rel="noopener"
      >
        {{t "comment-attachment.download"}}
      </a>
    </div>
  </div>
  */
  {"id":"SKter/Cb","block":"{\"symbols\":[\"&attrs\",\"@icon\",\"@attachment\"],\"statements\":[[11,\"div\"],[24,0,\"comment-attachment\"],[17,1],[12],[2,\"\\n\"],[6,[37,1],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"comment-attachment-icon\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"#comment-feed-attachment\"],[[\"width\",\"height\"],[20,20]]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"div\"],[14,0,\"comment-attachment-content\"],[12],[2,\"\\n    \"],[1,[32,3,[\"displayName\"]]],[2,\"\\n    \"],[10,\"a\"],[15,6,[32,3,[\"downloadURL\"]]],[15,\"download\",[32,3,[\"displayName\"]]],[14,0,\"comment-attachment-download\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener\"],[12],[2,\"\\n      \"],[1,[30,[36,2],[\"comment-attachment.download\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"svg-jar\",\"if\",\"t\"]}","meta":{"moduleName":"clarify/components/comment/attachment.hbs"}});

  class CommentAttachment extends _component.default {}

  _exports.default = CommentAttachment;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CommentAttachment);
});