define("clarify/helpers/can-manage", ["exports", "clarify/models/permission"], function (_exports, _permission) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CanManageHelper extends Ember.Helper {
    compute([timeline], _hash) {
      return timeline.mostSignificantPermissionType === _permission.PermissionType.owner;
    }

  }

  _exports.default = CanManageHelper;
});