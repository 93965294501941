define("clarify/components/settings-visualization-list/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="settings-visualization-list">
    <SortInteraction
      class="settings-visualization-list-sort-interaction"
      @placeholderClass="settings-visualization-list-placeholder"
      @allow={{array "clarify/visualization" "clarify/visualization-item"}}
      @modelContextProvider="visualization"
      @sort={{action @sortVisualizations}}
      @items={{@visualizations}} as |visualization|
    >
      <SettingsVisualizationList::Visualization
        @visualization={{visualization}}
        @sortItems={{action @sortItems}}
        @insertVisualizationItems={{action @insertVisualizationItems}}
        @colorDidChange={{action @colorDidChange}}
        @visualizationNameDidChange={{action @visualizationNameDidChange}}
        @visibilityDidChange={{action @visibilityDidChange}}
        @removeVisualizationItem={{action @removeVisualizationItem}}
        @removeVisualization={{action @removeVisualization}}
        @openVisualizationSettings={{action @openVisualizationSettings}}
      />
    </SortInteraction>
  </div>
  */
  {"id":"dChAaaP6","block":"{\"symbols\":[\"visualization\",\"@sortVisualizations\",\"@visualizations\",\"@sortItems\",\"@insertVisualizationItems\",\"@colorDidChange\",\"@visualizationNameDidChange\",\"@visibilityDidChange\",\"@removeVisualizationItem\",\"@removeVisualization\",\"@openVisualizationSettings\"],\"statements\":[[10,\"div\"],[14,0,\"settings-visualization-list\"],[12],[2,\"\\n  \"],[8,\"sort-interaction\",[[24,0,\"settings-visualization-list-sort-interaction\"]],[[\"@placeholderClass\",\"@allow\",\"@modelContextProvider\",\"@sort\",\"@items\"],[\"settings-visualization-list-placeholder\",[30,[36,0],[\"clarify/visualization\",\"clarify/visualization-item\"],null],\"visualization\",[30,[36,1],[[32,0],[32,2]],null],[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"settings-visualization-list/visualization\",[],[[\"@visualization\",\"@sortItems\",\"@insertVisualizationItems\",\"@colorDidChange\",\"@visualizationNameDidChange\",\"@visibilityDidChange\",\"@removeVisualizationItem\",\"@removeVisualization\",\"@openVisualizationSettings\"],[[32,1],[30,[36,1],[[32,0],[32,4]],null],[30,[36,1],[[32,0],[32,5]],null],[30,[36,1],[[32,0],[32,6]],null],[30,[36,1],[[32,0],[32,7]],null],[30,[36,1],[[32,0],[32,8]],null],[30,[36,1],[[32,0],[32,9]],null],[30,[36,1],[[32,0],[32,10]],null],[30,[36,1],[[32,0],[32,11]],null]]],null],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"array\",\"action\"]}","meta":{"moduleName":"clarify/components/settings-visualization-list/index.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});