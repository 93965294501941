define("clarify/components/dropdown-list/item/default", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <li class="dropdown-list-item-default" data-highlighted={{@highlighted}} ...attributes>
    {{#if @leading}}
      <div class="dropdown-list-item-default-leading">
        {{@leading}}
      </div>
    {{/if}}
    <div class="dropdown-list-item-default-title">
      {{@item.title}}
    </div>
  </li>
  */
  {"id":"Shlnd2w2","block":"{\"symbols\":[\"@leading\",\"@highlighted\",\"&attrs\",\"@item\"],\"statements\":[[11,\"li\"],[24,0,\"dropdown-list-item-default\"],[16,\"data-highlighted\",[32,2]],[17,3],[12],[2,\"\\n\"],[6,[37,0],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"dropdown-list-item-default-leading\"],[12],[2,\"\\n      \"],[1,[32,1]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"div\"],[14,0,\"dropdown-list-item-default-title\"],[12],[2,\"\\n    \"],[1,[32,4,[\"title\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}","meta":{"moduleName":"clarify/components/dropdown-list/item/default.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});