define("clarify/transforms/duration", ["exports", "ember-data", "clarify/models/duration"], function (_exports, _emberData, _duration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DisplayOptionsTransform extends _emberData.default.Transform {
    deserialize(serialized) {
      if (serialized) {
        return _duration.default.parse(serialized);
      }

      return null;
    }

    serialize(deserialized) {
      if (deserialized) {
        return deserialized.valueOf();
      }

      return null;
    }

  }

  _exports.default = DisplayOptionsTransform;
});