define("clarify/utilities/content-reference-delta", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.toContentReference = toContentReference;

  function toContentReference(content) {
    const {
      plainText,
      contentReferences
    } = content.reduce((refs, op) => {
      const insert = op.insert;

      if (typeof insert === 'string') {
        // Text
        let attributes = new Map(Object.entries(op.attributes || {}));
        let styleReferences = ['bold', 'italic', 'underline'].filter(value => attributes.has(value)).map(value => {
          return {
            start: refs.index,
            length: insert.length,
            type: value
          };
        });
        return {
          index: refs.index + insert.length,
          plainText: refs.plainText + insert,
          contentReferences: [...refs.contentReferences, ...styleReferences]
        };
      } else if (insert['auto-complete']) {
        let payload = insert['auto-complete'];
        let contentReference = {
          [payload.type]: payload.id,
          start: refs.index,
          length: payload.token.length,
          type: payload.type
        };
        return {
          index: refs.index + payload.token.length,
          contentReferences: [...refs.contentReferences, contentReference],
          plainText: refs.plainText + payload.token
        };
      } else {
        return refs;
      }
    }, {
      index: 0,
      plainText: '',
      contentReferences: []
    });
    return {
      content: plainText,
      contentReferences
    };
  }
});