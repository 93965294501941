define("clarify/components/p-user-info/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PUserInfo::-private @icon={{@user.picture}} @user={{@user.name}} @disabled={{@disabled}}>
    {{@user.email}}
  </PUserInfo::-private>
  */
  {"id":"wx97krQG","block":"{\"symbols\":[\"@user\",\"@disabled\"],\"statements\":[[8,\"p-user-info/-private\",[],[[\"@icon\",\"@user\",\"@disabled\"],[[32,1,[\"picture\"]],[32,1,[\"name\"]],[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[1,[32,1,[\"email\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[]}","meta":{"moduleName":"clarify/components/p-user-info/user.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});