define("clarify/adapters/timeline", ["exports", "clarify/adapters/meta"], function (_exports, _meta) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Timeline extends _meta.default {
    fields(_requestType) {
      return ['createdAt', 'createdBy', 'description', 'id', 'labels', 'name', 'updatedAt', 'updatedBy', 'organization', 'permissions', 'annotations'];
    }

  }

  _exports.default = Timeline;
});