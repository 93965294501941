define("clarify/models/duration", ["exports", "date-fns"], function (_exports, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addDuration = addDuration;
  _exports.default = void 0;
  const numbers = '\\d+(?:[\\.,]\\d+)?';
  const datePattern = `(${numbers}D)?`;
  const timePattern = `T(${numbers}H)?(${numbers}M)?(${numbers}S)?`;
  const duration = new RegExp(`^P(?:${datePattern}(?:${timePattern})?)$`);

  class Duration {
    constructor(days, hours, minutes, seconds, milliseconds) {
      this.days = days;
      this.hours = hours;
      this.minutes = minutes;
      this.seconds = seconds;
      this.milliseconds = milliseconds;
    }

    valueOf() {
      return this.days * 86400000 + this.hours * 3600000 + this.minutes * 60000 + this.seconds * 1000 + this.milliseconds;
    }

    toJSON() {
      let values = ['P'];

      if (this.days) {
        values.push(`${this.days}D`);
      }

      values.push('T');

      if (this.hours) {
        values.push(`${this.hours}H`);
      }

      if (this.minutes) {
        values.push(`${this.minutes}M`);
      }

      if (this.seconds || this.milliseconds) {
        values.push(`${this.seconds}S`);
      }

      return values.join('');
    }

    static fromMilliseconds(raw) {
      let value = Math.abs(raw);
      let days = Math.floor(value / 86400000);
      let hours = Math.floor(value / 3600000) % 24;
      let minutes = Math.floor(value / 60000) % 60;
      let seconds = Math.floor(value / 1000) % 60;
      let milliseconds = Math.floor(value) % 1000;
      return new Duration(days, hours, minutes, seconds, milliseconds);
    }

    static parse(string) {
      let match = string.match(duration);

      if (match) {
        let [days, hours, minutes, seconds] = match.slice(1);
        let daysValue = days ? parseFloat(days.slice(0, -1)) : 0;
        let hoursValue = hours ? parseFloat(hours.slice(0, -1)) : 0;
        let minutesValue = minutes ? parseFloat(minutes.slice(0, -1)) : 0;
        let secondsValue = seconds ? parseFloat(seconds.slice(0, -1)) : 0;
        let raw = secondsValue * 1000;
        let s = Math.floor(raw / 1000) % 60;
        let ms = Math.floor(raw) % 1000;
        return new Duration(daysValue, hoursValue, minutesValue, s, ms);
      }

      return null;
    }

  }

  _exports.default = Duration;

  function addDuration(date, duration) {
    date = (0, _dateFns.addDays)(date, duration.days);
    date = (0, _dateFns.addHours)(date, duration.hours);
    date = (0, _dateFns.addMinutes)(date, duration.minutes);
    date = (0, _dateFns.addSeconds)(date, duration.seconds);
    date = (0, _dateFns.addMilliseconds)(date, duration.milliseconds);
    return date;
  }
});