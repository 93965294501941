define("clarify/components/thread-feed/card", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="thread-feed-card">
    <div class="thread-feed-thread-header">
      <ThreadFeed::DateHeader @thread={{@thread}} />
    </div>
    <div role="button" class="thread-content" {{on "click" (fn @action)}}>
      <Thread
        @deleteThread={{fn @deleteThread}}
        @maxImageSize={{this.maxImageSize}}
        @thread={{@thread}}
        @headerStyle="feed"
        @enableDelete={{false}}
      />
    </div>
  </div>
  */
  {"id":"rhmU4goL","block":"{\"symbols\":[\"@thread\",\"@action\",\"@deleteThread\"],\"statements\":[[10,\"div\"],[14,0,\"thread-feed-card\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"thread-feed-thread-header\"],[12],[2,\"\\n    \"],[8,\"thread-feed/date-header\",[],[[\"@thread\"],[[32,1]]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[11,\"div\"],[24,\"role\",\"button\"],[24,0,\"thread-content\"],[4,[38,1],[\"click\",[30,[36,0],[[32,2]],null]],null],[12],[2,\"\\n    \"],[8,\"thread\",[],[[\"@deleteThread\",\"@maxImageSize\",\"@thread\",\"@headerStyle\",\"@enableDelete\"],[[30,[36,0],[[32,3]],null],[32,0,[\"maxImageSize\"]],[32,1],\"feed\",false]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\"]}","meta":{"moduleName":"clarify/components/thread-feed/card.hbs"}});

  class ThreadFeedCard extends _component.default {
    get maxImageSize() {
      return this.args.maxImageSize || {
        width: 326,
        height: 212
      };
    }

  }

  _exports.default = ThreadFeedCard;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ThreadFeedCard);
});