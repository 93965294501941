define("clarify/modifiers/auto-play", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _emberModifier.modifier)(element => {
    element.load();
    element.addEventListener('canplaythrough', () => {
      element.play();
    });
  });

  _exports.default = _default;
});