define("clarify/serializers/threshold", ["exports", "clarify/serializers/rest-layer"], function (_exports, _restLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class ThresholdSerializer extends _restLayer.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "root", 'thresholds');
    }

    normalize(modelClass, resourceHash, prop) {
      let payload = { ...resourceHash,
        displayOptions: resourceHash.displayOptions || {},
        source: {
          type: resourceHash.source.type,
          id: resourceHash.source.type === 'visualization' ? resourceHash.source.visualization : resourceHash.source.visualizationItem
        }
      };
      return super.normalize(modelClass, payload, prop);
    }

    serializeAttribute(snapshot, json, key, attribute) {
      switch (key) {
        case 'name':
        case 'displayOptions':
        case 'trigger':
        case 'source':
          super.serializeAttribute(snapshot, json, key, attribute);
          break;

        default:
          break;
      }
    }

    serializeBelongsTo(snapshot, json, relationship) {
      let typedSnapshot = snapshot;

      switch (relationship.key) {
        case 'source':
          let source = typedSnapshot.belongsTo('source');

          if (source) {
            switch (source.modelName) {
              case 'visualization':
                json.source = {
                  type: 'visualization',
                  visualization: source.id
                };
                break;

              case 'visualization-item':
                json.source = {
                  type: 'visualization-item',
                  visualizationItem: source.id
                };
                break;
            }
          }

          break;

        default:
          super.serializeBelongsTo(snapshot, json, relationship);
      }
    }

  }

  _exports.default = ThresholdSerializer;
});