define("clarify/mixins/threshold-source", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let {
    hasMany
  } = _emberData.default;
  const ThresholdSource = Ember.Mixin.create({
    thresholds: hasMany('threshold', {
      async: false,
      inverse: 'source'
    })
  });
  var _default = ThresholdSource;
  _exports.default = _default;
});