define("clarify/components/timeline-permission-popover/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="m-timeline-sharing-header" data-theme={{@theme}}>
    {{#if @isDisabled}}
      <div class="m-timeline-sharing-header-disabled">
        {{@disabled-title}}
      </div>
    {{else}}
      <div class="m-timeline-sharing-header-title">
        {{@title}}
      </div>
    {{/if}}
    {{yield}}
  </div>
  */
  {"id":"xFXJ0J0L","block":"{\"symbols\":[\"@title\",\"@disabled-title\",\"@theme\",\"@isDisabled\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"m-timeline-sharing-header\"],[15,\"data-theme\",[32,3]],[12],[2,\"\\n\"],[6,[37,0],[[32,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"m-timeline-sharing-header-disabled\"],[12],[2,\"\\n      \"],[1,[32,2]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"m-timeline-sharing-header-title\"],[12],[2,\"\\n      \"],[1,[32,1]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[18,5,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}","meta":{"moduleName":"clarify/components/timeline-permission-popover/header.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});