define("clarify/utilities/spring", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.spring = spring;

  function spring(k, c) {
    // Spring equations. f(0) = -1 (initial displacement), f'(0) = 0 (start velocity is 0), f''(t) + c * f'(t) + k*c = 0
    // Based on https://www.stewartcalculus.com/data/CALCULUS%20Concepts%20and%20Contexts/upfiles/3c3-AppsOf2ndOrders_Stu.pdf
    const characteristic = Math.pow(c, 2) - 4 * k;
    const decayCutoff = 0.000001; // Cutoff for steady state.

    if (characteristic < 0) {
      // Under damped
      // Equation x(t) = e^(-c*t/2)(c1*cos (w*t)+c2*sin (w*t))
      const c1 = -1; // Always -1 for x(0) = -1

      const c2 = -c / Math.sqrt(4 * k - Math.pow(c, 2));
      const w = Math.sqrt(4 * k - Math.pow(c, 2)) / 2;
      const duration = Math.log(decayCutoff) / (-c / 2);
      return {
        duration: duration,
        ease: time => {
          return Math.exp(-c * time / 2) * (c1 * Math.cos(w * time) + c2 * Math.sin(w * time));
        }
      };
    } else if (characteristic == 0) {
      // Critically damped
      // Equation x(t) = (c1 + c2*t)e^(-c*t/2)
      const c1 = -1; // Always -1 for x(0) = -1

      const c2 = -c / 2; // For x'(0) = 0

      const duration = Math.log(decayCutoff) / (-c / 2);
      return {
        duration: duration,
        ease: time => {
          return (c1 + c2 * time) * Math.exp(-c * time / 2);
        }
      };
    } else {
      // Over damped
      // Equation x(t) = c1e^r1*t+c2e^r2*t, where r1 = -1/2*(c+sqrt(c^2-4k)) and r2 = -1/2*(c-sqrt(c^2-4k))
      const w = Math.sqrt(Math.pow(c, 2) - 4 * k);
      const r1 = (-c + w) / 2;
      const r2 = (-c - w) / 2;
      const c2 = c / (2 * w) - 1 / 2;
      const c1 = -1 - c2;
      const duration = Math.log(decayCutoff) / Math.max(r1, r2);
      return {
        duration: duration,
        ease: time => {
          return c1 * Math.exp(r1 * time) + c2 * Math.exp(r2 * time);
        }
      };
    }
  }
});