define("clarify/colors", ["exports", "clarify/utilities/colors"], function (_exports, _colors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.colorInColorSet = colorInColorSet;
  _exports.defaultColors = _exports.namedColorSets = _exports.ColorSetName = _exports.thresholdColorSet = _exports.colorSets = _exports.textColors = _exports.graphColors = _exports.tooltipDark = _exports.tooltip = _exports.darkGraphColors = _exports.rawColors = void 0;
  let rawColors = {
    'grey-01': 0xd9defaff,
    'grey-02': 0x888eb8ff,
    'grey-03': 0x505779ff,
    'blue-01': 0x78b5f3ff,
    'blue-02': 0x318feeff,
    'blue-03': 0x155fbfff,
    'blue-04': 0x083d99ff,
    'cyan-01': 0x5bdfdaff,
    'cyan-02': 0xb0fcffff,
    'yellow-01': 0xf79b34ff,
    'yellow-02': 0xedbe32ff,
    'yellow-03': 0xfaef5fff,
    'yellow-04': 0xfffbccff,
    'purple-01': 0xa620efff,
    'purple-02': 0xb300c2ff,
    'purple-03': 0xf253d3ff,
    'purple-04': 0xf696e2ff,
    'purple-05': 0xffd0e3ff,
    'blue-ocean-01': 0x1a3078ff,
    'blue-ocean-02': 0x2542a1ff,
    'blue-ocean-03': 0x318feeff,
    'blue-ocean-04': 0x5973deff,
    'blue-ocean-05': 0x8497e0ff,
    'blue-ocean-06': 0xbac2e0ff,
    'blue-ocean-07': 0xfefefeff,
    'threshold-neutral-01': 0x425e82ff,
    'threshold-neutral-02': 0x7c88c3ff,
    'threshold-neutral-03': 0x425e82ff,
    'threshold-negative-01': 0xe75f6dff,
    'threshold-negative-02': 0xc43e62ff,
    'threshold-negative-03': 0xf0959eff,
    'threshold-negative-04': 0xf2c4c8ff,
    'threshold-positive-01': 0x1ae39bff,
    'threshold-positive-02': 0x39bbbcff,
    'threshold-medium-01': 0xf6da60ff
  };
  _exports.rawColors = rawColors;
  let darkGraphColors = new Map();
  _exports.darkGraphColors = darkGraphColors;
  let tooltip = new Map(); // Dots' background

  _exports.tooltip = tooltip;
  let tooltipDark = new Map();
  _exports.tooltipDark = tooltipDark;
  let graphColors = new Map();
  _exports.graphColors = graphColors;
  let textColors = new Map();
  _exports.textColors = textColors;

  for (let [name, color] of Object.entries(rawColors)) {
    let darkColor = (0, _colors.blend)(0x112133b3, color);
    let tooltipColor = color & 0xffffff00 | 0x3b;
    let tooltipDarkColor = darkColor & 0xffffff00 | 0x3b;
    graphColors.set(name, (0, _colors.toHex)(color));
    darkGraphColors.set(name, (0, _colors.toHex)(darkColor));
    tooltip.set(name, (0, _colors.toHex)(tooltipColor));
    tooltipDark.set(name, (0, _colors.toHex)(tooltipDarkColor));
    textColors.set(name, (0, _colors.textColor)(color));
  }

  let colorSets = [['grey-01', 'grey-02', 'grey-03'], ['blue-01', 'blue-02', 'blue-03', 'blue-04'], ['yellow-01', 'yellow-02', 'yellow-03', 'yellow-04'], ['purple-01', 'purple-02', 'purple-03', 'purple-04', 'purple-05'], ['cyan-01', 'cyan-02']];
  _exports.colorSets = colorSets;
  let thresholdColorSet = [['threshold-neutral-01', 'threshold-neutral-02', 'threshold-neutral-03'], ['threshold-negative-01', 'threshold-negative-02', 'threshold-negative-03', 'threshold-negative-04'], ['threshold-positive-01', 'threshold-positive-02'], ['threshold-medium-01']];
  _exports.thresholdColorSet = thresholdColorSet;
  let ColorSetName;
  _exports.ColorSetName = ColorSetName;

  (function (ColorSetName) {
    ColorSetName["oceanBlue"] = "blue-ocean";
  })(ColorSetName || (_exports.ColorSetName = ColorSetName = {}));

  let namedColorSets = {
    [ColorSetName.oceanBlue]: ['blue-ocean-01', 'blue-ocean-02', 'blue-ocean-03', 'blue-ocean-04', 'blue-ocean-05', 'blue-ocean-06', 'blue-ocean-07']
  };
  _exports.namedColorSets = namedColorSets;

  function indiciesForCount(count) {
    switch (count) {
      case 1:
        return [3];

      case 2:
        return [3, 5];

      case 3:
        return [1, 3, 5];

      case 4:
        return [1, 3, 5, 6];

      case 5:
        return [1, 3, 4, 5, 6];

      case 6:
        return [1, 2, 3, 4, 5, 6];

      default:
        return [0, 1, 2, 3, 4, 5, 6];
    }
  }

  function colorInColorSet(colorName, count, index) {
    let colors = namedColorSets[colorName];
    let indicies = indiciesForCount(count);
    return colors[indicies[index % indicies.length]];
  }

  let defaultColors = ['grey-01', 'grey-02', 'blue-02', 'yellow-02', 'purple-01', 'yellow-04', 'cyan-01', 'yellow-01'];
  _exports.defaultColors = defaultColors;
});