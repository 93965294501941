define("clarify/modifiers/transform", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _emberModifier.modifier)((element, [offset], hash) => {
    if (hash.center === false) {
      element.style.transform = `translateX(${offset}px)`;
    } else {
      element.style.transform = `translateX(calc(-50% + ${offset}px))`;
    }
  });

  _exports.default = _default;
});