define("clarify/components/a-item-card/metadata", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="a-item-card-metadata">
    <div class="a-item-card-data">
      {{svg-jar "#auto-complete-location" class="a-item-card-data-icon" width=16 height=16 role="presentation"}}
      <div class="a-item-card-data-title" data-placeholder={{not @item.locationString}}>
        {{or @item.locationString (t "item-card.empty-state.location.title")}}
      </div>
    </div>
    <div class="a-item-card-action">
      <div class="a-item-card-data">
        {{svg-jar
          "#design-system-p-icon-integration"
          class="a-item-card-data-icon"
          width=16
          height=16
          role="presentation"
        }}
        <div class="a-item-card-data-title" data-placeholder={{not @item.dataSource}}>
          {{or @item.dataSource (t "item-card.empty-state.data-source.title")}}
        </div>
      </div>
      {{yield to="actions"}}
    </div>
  </div>
  */
  {"id":"ysDv0S3W","block":"{\"symbols\":[\"@item\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"a-item-card-metadata\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"a-item-card-data\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"#auto-complete-location\"],[[\"class\",\"width\",\"height\",\"role\"],[\"a-item-card-data-icon\",16,16,\"presentation\"]]]],[2,\"\\n    \"],[10,\"div\"],[14,0,\"a-item-card-data-title\"],[15,\"data-placeholder\",[30,[36,1],[[32,1,[\"locationString\"]]],null]],[12],[2,\"\\n      \"],[1,[30,[36,3],[[32,1,[\"locationString\"]],[30,[36,2],[\"item-card.empty-state.location.title\"],null]],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"a-item-card-action\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"a-item-card-data\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"#design-system-p-icon-integration\"],[[\"class\",\"width\",\"height\",\"role\"],[\"a-item-card-data-icon\",16,16,\"presentation\"]]]],[2,\"\\n      \"],[10,\"div\"],[14,0,\"a-item-card-data-title\"],[15,\"data-placeholder\",[30,[36,1],[[32,1,[\"dataSource\"]]],null]],[12],[2,\"\\n        \"],[1,[30,[36,3],[[32,1,[\"dataSource\"]],[30,[36,2],[\"item-card.empty-state.data-source.title\"],null]],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[18,2,[[30,[36,4],[\"actions\"],null]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"svg-jar\",\"not\",\"t\",\"or\",\"-named-block-invocation\"]}","meta":{"moduleName":"clarify/components/a-item-card/metadata.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});