define("clarify/components/dropdown-list/item/subtitle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <li class="dropdown-list-item-subtitle" data-highlighted={{@highlighted}} ...attributes>
    <div class="dropdown-list-item-title-line">
      <span class="dropdown-list-item-labels-title">
        {{@item.title}}
      </span>
      <span class="dropdown-list-item-labels-accessory">
        {{@item.accessory}}
      </span>
    </div>
    <div class="dropdown-list-item-labels-subtitle">
      {{@item.subtitle}}
    </div>
  </li>
  */
  {"id":"m5epq+FJ","block":"{\"symbols\":[\"@highlighted\",\"&attrs\",\"@item\"],\"statements\":[[11,\"li\"],[24,0,\"dropdown-list-item-subtitle\"],[16,\"data-highlighted\",[32,1]],[17,2],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"dropdown-list-item-title-line\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"dropdown-list-item-labels-title\"],[12],[2,\"\\n      \"],[1,[32,3,[\"title\"]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"span\"],[14,0,\"dropdown-list-item-labels-accessory\"],[12],[2,\"\\n      \"],[1,[32,3,[\"accessory\"]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"dropdown-list-item-labels-subtitle\"],[12],[2,\"\\n    \"],[1,[32,3,[\"subtitle\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}","meta":{"moduleName":"clarify/components/dropdown-list/item/subtitle.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});