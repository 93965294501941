define("clarify/services/gesture", ["exports", "gesture-recognizer/services/gesture"], function (_exports, _gesture) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _gesture.default;
    }
  });
});