define("clarify/components/m-statistics-panel/info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <p class="stats-panel-info">
    {{t "statistics-panel.statistics-info"}}
  </p>
  */
  {"id":"MYF6vWhx","block":"{\"symbols\":[],\"statements\":[[10,\"p\"],[14,0,\"stats-panel-info\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"statistics-panel.statistics-info\"],null]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\"]}","meta":{"moduleName":"clarify/components/m-statistics-panel/info.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});