define("clarify/models/axis", ["exports", "d3-scale", "clarify/utilities/zoom-levels"], function (_exports, _d3Scale, _zoomLevels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Axis = (_dec = Ember.inject.service, _dec2 = Ember.computed.alias('zoom.rulerDateFormat'), _dec3 = Ember.computed.alias('zoom.presentDateFormat'), _dec4 = Ember.computed.alias('zoom.threadClusterLimit'), _dec5 = Ember.computed.alias('zoom.timeRangeTimeFormat'), _dec6 = Ember.computed('_zoom', '_scale'), (_class = (_temp = class Axis extends Ember.Object {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "locale", _descriptor, this);

      _defineProperty(this, "_scale", (0, _d3Scale.scaleTime)());

      _defineProperty(this, "zoom", _zoomLevels.DEFAULT_ZOOM);

      _defineProperty(this, "slots", []);

      _initializerDefineProperty(this, "rulerDateFormat", _descriptor2, this);

      _initializerDefineProperty(this, "presentTimeDateFormat", _descriptor3, this);

      _initializerDefineProperty(this, "threadClusterLimit", _descriptor4, this);

      _initializerDefineProperty(this, "timeRangeTimeFormat", _descriptor5, this);
    }

    get ticks() {
      return this.zoom.ticks(this._scale, this.locale);
    }

    update(scale, width) {
      let [from, to] = scale.domain();
      let zoom = this.findZoom(width, from, to);
      let force = this.zoom !== zoom;
      Ember.set(this, 'zoom', zoom);
      Ember.set(this, '_scale', scale);

      if (force === false && this.slots.length) {
        let first = this.slots[0];
        let last = this.slots[this.slots.length - 1];

        if (from.getTime() < first.from.getTime() || to.getTime() > last.to.getTime()) {
          this.generateSlots(from, to);
        }
      } else {
        this.generateSlots(from, to);
      }
    }

    findZoom(width, from, to) {
      return (0, _zoomLevels.zoomLevelForDomain)(width, from, to) || _zoomLevels.DEFAULT_ZOOM;
    }

    generateSlots(from, to) {
      let slots = this.zoom.slot.generate(from, to);
      Ember.set(this, 'slots', slots);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "locale", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "rulerDateFormat", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "presentTimeDateFormat", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "threadClusterLimit", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "timeRangeTimeFormat", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "ticks", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "ticks"), _class.prototype)), _class));
  _exports.default = Axis;
});