define("clarify/services/timeseries-data-provider", ["exports", "clarify/colors", "clarify/utilities/rpc/time-series-floats-visual", "clarify/utilities/rpc/time-series-enum-on-change", "clarify/models/item", "clarify/models/duration", "clarify/utilities/flat-map", "clarify/utilities/uuid"], function (_exports, _colors, _timeSeriesFloatsVisual, _timeSeriesEnumOnChange, _item, _duration, _flatMap, _uuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.PlainStatusPlot = _exports.VisualizationItemStatusPlot = _exports.PlainLineChartPlot = _exports.VisualizationItemLineChartPlot = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _dec6, _dec7, _class2, _temp, _dec8, _dec9, _dec10, _dec11, _class4, _dec12, _dec13, _class5, _temp2, _dec14, _class7, _descriptor, _temp3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let VisualizationItemLineChartPlot = (_dec = Ember.computed('visualizationItem.color'), _dec2 = Ember.computed('visualizationItem.visible'), _dec3 = Ember.computed('visualizationItem.displayName'), _dec4 = Ember.computed('visualizationItem.item'), _dec5 = Ember.computed('visualizationItem.id'), (_class = class VisualizationItemLineChartPlot {
    constructor(dataPoints, filtered, visualizationItem) {
      this.dataPoints = dataPoints;
      this.filtered = filtered;
      this.visualizationItem = visualizationItem;
    }

    get color() {
      return this.visualizationItem.color;
    }

    get visible() {
      return this.visualizationItem.visible != null ? this.visualizationItem.visible : true;
    }

    get displayName() {
      return this.visualizationItem.displayName;
    }

    get item() {
      return this.visualizationItem.item;
    }

    get identifier() {
      return this.visualizationItem.id;
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "color", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "color"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "visible", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "visible"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "displayName", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "displayName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "item", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "item"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "identifier", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "identifier"), _class.prototype)), _class));
  _exports.VisualizationItemLineChartPlot = VisualizationItemLineChartPlot;
  let PlainLineChartPlot = (_dec6 = Ember.computed('item.id'), _dec7 = Ember.computed('item.name'), (_class2 = (_temp = class PlainLineChartPlot {
    constructor(dataPoints, filtered, item) {
      this.dataPoints = dataPoints;
      this.filtered = filtered;
      this.item = item;

      _defineProperty(this, "visible", true);

      _defineProperty(this, "color", 'grey-01');
    }

    get identifier() {
      return this.item.id;
    }

    get displayName() {
      return this.item.name;
    }

  }, _temp), (_applyDecoratedDescriptor(_class2.prototype, "identifier", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "identifier"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "displayName", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "displayName"), _class2.prototype)), _class2));
  _exports.PlainLineChartPlot = PlainLineChartPlot;
  let VisualizationItemStatusPlot = (_dec8 = Ember.computed('visualizationItem.item'), _dec9 = Ember.computed('visualizationItem.displayName'), _dec10 = Ember.computed('visualizationItem.id'), _dec11 = Ember.computed('visualizationItem.visible'), (_class4 = class VisualizationItemStatusPlot {
    constructor(dataPoints, visualizationItem) {
      this.dataPoints = dataPoints;
      this.visualizationItem = visualizationItem;
    }

    get item() {
      return this.visualizationItem.item;
    }

    get displayName() {
      return this.visualizationItem.displayName;
    }

    get identifier() {
      return this.visualizationItem.id;
    }

    get visible() {
      return this.visualizationItem.visible != null ? this.visualizationItem.visible : true;
    }

  }, (_applyDecoratedDescriptor(_class4.prototype, "item", [_dec8], Object.getOwnPropertyDescriptor(_class4.prototype, "item"), _class4.prototype), _applyDecoratedDescriptor(_class4.prototype, "displayName", [_dec9], Object.getOwnPropertyDescriptor(_class4.prototype, "displayName"), _class4.prototype), _applyDecoratedDescriptor(_class4.prototype, "identifier", [_dec10], Object.getOwnPropertyDescriptor(_class4.prototype, "identifier"), _class4.prototype), _applyDecoratedDescriptor(_class4.prototype, "visible", [_dec11], Object.getOwnPropertyDescriptor(_class4.prototype, "visible"), _class4.prototype)), _class4));
  _exports.VisualizationItemStatusPlot = VisualizationItemStatusPlot;
  let PlainStatusPlot = (_dec12 = Ember.computed('item.id'), _dec13 = Ember.computed('item.name'), (_class5 = (_temp2 = class PlainStatusPlot {
    constructor(dataPoints, item) {
      this.dataPoints = dataPoints;
      this.item = item;

      _defineProperty(this, "visible", true);
    }

    get identifier() {
      return this.item.id;
    }

    get displayName() {
      return this.item.name;
    }

  }, _temp2), (_applyDecoratedDescriptor(_class5.prototype, "identifier", [_dec12], Object.getOwnPropertyDescriptor(_class5.prototype, "identifier"), _class5.prototype), _applyDecoratedDescriptor(_class5.prototype, "displayName", [_dec13], Object.getOwnPropertyDescriptor(_class5.prototype, "displayName"), _class5.prototype)), _class5));
  _exports.PlainStatusPlot = PlainStatusPlot;
  let TimeseriesDataProvider = (_dec14 = Ember.inject.service, (_class7 = (_temp3 = class TimeseriesDataProvider extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "network", _descriptor, this);
    }

    async fetchLineChart(item, from, to, signal) {
      let result;

      switch (item.type) {
        case _item.ItemType.numeric:
          result = await this.fetchNumeric(item, from, to, signal);
          break;

        case _item.ItemType.enum:
          result = await this.fetchEnum(item, from, to, signal);
          break;

        default:
          return Promise.reject(new Error('unsupported type'));
      }

      return result;
    }

    async fetchEnum(item, from, to, signal) {
      let request = _timeSeriesEnumOnChange.TimeSeriesEnumOnChangeRequestInfo.create((0, _uuid.default)(), {
        window: {
          from: from.toISOString(),
          to: to.toISOString()
        },
        query: {
          id: item.id,
          delta: item.deltaInterval
        }
      });

      let result = await this.network.rpc('timeseries/rpc', {
        signal
      }, request);
      let payload = result[0];

      if (payload.type === 'result') {
        return payload.result;
      }

      throw new Error('' + from + to + signal + item);
    }

    async fetchNumeric(item, from, to, signal) {
      let request = _timeSeriesFloatsVisual.TimeSeriesFloatsVisualRequestInfo.create((0, _uuid.default)(), {
        window: {
          from: from.toISOString(),
          to: to.toISOString(),
          pixels: 1500
        },
        query: {
          id: item.id,
          delta: item.deltaInterval
        }
      });

      let result = await this.network.rpc('timeseries/rpc', {
        signal
      }, request);
      let payload = result[0];

      if (payload.type === 'result') {
        return payload.result;
      }

      return Promise.reject(new Error('' + from + to + signal + item));
    }

    static normalizeSections(result) {
      let sections = result.sections || [];

      if (sections.length === 0 && (result.before || result.after)) {
        sections.push([]);
      }

      if (result.before) {
        sections[0].unshift(result.before);
      }

      if (result.after) {
        sections[sections.length - 1].push(result.after);
      }

      return sections;
    }

    static parsePayloadIntoStatusPlot(source, result) {
      let sections = this.normalizeSections(result);

      if (source instanceof _item.default) {
        let statusBlocks = (0, _flatMap.flatMap)(sections, section => this.parseSectionIntoStatusBlockPoint(section, source));
        return new PlainStatusPlot(statusBlocks, source);
      }

      let statusBlocks = (0, _flatMap.flatMap)(sections, section => this.parseSectionIntoStatusBlockPoint(section, source.item));
      return new VisualizationItemStatusPlot(statusBlocks, source);
    }

    static parseSectionIntoStatusBlockPoint(section, item) {
      let dataPoints = [...section];
      let firstDataPoint = dataPoints.shift();
      let enumValues = item.enumValues || {};
      let enumCount = Object.keys(enumValues).length;

      if (firstDataPoint) {
        let statusBlockPoints = [];
        let last = dataPoints.reduce((previous, next) => {
          if (previous[1] !== next[1]) {
            let value = previous[1];
            statusBlockPoints.push({
              value: value,
              from: new Date(previous[0]),
              to: new Date(next[0]),
              text: enumValues[`${value}`] || null,
              color: (0, _colors.colorInColorSet)(_colors.ColorSetName.oceanBlue, enumCount, value)
            });
            return next;
          }

          return previous;
        }, firstDataPoint);
        let duration = item.deltaInterval ? _duration.default.parse(item.deltaInterval) : null;
        let from = new Date(last[0]);
        let lastValue = section[section.length - 1];
        let toRaw = new Date(lastValue[0]);
        let value = last[1];
        let to = duration ? (0, _duration.addDuration)(toRaw, duration) : toRaw;

        if (from.getTime() !== to.getTime()) {
          statusBlockPoints.push({
            value,
            from,
            to,
            text: enumValues[`${value}`] || null,
            color: (0, _colors.colorInColorSet)(_colors.ColorSetName.oceanBlue, enumCount, value)
          });
        }

        return statusBlockPoints;
      }

      return [];
    }

    static parsePayloadIntoLineChartPlots(model, result) {
      let sections = TimeseriesDataProvider.normalizeSections(result);
      let linePlots = sections.map(section => {
        let raw = section;
        let dataPoints = raw.map(([dateString, value]) => {
          let date = new Date(dateString);
          return [date.valueOf() * 1000, value];
        });

        if (model instanceof _item.default) {
          return new PlainLineChartPlot(dataPoints, result.filtered, model);
        } else {
          return new VisualizationItemLineChartPlot(dataPoints, result.filtered, model);
        }
      });
      return linePlots;
    }

  }, _temp3), (_descriptor = _applyDecoratedDescriptor(_class7.prototype, "network", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class7));
  _exports.default = TimeseriesDataProvider;
});