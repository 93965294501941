define("clarify/components/m-statistics-panel/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <header class="stats-panel-header" data-style={{@style}}>
    <span>
      {{t "statistics-panel.title"}}
    </span>
    <div>
      <AButtonHiddenbox
        title={{t "statistics-panel.statistics-export"}}
        @icon={{svg-jar "#design-system-icon-export-24" width=24 height=24}}
        {{on "click" (fn @openExport)}}
      />
      <AButtonHiddenbox
        title={{t "statistics-panel.statistics-close"}}
        @icon={{svg-jar "#design-system-icon-cross-24" width=24 height=24}}
        {{on "click" (fn @close)}}
      />
    </div>
  </header>
  */
  {"id":"3RvFuobK","block":"{\"symbols\":[\"@style\",\"@openExport\",\"@close\"],\"statements\":[[10,\"header\"],[14,0,\"stats-panel-header\"],[15,\"data-style\",[32,1]],[12],[2,\"\\n  \"],[10,\"span\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"statistics-panel.title\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[8,\"a-button-hiddenbox\",[[16,\"title\",[30,[36,0],[\"statistics-panel.statistics-export\"],null]],[4,[38,3],[\"click\",[30,[36,2],[[32,2]],null]],null]],[[\"@icon\"],[[30,[36,1],[\"#design-system-icon-export-24\"],[[\"width\",\"height\"],[24,24]]]]],null],[2,\"\\n    \"],[8,\"a-button-hiddenbox\",[[16,\"title\",[30,[36,0],[\"statistics-panel.statistics-close\"],null]],[4,[38,3],[\"click\",[30,[36,2],[[32,3]],null]],null]],[[\"@icon\"],[[30,[36,1],[\"#design-system-icon-cross-24\"],[[\"width\",\"height\"],[24,24]]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"svg-jar\",\"fn\",\"on\"]}","meta":{"moduleName":"clarify/components/m-statistics-panel/header.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});