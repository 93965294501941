define("clarify/components/timeline-canvas/legend/visualization/button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <button ...attributes class="a-legend-visual-active">
    <span class="a-legend-visual-icon">
      {{svg-jar "#design-system-p-display-graph-18" width=16 height=16}}
    </span>
    {{#if (not @isDefaultName)}}
      <span class="a-legend-visual-title">
        {{@visualization.name}}
      </span>
    {{/if}}
  </button>
  */
  {"id":"iE00jxuY","block":"{\"symbols\":[\"@visualization\",\"&attrs\",\"@isDefaultName\"],\"statements\":[[11,\"button\"],[17,2],[24,0,\"a-legend-visual-active\"],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"a-legend-visual-icon\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"#design-system-p-display-graph-18\"],[[\"width\",\"height\"],[16,16]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,3]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"a-legend-visual-title\"],[12],[2,\"\\n      \"],[1,[32,1,[\"name\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"svg-jar\",\"not\",\"if\"]}","meta":{"moduleName":"clarify/components/timeline-canvas/legend/visualization/button.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});