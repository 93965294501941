define("clarify/adapters/threshold", ["exports", "clarify/adapters/meta"], function (_exports, _meta) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Threshold extends _meta.default {
    fields(_requestType) {
      return ['createdAt', 'createdBy', 'id', 'source', 'trigger', 'updatedAt', 'updatedBy', 'timeline', 'displayOptions'];
    }

  }

  _exports.default = Threshold;
});