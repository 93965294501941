define("clarify/components/keyword-filter/index", ["exports", "@glimmer/component", "clarify/utilities/compact-map", "clarify/utilities/create-tree"], function (_exports, _component, _compactMap, _createTree) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield
    (hash
      filter=(component
        "keyword-filter/filter" categories=@categories setEnabled=(fn this.setEnabled) isEnabled=(fn this.isEnabled)
      )
      clearAll=(fn this.clearAll)
      items=this.tokenFieldTokens
      filterBar=(component
        "keyword-filter/filter-bar"
        selectItem=(fn this.insertDropdownItem)
        filter=(fn this.filterDropdownItems)
        filters=@filters
        sections=this.dropdownSections
        items=this.tokenFieldTokens
        removeToken=(fn this.removeToken)
      )
    )
  }}
  */
  {"id":"F8RjL5hi","block":"{\"symbols\":[\"@filters\",\"@categories\",\"&default\"],\"statements\":[[18,3,[[30,[36,2],null,[[\"filter\",\"clearAll\",\"items\",\"filterBar\"],[[30,[36,1],[\"keyword-filter/filter\"],[[\"categories\",\"setEnabled\",\"isEnabled\"],[[32,2],[30,[36,0],[[32,0,[\"setEnabled\"]]],null],[30,[36,0],[[32,0,[\"isEnabled\"]]],null]]]],[30,[36,0],[[32,0,[\"clearAll\"]]],null],[32,0,[\"tokenFieldTokens\"]],[30,[36,1],[\"keyword-filter/filter-bar\"],[[\"selectItem\",\"filter\",\"filters\",\"sections\",\"items\",\"removeToken\"],[[30,[36,0],[[32,0,[\"insertDropdownItem\"]]],null],[30,[36,0],[[32,0,[\"filterDropdownItems\"]]],null],[32,1],[32,0,[\"dropdownSections\"]],[32,0,[\"tokenFieldTokens\"]],[30,[36,0],[[32,0,[\"removeToken\"]]],null]]]]]]]]]],\"hasEval\":false,\"upvars\":[\"fn\",\"component\",\"hash\"]}","meta":{"moduleName":"clarify/components/keyword-filter/index.hbs"}});

  let Index = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_class = (_temp = class Index extends _component.default {
    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "categories", _descriptor, this);

      this.restoreCategories(args.tokens);
    }

    restoreCategories(tokens) {
      for (const token of tokens) {
        if (token.type === 'keyword') {
          let category = this.args.categories.find(category => category.id === token.category);

          if (category) {
            let tree = _createTree.Tree.fromItems(category.items, item => item.id, item => item.parentId);

            let leaf = tree.get(token.item);

            while (leaf) {
              this.updateCategory(token.category, leaf.id, true);
              leaf = leaf.parentId ? tree.get(leaf.parentId) : undefined;
            }
          }
        }
      }
    }

    removeToken(token) {
      if (token.type === 'keyword') {
        this.setItemEnabled(token.item.category, token.item.item, false, false);
        let selectItems = this.categories.get(token.item.category.id) || new Set();
        this.forEachParent(token.item.category, token.item.item, item => {
          let checkedChild = item.children.find(child => selectItems.has(child.id));

          if (!checkedChild) {
            this.updateCategory(token.item.category.id, item.id, false);
          }
        });
        let tokens = this.args.tokens.filter(item => {
          if (item.type === 'keyword') {
            return item.item !== token.item.id;
          }

          return true;
        });
        this.args.didUpdateTokens(tokens);
      } else {
        let tokens = this.args.tokens.filter(item => {
          if (item.type === 'query') {
            return item.query !== token.query;
          }

          return true;
        });
        this.args.didUpdateTokens(tokens);
      }

      this.updateQuery();
    }

    clearAll() {
      if (this.args.tokens.length !== 0) {
        this.categories = new Map();
        this.args.didUpdateTokens([]);
        this.updateQuery();
      }
    }

    insertDropdownItem(item) {
      switch (item.type) {
        case 'keyword':
          let tree = _createTree.Tree.fromItems(item.model.category.items, item => item.id, item => item.parentId);

          let leaf = tree.get(item.model.item.id);

          while (leaf) {
            this.updateCategory(item.model.category.id, leaf.id, true);
            leaf = leaf.parentId ? tree.get(leaf.parentId) : undefined;
          }

          let tokens = [...this.args.tokens, {
            type: 'keyword',
            category: item.model.category.id,
            item: item.model.item.id
          }];
          this.args.didUpdateTokens(tokens);
          this.parents(item.model.category, item.model.item);
          break;

        case 'query':
          let queryTokens = [...this.args.tokens, {
            type: 'query',
            query: item.query
          }];
          this.args.didUpdateTokens(queryTokens);
          break;
      }

      this.updateQuery();
    }

    filterDropdownItems(items, query) {
      if (!query) {
        return {
          type: 'hidden'
        };
      }

      let lowerCaseQuery = query.toLowerCase();
      let sections = (0, _compactMap.default)(items, section => {
        let items = section.items.filter(item => {
          if (item.type === 'keyword') {
            let name = item.model.item.name.toLowerCase();
            return name.indexOf(lowerCaseQuery) !== -1;
          }

          return true;
        });

        if (items.length) {
          return { ...section,
            items
          };
        }

        return null;
      });
      let queryItem = {
        type: 'query',
        query
      };
      return {
        type: 'visible',
        sections: [{
          title: null,
          items: [queryItem]
        }, ...sections]
      };
    }

    get dropdownSections() {
      return (0, _compactMap.default)(this.args.categories, category => {
        let items = (0, _compactMap.default)(category.items, item => {
          if (item.count !== 0 && this.isEnabled(category, item) === false) {
            return {
              checked: false,
              model: {
                item,
                category
              },
              title: item.name,
              type: 'keyword'
            };
          }

          return null;
        });

        if (items.length !== 0) {
          return {
            title: category.name,
            items: items
          };
        }

        return null;
      });
    }

    get tokenFieldTokens() {
      let cache = new Map();

      for (let category of this.args.categories) {
        for (let item of category.items) {
          cache.set(item.id, {
            item: item,
            category,
            id: item.id
          });
        }
      }

      return (0, _compactMap.default)(this.args.tokens, id => {
        if (id.type === 'keyword') {
          let item = cache.get(id.item);

          if (item) {
            return {
              type: 'keyword',
              item
            };
          }
        }

        return id;
      });
    }

    isEnabled(category, item) {
      let categorySet = this.categories.get(category.id);

      if (categorySet) {
        return categorySet.has(item.id);
      }

      return false;
    }

    setEnabled(category, item, enabled) {
      this.setItemEnabled(category, item, enabled);
      this.updateQuery();
    }

    setItemEnabled(category, item, enabled, autoEnable = true) {
      this.updateCategory(category.id, item.id, enabled);

      if (enabled) {
        let tokens = [...this.args.tokens, {
          type: 'keyword',
          category: category.id,
          item: item.id
        }];
        this.args.didUpdateTokens(tokens);
        this.parents(category, item);
      } else {
        let tree = _createTree.Tree.fromItems(category.items, item => item.id, item => item.parentId);

        let treeItem = tree.get(item.id);

        let updateTreeItem = item => {
          this.updateCategory(category.id, item.id, enabled);

          for (let child of item.children) {
            updateTreeItem(child);
          }
        };

        let selectItems = this.categories.get(category.id) || new Set();

        if (treeItem) {
          updateTreeItem(treeItem);

          if (autoEnable && treeItem.parentId) {
            let parent = tree.get(treeItem.parentId);

            if (parent) {
              let checkedChild = parent.children.find(child => selectItems.has(child.id));

              if (!checkedChild) {
                let tokens = [...this.args.tokens, {
                  type: 'keyword',
                  category: category.id,
                  item: parent.id
                }];
                this.args.didUpdateTokens(tokens);
              }
            }
          }
        }

        let tokens = this.args.tokens.filter(token => {
          if (token.type === 'keyword' && token.category === category.id) {
            return selectItems.has(token.item);
          }

          return true;
        });
        this.args.didUpdateTokens(tokens);
      }
    }

    updateCategory(categoryId, itemId, enabled) {
      let categorySet = this.categories.get(categoryId);

      if (categorySet) {
        if (enabled) {
          categorySet.add(itemId);
        } else {
          categorySet.delete(itemId);
        }
      } else if (enabled) {
        this.categories.set(categoryId, new Set([itemId]));
      }

      this.categories = this.categories;
    }

    parents(category, item) {
      let tree = _createTree.Tree.fromItems(category.items, item => item.id, item => item.parentId);

      let treeItem = tree.get(item.id);
      let parents = new Set();

      while (treeItem) {
        if (treeItem.parentId) {
          parents.add(treeItem.parentId);
        }

        treeItem = treeItem.parentId ? tree.get(treeItem.parentId) : undefined;
      }

      let tokens = this.args.tokens.filter(token => {
        if (token.type === 'keyword') {
          return parents.has(token.item) ? false : true;
        }

        return true;
      });
      this.args.didUpdateTokens(tokens);
    }

    forEachParent(category, item, iterator) {
      if (!item.parentId) {
        return;
      }

      let tree = _createTree.Tree.fromItems(category.items, item => item.id, item => item.parentId);

      let treeItem = tree.get(item.parentId);

      while (treeItem) {
        iterator(treeItem);
        treeItem = treeItem.parentId ? tree.get(treeItem.parentId) : undefined;
      }
    }

    leafNodes(category) {
      let selectedItems = this.categories.get(category.id);

      if (!selectedItems) {
        return [];
      }

      let tree = _createTree.Tree.fromItems(category.items, item => item.id, item => item.parentId);

      let leafNodes = [];

      for (let child of tree.children) {
        this.appendLeafNodes(child, leafNodes, selectedItems);
      }

      return leafNodes;
    }

    appendLeafNodes(treeItem, leafNodes, selectItems) {
      if (selectItems.has(treeItem.id)) {
        let children = this.checkedChildren(treeItem, selectItems);

        if (children.length === 0) {
          leafNodes.push(treeItem.item);
        } else {
          for (let child of treeItem.children) {
            this.appendLeafNodes(child, leafNodes, selectItems);
          }
        }
      }
    }

    checkedChildren(treeItem, selectItems) {
      return treeItem.children.filter(child => selectItems.has(child.id));
    }

    updateQuery() {
      let query = {};

      for (let category of this.args.categories) {
        let leafNodes = this.leafNodes(category);

        if (leafNodes.length) {
          query[category.id] = leafNodes.map(node => node.path);
        }
      }

      let searchText = (0, _compactMap.default)(this.args.tokens, item => {
        if (item.type === 'query') {
          return `(?i)${item.query}`;
        }

        return null;
      });
      this.args.didUpdate(query, searchText);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "categories", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new Map();
    }
  }), _applyDecoratedDescriptor(_class.prototype, "removeToken", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "removeToken"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearAll", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "clearAll"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "insertDropdownItem", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "insertDropdownItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filterDropdownItems", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "filterDropdownItems"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isEnabled", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "isEnabled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setEnabled", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "setEnabled"), _class.prototype)), _class));
  _exports.default = Index;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Index);
});