define("clarify/app", ["exports", "clarify/resolver", "ember-load-initializers", "clarify/environment", "clarify/externals/sentry", "clarify/externals/segment"], function (_exports, _resolver, _emberLoadInitializers, _environment, _sentry, _segment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Clarify extends Ember.Application {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "modulePrefix", _environment.default.modulePrefix);

      _defineProperty(this, "podModulePrefix", _environment.default.podModulePrefix);

      _defineProperty(this, "Resolver", _resolver.default);

      _defineProperty(this, "customEvents", {
        paste: 'paste'
      });

      _defineProperty(this, "engines", {
        account: {
          dependencies: {
            services: ['m3-schema-manager', 'authentication', 'current-user', 'current-organization', 'network', 'locale', 'alert', {
              'app-store': 'store'
            }, {
              store: 'settings-store'
            }]
          }
        },
        admin: {
          dependencies: {
            services: ['m3-schema-manager', 'authentication', 'current-user', 'current-organization', 'network', 'locale', 'alert', {
              'app-store': 'store'
            }, {
              store: 'settings-store'
            }]
          }
        }
      });
    }

  }

  (0, _emberLoadInitializers.default)(Clarify, _environment.default.modulePrefix);
  var _default = Clarify;
  _exports.default = _default;
});