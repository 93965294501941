define("clarify/components/m-user-tag/group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <MUserTag::-private
    @icon="/design-system/design-system-profilepic-group-32.svg"
    @user={{@group}}
    @onRemove={{@removeTag}}
  />
  */
  {"id":"2xbbpImV","block":"{\"symbols\":[\"@group\",\"@removeTag\"],\"statements\":[[8,\"m-user-tag/-private\",[],[[\"@icon\",\"@user\",\"@onRemove\"],[\"/design-system/design-system-profilepic-group-32.svg\",[32,1],[32,2]]],null]],\"hasEval\":false,\"upvars\":[]}","meta":{"moduleName":"clarify/components/m-user-tag/group.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});