define("clarify/gestures/drag", ["exports", "gesture-recognizer/gestures/-base", "gesture-recognizer/gestures/pan"], function (_exports, _base, _pan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class DragGestureRecognizer extends _base.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "start", null);

      _defineProperty(this, "location", null);

      _defineProperty(this, "delta", null);
    }

    pointerDown(event) {
      this.update(event);
      Ember.set(this, 'state', _base.GestureRecognizerState.began);
    }

    pointerMove(event) {
      this.update(event);
      Ember.set(this, 'state', _base.GestureRecognizerState.changed);
    }

    pointerUp(event) {
      this.update(event);
      Ember.set(this, 'state', _base.GestureRecognizerState.ended);
    }

    update(event) {
      let location = (0, _pan.pointForEvent)(event, this.element);

      if (this.location) {
        this.delta = {
          x: location.x - this.location.x,
          y: location.y - this.location.y
        };
        this.location = location;
      } else {
        this.delta = {
          x: 0,
          y: 0
        };
        this.location = this.start = location;
      }
    }

    reset() {
      this.start = null;
      this.location = null;
      this.delta = null;
    }

  }

  _exports.default = DragGestureRecognizer;
});