define("clarify/initializers/modal-router", ["exports", "clarify/routes/organizations/show/items/show"], function (_exports, _show) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.itemModalParentRoutes = _exports.itemsNativePath = void 0;
  // import ItemModalParent from 'clarify/routes/organizations/show/items';
  let internalItemModalParentRoutes = ['organizations.show.timelines.index', 'organizations.show.timelines.show'];
  let itemsNativePath = 'organizations.show.items.index';
  _exports.itemsNativePath = itemsNativePath;
  let itemModalParentRoutes = [...internalItemModalParentRoutes, itemsNativePath];
  _exports.itemModalParentRoutes = itemModalParentRoutes;
  var _default = {
    name: 'modal-router',
    initialize: function (register) {
      register.register(`route:${itemsNativePath}.show`, _show.default);

      for (let parent of internalItemModalParentRoutes) {
        register.register(`route:${parent}.items.show`, _show.default);
      }
    }
  };
  _exports.default = _default;
});