define("clarify/draggable/tests/app.lint-test", [], function () {
  "use strict";

  QUnit.module('ESLint | app');
  QUnit.test('app/components/drag-source.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/drag-source.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/drop-zone.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/drop-zone.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/scroll-interaction.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/scroll-interaction.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/sort-interaction.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/sort-interaction.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/sort-placeholder.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/sort-placeholder.js should pass ESLint\n\n');
  });
  QUnit.test('app/initializers/draggable.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/initializers/draggable.js should pass ESLint\n\n');
  });
  QUnit.test('app/modifiers/drag-source.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/modifiers/drag-source.js should pass ESLint\n\n');
  });
  QUnit.test('app/services/drag-coordinator.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/services/drag-coordinator.js should pass ESLint\n\n');
  });
});