define("clarify/components/organizations/show/items/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <header ...attributes>
    <h2 class="items-header-title">
      {{@item.name}}
      {{#if @item.engUnit}}
        <span class="items-header-eng-unit">
          {{@item.engUnit}}
        </span>
      {{/if}}
    </h2>
    <ul class="items-header-metadata">
      <Organizations::Show::Items::MetadataEntry
        @title={{t "item-dialog.metadata.location"}}
        @icon={{svg-jar "#design-system-p-icon-location" width=16 height=16}}
        @value={{@item.locationString}}
      />
      <Organizations::Show::Items::MetadataEntry
        @icon={{svg-jar "#design-system-p-icon-integration" width=16 height=16}}
        @title={{t "item-dialog.metadata.data-source"}}
        @value={{@item.dataSource}}
      />
    </ul>
    <div class="items-header-actions">
      <AButtonHiddenbox
        class="items-header-actions-button"
        title={{t "item-dialog.close"}}
        @icon={{svg-jar "#design-system-icon-cross-24" width=24 height=24}}
        {{on "click" (fn @close)}}
      />
    </div>
  </header>
  */
  {"id":"AV88brjN","block":"{\"symbols\":[\"@item\",\"&attrs\",\"@close\"],\"statements\":[[11,\"header\"],[17,2],[12],[2,\"\\n  \"],[10,\"h2\"],[14,0,\"items-header-title\"],[12],[2,\"\\n    \"],[1,[32,1,[\"name\"]]],[2,\"\\n\"],[6,[37,0],[[32,1,[\"engUnit\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"span\"],[14,0,\"items-header-eng-unit\"],[12],[2,\"\\n        \"],[1,[32,1,[\"engUnit\"]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n  \"],[10,\"ul\"],[14,0,\"items-header-metadata\"],[12],[2,\"\\n    \"],[8,\"organizations/show/items/metadata-entry\",[],[[\"@title\",\"@icon\",\"@value\"],[[30,[36,1],[\"item-dialog.metadata.location\"],null],[30,[36,2],[\"#design-system-p-icon-location\"],[[\"width\",\"height\"],[16,16]]],[32,1,[\"locationString\"]]]],null],[2,\"\\n    \"],[8,\"organizations/show/items/metadata-entry\",[],[[\"@icon\",\"@title\",\"@value\"],[[30,[36,2],[\"#design-system-p-icon-integration\"],[[\"width\",\"height\"],[16,16]]],[30,[36,1],[\"item-dialog.metadata.data-source\"],null],[32,1,[\"dataSource\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"items-header-actions\"],[12],[2,\"\\n    \"],[8,\"a-button-hiddenbox\",[[24,0,\"items-header-actions-button\"],[16,\"title\",[30,[36,1],[\"item-dialog.close\"],null]],[4,[38,4],[\"click\",[30,[36,3],[[32,3]],null]],null]],[[\"@icon\"],[[30,[36,2],[\"#design-system-icon-cross-24\"],[[\"width\",\"height\"],[24,24]]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"t\",\"svg-jar\",\"fn\",\"on\"]}","meta":{"moduleName":"clarify/components/organizations/show/items/header.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});