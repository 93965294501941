define("clarify/components/timeline-card/comment-preview/content", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.comment}}
    <div class="timeline-card-comment">
      <div class="timeline-card-comment-content">
        <p class="timeline-card-comment-text-overflow">
          {{#if this.comment.createdByUser}}
            {{this.comment.createdByUser.name}}
          {{else}}
            {{t "helpers.user.unknown"}}
          {{/if}}
        </p>
        <span class="timeline-card-comment-text-overflow">
          {{#if this.isReply}}
            {{t "timeline-card.replied" date=(format-distance-to-now this.comment.createdAt)}}
          {{else}}
            {{t "timeline-card.commented" date=(format-distance-to-now this.comment.createdAt)}}
          {{/if}}
        </span>
      </div>
      <div class="timeline-card-comment-image">
        <Avatar @user={{this.comment.createdByUser}} width="28" height="28" />
      </div>
    </div>
  {{/if}}
  */
  {"id":"Zy6xVGU5","block":"{\"symbols\":[],\"statements\":[[6,[37,2],[[32,0,[\"comment\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"timeline-card-comment\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"timeline-card-comment-content\"],[12],[2,\"\\n      \"],[10,\"p\"],[14,0,\"timeline-card-comment-text-overflow\"],[12],[2,\"\\n\"],[6,[37,2],[[32,0,[\"comment\",\"createdByUser\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          \"],[1,[32,0,[\"comment\",\"createdByUser\",\"name\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"          \"],[1,[30,[36,1],[\"helpers.user.unknown\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n      \"],[10,\"span\"],[14,0,\"timeline-card-comment-text-overflow\"],[12],[2,\"\\n\"],[6,[37,2],[[32,0,[\"isReply\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          \"],[1,[30,[36,1],[\"timeline-card.replied\"],[[\"date\"],[[30,[36,0],[[32,0,[\"comment\",\"createdAt\"]]],null]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"          \"],[1,[30,[36,1],[\"timeline-card.commented\"],[[\"date\"],[[30,[36,0],[[32,0,[\"comment\",\"createdAt\"]]],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"timeline-card-comment-image\"],[12],[2,\"\\n      \"],[8,\"avatar\",[[24,\"width\",\"28\"],[24,\"height\",\"28\"]],[[\"@user\"],[[32,0,[\"comment\",\"createdByUser\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"format-distance-to-now\",\"t\",\"if\"]}","meta":{"moduleName":"clarify/components/timeline-card/comment-preview/content.hbs"}});

  class CommentIndicator extends _component.default {
    get comment() {
      let comments = this.args.thread.comments.toArray();
      return comments[comments.length - 1];
    }

    get isReply() {
      let comments = this.args.thread.comments.toArray();
      let lastComment = comments[comments.length - 1];
      let firstComment = this.args.thread.comment;

      if (firstComment && lastComment) {
        return lastComment.id !== firstComment.id;
      }

      return false;
    }

  }

  _exports.default = CommentIndicator;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CommentIndicator);
});