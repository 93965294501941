define("clarify/components/card/activity/default.d", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CommentActivityGroup extends _component.default {}

  _exports.default = CommentActivityGroup;
});