define("clarify/transitions/slide-out-to-right", ["exports", "clarify/transitions/slide-out"], function (_exports, _slideOut) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(opts) {
    return _slideOut.default.call(this, -1, opts);
  }
});