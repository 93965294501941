define("clarify/karl/tests/app.lint-test", [], function () {
  "use strict";

  QUnit.module('ESLint | app');
  QUnit.test('app/components/karl/backdrop.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/karl/backdrop.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/karl/container.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/karl/container.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/karl/index.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/karl/index.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/karl/offclick.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/karl/offclick.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/karl/wrapper.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/karl/wrapper.js should pass ESLint\n\n');
  });
  QUnit.test('app/helpers/move-focus.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/helpers/move-focus.js should pass ESLint\n\n');
  });
});