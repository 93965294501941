define("clarify/components/permission-type-dropdown/index", ["exports", "@glimmer/component", "clarify/models/permission"], function (_exports, _component, _permission) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <ATextDropdown
    ...attributes
    @disabled={{@disabled}}
    @title={{t (concat "permission-type-dropdown.title." @permissionType)}} as |menu|
  >
    <menu.options @value={{@permissionType}} @valueDidChange={{fn @permissionTypeDidChange}} as |Option|>
      {{#each this.options as |optionValue|}}
        <Option
          @title={{t (concat "permission-type-dropdown.options." optionValue ".title")}}
          @description={{t (concat "permission-type-dropdown.options." optionValue ".description")}}
          @value={{optionValue}}
        />
      {{/each}}
    </menu.options>
    {{#if @removePermission}}
      <menu.separator />
      <menu.item @title={{t "permission-type-dropdown.remove"}} @intent="primary" @action={{fn @removePermission}} />
    {{/if}}
  </ATextDropdown>
  */
  {"id":"zOseUxJ4","block":"{\"symbols\":[\"menu\",\"Option\",\"optionValue\",\"@removePermission\",\"&attrs\",\"@disabled\",\"@permissionType\",\"@permissionTypeDidChange\"],\"statements\":[[8,\"a-text-dropdown\",[[17,5]],[[\"@disabled\",\"@title\"],[[32,6],[30,[36,0],[[30,[36,2],[\"permission-type-dropdown.title.\",[32,7]],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"options\"]],[],[[\"@value\",\"@valueDidChange\"],[[32,7],[30,[36,1],[[32,8]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,0,[\"options\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,[32,2],[],[[\"@title\",\"@description\",\"@value\"],[[30,[36,0],[[30,[36,2],[\"permission-type-dropdown.options.\",[32,3],\".title\"],null]],null],[30,[36,0],[[30,[36,2],[\"permission-type-dropdown.options.\",[32,3],\".description\"],null]],null],[32,3]]],null],[2,\"\\n\"]],\"parameters\":[3]}]]],[2,\"  \"]],\"parameters\":[2]}]]],[2,\"\\n\"],[6,[37,5],[[32,4]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,[32,1,[\"separator\"]],[],[[],[]],null],[2,\"\\n    \"],[8,[32,1,[\"item\"]],[],[[\"@title\",\"@intent\",\"@action\"],[[30,[36,0],[\"permission-type-dropdown.remove\"],null],\"primary\",[30,[36,1],[[32,4]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"fn\",\"concat\",\"-track-array\",\"each\",\"if\"]}","meta":{"moduleName":"clarify/components/permission-type-dropdown/index.hbs"}});

  class PermissionTypeDropdown extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "options", [_permission.PermissionType.owner, _permission.PermissionType.collaborator, _permission.PermissionType.guest]);
    }

  }

  _exports.default = PermissionTypeDropdown;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PermissionTypeDropdown);
});