define("clarify/services/analytics", ["exports", "date-fns"], function (_exports, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SessionStorage = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class SessionStorage {
    constructor(id, expires) {
      _defineProperty(this, "id", void 0);

      _defineProperty(this, "expires", void 0);

      this.id = id;
      this.expires = expires;
    }

    renew() {
      this.expires = (0, _dateFns.addMinutes)(new Date(), 30);
    }

    isValid() {
      return this.expires.valueOf() > Date.now();
    }

    toJSON() {
      return {
        id: this.id,
        expires: this.expires.valueOf()
      };
    }

    static restore() {
      let session = localStorage.getItem(SessionStorage.key);

      if (session) {
        try {
          let payload = JSON.parse(session);

          if (payload.id && payload.expires) {
            if (typeof payload.id === 'number' && typeof payload.expires === 'number') {
              let session = new SessionStorage(payload.id, new Date(payload.expires));

              if (session.isValid()) {
                return session;
              }
            }
          }
        } catch (error) {
          console.error('SessionStorage::restore failed to restore token');
        }
      }

      return null;
    }

    static create() {
      return new SessionStorage(Date.now(), (0, _dateFns.addMinutes)(new Date(), 30));
    }

  }

  _exports.SessionStorage = SessionStorage;

  _defineProperty(SessionStorage, "key", 'clarify_session');

  const UTM_ANNOTATOR_COOKIE_NAME = 'searis-utm-annotater';
  let Analytics = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, (_class = (_temp = class Analytics extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "authentication", _descriptor2, this);

      _initializerDefineProperty(this, "session", _descriptor3, this);

      _initializerDefineProperty(this, "intercomEnabled", _descriptor4, this);

      _initializerDefineProperty(this, "intercomUnreadCount", _descriptor5, this);

      _initializerDefineProperty(this, "campaign", _descriptor6, this);
    }

    init() {
      super.init();
      this.router.on('routeDidChange', transition => {
        let from = transition.from && transition.from.name;
        let to = transition.to && transition.to.name;

        if (from !== to) {
          this.page(transition.to.name, transition.from && transition.from.name);
        } else {
          // Bump the session expiry time when any changes does accure. This will
          // happen when navigating in a timeline.
          this.renewSession();
        }
      });
      this.session = SessionStorage.restore() || SessionStorage.create();
      window.addEventListener('storage', event => {
        switch (event.key) {
          case SessionStorage.key:
            let session = SessionStorage.restore();

            if (session) {
              this.session = session;
            }

            break;
        }
      });
      analytics.ready(() => {
        this.intercomEnabled = true;
        Intercom('onUnreadCountChange', unreadCount => {
          this.intercomUnreadCount = unreadCount;
        });
      });
    }

    storeCampaign(campaign) {
      this.campaign = campaign;
    }

    renewSession() {
      if (this.session.isValid()) {
        this.session.renew();
      } else {
        this.session = SessionStorage.create();
      }

      localStorage.setItem(SessionStorage.key, JSON.stringify(this.session));
      return this.session;
    }

    userId() {
      var _analytics$user, _analytics;

      return (_analytics$user = (_analytics = analytics).user) === null || _analytics$user === void 0 ? void 0 : _analytics$user.call(_analytics).id();
    }

    identify(user) {
      let context = this.campaign ? {
        campaign: this.campaign
      } : undefined;
      analytics.identify(user.id, {
        name: user.name,
        email: user.email
      }, {
        integrations: {
          Intercom: {
            user_hash: this.authentication.intercomHMAC
          }
        },
        context
      });
    }

    group(organization) {
      if (organization) {
        let traits = {
          name: organization.name,
          company: 'company',
          ...this.campaign
        };
        analytics.group(organization.id, traits);
      } else {
        var _analytics$group$rese, _analytics$group;

        // @ts-expect-error
        (_analytics$group$rese = (_analytics$group = analytics.group()).reset) === null || _analytics$group$rese === void 0 ? void 0 : _analytics$group$rese.call(_analytics$group);
      }
    }

    groupTraits(newTraits) {
      var _analytics$group$trai, _analytics$group2;

      (_analytics$group$trai = (_analytics$group2 = analytics.group()).traits) === null || _analytics$group$trai === void 0 ? void 0 : _analytics$group$trai.call(_analytics$group2, newTraits);
    }
    /**
     * Parses UTM annotator cookie set on the marketing site. We want to track were we aquire our customers.
     * When a user visits our marketing site the campaign is stored in a cookie so we can access it and
     * assosiated it with the user and its organization.
     *
     * This method will delete the cookie.
     */


    popCampaign() {
      try {
        var _document$cookie$matc;

        let matcher = new RegExp(`(^|;)\\s*${UTM_ANNOTATOR_COOKIE_NAME}\\s*=\\s*([^;]+)`);
        let result = (_document$cookie$matc = document.cookie.match(matcher)) === null || _document$cookie$matc === void 0 ? void 0 : _document$cookie$matc.pop();

        if (result) {
          let {
            source,
            campaign,
            medium,
            term,
            content
          } = JSON.parse(decodeURIComponent(result));

          if (source && campaign && medium) {
            let host = location.hostname.split('.').splice(-2).join('.');
            document.cookie = `${UTM_ANNOTATOR_COOKIE_NAME}= ; path = / ; domain = .${host} ; expires = Thu, 01 Jan 1970 00:00:00 GMT`;
            return {
              source: source,
              campaign: campaign,
              medium: medium,
              term: term,
              content: content
            };
          }
        }

        return undefined;
      } catch {
        return undefined;
      }
    }

    showIntercom() {
      Intercom('show');
    }

    logout() {
      analytics.reset();
    }

    page(to, _from) {
      analytics.page(to, {}, this.analyticsOptions());
    }

    analyticsOptions() {
      let {
        id
      } = this.renewSession();
      return {
        integrations: {
          Amplitude: {
            session_id: id
          }
        }
      };
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "authentication", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "intercomEnabled", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "intercomUnreadCount", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "campaign", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  })), _class));
  _exports.default = Analytics;
});