define("clarify/components/comment/image", ["exports", "@glimmer/component", "clarify/helpers/resize-attachment"], function (_exports, _component, _resizeAttachment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="comment-image" ...attributes>
    {{#if @attachment.downloadURL}}
      <Image
        @width={{this.previewSize.width}}
        @height={{this.previewSize.height}}
        @source={{resize-attachment @attachment width=@maxImageSize.width height=@maxImageSize.height}}
      />
    {{/if}}
  </div>
  */
  {"id":"WsRVfHSq","block":"{\"symbols\":[\"@maxImageSize\",\"@attachment\",\"&attrs\"],\"statements\":[[11,\"div\"],[24,0,\"comment-image\"],[17,3],[12],[2,\"\\n\"],[6,[37,1],[[32,2,[\"downloadURL\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"image\",[],[[\"@width\",\"@height\",\"@source\"],[[32,0,[\"previewSize\",\"width\"]],[32,0,[\"previewSize\",\"height\"]],[30,[36,0],[[32,2]],[[\"width\",\"height\"],[[32,1,[\"width\"]],[32,1,[\"height\"]]]]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"resize-attachment\",\"if\"]}","meta":{"moduleName":"clarify/components/comment/image.hbs"}});

  class CommentImage extends _component.default {
    get previewSize() {
      let {
        size
      } = (0, _resizeAttachment.previewSize)(this.args.attachment.dimensions, this.args.maxImageSize, _resizeAttachment.ContentMode.scaleDown);
      return size;
    }

  }

  _exports.default = CommentImage;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CommentImage);
});