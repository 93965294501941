define("clarify/components/app-header/item-profile/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="item-profile">
    <img aria-hidden="true" src={{@user.picture}} class="item-profile--user-avatar" width="40" height="40" />
    <div class="item-profile-user--name">
      {{@user.name}}
    </div>
    <div class="item-profile-user--email">
      {{@user.email}}
    </div>
    <AButtonHiddenbox
      class="item-profile-user--button"
      @icon={{svg-jar "#profile-logout-24" width=24 height=24}}
      @theme="light"
      @title={{t "app-header.logout"}}
      {{on "click" (prevent-default (stop-propagation @logout))}}
    />
  </div>
  */
  {"id":"fkF/w1Xy","block":"{\"symbols\":[\"@user\",\"@logout\"],\"statements\":[[10,\"div\"],[14,0,\"item-profile\"],[12],[2,\"\\n  \"],[10,\"img\"],[14,\"aria-hidden\",\"true\"],[15,\"src\",[32,1,[\"picture\"]]],[14,0,\"item-profile--user-avatar\"],[14,\"width\",\"40\"],[14,\"height\",\"40\"],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"item-profile-user--name\"],[12],[2,\"\\n    \"],[1,[32,1,[\"name\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"item-profile-user--email\"],[12],[2,\"\\n    \"],[1,[32,1,[\"email\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[8,\"a-button-hiddenbox\",[[24,0,\"item-profile-user--button\"],[4,[38,4],[\"click\",[30,[36,3],[[30,[36,2],[[32,2]],null]],null]],null]],[[\"@icon\",\"@theme\",\"@title\"],[[30,[36,0],[\"#profile-logout-24\"],[[\"width\",\"height\"],[24,24]]],\"light\",[30,[36,1],[\"app-header.logout\"],null]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"svg-jar\",\"t\",\"stop-propagation\",\"prevent-default\",\"on\"]}","meta":{"moduleName":"clarify/components/app-header/item-profile/index.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});