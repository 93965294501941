define("clarify/components/avatar/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <img src={{@user.picture}} class="avatar" width="36" height="36" ...attributes />
  */
  {"id":"Bix9SJdq","block":"{\"symbols\":[\"@user\",\"&attrs\"],\"statements\":[[11,\"img\"],[16,\"src\",[32,1,[\"picture\"]]],[24,0,\"avatar\"],[24,\"width\",\"36\"],[24,\"height\",\"36\"],[17,2],[12],[13]],\"hasEval\":false,\"upvars\":[]}","meta":{"moduleName":"clarify/components/avatar/index.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});