define("clarify/components/dropdown-list/index", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <ol class="dropdown-list" ...attributes>
    {{#each @items as |item index|}}
      {{#let (component (concat "dropdown-list/item/" item.type)) as |Entry|}}
        <Entry @highlighted={{eq index @highlightedIndex}} @item={{item}} {{on "click" (action @select item index)}} />
      {{/let}}
    {{else if @emptyState}}
      {{#let @emptyState as |LocalEmptyState|}}
        <LocalEmptyState class={{@emptyStateClassName}} />
      {{/let}}
    {{/each}}
  </ol>
  */
  {"id":"Cd7PrkJ2","block":"{\"symbols\":[\"LocalEmptyState\",\"item\",\"index\",\"Entry\",\"@emptyStateClassName\",\"@emptyState\",\"@highlightedIndex\",\"@select\",\"&attrs\",\"@items\"],\"statements\":[[11,\"ol\"],[24,0,\"dropdown-list\"],[17,9],[12],[2,\"\\n\"],[6,[37,8],[[30,[36,7],[[30,[36,7],[[32,10]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,0],[[30,[36,6],[[30,[36,5],[\"dropdown-list/item/\",[32,2,[\"type\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,[32,4],[[4,[38,4],[\"click\",[30,[36,3],[[32,0],[32,8],[32,2],[32,3]],null]],null]],[[\"@highlighted\",\"@item\"],[[30,[36,2],[[32,3],[32,7]],null],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[4]}]]]],\"parameters\":[2,3]},{\"statements\":[[6,[37,1],[[32,6]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[32,6]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,[32,1],[[16,0,[32,5]]],[[],[]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"let\",\"if\",\"eq\",\"action\",\"on\",\"concat\",\"component\",\"-track-array\",\"each\"]}","meta":{"moduleName":"clarify/components/dropdown-list/index.hbs"}});

  class DropdownList extends _component.default {}

  _exports.default = DropdownList;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DropdownList);
});