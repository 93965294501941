define("clarify/templates/organizations/show/timelines/show/threads/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qFUU410X",
    "block": "{\"symbols\":[],\"statements\":[[8,\"thread-pane\",[],[[\"@thread\",\"@users\",\"@layoutModel\"],[[32,0,[\"thread\"]],[32,0,[\"organization\",\"activeUsers\"]],[32,0,[\"layout\"]]]],null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "clarify/templates/organizations/show/timelines/show/threads/show.hbs"
    }
  });

  _exports.default = _default;
});