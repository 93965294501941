define("clarify/ui/tests/app.lint-test", [], function () {
  "use strict";

  QUnit.module('ESLint | app');
  QUnit.test('app/components/a-button-float/index.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/a-button-float/index.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/a-button-float/link.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/a-button-float/link.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/a-button-hiddenbox/index.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/a-button-hiddenbox/index.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/a-button-hiddenbox/link.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/a-button-hiddenbox/link.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/a-button/index.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/a-button/index.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/a-input-field/-color-label.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/a-input-field/-color-label.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/a-input-field/-edit-label.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/a-input-field/-edit-label.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/a-input-field/index.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/a-input-field/index.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/a-menu/button.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/a-menu/button.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/a-menu/index.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/a-menu/index.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/a-tag/-private.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/a-tag/-private.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/a-tag/button.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/a-tag/button.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/a-tag/filter.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/a-tag/filter.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/a-tag/index.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/a-tag/index.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/a-text-dropdown/-button.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/a-text-dropdown/-button.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/a-text-dropdown/index.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/a-text-dropdown/index.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/auto-complete/index.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/auto-complete/index.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/button.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/button.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/checkbox-fieldset.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/checkbox-fieldset.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/checkbox.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/checkbox.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/context-menu/index.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/context-menu/index.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/context-menu/item.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/context-menu/item.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/context-menu/option.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/context-menu/option.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/context-menu/options.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/context-menu/options.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/context-menu/separator.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/context-menu/separator.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/empty-state.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/empty-state.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/infinite/index.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/infinite/index.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/infinite/sentinel.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/infinite/sentinel.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/keyword-label-dropdown/index.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/keyword-label-dropdown/index.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/keyword-label-dropdown/input.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/keyword-label-dropdown/input.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/keyword-label-dropdown/item.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/keyword-label-dropdown/item.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/keyword-label-dropdown/query.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/keyword-label-dropdown/query.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/keyword-label-dropdown/section.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/keyword-label-dropdown/section.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/link-to-route/index.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/link-to-route/index.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/modal.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/modal.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/radio-button/index.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/radio-button/index.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/radio-button/input.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/radio-button/input.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/radio-button/label.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/radio-button/label.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/tab-bar/index.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/tab-bar/index.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/tab-bar/item.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/tab-bar/item.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/token-field/index.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/token-field/index.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/token-field/input.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/token-field/input.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/token-field/results.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/token-field/results.js should pass ESLint\n\n');
  });
  QUnit.test('app/helpers/route.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/helpers/route.js should pass ESLint\n\n');
  });
  QUnit.test('app/modifiers/in-view.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/modifiers/in-view.js should pass ESLint\n\n');
  });
  QUnit.test('app/modifiers/set-focus.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/modifiers/set-focus.js should pass ESLint\n\n');
  });
  QUnit.test('app/modifiers/trap-focus.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/modifiers/trap-focus.js should pass ESLint\n\n');
  });
});