define("clarify/utilities/partition-threads", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.partitionThreads = partitionThreads;
  const MINIMUM_CLUSTER_SIZE = 5;

  function partitionThreads(threads, x = 3600 * 24 * 15 * 1000) {
    if (threads.length === 0) {
      return {
        comments: [],
        dots: [],
        aggregated: []
      };
    }

    let all = threads.slice(0);
    let active = [all.shift()];
    let result = [];

    for (let thread of all) {
      let last = active[active.length - 1];

      if (thread.timestamp.getTime() - last.timestamp.getTime() < x) {
        active.push(thread);
      } else {
        result.push(active);
        active = [thread];
      }
    }

    result.push(active);
    let aggregated = [];
    let comments = [];
    let dots = [];
    result.forEach(threads => {
      if (threads.length === 1) {
        comments.push({
          type: 'thread',
          thread: threads[0],
          previewComment: true
        });
      } else if (threads.length < MINIMUM_CLUSTER_SIZE) {
        threads.forEach(thread => {
          dots.push({
            type: 'thread',
            thread: thread,
            previewComment: false
          });
        });
      } else {
        let first = threads[0];
        let last = threads[threads.length - 1];
        aggregated.push({
          type: 'aggregated_threads',
          from: first.timestamp,
          to: last.timestamp,
          threads: threads
        });
      }
    });
    return {
      comments,
      dots,
      aggregated
    };
  }
});