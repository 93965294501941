define("clarify/utilities/bisect", ["exports", "d3-array"], function (_exports, _d3Array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.limit = limit;
  _exports.closestDataPointWithAccessor = closestDataPointWithAccessor;
  _exports.ClosestRounding = _exports.LimitCap = void 0;
  let LimitCap; /// Trims a multidimentional array using binary search.

  _exports.LimitCap = LimitCap;

  (function (LimitCap) {
    LimitCap[LimitCap["noCap"] = 0] = "noCap";
    LimitCap[LimitCap["extendBeginning"] = 1] = "extendBeginning";
    LimitCap[LimitCap["extendEnd"] = 2] = "extendEnd";
  })(LimitCap || (_exports.LimitCap = LimitCap = {}));

  function limit(dataPoints, accessor, from, to, cap = LimitCap.extendBeginning | LimitCap.extendEnd) {
    if (dataPoints.length < 0) {
      return dataPoints;
    }

    const handler = (0, _d3Array.bisector)(accessor);
    const beginDelta = (cap & LimitCap.extendBeginning) !== 0 ? -1 : 0;
    const endDelta = (cap & LimitCap.extendEnd) !== 0 ? 1 : 0;
    const start = handler.left(dataPoints, from) + beginDelta;
    const end = handler.right(dataPoints, to) + endDelta;
    return dataPoints.slice(Math.max(start, 0), Math.min(end, dataPoints.length));
  }

  let ClosestRounding;
  _exports.ClosestRounding = ClosestRounding;

  (function (ClosestRounding) {
    ClosestRounding[ClosestRounding["round"] = 0] = "round";
    ClosestRounding[ClosestRounding["floor"] = 1] = "floor";
  })(ClosestRounding || (_exports.ClosestRounding = ClosestRounding = {}));

  function closestDataPointWithAccessor(dataPoints, accessor, value, rounding = ClosestRounding.round, cap = LimitCap.noCap) {
    if (dataPoints.length === 0) {
      return null;
    }

    const bisect = (0, _d3Array.bisector)(accessor).right;
    const index = bisect(dataPoints, value);

    if (index === 0) {
      return (cap & LimitCap.extendBeginning) !== 0 ? index : null;
    } else if (index === dataPoints.length) {
      if ((cap & LimitCap.extendEnd) !== 0) {
        return index - 1;
      } else {
        let point = accessor(dataPoints[index - 1]);

        if (point === value) {
          return index - 1;
        }
      }
    } else if (rounding === ClosestRounding.floor) {
      return index - 1;
    } else {
      const secondPointValue = accessor(dataPoints[index]);
      const firstPointValue = accessor(dataPoints[index - 1]);
      return firstPointValue - value > value - secondPointValue ? index - 1 : index;
    }

    return null;
  }
});