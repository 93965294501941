define("clarify/transitions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    this.transition(this.outletName('right-anthor'), this.fromRoute('organizations.show.timelines.show.activity'), this.toRoute('organizations.show.timelines.show.threads'), this.use('slideToLeft'), this.reverse('slideOutToRight'));
    this.transition(this.outletName('right-anthor'), this.fromRoute('organizations.show.timelines.show.settings'), this.toRoute(['organizations.show.timelines.show.visualizations', 'organizations.show.timelines.show.threads', 'organizations.show.timelines.show.activity']), this.use('slideToLeft'), this.reverse('slideOutToRight'));
    this.transition(this.outletName('right-anthor'), this.fromRoute(null), this.toRoute(['organizations.show.timelines.show.visualizations', 'organizations.show.timelines.show.settings', 'organizations.show.timelines.show.threads', 'organizations.show.timelines.show.activity']), this.use('slideToLeft'), this.reverse('slideOutToRight'));
    this.transition(this.outletName('bottom-anthor'), this.fromRoute(null), this.toRoute('organizations.show.timelines.show.stats'), this.use('toUp'), this.reverse('toDown'));
  }
});