define("clarify/components/data-canvas/data-canvas-yield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield @dataCanvas @scrollView}}
  */
  {"id":"GP0Bdg4+","block":"{\"symbols\":[\"@scrollView\",\"@dataCanvas\",\"&default\"],\"statements\":[[18,3,[[32,2],[32,1]]]],\"hasEval\":false,\"upvars\":[]}","meta":{"moduleName":"clarify/components/data-canvas/data-canvas-yield.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});