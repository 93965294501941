define("clarify/components/organizations/show/items/metadata-entry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <li class="items-header-metadata-entry">
    <span class="items-header-metadata-entry-title">
      {{@title}}:
    </span>
    {{#if @icon}}
      <div role="presentation" class="items-header-metadata-entry-icon">
        {{@icon}}
      </div>
    {{/if}}
    <span class="items-header-metadata-entry-value" data-placeholder={{not @value}}>
      {{or @value (t "item-dialog.metadata.empty-state.value")}}
    </span>
  </li>
  */
  {"id":"YLuQkX5u","block":"{\"symbols\":[\"@icon\",\"@title\",\"@value\"],\"statements\":[[10,\"li\"],[14,0,\"items-header-metadata-entry\"],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"items-header-metadata-entry-title\"],[12],[2,\"\\n    \"],[1,[32,2]],[2,\":\\n  \"],[13],[2,\"\\n\"],[6,[37,0],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,\"role\",\"presentation\"],[14,0,\"items-header-metadata-entry-icon\"],[12],[2,\"\\n      \"],[1,[32,1]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"span\"],[14,0,\"items-header-metadata-entry-value\"],[15,\"data-placeholder\",[30,[36,1],[[32,3]],null]],[12],[2,\"\\n    \"],[1,[30,[36,3],[[32,3],[30,[36,2],[\"item-dialog.metadata.empty-state.value\"],null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"not\",\"t\",\"or\"]}","meta":{"moduleName":"clarify/components/organizations/show/items/metadata-entry.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});