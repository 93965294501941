define("clarify/models/thread", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let {
    hasMany,
    attr,
    belongsTo
  } = _emberData.default;
  let Thread = (_dec = hasMany('comment', {
    async: false,
    inverse: null
  }), _dec2 = belongsTo('comment', {
    async: false,
    inverse: null
  }), _dec3 = belongsTo('timeline', {
    async: false,
    inverse: null
  }), _dec4 = attr('date'), _dec5 = attr('date'), _dec6 = belongsTo('user', {
    inverse: null,
    async: false
  }), _dec7 = attr('date'), _dec8 = attr('string'), _dec9 = attr('number'), _dec10 = belongsTo('organization', {
    async: false,
    inverse: null
  }), _dec11 = Ember.computed('comments.[]'), _dec12 = Ember.computed('comment.isNew'), _dec13 = Ember.computed('isDeleted', 'hasDirtyAttributes', 'isSaving'), (_class = (_temp = class Thread extends _emberData.default.Model {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "comments", _descriptor, this);

      _initializerDefineProperty(this, "comment", _descriptor2, this);

      _initializerDefineProperty(this, "timeline", _descriptor3, this);

      _initializerDefineProperty(this, "timestamp", _descriptor4, this);

      _initializerDefineProperty(this, "createdAt", _descriptor5, this);

      _initializerDefineProperty(this, "createdBy", _descriptor6, this);

      _initializerDefineProperty(this, "updatedAt", _descriptor7, this);

      _initializerDefineProperty(this, "updatedBy", _descriptor8, this);

      _initializerDefineProperty(this, "replies", _descriptor9, this);

      _initializerDefineProperty(this, "organization", _descriptor10, this);
    }

    get commentReplies() {
      return this.comments.filter(comment => comment !== this.comment);
    }

    get isReady() {
      return this.comment && Ember.get(this.comment, 'isNew') === false;
    }

    get isDeletedOnAPI() {
      return this.isDeleted && Ember.get(this, 'hasDirtyAttributes') === false && Ember.get(this, 'isSaving') === false;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "comments", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "comment", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "timeline", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "timestamp", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "createdAt", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "createdBy", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "updatedAt", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "updatedBy", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "replies", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "organization", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "commentReplies", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "commentReplies"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isReady", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "isReady"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isDeletedOnAPI", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "isDeletedOnAPI"), _class.prototype)), _class));
  _exports.default = Thread;
});