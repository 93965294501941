define("clarify/components/timeline-filter/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <header class="timelines-content-header">
    <div>
      {{t "timeline-filter.title"}}
    </div>
    <div>
      <button
        data-intercom-target="Close item browser"
        class="timelines-content-header-button"
        title={{t "timeline-filter.hide"}}
        {{on "click" @close}}
      >
        {{svg-jar
          "#design-system-icon-cross-24"
          class="timelines-content-header-icon"
          width=24
          height=24
          role="presentation"
        }}
      </button>
    </div>
  </header>
  */
  {"id":"T4MwQSlR","block":"{\"symbols\":[\"@close\"],\"statements\":[[10,\"header\"],[14,0,\"timelines-content-header\"],[12],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"timeline-filter.title\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,\"data-intercom-target\",\"Close item browser\"],[24,0,\"timelines-content-header-button\"],[16,\"title\",[30,[36,0],[\"timeline-filter.hide\"],null]],[4,[38,1],[\"click\",[32,1]],null],[12],[2,\"\\n      \"],[1,[30,[36,2],[\"#design-system-icon-cross-24\"],[[\"class\",\"width\",\"height\",\"role\"],[\"timelines-content-header-icon\",24,24,\"presentation\"]]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"on\",\"svg-jar\"]}","meta":{"moduleName":"clarify/components/timeline-filter/header.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});