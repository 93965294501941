define("clarify/routes/organizations/show/timelines/show/threads/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TimelinesShowThreadsNewRoute = (_dec = Ember.inject.service, _dec2 = Ember._action, (_class = (_temp = class TimelinesShowThreadsNewRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", {
        timestamp: {
          replace: true
        }
      });

      _initializerDefineProperty(this, "currentUser", _descriptor, this);
    }

    async model(params) {
      let model = this.modelFor('organizations/show/timelines/show');
      let {
        organization
      } = this.modelFor('organizations/show');
      const thread = this.store.createRecord('thread', {
        timeline: model.timeline,
        timestamp: new Date(params.timestamp),
        createdBy: this.currentUser.user,
        updatedBy: this.currentUser.user,
        organization: organization
      });
      return {
        thread,
        layout: model.layout,
        organization
      };
    }

    setupController(controller, model, transition) {
      super.setupController(controller, model, transition);
      controller.set('thread', model.thread);
      controller.set('organization', model.organization);
      controller.set('layout', model.layout);
    }

    loading() {
      return false;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loading", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "loading"), _class.prototype)), _class));
  _exports.default = TimelinesShowThreadsNewRoute;
});