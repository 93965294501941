define("clarify/routes/organizations/show/timelines/show/activity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TimelinesShowSettingsIndexRoute extends Ember.Route {
    renderTemplate() {
      this.render('organizations.show.timelines.show.activity', {
        outlet: 'right-anthor'
      });
    }

  }

  _exports.default = TimelinesShowSettingsIndexRoute;
});