define("clarify/models/visualization", ["exports", "ember-data", "clarify/mixins/threshold-source", "clarify/utilities/flat-map", "clarify/utilities/compact-map"], function (_exports, _emberData, _thresholdSource, _flatMap, _compactMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.VisualizationType = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let {
    attr,
    hasMany
  } = _emberData.default;
  let VisualizationType;
  _exports.VisualizationType = VisualizationType;

  (function (VisualizationType) {
    VisualizationType["lineChart"] = "line-chart";
    VisualizationType["status"] = "status";
  })(VisualizationType || (_exports.VisualizationType = VisualizationType = {}));

  let ThresholdSourceModel = _emberData.default.Model.extend(_thresholdSource.default);

  let Visualization = (_dec = attr('string'), _dec2 = attr('string'), _dec3 = attr('string'), _dec4 = attr('string'), _dec5 = attr('date'), _dec6 = attr('string'), _dec7 = attr('date'), _dec8 = attr('string'), _dec9 = attr('threshold-options'), _dec10 = attr('display-options'), _dec11 = hasMany('visualization-item', {
    async: false
  }), _dec12 = hasMany('threshold', {
    async: false,
    inverse: 'source'
  }), _dec13 = Ember.computed('displayOptions.axisScaleMin', 'displayOptions.axisScaleMax', 'displayOptions.axisScaleScaling'), _dec14 = Ember.computed('visualizationItems.@each.item'), _dec15 = Ember.computed('thresholds.[]', 'thresholds.@each.{trigger,displayOptions}', 'visualizationItems.@each.visibleThresholds', 'thresholdOptions.enable'), (_class = (_temp = class Visualization extends ThresholdSourceModel {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "name", _descriptor, this);

      _initializerDefineProperty(this, "description", _descriptor2, this);

      _initializerDefineProperty(this, "dataFilter", _descriptor3, this);

      _initializerDefineProperty(this, "type", _descriptor4, this);

      _initializerDefineProperty(this, "createdAt", _descriptor5, this);

      _initializerDefineProperty(this, "createdBy", _descriptor6, this);

      _initializerDefineProperty(this, "updatedAt", _descriptor7, this);

      _initializerDefineProperty(this, "updatedBy", _descriptor8, this);

      _initializerDefineProperty(this, "thresholdOptions", _descriptor9, this);

      _initializerDefineProperty(this, "displayOptions", _descriptor10, this);

      _initializerDefineProperty(this, "visualizationItems", _descriptor11, this);

      _initializerDefineProperty(this, "thresholds", _descriptor12, this);
    }

    get lineChartAxisScaling() {
      return {
        scaling: this.displayOptions.axisScaleScaling,
        min: this.displayOptions.axisScaleMin,
        max: this.displayOptions.axisScaleMax
      };
    }

    get items() {
      return this.visualizationItems.map(visualizationItem => visualizationItem.item);
    }

    get visibleThresholds() {
      let ownThresholds = this.thresholdOptions.enable ? (0, _compactMap.default)(this.thresholds.toArray(), threshold => {
        return threshold.displayOptions.visible === true ? threshold : null;
      }) : [];
      let visualizationItems = (0, _flatMap.flatMap)(this.visualizationItems.toArray(), visualizationItem => visualizationItem.visibleThresholds);
      return [...ownThresholds, ...visualizationItems];
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "dataFilter", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "type", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "createdAt", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "createdBy", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "updatedAt", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "updatedBy", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "thresholdOptions", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "displayOptions", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "visualizationItems", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "thresholds", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "lineChartAxisScaling", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "lineChartAxisScaling"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "items", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "items"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "visibleThresholds", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "visibleThresholds"), _class.prototype)), _class));
  _exports.default = Visualization;
});