define("clarify/services/authentication", ["exports", "auth0-js", "clarify/environment", "idtoken-verifier", "clarify/errors/authentication-error"], function (_exports, _auth0Js, _environment, _idtokenVerifier, _authenticationError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.AuthenticationStatus = void 0;

  var _dec, _dec2, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AuthenticationStatus;
  _exports.AuthenticationStatus = AuthenticationStatus;

  (function (AuthenticationStatus) {
    AuthenticationStatus[AuthenticationStatus["authenticated"] = 0] = "authenticated";
    AuthenticationStatus[AuthenticationStatus["requiresSetup"] = 1] = "requiresSetup";
    AuthenticationStatus[AuthenticationStatus["invalid"] = 2] = "invalid";
  })(AuthenticationStatus || (_exports.AuthenticationStatus = AuthenticationStatus = {}));

  let Authentication = (_dec = Ember._tracked, _dec2 = Ember.computed('accessToken'), (_class = (_temp = class Authentication extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "accessToken", _descriptor, this);

      _defineProperty(this, "auth0", new _auth0Js.default.WebAuth({
        domain: _environment.default.APP.AUTH0_DOMAIN,
        clientID: _environment.default.APP.AUTH0_CLIENT_ID,
        redirectUri: `${location.origin}/callback`,
        audience: _environment.default.APP.AUTH0_AUDIENCE,
        responseType: 'token id_token',
        scope: 'openid profile email'
      }));
    }

    get isAuthenticated() {
      return Ember.isPresent(this.accessToken) && this.isNotExpired();
    }

    get payload() {
      if (this.accessToken) {
        let parsed = new _idtokenVerifier.default().decode(this.accessToken);
        return parsed === null || parsed === void 0 ? void 0 : parsed.payload;
      }

      return undefined;
    }

    get userId() {
      var _this$payload;

      return (_this$payload = this.payload) === null || _this$payload === void 0 ? void 0 : _this$payload.sub;
    }

    get intercomHMAC() {
      var _this$payload2;

      return (_this$payload2 = this.payload) === null || _this$payload2 === void 0 ? void 0 : _this$payload2['https://clfy.io/intercomHMAC'];
    }

    login() {
      this.auth0.authorize({
        show_signup: true
      });
    }

    hasOrganizations() {
      if (this.payload) {
        let roles = this.payload['https://clfy.io/roles'];
        let organization = roles.find(role => role.startsWith('oA:'));
        return !!organization;
      }

      return false;
    }

    isAdmin(organizationId) {
      return this.payload ? this.payload['https://clfy.io/roles'].includes(`oA:${organizationId}`) : false;
    }

    handleAuthentication(source) {
      return new Promise((resolve, reject) => {
        let hash = location.hash.replace(/^#?\/?/, '');
        let searchParams = new URLSearchParams(hash);

        if (searchParams.has('error')) {
          this.auth0.parseHash(error => {
            if (!error) {
              return resolve();
            }

            return reject(new _authenticationError.default(error, source));
          });
        } else {
          resolve();
        }
      });
    }

    passwordlessStart(options) {
      return new Promise((resolve, reject) => {
        this.auth0.passwordlessStart(options, function (error, response) {
          if (response) {
            resolve(response);
          } else {
            reject(error);
          }
        });
      });
    }

    async status() {
      try {
        var _this$payload3;

        await this.checkSession();

        if (((_this$payload3 = this.payload) === null || _this$payload3 === void 0 ? void 0 : _this$payload3['https://clfy.io/userStatus']) === 'email-only') {
          return AuthenticationStatus.requiresSetup;
        }

        return AuthenticationStatus.authenticated;
      } catch {
        return AuthenticationStatus.invalid;
      }
    }

    checkSession(force = false) {
      if (force !== true && this.isNotExpired()) {
        return Promise.resolve();
      } else {
        return new Promise((resolve, reject) => {
          this.auth0.checkSession({}, (error, response) => {
            if (error) {
              reject(error);
            } else {
              this.setSession(response);
              resolve();
            }
          });
        });
      }
    }

    setSession(authResult) {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.accessToken = authResult.accessToken;
      }
    }

    saveTransition(uri) {
      localStorage.setItem('clarify.loginTransition', uri);
    }

    getAndResetTransition() {
      const transition = localStorage.getItem('clarify.loginTransition');
      localStorage.removeItem('clarify.loginTransition');
      return transition;
    }

    logout() {
      this.auth0.logout({
        returnTo: `${location.protocol}//${location.host}/login`
      });
    }

    isNotExpired() {
      var _this$payload4;

      let expiresAt = (_this$payload4 = this.payload) === null || _this$payload4 === void 0 ? void 0 : _this$payload4.exp;

      if (expiresAt) {
        return Date.now() < expiresAt * 1000;
      }

      return false;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "accessToken", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "payload", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "payload"), _class.prototype)), _class));
  _exports.default = Authentication;
});