define("clarify/components/context-menus/visualization-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <MenuButton @placement="bottom-end">
    <:button as |button|>
      {{yield button}}
    </:button>
    <:menu as |menu|>
      <menu.item
        @text={{t "context-menus.visualization-settings.open-settings"}}
        @select={{action @openVisualizationSettings @visualization}}
        @icon="#p-settings-24"
      />
      <menu.separator />
      <menu.item
        @text={{t "context-menus.visualization-settings.delete"}}
        @icon="#delete"
        @intent="danger"
        @select={{action
          (delete-visualization-alert visualization=@visualization action=(action @removeVisualization @visualization))
        }}
      />
    </:menu>
  </MenuButton>
  */
  {"id":"iLQKoOPH","block":"{\"symbols\":[\"__arg0\",\"__arg1\",\"menu\",\"button\",\"@visualization\",\"@openVisualizationSettings\",\"@removeVisualization\",\"&default\"],\"statements\":[[8,\"menu-button\",[],[[\"@placement\",\"@namedBlocksInfo\"],[\"bottom-end\",[30,[36,6],null,[[\"button\",\"menu\"],[1,1]]]]],[[\"default\"],[{\"statements\":[[6,[37,5],[[30,[36,4],[[32,1],\"button\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,3],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[18,8,[[32,4]]],[2,\"\\n  \"]],\"parameters\":[4]}]]]],\"parameters\":[]},{\"statements\":[[6,[37,5],[[30,[36,4],[[32,1],\"menu\"],null]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,3,[\"item\"]],[],[[\"@text\",\"@select\",\"@icon\"],[[30,[36,0],[\"context-menus.visualization-settings.open-settings\"],null],[30,[36,1],[[32,0],[32,6],[32,5]],null],\"#p-settings-24\"]],null],[2,\"\\n    \"],[8,[32,3,[\"separator\"]],[],[[],[]],null],[2,\"\\n    \"],[8,[32,3,[\"item\"]],[],[[\"@text\",\"@icon\",\"@intent\",\"@select\"],[[30,[36,0],[\"context-menus.visualization-settings.delete\"],null],\"#delete\",\"danger\",[30,[36,1],[[32,0],[30,[36,2],null,[[\"visualization\",\"action\"],[[32,5],[30,[36,1],[[32,0],[32,7],[32,5]],null]]]]],null]]],null],[2,\"\\n  \"]],\"parameters\":[3]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1,2]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"delete-visualization-alert\",\"let\",\"-is-named-block-invocation\",\"if\",\"hash\"]}","meta":{"moduleName":"clarify/components/context-menus/visualization-settings.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});