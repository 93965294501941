define("clarify/models/item", ["exports", "clarify/models/item-abstract", "ember-data"], function (_exports, _itemAbstract, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ItemType = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let {
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;
  let ItemType;
  _exports.ItemType = ItemType;

  (function (ItemType) {
    ItemType["numeric"] = "numeric";
    ItemType["enum"] = "enum";
  })(ItemType || (_exports.ItemType = ItemType = {}));

  let Item = (_dec = attr('string'), _dec2 = attr('date'), _dec3 = attr('date'), _dec4 = belongsTo('visualization-item'), _dec5 = attr('string'), _dec6 = attr('string'), _dec7 = attr('duration'), _dec8 = attr('string'), _dec9 = attr('string'), _dec10 = hasMany('permission', {
    polymorphic: true,
    async: false,
    inverse: null
  }), _dec11 = attr('item-display-options'), _dec12 = Ember.computed.alias('displayOptions.precision'), _dec13 = attr('boolean'), _dec14 = Ember.computed('locationValue'), _dec15 = attr('item-labels'), _dec16 = attr('enum-values'), (_class = (_temp = class Item extends _itemAbstract.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "name", _descriptor, this);

      _initializerDefineProperty(this, "updatedAt", _descriptor2, this);

      _initializerDefineProperty(this, "createdAt", _descriptor3, this);

      _initializerDefineProperty(this, "visualization", _descriptor4, this);

      _initializerDefineProperty(this, "deltaInterval", _descriptor5, this);

      _initializerDefineProperty(this, "sourceType", _descriptor6, this);

      _initializerDefineProperty(this, "sampleInterval", _descriptor7, this);

      _initializerDefineProperty(this, "type", _descriptor8, this);

      _initializerDefineProperty(this, "engUnit", _descriptor9, this);

      _initializerDefineProperty(this, "permissions", _descriptor10, this);

      _initializerDefineProperty(this, "displayOptions", _descriptor11, this);

      _initializerDefineProperty(this, "precision", _descriptor12, this);

      _initializerDefineProperty(this, "visible", _descriptor13, this);

      _initializerDefineProperty(this, "labels", _descriptor14, this);

      _initializerDefineProperty(this, "enumValues", _descriptor15, this);
    }

    get locationString() {
      return this.locationValue.join(' • ');
    }

    get dataSource() {
      let dataSources = this.labels['data-source'];
      return dataSources ? dataSources.join(', ') : '';
    }

    get locationValue() {
      var _this$labels$location;

      return (_this$labels$location = this.labels.location) !== null && _this$labels$location !== void 0 ? _this$labels$location : [];
    }

    scoreForQuery(query) {
      return this.name.indexOf(query) === -1 ? 0 : 1;
    }

    static isAccessible(item) {
      if (item instanceof Item) {
        return item.visible;
      }

      return false;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "updatedAt", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "createdAt", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "visualization", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "deltaInterval", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "sourceType", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "sampleInterval", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "type", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "engUnit", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "permissions", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "displayOptions", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "precision", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "visible", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "locationString", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "locationString"), _class.prototype), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "labels", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "enumValues", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = Item;
});