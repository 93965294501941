define("clarify/drag-images/item", ["exports", "typesettable", "clarify/models/item"], function (_exports, _typesettable, _item) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.roundedRect = roundedRect;
  _exports.createTypesetter = createTypesetter;
  _exports.drawItem = drawItem;
  _exports.default = void 0;

  function roundedRect(context, x, y, width, height, radius) {
    context.beginPath();
    context.moveTo(x + radius, y);
    context.arcTo(x + width, y, x + width, y + height, radius);
    context.arcTo(x + width, y + height, x, y + height, radius);
    context.arcTo(x, y + height, x, y, radius);
    context.arcTo(x, y, x + width, y, radius);
    context.closePath();
  }

  function createTypesetter(context, style, lineHeight) {
    let typesetter = _typesettable.Typesetter.canvas(context, lineHeight, style);

    typesetter.wrapper.maxLines(1);
    typesetter.wrapper.textTrimming('ellipsis');
    return typesetter;
  }

  let width = 268;
  let height = 52;
  let textWidth = width - 50;

  function drawItem(item, canvasConstructor, event) {
    let clientRect = event.target.getBoundingClientRect();
    let position = {
      x: event.pageX - clientRect.left,
      y: event.pageY - clientRect.top
    };
    let canvas = canvasConstructor(width, height);
    let context = canvas.getContext('2d');
    roundedRect(context, 0, 0, width, height, 8);
    context.fillStyle = '#313E50';
    context.fill();
    let titleTypesetter = createTypesetter(context, {
      fill: '#F0F2FF',
      font: '15px akkurat'
    });
    let locationTypesetter = createTypesetter(context, {
      fill: '#E6EAFC',
      font: '13px akkurat'
    });
    context.save();
    context.translate(22, 13);
    titleTypesetter.write(Ember.get(item, 'name'), textWidth, 15);
    context.restore();
    context.save();
    context.translate(22, 30);
    let location = item.labels.location || [];
    locationTypesetter.write(location.join(' • '), textWidth, 13);
    context.restore();
    context.save();
    context.translate(22 + textWidth + 10, 13);
    return {
      canvas,
      x: position.x - 20,
      y: 10
    };
  }

  class ItemDragImage extends Ember.Object {
    draw(canvasConstructor, models, event) {
      let model = models[0];

      if (!model) {
        return null;
      }

      if (_item.default.isAccessible(model)) {
        return drawItem(model, canvasConstructor, event);
      }

      return null;
    }

  }

  _exports.default = ItemDragImage;
});