define("clarify/components/keyword-filter/filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div ...attributes>
    {{#each @categories key="id" as |category|}}
      <KeywordFilter::Category
        @category={{category}}
        @isEnabled={{fn @isEnabled category}}
        @setEnabled={{fn @setEnabled category}}
      />
    {{/each}}
  </div>
  */
  {"id":"kW0AR78p","block":"{\"symbols\":[\"category\",\"@isEnabled\",\"@setEnabled\",\"&attrs\",\"@categories\"],\"statements\":[[11,\"div\"],[17,4],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,5]],null]],null]],[[\"key\"],[\"id\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"keyword-filter/category\",[],[[\"@category\",\"@isEnabled\",\"@setEnabled\"],[[32,1],[30,[36,0],[[32,2],[32,1]],null],[30,[36,0],[[32,3],[32,1]],null]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"-track-array\",\"each\"]}","meta":{"moduleName":"clarify/components/keyword-filter/filter.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});