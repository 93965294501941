define("clarify/externals/sentry", ["clarify/environment", "@sentry/browser", "@sentry/integrations"], function (_environment, _browser, _integrations) {
  "use strict";

  if (_environment.default.APP.SENTRY_API_KEY) {
    (0, _browser.init)({
      dsn: _environment.default.APP.SENTRY_API_KEY,
      integrations: [new _integrations.Ember()],

      beforeSend(event, hint) {
        if (hint && hint.originalException) {
          let name = hint.originalException instanceof Error ? hint.originalException.name : hint.originalException;

          switch (name) {
            case 'TransitionAborted':
            case 'UnrecognizedURLError':
              return null;
          }
        }

        return event;
      }

    });
  }
});