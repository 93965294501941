define("clarify/components/inline-editable/index", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    contenteditable={{this.isEditable}}
    class="inline-editable"
    placeholder={{@placeholder}}
    ...attributes
    {{on "keyup" this.handleKeyUp}}
    {{on "keydown" this.handleKeyDown}}
    {{on "paste" this.handlePaste}}
    {{on "focusout" this.handleFocusOut}}
    {{on "focusin" this.handleFocusIn}}
    {{on "input" this.handleInput}}
    {{did-insert this.handleDidInsert}}
    {{did-update this.handleUpdateValue @value}}
  ></div>
  */
  {"id":"9v55+2mU","block":"{\"symbols\":[\"@placeholder\",\"&attrs\",\"@value\"],\"statements\":[[11,\"div\"],[16,\"contenteditable\",[32,0,[\"isEditable\"]]],[24,0,\"inline-editable\"],[16,\"placeholder\",[32,1]],[17,2],[4,[38,0],[\"keyup\",[32,0,[\"handleKeyUp\"]]],null],[4,[38,0],[\"keydown\",[32,0,[\"handleKeyDown\"]]],null],[4,[38,0],[\"paste\",[32,0,[\"handlePaste\"]]],null],[4,[38,0],[\"focusout\",[32,0,[\"handleFocusOut\"]]],null],[4,[38,0],[\"focusin\",[32,0,[\"handleFocusIn\"]]],null],[4,[38,0],[\"input\",[32,0,[\"handleInput\"]]],null],[4,[38,1],[[32,0,[\"handleDidInsert\"]]],null],[4,[38,2],[[32,0,[\"handleUpdateValue\"]],[32,3]],null],[12],[13]],\"hasEval\":false,\"upvars\":[\"on\",\"did-insert\",\"did-update\"]}","meta":{"moduleName":"clarify/components/inline-editable/index.hbs"}});

  let InlineEditable = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, (_class = (_temp = class InlineEditable extends _component.default {
    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "value", _descriptor, this);

      this.value = args.value;
    }

    get isEditable() {
      if (this.args.disabled != null) {
        return !this.args.disabled;
      }

      return true;
    }

    handleKeyUp(event) {
      switch (event.key) {
        case 'Escape':
          {
            let element = event.target;
            element.blur();
            break;
          }
      }
    }

    handleInput(event) {
      this.value = event.currentTarget.innerText.trim();
    }

    handleKeyDown(event) {
      if (event.key === 'Enter') {
        event.preventDefault();
        let element = event.currentTarget;
        element.blur();
      }
    }

    handleFocusOut(event) {
      let element = event.currentTarget;

      if (this.args.value !== this.value) {
        // Force recomputed width
        element.style.width = 'max-content';
        this.args.onChange(this.value);
      }
    }

    handleFocusIn(event) {
      let element = event.currentTarget;
      this.updateSize(element);
    }

    handlePaste(event) {
      let clipboardData = event.clipboardData;

      if (clipboardData) {
        let pastedText = clipboardData.getData('text');
        event.preventDefault();
        document.execCommand('insertText', false, pastedText);
      }
    }

    handleDidInsert(element) {
      element.innerText = this.value;
      this.updateSize(element);
    }

    handleUpdateValue(element) {
      this.value = this.args.value;
      element.innerText = this.args.value;
      this.updateSize(element);
    }

    updateSize(element) {
      const width = Math.ceil(element.getBoundingClientRect().width);
      element.style.width = `${width}px`;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "value", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "handleKeyUp", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "handleKeyUp"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleInput", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "handleInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleKeyDown", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "handleKeyDown"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleFocusOut", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "handleFocusOut"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleFocusIn", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "handleFocusIn"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handlePaste", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "handlePaste"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleDidInsert", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "handleDidInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleUpdateValue", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "handleUpdateValue"), _class.prototype)), _class));
  _exports.default = InlineEditable;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, InlineEditable);
});