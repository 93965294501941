define("clarify/utilities/merge-time-series", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.mergeTimeSeries = mergeTimeSeries;

  function mergeTimeSeries(slots) {
    let segments = [...slots];
    let first = segments.shift();

    if (first) {
      let compiled = segments.reduce((compact, segment) => {
        let compactSections = [...(compact.sections || [])];
        let segmentSections = [...(segment.sections || [])];

        if (compact.after && segment.before) {
          let after = new Date(compact.after[0]);
          let before = new Date(segment.before[0]);

          if (before <= after) {
            let last = compactSections.pop();
            let first = segmentSections.shift();
            return { ...compact,
              sections: [...compactSections, [...last, ...first], ...segmentSections],
              after: segment.after
            };
          }
        }

        return { ...compact,
          sections: [...compactSections, ...segmentSections],
          after: segment.after
        };
      }, first);
      return compiled;
    }

    return null;
  }
});