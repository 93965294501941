define("clarify/components/export-modal/visualization-item/index", ["exports", "@glimmer/component", "clarify/models/visualization-item"], function (_exports, _component, _visualizationItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="export-modal-visualization-container">
    <div class="export-modal-items-col">
      <Checkbox
        class="export-modal-items-checkbox"
        @onChange={{fn this.handleCheckboxChange "active"}}
        @checked={{@visualizationItemViewModel.active}}
        @size="normal"
        @style="dark"
      />
      <ExportModal::Signals @visualizationItemViewModel={{@visualizationItemViewModel}} />
    </div>
    <div class="export-modal-items-col">
      <ExportModal::CheckboxFieldSet
        @visualizationItemViewModel={{@visualizationItemViewModel}}
        @handleCheckboxChange={{this.handleCheckboxChange}}
      />
    </div>
  </div>
  */
  {"id":"w7hR7FZH","block":"{\"symbols\":[\"@visualizationItemViewModel\"],\"statements\":[[10,\"div\"],[14,0,\"export-modal-visualization-container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"export-modal-items-col\"],[12],[2,\"\\n    \"],[8,\"checkbox\",[[24,0,\"export-modal-items-checkbox\"]],[[\"@onChange\",\"@checked\",\"@size\",\"@style\"],[[30,[36,0],[[32,0,[\"handleCheckboxChange\"]],\"active\"],null],[32,1,[\"active\"]],\"normal\",\"dark\"]],null],[2,\"\\n    \"],[8,\"export-modal/signals\",[],[[\"@visualizationItemViewModel\"],[[32,1]]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"export-modal-items-col\"],[12],[2,\"\\n    \"],[8,\"export-modal/checkbox-field-set\",[],[[\"@visualizationItemViewModel\",\"@handleCheckboxChange\"],[[32,1],[32,0,[\"handleCheckboxChange\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"fn\"]}","meta":{"moduleName":"clarify/components/export-modal/visualization-item/index.hbs"}});

  let ExportModalVisualizationItem = (_dec = Ember._action, (_class = class ExportModalVisualizationItem extends _component.default {
    handleCheckboxChange(field, checked) {
      this.args.updateVisualizationItemViewModelMethod(this.args.visualizationItemViewModel, field, checked);
    }

    get location() {
      if (_visualizationItem.default.isAccessible(this.args.visualizationItemViewModel.visualizationItem)) {
        return this.args.visualizationItemViewModel.visualizationItem.item.locationValue;
      }

      return '';
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "handleCheckboxChange", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "handleCheckboxChange"), _class.prototype)), _class));
  _exports.default = ExportModalVisualizationItem;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ExportModalVisualizationItem);
});