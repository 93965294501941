define("clarify/utilities/rpc/time-series-enum-on-change", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TimeSeriesEnumOnChangeRequestInfo = void 0;

  class TimeSeriesEnumOnChangeRequestInfo {
    static create(id, parameters) {
      return {
        method: 'timeseries.EnumsOnChange',
        version: '0.5',
        id,
        parameters
      };
    }

  }

  _exports.TimeSeriesEnumOnChangeRequestInfo = TimeSeriesEnumOnChangeRequestInfo;
});