define("clarify/utilities/colors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.blend = blend;
  _exports.isLight = isLight;
  _exports.textColor = textColor;
  _exports.toHex = toHex;
  _exports.TextColor = void 0;

  function components(number) {
    let red = number >> 24 & 255;
    let green = number >> 16 & 255;
    let blue = number >> 8 & 255;
    let alpha = number & 255;
    return [red / 255, green / 255, blue / 255, alpha / 255];
  }

  function blend(foreground, background) {
    let [foregroundRed, foregroundGreen, foregroundBlue, foregroundAlpha] = components(foreground);
    let [backgroundRed, backgroundGreen, backgroundBlue, backgroundAlpha] = components(background);
    let red = foregroundRed * foregroundAlpha + backgroundRed * backgroundAlpha * (1 - foregroundAlpha);
    let green = foregroundGreen * foregroundAlpha + backgroundGreen * backgroundAlpha * (1 - foregroundAlpha);
    let blue = foregroundBlue * foregroundAlpha + backgroundBlue * backgroundAlpha * (1 - foregroundAlpha);
    return red * 255 << 24 | green * 255 << 16 | blue * 255 << 8 | 0xff;
  }

  let TextColor;
  _exports.TextColor = TextColor;

  (function (TextColor) {
    TextColor[TextColor["dark"] = 0] = "dark";
    TextColor[TextColor["light"] = 1] = "light";
  })(TextColor || (_exports.TextColor = TextColor = {}));

  function isLight(color) {
    let [red, green, blue] = components(color);
    let luma = 0.2126 * red + 0.7152 * green + 0.0722 * blue;
    return luma >= 0.6;
  }

  function textColor(color) {
    let light = isLight(color);
    return light ? TextColor.dark : TextColor.light;
  }

  function toHex(color) {
    let alpha = color & 0xff;

    if (alpha === 0xff) {
      let colorWithoutAlpha = color >> 8 & 0xffffff;
      return `#${colorWithoutAlpha.toString(16).padStart(6, '0')}`;
    }

    let red = color >> 24 & 0xff;
    let green = color >> 16 & 0xff;
    let blue = color >> 8 & 0xff;
    return `rgba(${red}, ${green}, ${blue}, ${alpha / 255})`;
  }
});