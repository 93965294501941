define("clarify/components/reusable/index", ["exports", "@glimmer/component"], function (_exports, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield (component "reusable/child" controller=this)}}
  */
  {"id":"zxzr0ri+","block":"{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,0],[\"reusable/child\"],[[\"controller\"],[[32,0]]]]]]],\"hasEval\":false,\"upvars\":[\"component\"]}","meta":{"moduleName":"clarify/components/reusable/index.hbs"}});

  class Reusable extends _component2.default {
    constructor(owner, args) {
      super(owner, args);

      _defineProperty(this, "intersectionObserver", null);

      _defineProperty(this, "components", new WeakMap());

      _defineProperty(this, "callback", (entries, _observer) => {
        entries.forEach(entry => {
          let component = this.components.get(entry.target);

          if (component) {
            component.visible = entry.isIntersecting;
          }
        });
      });

      if ('IntersectionObserver' in window) {
        this.intersectionObserver = new IntersectionObserver(this.callback, {
          root: this.args.container
        });
      }
    }

    willDestroy() {
      if (this.intersectionObserver) {
        this.intersectionObserver.disconnect();
      }
    }

    observe(element, component) {
      this.components.set(element, component);

      if (this.intersectionObserver) {
        this.intersectionObserver.observe(element);
      } else {
        component.visible = true;
      }
    }

    unobserve(element, _component) {
      this.components.delete(element);

      if (this.intersectionObserver) {
        this.intersectionObserver.unobserve(element);
      }
    }

  }

  _exports.default = Reusable;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Reusable);
});