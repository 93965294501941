define("clarify/serializers/-ember-m3", ["exports", "clarify-data-layer/serializers/-ember-m3"], function (_exports, _emberM) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _emberM.default;
    }
  });
});