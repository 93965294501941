define("clarify/models/comment", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let {
    belongsTo,
    attr
  } = _emberData.default;
  let imageMimeTypes = new Set(['image/gif', 'image/jpeg', 'image/png']);
  let Comment = (_dec = Ember.inject.service, _dec2 = belongsTo('thread'), _dec3 = belongsTo('timeline'), _dec4 = attr('string'), _dec5 = attr('date'), _dec6 = belongsTo('user', {
    inverse: null,
    async: false
  }), _dec7 = attr('string'), _dec8 = attr('date'), _dec9 = attr('attachments'), _dec10 = attr('content-references'), _dec11 = belongsTo('organization', {
    async: false,
    inverse: null
  }), _dec12 = Ember.computed('attachments.[]'), _dec13 = Ember.computed('attachments.[]'), (_class = (_temp = class Comment extends _emberData.default.Model {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "thread", _descriptor2, this);

      _initializerDefineProperty(this, "timeline", _descriptor3, this);

      _initializerDefineProperty(this, "textContent", _descriptor4, this);

      _initializerDefineProperty(this, "createdAt", _descriptor5, this);

      _initializerDefineProperty(this, "createdBy", _descriptor6, this);

      _initializerDefineProperty(this, "updatedBy", _descriptor7, this);

      _initializerDefineProperty(this, "updatedAt", _descriptor8, this);

      _initializerDefineProperty(this, "attachments", _descriptor9, this);

      _initializerDefineProperty(this, "contentReferences", _descriptor10, this);

      _initializerDefineProperty(this, "organization", _descriptor11, this);
    }

    get images() {
      return this.attachments.filter(attachment => imageMimeTypes.has(attachment.contentType));
    }

    get files() {
      return this.attachments.filter(attachment => !imageMimeTypes.has(attachment.contentType));
    }

    get createdByUser() {
      if (this.store.hasRecordForId('user', this.belongsTo('createdBy').id())) {
        return this.createdBy;
      }

      return null;
    }

    get createdById() {
      return this.belongsTo('createdBy').id();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "thread", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "timeline", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "textContent", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "createdAt", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "createdBy", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "updatedBy", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "updatedAt", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "attachments", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "contentReferences", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "organization", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "images", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "images"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "files", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "files"), _class.prototype)), _class));
  _exports.default = Comment;
});