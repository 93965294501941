define("clarify/routes/organizations/show/timelines/redirect-demo", ["exports", "clarify/errors/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TimelinesDemoShow extends Ember.Route {
    async model() {
      let {
        organization
      } = this.modelFor('organizations.show');
      let timelines = await this.store.query('timeline', {
        limit: 1,
        filter: {
          organization: organization.id,
          'annotations.clarify/industry-type': {
            $exists: true
          }
        }
      });

      if (timelines.length) {
        return timelines.toArray()[0];
      }

      return Promise.reject(new _route.default(_route.RouteErrorType.notFound));
    }

    redirect(timeline) {
      this.transitionTo('organizations.show.timelines.show', timeline.id);
    }

  }

  _exports.default = TimelinesDemoShow;
});