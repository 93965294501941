define("clarify/components/p-user-info/permission", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @permission.user}}
    <PUserInfo::User @user={{@permission.user}} />
  {{else if @permission.group}}
    <PUserInfo::Group @group={{@permission.group}} />
  {{/if}}
  */
  {"id":"3h/JfrsA","block":"{\"symbols\":[\"@permission\"],\"statements\":[[6,[37,0],[[32,1,[\"user\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"p-user-info/user\",[],[[\"@user\"],[[32,1,[\"user\"]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[32,1,[\"group\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"p-user-info/group\",[],[[\"@group\"],[[32,1,[\"group\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}","meta":{"moduleName":"clarify/components/p-user-info/permission.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});