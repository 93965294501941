define("clarify/components/text-engine/editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    role="textbox"
    ...attributes
    {{quill placeholder=@placeholder modules=@modules onInit=(action @setAPI)}}
    {{on "focus" @handleFocus}}
    {{on "blur" @handleBlur}}
  ></div>
  */
  {"id":"oXDHfu5B","block":"{\"symbols\":[\"&attrs\",\"@setAPI\",\"@modules\",\"@placeholder\",\"@handleFocus\",\"@handleBlur\"],\"statements\":[[11,\"div\"],[24,\"role\",\"textbox\"],[17,1],[4,[38,1],null,[[\"placeholder\",\"modules\",\"onInit\"],[[32,4],[32,3],[30,[36,0],[[32,0],[32,2]],null]]]],[4,[38,2],[\"focus\",[32,5]],null],[4,[38,2],[\"blur\",[32,6]],null],[12],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"quill\",\"on\"]}","meta":{"moduleName":"clarify/components/text-engine/editor.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});