define("clarify/templates/organizations/show/timelines/index-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IcdxZsuT",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"index-loading-error\"],[12],[10,\"div\"],[14,0,\"index-loading-error-content\"],[12],[10,\"img\"],[14,\"src\",\"/design-system/design-system-loading-illustraton.jpg\"],[14,\"srcset\",\"/design-system/design-system-loading-illustraton@2x.jpg 2x\"],[12],[13],[2,\" \"],[10,\"h2\"],[14,0,\"index-loading-error-text\"],[12],[1,[30,[36,0],[\"timelines-show-error.title\"],null]],[13],[2,\" \"],[8,\"button\",[[24,0,\"index-loading-error-button\"],[4,[38,2],[\"click\",[30,[36,1],[[32,0,[\"retry\"]]],null]],null]],[[\"@size\",\"@color\",\"@title\"],[\"normal\",\"blue\",[30,[36,0],[\"timelines-show-error.button\"],null]]],null],[13],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "clarify/templates/organizations/show/timelines/index-error.hbs"
    }
  });

  _exports.default = _default;
});