define("clarify/templates/404", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Jwg2ZYa/",
    "block": "{\"symbols\":[],\"statements\":[[8,\"app-header\",[],[[\"@style\"],[\"dark\"]],null],[2,\" \"],[8,\"alert/provider\",[],[[],[]],null],[2,\" \"],[8,\"initial-state\",[[24,0,\"application-error\"]],[[\"@theme\",\"@title\",\"@description\",\"@image\",\"@retinaImage\"],[\"dark\",[30,[36,0],[\"application-404.title\"],null],[30,[36,0],[\"application-404.description\"],null],\"/assets/empty-state-404.png\",\"/assets/empty-state-404@2x.png\"]],null]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "clarify/templates/404.hbs"
    }
  });

  _exports.default = _default;
});