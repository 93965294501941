define("clarify/services/uploader", ["exports", "clarify/models/attachment"], function (_exports, _attachment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Upload extends Ember.Object {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "file", void 0);

      _defineProperty(this, "progress", {
        loaded: 0,
        total: 0
      });

      _defineProperty(this, "resolve", void 0);

      _defineProperty(this, "reject", void 0);

      _defineProperty(this, "request", new XMLHttpRequest());

      _defineProperty(this, "promise", new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      }));

      _defineProperty(this, "uploadProgress", event => {
        const {
          loaded,
          total
        } = event;
        this.set('progress', {
          loaded,
          total
        });
      });

      _defineProperty(this, "uploadError", () => {
        this.reject();
      });

      _defineProperty(this, "handleAbort", () => {
        this.reject(new Error('User aborted'));
      });

      _defineProperty(this, "handleReadyState", () => {
        if (this.request.readyState === this.request.DONE) {
          let response = this.request.response;

          if (this.request.status === 201) {
            const result = JSON.parse(response);

            let attachment = _attachment.default.create({
              name: result.path,
              contentType: this.file.type
            });

            this.resolve(attachment);
          } else {
            this.reject(new Error('API Error'));
          }
        }
      });
    }

    async upload(uri, headers) {
      let request = this.request;
      request.upload.addEventListener('progress', this.uploadProgress);
      request.upload.addEventListener('error', this.uploadError);
      request.upload.addEventListener('abort', this.handleAbort);
      request.addEventListener('readystatechange', this.handleReadyState);
      request.open('PUT', uri);
      Object.entries(headers).forEach(([name, value]) => {
        request.setRequestHeader(name, value);
      });
      request.setRequestHeader('Content-Type', this.file.type);
      request.send(this.file);
      return this.promise;
    }

    cancel() {
      this.request.abort();
    }

  }

  _exports.default = Upload;
});