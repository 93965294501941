define("clarify/components/m-table/item/state/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div title={{t "statistics-panel.status.loading"}}>
    {{svg-jar "visualization-legend-item-loading"}}
  </div>
  */
  {"id":"pssPeiTO","block":"{\"symbols\":[],\"statements\":[[10,\"div\"],[15,\"title\",[30,[36,0],[\"statistics-panel.status.loading\"],null]],[12],[2,\"\\n  \"],[1,[30,[36,1],[\"visualization-legend-item-loading\"],null]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"svg-jar\"]}","meta":{"moduleName":"clarify/components/m-table/item/state/loading.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});