define("clarify/templates/organizations/show/timelines/show/stats-failed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jo5sjb4O",
    "block": "{\"symbols\":[],\"statements\":[[8,\"m-statistics-panel/failed-blank\",[],[[\"@description\"],[[31,[[30,[36,0],[\"statistics-panel.status.failed.description\"],null]]]]],null]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "clarify/templates/organizations/show/timelines/show/stats-failed.hbs"
    }
  });

  _exports.default = _default;
});