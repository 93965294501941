define("clarify/components/timeline-canvas/visualizations/layers/axis", ["exports", "canvas/components/layer", "clarify/utilities/bisect", "ui/helpers/design-system-particles"], function (_exports, _layer, _bisect, _designSystemParticles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PADDING_INSET = {
    top: 8.5,
    bottom: 8.5
  };
  const CONTENT_INSET = {
    top: 9,
    bottom: 9
  };

  class Axis extends _layer.default {
    render() {
      let context = this.context;
      context.save();
      context.beginPath();
      let height = this.args.height - PADDING_INSET.bottom - PADDING_INSET.top;

      for (let line = 0; line < this.args.horizontalLines; line++) {
        let ratio = line / (this.args.horizontalLines - 1);
        let position = ratio * height + PADDING_INSET.top;
        context.moveTo(0, position);
        context.lineTo(this.args.width, position);
      }

      let verticalGridLines = (0, _bisect.limit)(this.args.verticalGridLines, line => line.timestamp.getTime(), this.args.from.getTime(), this.args.to.getTime(), _bisect.LimitCap.noCap);
      verticalGridLines.forEach(line => {
        let position = this.args.rangeX(line.timestamp.valueOf());
        position = Math.floor(position) + 0.5;
        context.moveTo(position, CONTENT_INSET.top);
        context.lineTo(position, this.args.height - CONTENT_INSET.bottom);
      });
      context.strokeStyle = _designSystemParticles.darkSystemCanvasGrid;
      context.lineWidth = 1;
      context.stroke();
      context.restore();
    }

  }

  _exports.default = Axis;
});