define("clarify/utils/namespace-engine-route-name", ["exports", "ember-engines-router-service/utils/namespace-engine-route-name"], function (_exports, _namespaceEngineRouteName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _namespaceEngineRouteName.default;
    }
  });
  Object.defineProperty(_exports, "namespaceEngineRouteName", {
    enumerable: true,
    get: function () {
      return _namespaceEngineRouteName.namespaceEngineRouteName;
    }
  });
});