define("clarify/routes/organizations/show", ["exports", "clarify/routes/protected", "clarify/utilities/rpc/keyword-labels", "clarify/utilities/uuid", "clarify/errors/route"], function (_exports, _protected, _keywordLabels, _uuid, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let OrganizationsShowRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, (_class = (_temp = class OrganizationsShowRoute extends _protected.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "authentication", _descriptor, this);

      _initializerDefineProperty(this, "currentUser", _descriptor2, this);

      _initializerDefineProperty(this, "currentOrganization", _descriptor3, this);

      _initializerDefineProperty(this, "analytics", _descriptor4, this);

      _initializerDefineProperty(this, "network", _descriptor5, this);
    }

    async model(params) {
      await this.store.findAll('organization');
      let organization = await this.store.peekRecord('organization', params.organizationId);

      if (!organization) {
        return Promise.reject(new _route.default(_route.RouteErrorType.notFound));
      }

      let categoryRequest = this.fetchLabels(params.organizationId);
      let [users] = await Promise.all([organization.users, organization.groups, categoryRequest]);
      return {
        organization,
        users: users.toArray()
      };
    }

    async fetchLabels(organizationId) {
      let request = _keywordLabels.KeywordLabelsRequestInfo.create((0, _uuid.default)(), {
        organization: organizationId,
        resource: 'timelines',
        noValues: true
      });

      let result = await this.network.perform('meta/rpc', {}, request);

      for (let category of result) {
        let payload = this.store.normalize('category', category);
        this.store.push(payload);
      }
    }

    afterModel(model) {
      Ember.set(this.currentOrganization, 'organization', model.organization);

      if (this.authentication.userId) {
        let user = this.store.peekRecord('user', this.authentication.userId);
        Ember.set(this.currentUser, 'user', user);

        if (user) {
          this.analytics.identify(user);
        }
      }

      this.analytics.group(model.organization);
      this.analytics.storeCampaign(null);
    }

    setupController(controller, model) {
      Ember.set(controller, 'organization', model.organization);
    }

    willDestroy() {
      super.willDestroy();
      Ember.set(this.currentOrganization, 'organization', null);
    }

    titleToken({
      organization
    }) {
      return organization.name;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "authentication", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentOrganization", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "analytics", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "network", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = OrganizationsShowRoute;
});