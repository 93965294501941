define("clarify/components/timeline-search-bar/index", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <section ...attributes>
    <div
      class="timelines-content-filter-bar-inner"
      data-intercom-target="Filter bar"
      data-marketing-landmark="timelines.index.timelines-filter-bar"
      {{on "click" (fn this.focus)}}
    >
      <div class="timelines-content-filter-bar-label">
        {{#if (not @filtersApplied)}}
          {{svg-jar
            "#design-system-p-search-24"
            class="timelines-content-filter-bar-icon"
            aria-hidden="true"
            width=24
            height=24
          }}
        {{/if}}
      </div>
      {{yield}}
    </div>
    <div class="timelines-content-filter-bar-results">
      <div class="timelines-content-filter-bar-item">
        {{#if (not-eq @filters 0)}}
          <button type="button" class="timelines-content-clear" {{on "click" @clearAll}}>
            {{t "timelines.clear" filter=@filters}}
          </button>
        {{/if}}
      </div>
      <div class="timelines-content-filter-bar-item">
        <div class="timelines-content-filter-bar-item-header">
          {{@results}}
        </div>
      </div>
    </div>
  </section>
  */
  {"id":"QP+G5Nfp","block":"{\"symbols\":[\"@clearAll\",\"@filters\",\"&attrs\",\"@filtersApplied\",\"&default\",\"@results\"],\"statements\":[[11,\"section\"],[17,3],[12],[2,\"\\n  \"],[11,\"div\"],[24,0,\"timelines-content-filter-bar-inner\"],[24,\"data-intercom-target\",\"Filter bar\"],[24,\"data-marketing-landmark\",\"timelines.index.timelines-filter-bar\"],[4,[38,0],[\"click\",[30,[36,3],[[32,0,[\"focus\"]]],null]],null],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"timelines-content-filter-bar-label\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[32,4]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,2],[\"#design-system-p-search-24\"],[[\"class\",\"aria-hidden\",\"width\",\"height\"],[\"timelines-content-filter-bar-icon\",\"true\",24,24]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n    \"],[18,5,null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"timelines-content-filter-bar-results\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"timelines-content-filter-bar-item\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,6],[[32,2],0],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[11,\"button\"],[24,0,\"timelines-content-clear\"],[24,4,\"button\"],[4,[38,0],[\"click\",[32,1]],null],[12],[2,\"\\n          \"],[1,[30,[36,1],[\"timelines.clear\"],[[\"filter\"],[[32,2]]]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"timelines-content-filter-bar-item\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"timelines-content-filter-bar-item-header\"],[12],[2,\"\\n        \"],[1,[32,6]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"on\",\"t\",\"svg-jar\",\"fn\",\"not\",\"if\",\"not-eq\"]}","meta":{"moduleName":"clarify/components/timeline-search-bar/index.hbs"}});

  let TimelineSearchBar = (_dec = Ember._action, (_class = class TimelineSearchBar extends _component.default {
    focus(event) {
      let currentTarget = event.currentTarget;

      if (currentTarget === event.target) {
        var _currentTarget$queryS;

        (_currentTarget$queryS = currentTarget.querySelector('input')) === null || _currentTarget$queryS === void 0 ? void 0 : _currentTarget$queryS.focus();
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "focus", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "focus"), _class.prototype)), _class));
  _exports.default = TimelineSearchBar;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TimelineSearchBar);
});