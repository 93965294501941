define("clarify/components/timeline-canvas/visualizations/line-chart/index", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.between = between;
  _exports.isVisible = isVisible;
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <TimelineCanvas::Visualizations::LineChart::Source
    @slotIds={{@slotIds}}
    @visible={{@visible}}
    @source={{hash type="visualization" visualization=@visualization}}
    @interactions={{@interactions}} as |source|
  >
    <div class="visualization-line-chart-canvas-wrapper">
      <TimelineCanvas::Visualizations::LineChart::Canvas
        @thresholds={{@visualization.visibleThresholds}}
        @interactions={{@interactions}}
        @segments={{source.segments}}
        @loadingSlots={{source.loadingSlots}}
        @devicePixelRatio={{@devicePixelRatio}}
        @width={{@width}}
        @inset={{@inset}}
        @lineChartHeight={{this.lineChartHeight}}
        @visible={{@visible}}
        @axisScaling={{@axisScaling}}
        @ruler={{@ruler}}
        @rulers={{@rulers}}
        @verticalGridLines={{@verticalGridLines}}
        @domain={{@domain}}
        @visibleRangeX={{@visibleRangeX}}
        @timeRange={{@timeRange}}
        @now={{@now}}
      />
    </div>
  </TimelineCanvas::Visualizations::LineChart::Source>
  */
  {"id":"ukp9sBj8","block":"{\"symbols\":[\"source\",\"@slotIds\",\"@visible\",\"@visualization\",\"@interactions\",\"@devicePixelRatio\",\"@width\",\"@inset\",\"@axisScaling\",\"@ruler\",\"@rulers\",\"@verticalGridLines\",\"@domain\",\"@visibleRangeX\",\"@timeRange\",\"@now\"],\"statements\":[[8,\"timeline-canvas/visualizations/line-chart/source\",[],[[\"@slotIds\",\"@visible\",\"@source\",\"@interactions\"],[[32,2],[32,3],[30,[36,0],null,[[\"type\",\"visualization\"],[\"visualization\",[32,4]]]],[32,5]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"visualization-line-chart-canvas-wrapper\"],[12],[2,\"\\n    \"],[8,\"timeline-canvas/visualizations/line-chart/canvas\",[],[[\"@thresholds\",\"@interactions\",\"@segments\",\"@loadingSlots\",\"@devicePixelRatio\",\"@width\",\"@inset\",\"@lineChartHeight\",\"@visible\",\"@axisScaling\",\"@ruler\",\"@rulers\",\"@verticalGridLines\",\"@domain\",\"@visibleRangeX\",\"@timeRange\",\"@now\"],[[32,4,[\"visibleThresholds\"]],[32,5],[32,1,[\"segments\"]],[32,1,[\"loadingSlots\"]],[32,6],[32,7],[32,8],[32,0,[\"lineChartHeight\"]],[32,3],[32,9],[32,10],[32,11],[32,12],[32,13],[32,14],[32,15],[32,16]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"hash\"]}","meta":{"moduleName":"clarify/components/timeline-canvas/visualizations/line-chart/index.hbs"}});

  function between(timeRange, position) {
    return position >= timeRange.from.valueOf() && position <= timeRange.to.valueOf();
  }

  function isVisible(viewport, element) {
    return viewport.from.getTime() <= element.to.getTime() && viewport.to.getTime() >= element.from.getTime();
  }

  class VisualizationLineChart extends _component.default {}

  _exports.default = VisualizationLineChart;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, VisualizationLineChart);
});