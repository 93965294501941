define("clarify/templates/organizations/show/timelines/show/activity/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8bQAHhYo",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"thread-feed-pane\"],[12],[10,\"div\"],[14,0,\"thread-feed-pane-header\"],[12],[10,\"span\"],[14,0,\"thread-feed-pane-header-title\"],[12],[1,[30,[36,0],[\"thread-feed.activity\"],null]],[13],[2,\" \"],[8,\"button\",[[24,0,\"thread-feed-pane-header-back-button\"]],[[\"@rightIcon\",\"@size\",\"@color\",\"@style\"],[[30,[36,1],[\"#settings-header-icon\"],[[\"width\",\"height\"],[20,20]]],\"normal\",\"blue\",\"icon\"]],null],[13],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"svg-jar\"]}",
    "meta": {
      "moduleName": "clarify/templates/organizations/show/timelines/show/activity/loading.hbs"
    }
  });

  _exports.default = _default;
});