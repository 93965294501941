define("clarify/routes/organizations/show/timelines/show/threads/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let TimelinesShowThreadsShowRoute = (_dec = Ember._action, (_class = class TimelinesShowThreadsShowRoute extends Ember.Route {
    async model(params) {
      let thread = this.store.findRecord('thread', params.threadId);
      let comments = this.store.query('comment', {
        thread: params.threadId
      });
      let [threadModel] = await Promise.all([thread, comments]);
      return {
        thread: threadModel
      };
    }

    setupController(controller, model, transition) {
      super.setupController(controller, model, transition);
      let {
        organization
      } = this.modelFor('organizations.show');
      let {
        layout
      } = this.modelFor('organizations.show.timelines.show');
      controller.set('layout', layout);
      controller.set('organization', organization);
      controller.set('thread', model.thread);
    }

    loading() {
      this.intermediateTransitionTo('organizations.show.timelines.show.threads.loading');
      return false;
    }

    serialize(model) {
      return {
        threadId: model.thread.id
      };
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "loading", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "loading"), _class.prototype)), _class));
  _exports.default = TimelinesShowThreadsShowRoute;
});