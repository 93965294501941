define("clarify/components/timeline-filter/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#each @items as |item index|}}
    <AItemCard
      data-intercom-target={{if (eq index 0) "First item"}}
      @item={{item}}
      class="m-item-card-container"
      {{drag-source model=item contextProvider="item" dragImage="item"}}
    >
      <:actions>
        <Button
          @size="small"
          @style="link-underline"
          @leftIcon={{svg-jar "#x-button-icon-plus" width=7 height=7}}
          @title={{t "item-card.add"}}
          @action={{fn @handleAddItem item}}
          class="item-card-button"
        />
      </:actions>
    </AItemCard>
  {{/each}}
  */
  {"id":"XelrJHVy","block":"{\"symbols\":[\"item\",\"index\",\"__arg0\",\"@handleAddItem\",\"@items\"],\"statements\":[[6,[37,9],[[30,[36,8],[[30,[36,8],[[32,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"a-item-card\",[[16,\"data-intercom-target\",[30,[36,4],[[30,[36,3],[[32,2],0],null],\"First item\"],null]],[24,0,\"m-item-card-container\"],[4,[38,6],null,[[\"model\",\"contextProvider\",\"dragImage\"],[[32,1],\"item\",\"item\"]]]],[[\"@item\",\"@namedBlocksInfo\"],[[32,1],[30,[36,5],null,[[\"actions\"],[0]]]]],[[\"default\"],[{\"statements\":[[6,[37,4],[[30,[36,7],[[32,3],\"actions\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"button\",[[24,0,\"item-card-button\"]],[[\"@size\",\"@style\",\"@leftIcon\",\"@title\",\"@action\"],[\"small\",\"link-underline\",[30,[36,0],[\"#x-button-icon-plus\"],[[\"width\",\"height\"],[7,7]]],[30,[36,1],[\"item-card.add\"],null],[30,[36,2],[[32,4],[32,1]],null]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]]],\"parameters\":[3]}]]],[2,\"\\n\"]],\"parameters\":[1,2]}]]]],\"hasEval\":false,\"upvars\":[\"svg-jar\",\"t\",\"fn\",\"eq\",\"if\",\"hash\",\"drag-source\",\"-is-named-block-invocation\",\"-track-array\",\"each\"]}","meta":{"moduleName":"clarify/components/timeline-filter/list.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});