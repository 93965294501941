define("clarify/adapters/activity-group", ["exports", "clarify/adapters/meta"], function (_exports, _meta) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class ActivityGroup extends _meta.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "namespace", 'api/activity');

      _defineProperty(this, "includeAll", true);

      _defineProperty(this, "removeDeleted", true);
    }

    pathForType() {
      return 'groups';
    }

  }

  _exports.default = ActivityGroup;
});