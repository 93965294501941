define("clarify/routes/organizations/show/timelines/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TimelinesShow = (_dec = Ember.inject.service, (_class = (_temp = class TimelinesShow extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _defineProperty(this, "queryParams", {
        from: {
          replace: true
        },
        to: {
          replace: true
        },
        signals: {
          replace: false
        }
      });
    }

    async model(params) {
      let data = await Ember.RSVP.hash({
        timeline: this.store.findRecord('timeline', params.id),
        layouts: this.store.query('layout', {
          timeline: params.id
        })
      });
      let layout = data.layouts.find(layout => Ember.get(layout, 'type') === 'clfy.io/main');

      if (!layout) {
        layout = this.store.createRecord('layout', {
          type: 'clfy.io/main'
        });
        Ember.set(layout, 'timeline', data.timeline);
        data.timeline.layouts.pushObject(layout);
      } // For now we're ensuring every threshold are loaded before showing the route,
      // this can be changed in the future, to lazy load in the thresholds.


      await data.timeline.thresholds;
      return {
        timeline: data.timeline,
        layout
      };
    }

    setupController(controller, model) {
      Ember.set(controller, 'timeline', model.timeline);
      Ember.set(controller, 'layout', model.layout);
      Ember.set(controller, 'demo', !!model.timeline.annotations['clarify/industry-type']);
    }

    resetController(controller) {
      Ember.set(controller, 'timeRange', undefined);
      Ember.set(controller, 'demo', false);
      Ember.set(controller, 'new', false);
    }

    serialize(model) {
      return {
        id: model.timeline.id
      };
    }

    titleToken({
      timeline
    }) {
      return timeline.name || this.intl.t('routes.organizations/show/timelines/show.untitled');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = TimelinesShow;
});