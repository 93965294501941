define("clarify/components/group-members-modal/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <section class="group-members-list">
    <header class="group-members-list-header">
      <PUserInfo::Group @style="disabled" @group={{@group}} />
      <LinkTo title={{t "item-dialog.close"}} @route="organizations.show.timelines.show">
        {{svg-jar "#design-system-icon-cross-24" class="group-members-list-icon" width=24 height=24}}
      </LinkTo>
    </header>
    <div class="group-members-list-items">
      {{#each @group.members as |user|}}
        <MListItem>
          <PUserInfo::User @user={{user}} />
        </MListItem>
      {{/each}}
    </div>
  </section>
  */
  {"id":"zOXosTVc","block":"{\"symbols\":[\"user\",\"@group\"],\"statements\":[[10,\"section\"],[14,0,\"group-members-list\"],[12],[2,\"\\n  \"],[10,\"header\"],[14,0,\"group-members-list-header\"],[12],[2,\"\\n    \"],[8,\"p-user-info/group\",[],[[\"@style\",\"@group\"],[\"disabled\",[32,2]]],null],[2,\"\\n    \"],[8,\"link-to\",[[16,\"title\",[30,[36,0],[\"item-dialog.close\"],null]]],[[\"@route\"],[\"organizations.show.timelines.show\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,1],[\"#design-system-icon-cross-24\"],[[\"class\",\"width\",\"height\"],[\"group-members-list-icon\",24,24]]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"group-members-list-items\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,2,[\"members\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"m-list-item\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"p-user-info/user\",[],[[\"@user\"],[[32,1]]],null],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"svg-jar\",\"-track-array\",\"each\"]}","meta":{"moduleName":"clarify/components/group-members-modal/index.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});