define("clarify/services/screen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Screen = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, (_class = (_temp = class Screen extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "devicePixelRatio", _descriptor, this);

      _initializerDefineProperty(this, "width", _descriptor2, this);

      _initializerDefineProperty(this, "height", _descriptor3, this);

      _initializerDefineProperty(this, "scrollBarWidth", _descriptor4, this);

      _defineProperty(this, "isRetinaMediaQuery", window.matchMedia('screen and (min-resolution: 2dppx), screen and (-webkit-min-device-pixel-ratio: 2)'));
    }

    init() {
      super.init();
      this.updateDimentions();
      this.updateDevicePixelRatio();
      this.scrollBarWidth = this.calculateScrollBarWidth();
      window.addEventListener('resize', this.updateDimentions);
      this.isRetinaMediaQuery.addListener(this.updateDevicePixelRatio);
    }

    updateDimentions() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
    }

    updateDevicePixelRatio() {
      this.devicePixelRatio = window.devicePixelRatio || 1;
    }

    calculateScrollBarWidth() {
      let container = document.createElement('div');
      container.style.visibility = 'hidden';
      container.style.overflow = 'scroll';
      document.body.appendChild(container);
      let element = document.createElement('div');
      container.appendChild(element);
      let width = container.offsetWidth - element.offsetWidth;
      container.remove();
      return width;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "devicePixelRatio", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "width", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "height", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "scrollBarWidth", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateDimentions", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "updateDimentions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateDevicePixelRatio", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "updateDevicePixelRatio"), _class.prototype)), _class));
  _exports.default = Screen;
});