define("clarify/helpers/belongs-to-optional", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class BelongsToOptionalHelper extends Ember.Helper {
    // @service store!: DS.Store;
    compute([model, key]) {
      // @ts-expect-error
      let relationship = model.belongsTo(key);
      return relationship.value();
    }

  }

  _exports.default = BelongsToOptionalHelper;
});