define("clarify/templates/organizations/show/items/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SjFIPW/n",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"main-modal-container\"],[12],[10,\"div\"],[14,0,\"main-modal-container-backdrop\"],[12],[13],[2,\" \"],[8,\"organizations/show/items/show\",[],[[\"@item\"],[[32,0,[\"item\"]]]],null],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "clarify/templates/organizations/show/items/show.hbs"
    }
  });

  _exports.default = _default;
});