define("clarify/components/p-user-info/-private", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="p-user" aria-disabled={{if @disabled "true" "false"}} ...attributes>
    <img src={{@icon}} class="avatar" width="28" height="28" ...attributes />
    <div class="p-user-info">
      <p class="p-user-info-name">
        {{@user}}
      </p>
      <p class="p-user-info-content">
        {{yield}}
      </p>
    </div>
  </div>
  */
  {"id":"437lpuEi","block":"{\"symbols\":[\"@disabled\",\"&attrs\",\"@icon\",\"@user\",\"&default\"],\"statements\":[[11,\"div\"],[24,0,\"p-user\"],[16,\"aria-disabled\",[30,[36,0],[[32,1],\"true\",\"false\"],null]],[17,2],[12],[2,\"\\n  \"],[11,\"img\"],[16,\"src\",[32,3]],[24,0,\"avatar\"],[24,\"width\",\"28\"],[24,\"height\",\"28\"],[17,2],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"p-user-info\"],[12],[2,\"\\n    \"],[10,\"p\"],[14,0,\"p-user-info-name\"],[12],[2,\"\\n      \"],[1,[32,4]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"p\"],[14,0,\"p-user-info-content\"],[12],[2,\"\\n      \"],[18,5,null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}","meta":{"moduleName":"clarify/components/p-user-info/-private.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});