define("clarify/components/timeline-canvas/time-range-tooltip/index", ["exports", "@glimmer/component", "clarify/models/duration", "clarify/utilities/zoom-levels"], function (_exports, _component, _duration, _zoomLevels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="time-range-tooltip" data-temporarily={{this.temporarily}}>
    <div class="time-range-tooltip-label time-range-tooltip-start-label">
      {{t "time-range.start"}}
    </div>
    <div class="time-range-tooltip-start-date time-range-tooltip-content" data-selected={{this.fromSelected}}>
      {{this.fromDate}}
    </div>
    <div class="time-range-tooltip-start-time time-range-tooltip-content" data-selected={{this.fromSelected}}>
      {{this.fromTime}}
    </div>
    <div class="time-range-tooltip-end-label time-range-tooltip-label">
      {{t "time-range.end"}}
    </div>
    <div class="time-range-tooltip-end-date time-range-tooltip-content" data-selected={{this.toSelected}}>
      {{this.toDate}}
    </div>
    <div class="time-range-tooltip-end-time time-range-tooltip-content" data-selected={{this.toSelected}}>
      {{this.toTime}}
    </div>
    <div class="time-range-tooltip-label time-range-tooltip-length">
      {{t "time-range.length"}}
    </div>
    <div class="time-range-tooltip-length-value time-range-tooltip-content">
      {{format-duration this.duration seconds=this.showSeconds}}
    </div>
  </div>
  */
  {"id":"wZneIwXy","block":"{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"time-range-tooltip\"],[15,\"data-temporarily\",[32,0,[\"temporarily\"]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"time-range-tooltip-label time-range-tooltip-start-label\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"time-range.start\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"time-range-tooltip-start-date time-range-tooltip-content\"],[15,\"data-selected\",[32,0,[\"fromSelected\"]]],[12],[2,\"\\n    \"],[1,[32,0,[\"fromDate\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"time-range-tooltip-start-time time-range-tooltip-content\"],[15,\"data-selected\",[32,0,[\"fromSelected\"]]],[12],[2,\"\\n    \"],[1,[32,0,[\"fromTime\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"time-range-tooltip-end-label time-range-tooltip-label\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"time-range.end\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"time-range-tooltip-end-date time-range-tooltip-content\"],[15,\"data-selected\",[32,0,[\"toSelected\"]]],[12],[2,\"\\n    \"],[1,[32,0,[\"toDate\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"time-range-tooltip-end-time time-range-tooltip-content\"],[15,\"data-selected\",[32,0,[\"toSelected\"]]],[12],[2,\"\\n    \"],[1,[32,0,[\"toTime\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"time-range-tooltip-label time-range-tooltip-length\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"time-range.length\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"time-range-tooltip-length-value time-range-tooltip-content\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[[32,0,[\"duration\"]]],[[\"seconds\"],[[32,0,[\"showSeconds\"]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"format-duration\"]}","meta":{"moduleName":"clarify/components/timeline-canvas/time-range-tooltip/index.hbs"}});

  let TimeRangeTooltip = (_dec = Ember.inject.service, _dec2 = Ember.computed('args.timeRange.temporarily'), _dec3 = Ember.computed('args.timeRange.temporarily'), _dec4 = Ember.computed('args.timeRange.temporarily'), _dec5 = Ember.computed('args.timeRange.from'), _dec6 = Ember.computed('args.timeRange.from', 'showSeconds'), _dec7 = Ember.computed('args.timeRange.to'), _dec8 = Ember.computed('args.timeRange.to', 'showSeconds'), _dec9 = Ember.computed('args.timeRange.{to,from}'), _dec10 = Ember.computed('args.timeRange.{to,from}', 'args.timeRangeTimeFormat'), (_class = (_temp = class TimeRangeTooltip extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "locale", _descriptor, this);
    }

    get temporarily() {
      return this.args.timeRange.temporarily !== null;
    }

    get fromSelected() {
      return this.args.timeRange.temporarily === 'from';
    }

    get toSelected() {
      return this.args.timeRange.temporarily === 'to';
    }

    get fromDate() {
      return this.locale.format(this.args.timeRange.from, this.locale.current.timeRangeTooltip.date);
    }

    get fromTime() {
      return this.locale.format(this.args.timeRange.from, this.showSeconds ? this.locale.current.timeRangeTooltip.timeWithSeconds : this.locale.current.timeRangeTooltip.time);
    }

    get toDate() {
      return this.locale.format(this.args.timeRange.to, this.locale.current.timeRangeTooltip.date);
    }

    get toTime() {
      return this.locale.format(this.args.timeRange.to, this.showSeconds ? this.locale.current.timeRangeTooltip.timeWithSeconds : this.locale.current.timeRangeTooltip.time);
    }

    get duration() {
      return _duration.default.fromMilliseconds(this.args.timeRange.from.valueOf() - this.args.timeRange.to.valueOf());
    }

    get showSeconds() {
      return this.args.timeRangeTimeFormat === _zoomLevels.TimeRangeTimeFormat.full || this.duration.valueOf() < 1000 * 60;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "locale", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "temporarily", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "temporarily"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fromSelected", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "fromSelected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toSelected", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "toSelected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fromDate", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "fromDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fromTime", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "fromTime"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toDate", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "toDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toTime", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "toTime"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "duration", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "duration"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showSeconds", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "showSeconds"), _class.prototype)), _class));
  _exports.default = TimeRangeTooltip;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TimeRangeTooltip);
});