define("clarify/models/keyword", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Keyword extends Ember.Object {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "path", void 0);

      _defineProperty(this, "id", void 0);

      _defineProperty(this, "name", void 0);

      _defineProperty(this, "type", void 0);

      _defineProperty(this, "parentId", void 0);

      _defineProperty(this, "filter", void 0);
    }

    get displayName() {
      if (this.path.length > 0) {
        return this.path.join(' / ');
      } else {
        return this.name;
      }
    }

  }

  _exports.default = Keyword;
});