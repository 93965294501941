define("clarify/components/m-statistics-panel/failed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="stats-panel-failed">
    <div class="stats-panel-failed-icon" title={{t "statistics-panel.status.failed"}}>
      {{svg-jar "#design-system-icon-warning-triangle-24" width=24 height=24}}
    </div>
    <p class="stats-panel-failed-content">
      {{@description}}
    </p>
    <div class="stats-panel-failed-button-container">
      <AButton
        class="stats-panel-failed-button"
        @style="dark"
        @size="small"
        title={{t "statistics-panel.status.refresh"}}
        {{on "click" (fn @retry)}}
      >
        {{t "statistics-panel.status.refresh"}}
      </AButton>
    </div>
  </div>
  */
  {"id":"9dZANJ4/","block":"{\"symbols\":[\"@description\",\"@retry\"],\"statements\":[[10,\"div\"],[14,0,\"stats-panel-failed\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"stats-panel-failed-icon\"],[15,\"title\",[30,[36,0],[\"statistics-panel.status.failed\"],null]],[12],[2,\"\\n    \"],[1,[30,[36,1],[\"#design-system-icon-warning-triangle-24\"],[[\"width\",\"height\"],[24,24]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"p\"],[14,0,\"stats-panel-failed-content\"],[12],[2,\"\\n    \"],[1,[32,1]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"stats-panel-failed-button-container\"],[12],[2,\"\\n    \"],[8,\"a-button\",[[24,0,\"stats-panel-failed-button\"],[16,\"title\",[30,[36,0],[\"statistics-panel.status.refresh\"],null]],[4,[38,3],[\"click\",[30,[36,2],[[32,2]],null]],null]],[[\"@style\",\"@size\"],[\"dark\",\"small\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,0],[\"statistics-panel.status.refresh\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"svg-jar\",\"fn\",\"on\"]}","meta":{"moduleName":"clarify/components/m-statistics-panel/failed.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});