define("clarify/templates/organizations/show/settings-non-admin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hwWonzFX",
    "block": "{\"symbols\":[],\"statements\":[[8,\"app-header\",[],[[\"@style\"],[\"light\"]],null],[2,\" \"],[8,\"non-ideal-state\",[[24,\"data-style\",\"expand\"]],[[\"@title\"],[[30,[36,0],[\"non-admin.description\"],null]]],null]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "clarify/templates/organizations/show/settings-non-admin.hbs"
    }
  });

  _exports.default = _default;
});