define("clarify/routes/organizations/show/timelines/show/activity/index", ["exports", "clarify/utilities/resource"], function (_exports, _resource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const sortKeyMap = {
    activity: '-updatedAt',
    chronological: '-timestamp'
  };
  let TimelinesShowActivityIndexRoute = (_dec = Ember._action, (_class = (_temp = class TimelinesShowActivityIndexRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", {
        sortBy: {
          refreshModel: true
        }
      });
    }

    loading(transition) {
      let fromRouteInfo = transition.from;

      if (fromRouteInfo && fromRouteInfo.name === 'organizations.show.timelines.show.activity.index') {
        return false;
      }

      this.intermediateTransitionTo('organizations.show.timelines.show.activity.loading');
      return false;
    }

    async model(params) {
      const sortKey = params.sortBy || 'activity';
      let model = this.modelFor('organizations/show/timelines/show');

      let resource = _resource.default.create({
        store: this.store,
        type: 'thread',
        query: {
          filter: {
            timeline: model.timeline.id
          },
          sort: sortKeyMap[sortKey]
        }
      });

      await resource.load();
      return resource;
    }

    setupController(controller, model) {
      Ember.set(controller, 'resource', model);
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "loading", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "loading"), _class.prototype)), _class));
  _exports.default = TimelinesShowActivityIndexRoute;
});