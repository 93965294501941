define("clarify/components/timeline-canvas/visualizations/layers/background", ["exports", "canvas/components/layer", "ui/helpers/design-system-particles", "clarify/utilities/colors"], function (_exports, _layer, _designSystemParticles, _colors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let color = parseInt(`0x${_designSystemParticles.darkSystemCanvasBgBlackoverlay.slice(1)}`, 16) << 8 | 0x7a;
  let timeRangeBacground = parseInt(`0x${_designSystemParticles.darkNeutralTxtNeutralStrongest.slice(1)}`, 16) << 8 | 0x08;
  const TIME_RANGE_BACKGROUND_TRANSPARENT = (0, _colors.toHex)(timeRangeBacground);
  const TIME_RANGE_BACKGROUND_OVERLAY = (0, _colors.toHex)(color);
  const BACKGROUND_INSET = {
    top: 8,
    bottom: 8
  };

  class BackgroundLayer extends _layer.default {
    render() {
      let position = this.args.rangeX(this.args.now);
      let insetHeight = this.args.height - BACKGROUND_INSET.top - BACKGROUND_INSET.bottom;
      position = Math.round(position);

      if (position > 0) {
        this.context.fillStyle = _designSystemParticles.darkSystemCanvasBgPrimary;
        this.context.fillRect(0, 0, position, this.args.height);
        this.context.fillStyle = _designSystemParticles.darkSystemGraphBgPrimary;
        this.context.fillRect(0, BACKGROUND_INSET.top, position, insetHeight);
      }

      if (position < this.args.width) {
        this.context.fillStyle = _designSystemParticles.darkSystemCanvasBgFuture;
        this.context.fillRect(position, 0, this.args.width - position, this.args.height);
        this.context.fillStyle = _designSystemParticles.darkSystemGraphBgFuture;
        this.context.fillRect(position, BACKGROUND_INSET.top, this.args.width - position, insetHeight);
      }

      this.renderTimeRangeBackground();
    }

    renderTimeRangeBackground() {
      let timeRange = this.args.timeRange;
      let height = this.args.height - BACKGROUND_INSET.top - BACKGROUND_INSET.bottom;

      if (timeRange) {
        let context = this.context;
        let from = this.args.rangeX(timeRange.from);
        let to = this.args.rangeX(timeRange.to);
        context.beginPath();
        context.fillStyle = TIME_RANGE_BACKGROUND_TRANSPARENT;
        context.rect(Math.round(from), BACKGROUND_INSET.top, Math.round(to) - Math.round(from), height);
        context.fill();

        if (from > 0) {
          context.beginPath();
          context.fillStyle = TIME_RANGE_BACKGROUND_OVERLAY;
          context.rect(0, BACKGROUND_INSET.top, Math.round(from), height);
          context.fill();
        }

        if (to < this.args.width) {
          context.beginPath();
          context.fillStyle = TIME_RANGE_BACKGROUND_OVERLAY;
          context.rect(Math.round(to), BACKGROUND_INSET.top, this.args.width, height);
          context.fill();
        }
      }
    }

  }

  _exports.default = BackgroundLayer;
});