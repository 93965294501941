define("clarify/components/visualization-settings/fieldset-tab-view", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <TabBar
    ...attributes
    data-style={{this.style}}
    class="visualization-settings-tab-bar"
    @valueDidChange={{fn this.visualizationPreferenceDidChange}}
    @value={{this.visualizationPreference}}
    {{did-update (fn this.handleTabsUpdate @tabs)}} as |TabBarItem|
  >
    {{#each @tabs as |option|}}
      <TabBarItem class="visualization-settings-tab-bar-item" @value={{option}}>
        {{t (concat "visualization-settings.fieldsets." option)}}
      </TabBarItem>
    {{/each}}
  </TabBar>
  {{yield this.visualizationPreference}}
  */
  {"id":"4ZLj7dC1","block":"{\"symbols\":[\"TabBarItem\",\"option\",\"&attrs\",\"@tabs\",\"&default\"],\"statements\":[[8,\"tab-bar\",[[17,3],[16,\"data-style\",[32,0,[\"style\"]]],[24,0,\"visualization-settings-tab-bar\"],[4,[38,3],[[30,[36,2],[[32,0,[\"handleTabsUpdate\"]],[32,4]],null]],null]],[[\"@valueDidChange\",\"@value\"],[[30,[36,2],[[32,0,[\"visualizationPreferenceDidChange\"]]],null],[32,0,[\"visualizationPreference\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,[32,1],[[24,0,\"visualization-settings-tab-bar-item\"]],[[\"@value\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,1],[[30,[36,0],[\"visualization-settings.fieldsets.\",[32,2]],null]],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"],[18,5,[[32,0,[\"visualizationPreference\"]]]]],\"hasEval\":false,\"upvars\":[\"concat\",\"t\",\"fn\",\"did-update\",\"-track-array\",\"each\"]}","meta":{"moduleName":"clarify/components/visualization-settings/fieldset-tab-view.hbs"}});

  let VisualizationSettingsFieldsetTabView = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, (_class = (_temp = class VisualizationSettingsFieldsetTabView extends _component.default {
    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "visualizationPreference", _descriptor, this);

      this.visualizationPreference = this.args.tabs[0];
    }

    handleTabsUpdate() {
      let index = this.args.tabs.indexOf(this.visualizationPreference);

      if (index === -1) {
        this.visualizationPreference = this.args.tabs[0];
      }
    }

    visualizationPreferenceDidChange(visualizationPreference) {
      this.visualizationPreference = visualizationPreference;
    }

    get style() {
      return this.args.style || 'green';
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "visualizationPreference", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "handleTabsUpdate", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "handleTabsUpdate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "visualizationPreferenceDidChange", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "visualizationPreferenceDidChange"), _class.prototype)), _class));
  _exports.default = VisualizationSettingsFieldsetTabView;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, VisualizationSettingsFieldsetTabView);
});