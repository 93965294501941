define("clarify/routes/organizations/show/activities", ["exports", "clarify/utilities/resource"], function (_exports, _resource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class OrganizationsShowActivities extends Ember.Route {
    async model() {
      let model = this.modelFor('organizations/show');

      let resource = _resource.default.create({
        store: this.store,
        type: 'activity-group',
        includeTotal: false,
        limit: 20,
        query: {
          filter: {
            organization: model.organization.id
          }
        }
      });

      await resource.load();
      return resource;
    }

    setupController(controller, model) {
      Ember.set(controller, 'resource', model);
    }

  }

  _exports.default = OrganizationsShowActivities;
});