define("clarify/components/card/activity/comment/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <LinkTo
    class="m-card-activity-comment"
    @route="organizations.show.timelines.show.threads.show"
    @models={{array @comment.thread.timeline.id @comment.thread.id}}
    ...attributes
  >
    <div class="m-card-activity-comment-container">
      <Avatar title={{@comment.createdBy.name}} src={{@comment.createdBy.picture}} width="40" height="40" />
      <Card::Activity::Comment::TextContent
        class="m-card-activity-comment-text"
        data-preview={{@preview}}
        @comment={{@comment}}
      />
    </div>
    {{#if @showAttachments}}
      <Card::Activity::Comment::Attachments @comment={{@comment}} @maxImageSize={{@maxImageSize}} />
    {{/if}}
  </LinkTo>
  */
  {"id":"qrYDg6Ym","block":"{\"symbols\":[\"@comment\",\"@maxImageSize\",\"&attrs\",\"@preview\",\"@showAttachments\"],\"statements\":[[8,\"link-to\",[[24,0,\"m-card-activity-comment\"],[17,3]],[[\"@route\",\"@models\"],[\"organizations.show.timelines.show.threads.show\",[30,[36,0],[[32,1,[\"thread\",\"timeline\",\"id\"]],[32,1,[\"thread\",\"id\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"m-card-activity-comment-container\"],[12],[2,\"\\n    \"],[8,\"avatar\",[[16,\"title\",[32,1,[\"createdBy\",\"name\"]]],[16,\"src\",[32,1,[\"createdBy\",\"picture\"]]],[24,\"width\",\"40\"],[24,\"height\",\"40\"]],[[],[]],null],[2,\"\\n    \"],[8,\"card/activity/comment/text-content\",[[24,0,\"m-card-activity-comment-text\"],[16,\"data-preview\",[32,4]]],[[\"@comment\"],[[32,1]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,1],[[32,5]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"card/activity/comment/attachments\",[],[[\"@comment\",\"@maxImageSize\"],[[32,1],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"array\",\"if\"]}","meta":{"moduleName":"clarify/components/card/activity/comment/index.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});