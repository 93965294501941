define("clarify/components/timeline-canvas/visualizations/layers/rulers", ["exports", "canvas/components/layer", "clarify/components/timeline-canvas", "ui/helpers/design-system-particles"], function (_exports, _layer, _timelineCanvas, _designSystemParticles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const RULER_INSET = {
    top: 5,
    bottom: 5
  };

  class RulersLayer extends _layer.default {
    render() {
      let context = this.context;
      let height = this.args.height - RULER_INSET.top - RULER_INSET.bottom;
      this.args.rulers.forEach(ruler => {
        context.beginPath();

        switch (ruler.style) {
          case _timelineCanvas.TimelineRulerStyle.scrubbing:
            context.fillStyle = _designSystemParticles.darkSystemCanvasRulerNormal;
            break;

          case _timelineCanvas.TimelineRulerStyle.pinned:
          case _timelineCanvas.TimelineRulerStyle.highlighted:
            context.fillStyle = _designSystemParticles.darkActionActionPrimaryBg;
            break;
        }

        let position = this.args.rangeX(ruler.date);
        context.rect(Math.round(position) - 1, RULER_INSET.top, 1, height);
        context.fill();
      });
    }

  }

  _exports.default = RulersLayer;
});