define("clarify/components/timeline-canvas/legend/item/inaccessible", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="a-legend-signal-denied">
    <span class="a-legend-signal-denied-icon">
      {{svg-jar "#design-system-p-share-personal-14" width=14 height=14}}
    </span>
    <div class="a-legend-signal-denied-name">
      {{t "visualization-legend-item.inaccesible.title"}}
    </div>
  </div>
  */
  {"id":"k2Wvq/rd","block":"{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"a-legend-signal-denied\"],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"a-legend-signal-denied-icon\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"#design-system-p-share-personal-14\"],[[\"width\",\"height\"],[14,14]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"a-legend-signal-denied-name\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[\"visualization-legend-item.inaccesible.title\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"svg-jar\",\"t\"]}","meta":{"moduleName":"clarify/components/timeline-canvas/legend/item/inaccessible.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});