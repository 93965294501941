define("clarify/components/timeline-canvas/visualizations/layers/loading", ["exports", "canvas/components/layer", "clarify/components/timeline-canvas/visualizations/line-chart"], function (_exports, _layer, _lineChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PADDING_INSET = {
    top: 8.5,
    bottom: 8.5
  };

  class LoadingLayer extends _layer.default {
    render() {
      let viewport = {
        from: this.args.from,
        to: this.args.to
      };
      this.context.save();
      this.context.fillStyle = '#343c5b';

      for (let slot of this.args.loadingSlots) {
        if ((0, _lineChart.isVisible)(viewport, {
          from: slot.from,
          to: slot.to
        })) {
          let from = Math.max(this.args.rangeX(slot.from), 0);
          let to = Math.min(this.args.rangeX(slot.to), this.args.width);
          this.context.fillRect(from, this.args.height - PADDING_INSET.bottom - PADDING_INSET.top + 1, to - from, 8);
        }
      }

      this.context.restore();
    }

  }

  _exports.default = LoadingLayer;
});