define("clarify/utilities/content-reference-formatter", ["exports", "clarify/utilities/content-reference-parser"], function (_exports, _contentReferenceParser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.toHTML = toHTML;
  _exports.render = render;
  let htmlFormatter = {
    finalize(node, children) {
      children.forEach(child => {
        node.appendChild(child);
      });
    },

    createRoot() {
      return document.createElement('div');
    },

    createText(node) {
      let strings = node.content.split('\n');
      let fragment = document.createDocumentFragment();

      for (let [index, string] of strings.entries()) {
        if (index) {
          fragment.appendChild(document.createElement('br'));
        }

        fragment.appendChild(document.createTextNode(string));
      }

      return fragment;
    },

    createContentReference(node) {
      let nodeName;
      let className;

      switch (node.contentReference.type) {
        case 'bold':
          nodeName = 'b';
          break;

        case 'italic':
          nodeName = 'i';
          break;

        case 'underline':
          nodeName = 'u';
          break;

        case 'code':
          nodeName = 'code';
          break;

        case 'blockquote':
          nodeName = 'blockquote';
          break;

        case 'user':
          nodeName = 'span';
          className = 'mentioned-user';
          break;

        case 'item':
          nodeName = 'span';
          className = 'mentioned-item';
          break;

        default:
          nodeName = 'div';
          break;
      }

      const domNode = document.createElement(nodeName);

      if (className) {
        domNode.classList.add(className);
      }

      return domNode;
    }

  };

  function toHTML(tree) {
    let container = render(tree, htmlFormatter);
    return container.innerHTML;
  }

  function render(node, adapter) {
    switch (node.type) {
      case _contentReferenceParser.NodeType.text:
        return adapter.createText(node);

      case _contentReferenceParser.NodeType.contentReference:
      case _contentReferenceParser.NodeType.root:
        let element = node.type === _contentReferenceParser.NodeType.root ? adapter.createRoot() : adapter.createContentReference(node);
        let children = node.children.map(child => render(child, adapter));
        adapter.finalize(element, children);
        return element;
    }
  }
});