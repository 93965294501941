define("clarify/components/header-contextual/index", ["exports", "design-library/components/header-contextual"], function (_exports, _headerContextual) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _headerContextual.default;
    }
  });
});