define("clarify/components/m-statistics-panel/failed-blank", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="stats-panel-content-failed-blank">
    <aside class="stats-panel-aside-content-failed-blank"></aside>
    <main class="stats-panel-main-content-container-failed-blank">
      <MStatisticsPanel::Header @style="dark" />
      <div class="stats-panel-main-content-failed-blank">
        <MStatisticsPanel::Failed @description={{@description}} />
      </div>
    </main>
  </div>
  */
  {"id":"zyJgpqXL","block":"{\"symbols\":[\"@description\"],\"statements\":[[10,\"div\"],[14,0,\"stats-panel-content-failed-blank\"],[12],[2,\"\\n  \"],[10,\"aside\"],[14,0,\"stats-panel-aside-content-failed-blank\"],[12],[13],[2,\"\\n  \"],[10,\"main\"],[14,0,\"stats-panel-main-content-container-failed-blank\"],[12],[2,\"\\n    \"],[8,\"m-statistics-panel/header\",[],[[\"@style\"],[\"dark\"]],null],[2,\"\\n    \"],[10,\"div\"],[14,0,\"stats-panel-main-content-failed-blank\"],[12],[2,\"\\n      \"],[8,\"m-statistics-panel/failed\",[],[[\"@description\"],[[32,1]]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}","meta":{"moduleName":"clarify/components/m-statistics-panel/failed-blank.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});