define("clarify/components/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="error-screen" data-theme={{@theme}}>
    <div class="error-screen-content">
      <div class="error-screen-title">
        {{@title}}
      </div>
      <div class="error-screen-paragraphs">
        <div class="error-screen-paragraph">
          {{@description}}
        </div>
        <div class="error-screen-paragraph">
          {{t "error.faq"}}
        </div>
        <div class="error-screen-paragraph">
          {{t "error.support" htmlSafe=true}}
        </div>
      </div>
    </div>
  </div>
  */
  {"id":"FIKTO2f8","block":"{\"symbols\":[\"@theme\",\"@title\",\"@description\"],\"statements\":[[10,\"div\"],[14,0,\"error-screen\"],[15,\"data-theme\",[32,1]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"error-screen-content\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"error-screen-title\"],[12],[2,\"\\n      \"],[1,[32,2]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"error-screen-paragraphs\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"error-screen-paragraph\"],[12],[2,\"\\n        \"],[1,[32,3]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"error-screen-paragraph\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"error.faq\"],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"error-screen-paragraph\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"error.support\"],[[\"htmlSafe\"],[true]]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\"]}","meta":{"moduleName":"clarify/components/error.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});