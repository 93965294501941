define("clarify/components/timeline-canvas/visualizations/layers/thresholds", ["exports", "canvas/components/layer", "d3-scale", "clarify/colors"], function (_exports, _layer, _d3Scale, _colors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PADDING_INSET = {
    top: 8.5,
    bottom: 8.5
  };
  const DEFAULT_COLOR = 'threshold-neutral-02';

  class ThresholdsLayer extends _layer.default {
    get rangeY() {
      return (0, _d3Scale.scaleLinear)().domain([this.args.rangeMin, this.args.rangeMax]).range([this.args.height - PADDING_INSET.bottom, PADDING_INSET.top]);
    }

    render() {
      let rangeY = this.rangeY;

      for (let threshold of this.args.thresholds) {
        this.renderThresholds(threshold, rangeY);
      }
    }

    renderThresholdLine(value, color, rangeY) {
      if (value >= this.args.rangeMin && value <= this.args.rangeMax) {
        let colorValue = _colors.graphColors.get(color);

        if (colorValue) {
          this.context.save();
          this.context.beginPath();
          let position = Math.floor(rangeY(value)) + 0.5;
          this.context.strokeStyle = colorValue;
          this.context.lineWidth = 1;
          this.context.setLineDash([2, 2]);
          this.context.moveTo(0, position);
          this.context.lineTo(this.args.width, position);
          this.context.stroke();
          this.context.restore();
        }
      }
    }

    renderThresholds(threshold, rangeY) {
      if (threshold.trigger.gt) {
        let value = parseFloat(threshold.trigger.gt);

        if (isFinite(value)) {
          this.renderThresholdLine(value, threshold.displayOptions.color || DEFAULT_COLOR, rangeY);
        }
      }

      if (threshold.trigger.lte) {
        let value = parseFloat(threshold.trigger.lte);

        if (isFinite(value)) {
          this.renderThresholdLine(value, threshold.displayOptions.color || DEFAULT_COLOR, rangeY);
        }
      }
    }

  }

  _exports.default = ThresholdsLayer;
});