define("clarify/ui/tests/templates.template.lint-test", [], function () {
  "use strict";

  QUnit.module('TemplateLint');
  QUnit.test('addon/templates/components/button.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/button.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/checkbox-fieldset.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/checkbox-fieldset.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/modal.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/modal.hbs should pass TemplateLint.\n\n');
  });
});