define("clarify/utilities/range", ["exports", "clarify/utilities/compact-map", "clarify/utilities/bisect"], function (_exports, _compactMap, _bisect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.calculatePlotRange = calculatePlotRange;
  _exports.calculateRangeForSegments = calculateRangeForSegments;

  function calculatePlotRange(values) {
    let componentYMin = Math.min(...values.map(edge => edge.min));
    let componentYMax = Math.max(...values.map(edge => edge.max));
    return {
      min: componentYMin,
      max: componentYMax
    };
  }

  function calculateRangeForSegments(segments, from, to) {
    let ranges = (0, _compactMap.default)(segments, segment => {
      if (segment.visible === false) {
        return null;
      }

      let dataPoints = (0, _bisect.limit)(segment.dataPoints, dataPoint => dataPoint[0], from.getTime() * 1000, to.getTime() * 1000, 0);

      if (dataPoints.length) {
        let values = dataPoints.map(dataPoint => dataPoint[1]);
        return {
          min: Math.min(...values),
          max: Math.max(...values)
        };
      }

      return null;
    });
    return ranges.length ? calculatePlotRange(ranges) : null;
  }
});