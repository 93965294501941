define("clarify/components/visualization-settings/type-segmented-control-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{svg-jar @iconName class="visualization-settings-segmented-control-item-icon" width=22 height=22}}
  <div class="visualization-settings-segmented-control-item-title">
    {{@title}}
  </div>
  */
  {"id":"lAxDdbyE","block":"{\"symbols\":[\"@iconName\",\"@title\"],\"statements\":[[1,[30,[36,0],[[32,1]],[[\"class\",\"width\",\"height\"],[\"visualization-settings-segmented-control-item-icon\",22,22]]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"visualization-settings-segmented-control-item-title\"],[12],[2,\"\\n  \"],[1,[32,2]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"svg-jar\"]}","meta":{"moduleName":"clarify/components/visualization-settings/type-segmented-control-item.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});