define("clarify/components/timeline-canvas/visualizations/layers/y-labels", ["exports", "canvas/components/layer", "clarify/drag-images/item"], function (_exports, _layer, _item) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const NUMBER_FORMATTER = new Intl.NumberFormat([], {
    maximumFractionDigits: 2
  });
  const CONTENT_INSET = {
    top: 9,
    bottom: 9
  };
  const HEIGHT = 16;
  let YLabels = (_dec = Ember.computed('context'), (_class = class YLabels extends _layer.default {
    get typesetter() {
      let typesetter = (0, _item.createTypesetter)(this.context, {
        fill: '#8699BD',
        font: '13px roboto'
      });
      typesetter.wrapper.textTrimming('none');
      return typesetter;
    }

    render() {
      let context = this.context;
      let values = [{
        value: this.args.rangeMax,
        position: CONTENT_INSET.top
      }, {
        value: (this.args.rangeMin + this.args.rangeMax) / 2,
        position: CONTENT_INSET.top + (this.args.height - CONTENT_INSET.top - CONTENT_INSET.bottom - HEIGHT) / 2
      }, {
        value: this.args.rangeMin,
        position: this.args.height - CONTENT_INSET.bottom - HEIGHT
      }];

      for (let {
        value,
        position
      } of values) {
        context.save();
        context.beginPath();
        context.translate(this.args.width - this.args.inset[1], position);
        this.renderNumber(value);
        context.restore();
      }
    }

    renderNumber(value) {
      let formatted = NUMBER_FORMATTER.format(value);
      let size = this.typesetter.measurer.measure(formatted);
      let width = Math.ceil(size.width) + 4;
      this.context.rect(-width, 0, width, 16);
      this.context.fillStyle = 'rgba(10, 20, 34, 0.85)';
      this.context.fill();
      this.context.translate(-width + 2, 3);
      this.typesetter.write(formatted, Math.ceil(size.width), size.height);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "typesetter", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "typesetter"), _class.prototype)), _class));
  _exports.default = YLabels;
});