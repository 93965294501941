define("clarify/serializers/rest-layer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const EmbeddedRESTSerializer = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin);

  class RestLayerSerializer extends EmbeddedRESTSerializer {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "root", void 0);

      _defineProperty(this, "attrs", {});
    }

    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      return super.normalizeResponse(store, primaryModelClass, {
        [this.root]: payload.data,
        meta: payload.meta
      }, id, requestType);
    }

    serializeIntoHash(hash, _typeClass, snapshot, options) {
      Object.assign(hash, this.serialize(snapshot, options));
    }

  }

  _exports.default = RestLayerSerializer;
});