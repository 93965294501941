define("clarify/utilities/create-tree", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createTree = createTree;
  _exports.Tree = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Tree {
    constructor(children, cache) {
      _defineProperty(this, "children", void 0);

      _defineProperty(this, "cache", void 0);

      this.children = children;
      this.cache = cache;
    }

    get(id) {
      return this.cache.get(id);
    }

    static fromItems(items, id, parentId) {
      let cache = new Map();

      for (let item of items) {
        cache.set(id(item), {
          item,
          id: id(item),
          parentId: parentId(item),
          children: []
        });
      }

      for (let [, value] of cache) {
        if (value.parentId) {
          let parent = cache.get(value.parentId);

          if (parent) {
            parent.children.push(value);
          }
        }
      }

      let treeItems = Array.from(cache.values()).filter(item => item.parentId === null);
      return new Tree(treeItems, cache);
    }

  }

  _exports.Tree = Tree;

  function createTree(items, id, parentId) {
    let cache = new Map();

    for (let item of items) {
      cache.set(id(item), {
        item,
        id: id(item),
        parentId: parentId(item),
        children: []
      });
    }

    for (let [, value] of cache) {
      if (value.parentId) {
        let parent = cache.get(value.parentId);

        if (parent) {
          parent.children.push(value);
        }
      }
    }

    return Array.from(cache.values()).filter(item => item.parentId === null);
  }
});