define("clarify/templates/organizations/show/timelines/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2WIYYy8B",
    "block": "{\"symbols\":[],\"statements\":[[8,\"app-header\",[],[[\"@style\"],[\"dark\"]],null],[2,\" \"],[8,\"organizations/show/timelines/show\",[],[[\"@timeline\",\"@layout\",\"@timeRange\",\"@updateTimeRange\",\"@visibleDomainChanged\",\"@itemMenuVisible\",\"@setItemMenuVisible\",\"@domainFrom\",\"@domainTo\"],[[32,0,[\"timeline\"]],[32,0,[\"layout\"]],[32,0,[\"timeRange\"]],[30,[36,0],[[32,0,[\"updateTimeRange\"]]],null],[30,[36,0],[[32,0,[\"visibleDomainChanged\"]]],null],[32,0,[\"signals\"]],[30,[36,0],[[32,0,[\"setItemMenuVisible\"]]],null],[32,0,[\"from\"]],[32,0,[\"to\"]]]],null],[2,\" \"],[1,[30,[36,1],[\"right-anthor\"],[[\"enableGrowth\",\"class\"],[false,\"right-sidebar\"]]]],[2,\" \"],[1,[30,[36,1],[\"bottom-anthor\"],[[\"enableGrowth\",\"class\"],[false,\"bottom-anthor\"]]]],[2,\" \"],[1,[30,[36,3],[[30,[36,2],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"fn\",\"liquid-outlet\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "clarify/templates/organizations/show/timelines/show.hbs"
    }
  });

  _exports.default = _default;
});