define("clarify/components/comment-feed/index", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="comment-feed" ...attributes>
    {{#each @cells as |cell|}}
      {{#if (eq cell.type "header")}}
        <Comment::Header @user={{cell.user}} @date={{cell.date}} />
      {{/if}}
      {{#if (eq cell.type "comment")}}
        <Comment @maxImageSize={{@maxImageSize}} @comment={{cell.comment}} @enableDelete={{@enableDelete}} />
      {{/if}}
      {{#if (eq cell.type "date-header")}}
        <CommentFeed::DateHeader @date={{cell.date}} />
      {{/if}}
    {{/each}}
  </div>
  */
  {"id":"3Jsc0LyU","block":"{\"symbols\":[\"cell\",\"@maxImageSize\",\"@enableDelete\",\"&attrs\",\"@cells\"],\"statements\":[[11,\"div\"],[24,0,\"comment-feed\"],[17,4],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[30,[36,0],[[32,1,[\"type\"]],\"header\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"comment/header\",[],[[\"@user\",\"@date\"],[[32,1,[\"user\"]],[32,1,[\"date\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],[[30,[36,0],[[32,1,[\"type\"]],\"comment\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"comment\",[],[[\"@maxImageSize\",\"@comment\",\"@enableDelete\"],[[32,2],[32,1,[\"comment\"]],[32,3]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],[[30,[36,0],[[32,1,[\"type\"]],\"date-header\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"comment-feed/date-header\",[],[[\"@date\"],[[32,1,[\"date\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\",\"-track-array\",\"each\"]}","meta":{"moduleName":"clarify/components/comment-feed/index.hbs"}});

  class CommentFeed extends _component.default {}

  _exports.default = CommentFeed;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CommentFeed);
});