define("clarify/helpers/delete-timeline-alert", ["exports", "clarify/helpers/alert"], function (_exports, _alert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DeleteTimelineAlert extends _alert.default {
    compute(_parameters, hash) {
      return event => {
        var _hash$target;

        let {
          timeline,
          action
        } = hash;
        let deleteButton = timeline.name ? this.intl.t('delete-timeline-alert.delete-button.named', {
          name: timeline.name
        }) : this.intl.t('delete-timeline-alert.delete-button.untitled');
        this.showAlert({
          title: this.intl.t('delete-timeline-alert.title', timeline),
          message: this.intl.t('delete-timeline-alert.message'),
          primaryButton: {
            title: deleteButton,
            style: 'destructive',
            action: action
          }
        }, (_hash$target = hash.target) !== null && _hash$target !== void 0 ? _hash$target : event === null || event === void 0 ? void 0 : event.currentTarget);
      };
    }

  }

  _exports.default = DeleteTimelineAlert;
});