define("clarify/components/visualization-item-icon/status", ["exports", "@glimmer/component", "clarify/colors"], function (_exports, _component, _colors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg" ...attributes>
    <rect width="6" height="6" fill={{get this.colors "first"}}></rect>
    <rect x="3" width="3" height="6" fill={{get this.colors "second"}}></rect>
  </svg>
  */
  {"id":"QZsoXnhN","block":"{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"svg\"],[24,\"width\",\"6\"],[24,\"height\",\"6\"],[24,\"viewBox\",\"0 0 6 6\"],[24,\"fill\",\"none\"],[24,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[17,1],[12],[2,\"\\n  \"],[10,\"rect\"],[14,\"width\",\"6\"],[14,\"height\",\"6\"],[15,\"fill\",[30,[36,0],[[32,0,[\"colors\"]],\"first\"],null]],[12],[13],[2,\"\\n  \"],[10,\"rect\"],[14,\"x\",\"3\"],[14,\"width\",\"3\"],[14,\"height\",\"6\"],[15,\"fill\",[30,[36,0],[[32,0,[\"colors\"]],\"second\"],null]],[12],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"get\"]}","meta":{"moduleName":"clarify/components/visualization-item-icon/status.hbs"}});

  let VisualizationItemIconStatus = (_dec = Ember.computed(), (_class = class VisualizationItemIconStatus extends _component.default {
    get colors() {
      let enumCount = this.args.visualizationItem.item.enumValues ? Object.keys(this.args.visualizationItem.item.enumValues).length : 0;
      return {
        first: _colors.graphColors.get((0, _colors.colorInColorSet)(_colors.ColorSetName.oceanBlue, enumCount, 0)),
        second: _colors.graphColors.get((0, _colors.colorInColorSet)(_colors.ColorSetName.oceanBlue, enumCount, 1))
      };
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "colors", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "colors"), _class.prototype)), _class));
  _exports.default = VisualizationItemIconStatus;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, VisualizationItemIconStatus);
});