define("clarify/components/dropdown-list/item/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <DropdownList::Item::default
    @highlighted={{@highlighted}}
    @item={{@item}}
    @leading={{component "avatar" user=@item.value}}
    ...attributes
  />
  */
  {"id":"V2KZtCCa","block":"{\"symbols\":[\"@highlighted\",\"@item\",\"&attrs\"],\"statements\":[[8,\"dropdown-list/item/default\",[[17,3]],[[\"@highlighted\",\"@item\",\"@leading\"],[[32,1],[32,2],[30,[36,0],[\"avatar\"],[[\"user\"],[[32,2,[\"value\"]]]]]]],null]],\"hasEval\":false,\"upvars\":[\"component\"]}","meta":{"moduleName":"clarify/components/dropdown-list/item/user.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});