define("clarify/components/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="loading-screen" data-theme={{@theme}} ...attributes>
    {{svg-jar "clarify-application-loading" class="loading-screen-logo" width=180 height=120}}
    <div class="loading-screen-title">
      {{@title}}
    </div>
  </div>
  */
  {"id":"FjSr3JVz","block":"{\"symbols\":[\"@theme\",\"&attrs\",\"@title\"],\"statements\":[[11,\"div\"],[24,0,\"loading-screen\"],[16,\"data-theme\",[32,1]],[17,2],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"clarify-application-loading\"],[[\"class\",\"width\",\"height\"],[\"loading-screen-logo\",180,120]]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"loading-screen-title\"],[12],[2,\"\\n    \"],[1,[32,3]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"svg-jar\"]}","meta":{"moduleName":"clarify/components/loading.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});