define("clarify/components/routes/organizations/new/-demo-data-card", ["exports", "@glimmer/component", "ui/helpers/normalize-key-codes"], function (_exports, _component, _normalizeKeyCodes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#let (uuid) as |id|}}
    <div
      class="demo-data-card"
      role="radio"
      aria-labelledby="{{id}}-content"
      aria-describedby="{{id}}-description"
      aria-checked={{if (eq @field.value @value) "true" "false"}}
      tabindex="0"
      ...attributes
      {{on "click" (fn this.select)}}
      {{on "keydown" (fn this.handleKeydown)}}
    >
      {{#let this.image as |image|}}
        <img
          class="demo-data-card--image"
          height="134"
          role="presentation"
          src={{image.normal}}
          srcset="{{image.retina}} 2x"
        />
      {{/let}}
      <div class="demo-data-card--content">
        <h2 id="{{id}}-content" class="demo-data-card--title">
          {{@title}}
        </h2>
        <p id="{{id}}-description" class="demo-data-card--description">
          {{@description}}
        </p>
      </div>
      {{svg-jar "#onboarding-checkmark-24" class="demo-data-card--checkmark" width=24 height=24 aria-hidden="true"}}
    </div>
  {{/let}}
  */
  {"id":"E/pJBH9Q","block":"{\"symbols\":[\"id\",\"image\",\"@value\",\"@field\",\"&attrs\",\"@title\",\"@description\"],\"statements\":[[6,[37,4],[[30,[36,6],null,null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"div\"],[24,0,\"demo-data-card\"],[24,\"role\",\"radio\"],[16,\"aria-labelledby\",[31,[[32,1],\"-content\"]]],[16,\"aria-describedby\",[31,[[32,1],\"-description\"]]],[16,\"aria-checked\",[30,[36,1],[[30,[36,0],[[32,4,[\"value\"]],[32,3]],null],\"true\",\"false\"],null]],[24,\"tabindex\",\"0\"],[17,5],[4,[38,3],[\"click\",[30,[36,2],[[32,0,[\"select\"]]],null]],null],[4,[38,3],[\"keydown\",[30,[36,2],[[32,0,[\"handleKeydown\"]]],null]],null],[12],[2,\"\\n\"],[6,[37,4],[[32,0,[\"image\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"img\"],[14,0,\"demo-data-card--image\"],[14,\"height\",\"134\"],[14,\"role\",\"presentation\"],[15,\"src\",[32,2,[\"normal\"]]],[15,\"srcset\",[31,[[32,2,[\"retina\"]],\" 2x\"]]],[12],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"    \"],[10,\"div\"],[14,0,\"demo-data-card--content\"],[12],[2,\"\\n      \"],[10,\"h2\"],[15,1,[31,[[32,1],\"-content\"]]],[14,0,\"demo-data-card--title\"],[12],[2,\"\\n        \"],[1,[32,6]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"p\"],[15,1,[31,[[32,1],\"-description\"]]],[14,0,\"demo-data-card--description\"],[12],[2,\"\\n        \"],[1,[32,7]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[1,[30,[36,5],[\"#onboarding-checkmark-24\"],[[\"class\",\"width\",\"height\",\"aria-hidden\"],[\"demo-data-card--checkmark\",24,24,\"true\"]]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\",\"fn\",\"on\",\"let\",\"svg-jar\",\"uuid\"]}","meta":{"moduleName":"clarify/components/routes/organizations/new/-demo-data-card.hbs"}});

  let DemoDataCard = (_dec = Ember._action, _dec2 = Ember._action, (_class = class DemoDataCard extends _component.default {
    select() {
      this.args.field.value = this.args.value;
    }

    handleKeydown(event) {
      let key = (0, _normalizeKeyCodes.default)(event);

      switch (key) {
        case _normalizeKeyCodes.Key.enter:
        case _normalizeKeyCodes.Key.space:
          event.preventDefault();
          this.select();
          break;
      }
    }

    get image() {
      switch (this.args.industryType) {
        case 'aquaculture':
          return {
            normal: '/assets/demo-data/aquaculture.jpg',
            retina: '/assets/demo-data/aquaculture@2x.jpg'
          };

        case 'manufacturing':
          return {
            normal: '/assets/demo-data/manufacturing.jpg',
            retina: '/assets/demo-data/manufacturing@2x.jpg'
          };

        case 'maritime':
          return {
            normal: '/assets/demo-data/maritime.jpg',
            retina: '/assets/demo-data/maritime@2x.jpg'
          };

        case 'other':
        default:
          return {
            normal: '/assets/demo-data/other.jpg',
            retina: '/assets/demo-data/other@2x.jpg'
          };
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "select", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "select"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleKeydown", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "handleKeydown"), _class.prototype)), _class));
  _exports.default = DemoDataCard;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DemoDataCard);
});