define("clarify/router", ["exports", "clarify/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Router extends Ember.Router {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "location", _environment.default.locationType);

      _defineProperty(this, "rootURL", _environment.default.rootURL);
    }

  }

  function mountItemsModal(routerDsl) {
    routerDsl.route('items', function () {
      this.route('show', {
        path: '/:itemId'
      });
    });
  }

  Router.map(function () {
    this.route('setup');
    this.route('resend', {
      path: '/signup/resend'
    });
    this.route('invitations', function () {
      this.route('show', {
        path: '/:invitationId'
      });
    });
    this.route('organizations', function () {
      this.route('new');
      this.route('show', {
        path: '/:organizationId'
      }, function () {
        this.route('creating');
        this.route('account', function () {
          this.mount('account', {
            path: '/'
          });
        });
        this.route('settings-non-admin');
        this.route('settings', function () {
          this.mount('admin', {
            path: '/'
          });
        });
        this.route('timelines', function () {
          this.route('index', {
            path: '/'
          }, function () {
            mountItemsModal(this);
          });
          this.route('show', {
            path: '/:id'
          }, function () {
            mountItemsModal(this);
            this.route('export', function () {});
            this.route('settings', function () {});
            this.route('visualizations', function () {
              this.route('show', {
                path: '/:visualizationId'
              });
            });
            this.route('stats', function () {
              this.route('show', {
                path: '/:visualizationId'
              });
            });
            this.route('threads', function () {
              this.route('show', {
                path: '/:threadId'
              });
              this.route('new');
            });
            this.route('activity', function () {});
            this.route('groups', function () {
              this.route('show', {
                path: '/:groupId'
              });
            });
          });
          this.route('new');
          this.route('redirect-demo', {
            path: '/demo'
          });
        });
        this.route('items', {
          path: '/items'
        }, function () {
          this.route('index', {
            path: '/'
          }, function () {
            this.route('show', {
              path: '/:itemId'
            });
          });
        });
        this.route('activities');
      });
    });
    this.route('callback');
    this.route('login');
    this.route('logout');
    this.route('application-browser-error');
    this.route('application-mobile-upsell');
    this.route('redirect-timelines-show', {
      path: '/timelines/:id'
    });
    this.route('redirect-timelines', {
      path: '/timelines'
    });
    this.route('404', {
      path: '/*'
    });
    this.mount('account');
  });
  var _default = Router;
  _exports.default = _default;
});