define("clarify/components/settings-error/index", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="visualization-settings">
    <SettingsHeader @title="" @onClose={{fn this.close}} @theme="dark" />
    <Error @theme="grey" @title={{@title}} @description={{@description}} />
  </div>
  */
  {"id":"4BwoTFg9","block":"{\"symbols\":[\"@title\",\"@description\"],\"statements\":[[10,\"div\"],[14,0,\"visualization-settings\"],[12],[2,\"\\n  \"],[8,\"settings-header\",[],[[\"@title\",\"@onClose\",\"@theme\"],[\"\",[30,[36,0],[[32,0,[\"close\"]]],null],\"dark\"]],null],[2,\"\\n  \"],[8,\"error\",[],[[\"@theme\",\"@title\",\"@description\"],[\"grey\",[32,1],[32,2]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"fn\"]}","meta":{"moduleName":"clarify/components/settings-error/index.hbs"}});

  let SettingsError = (_dec = Ember.inject.service, _dec2 = Ember._action, (_class = (_temp = class SettingsError extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);
    }

    close() {
      this.router.transitionTo('organizations.show.timelines.show');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "close", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype)), _class));
  _exports.default = SettingsError;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SettingsError);
});