define("clarify/errors/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.RouteErrorType = void 0;
  let RouteErrorType;
  _exports.RouteErrorType = RouteErrorType;

  (function (RouteErrorType) {
    RouteErrorType[RouteErrorType["notFound"] = 0] = "notFound";
    RouteErrorType[RouteErrorType["noAccess"] = 1] = "noAccess";
  })(RouteErrorType || (_exports.RouteErrorType = RouteErrorType = {}));

  class RouteError extends Error {
    constructor(errorType) {
      super(`Route error: ${errorType}`);
      this.errorType = errorType;
      Object.setPrototypeOf(this, RouteError.prototype);
      this.name = 'RouteError';
    }

  }

  _exports.default = RouteError;
});