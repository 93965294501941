define("clarify/components/timeline-card/comment-preview/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="timeline-comment-loading">
    <div class="timeline-comment-container">
      <div class="timeline-comment-loading-line"></div>
      <div class="timeline-comment-loading-line"></div>
    </div>
    <div class="timeline-comment-loading-avatar"></div>
  </div>
  */
  {"id":"Pd28KFbL","block":"{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"timeline-comment-loading\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"timeline-comment-container\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"timeline-comment-loading-line\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"timeline-comment-loading-line\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"timeline-comment-loading-avatar\"],[12],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}","meta":{"moduleName":"clarify/components/timeline-card/comment-preview/loading.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});