define("clarify/models/attachment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let imageMimeTypes = new Set(['image/gif', 'image/jpeg', 'image/png']);
  let Attachment = (_dec = Ember.computed('name'), (_class = (_temp = class Attachment extends Ember.Object {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "contentLength", void 0);

      _defineProperty(this, "contentType", void 0);

      _defineProperty(this, "downloadURL", void 0);

      _defineProperty(this, "previewURL", void 0);

      _defineProperty(this, "name", void 0);

      _defineProperty(this, "dimensions", null);
    }

    get displayName() {
      let components = this.name.split('/');
      return components[components.length - 1];
    }

    get isImage() {
      return imageMimeTypes.has(this.contentType);
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "displayName", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "displayName"), _class.prototype)), _class));
  _exports.default = Attachment;
});