define("clarify/adapters/group", ["exports", "clarify/adapters/meta"], function (_exports, _meta) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Group extends _meta.default {
    fields(_requestType) {
      return ['id', 'name', 'organization', 'createdAt', 'createdBy', 'updatedAt', 'updatedBy', 'members'];
    }

    urlForQuery(query, _modelName) {
      let path = this.buildURL('timeline', query.timeline);
      return `${path}/layouts`;
    }

    urlForUpdateRecord(id, _modelName, snapshot) {
      let typedSnapshot = snapshot;
      let timeline = typedSnapshot.belongsTo('timeline', {
        id: true
      });
      let path = this.buildURL('timeline', timeline);
      return `${path}/layouts/${id}`;
    }

    urlForCreateRecord(_modelName, snapshot) {
      let typedSnapshot = snapshot;
      const timeline = typedSnapshot.belongsTo('timeline', {
        id: true
      });
      return `${this.host}/${this.namespace}/timelines/${timeline}/layouts`;
    }

  }

  _exports.default = Group;
});