define("clarify/components/organizations/show/items/details-entry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="items-details-entry" data-size={{or @size "small"}}>
    <div class="items-details-entry-title">
      {{@title}}
    </div>
    <div class="items-details-entry-value">
      {{or @value "–"}}
    </div>
    {{#if @subTitle}}
      <div class="items-details-entry-sub-title">
        {{@subTitle}}
      </div>
    {{/if}}
  </div>
  */
  {"id":"7+3wJBpu","block":"{\"symbols\":[\"@subTitle\",\"@size\",\"@title\",\"@value\"],\"statements\":[[10,\"div\"],[14,0,\"items-details-entry\"],[15,\"data-size\",[30,[36,0],[[32,2],\"small\"],null]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"items-details-entry-title\"],[12],[2,\"\\n    \"],[1,[32,3]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"items-details-entry-value\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[[32,4],\"–\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,1],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"items-details-entry-sub-title\"],[12],[2,\"\\n      \"],[1,[32,1]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"or\",\"if\"]}","meta":{"moduleName":"clarify/components/organizations/show/items/details-entry.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});