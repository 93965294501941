define("clarify/utilities/zoom-levels", ["exports", "d3-scale", "d3-time", "date-fns"], function (_exports, _d3Scale, _d3Time, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.zoomLevelForDomain = zoomLevelForDomain;
  _exports.zoomLevelForPixelsPerSecond = zoomLevelForPixelsPerSecond;
  _exports.DEFAULT_ZOOM = _exports.LevelTwentySlot = _exports.LevelNineteenSlot = _exports.LevelEighteenSlot = _exports.LevelSeventeenSlot = _exports.LevelSixteenSlot = _exports.LevelFifteenSlot = _exports.LevelFourteenSlot = _exports.LevelThirteenSlot = _exports.LevelTwelveSlot = _exports.LevelElevenSlot = _exports.LevelTenSlot = _exports.LevelNineSlot = _exports.LevelEightSlot = _exports.LevelSevenSlot = _exports.LevelSixSlot = _exports.LevelFiveSlot = _exports.LevelFourSlot = _exports.LevelThreeSlot = _exports.LevelTwoSlot = _exports.LevelOneSlot = _exports.Slot = _exports.TimeRangeTimeFormat = _exports.RulerDateFormat = _exports.PresentTimeFormat = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  let PresentTimeFormat;
  _exports.PresentTimeFormat = PresentTimeFormat;

  (function (PresentTimeFormat) {
    PresentTimeFormat[PresentTimeFormat["timestamp"] = 0] = "timestamp";
    PresentTimeFormat[PresentTimeFormat["date"] = 1] = "date";
  })(PresentTimeFormat || (_exports.PresentTimeFormat = PresentTimeFormat = {}));

  let RulerDateFormat;
  _exports.RulerDateFormat = RulerDateFormat;

  (function (RulerDateFormat) {
    RulerDateFormat[RulerDateFormat["millisecond"] = 0] = "millisecond";
    RulerDateFormat[RulerDateFormat["seconds"] = 1] = "seconds";
    RulerDateFormat[RulerDateFormat["minutes"] = 2] = "minutes";
    RulerDateFormat[RulerDateFormat["dateTime"] = 3] = "dateTime";
    RulerDateFormat[RulerDateFormat["date"] = 4] = "date";
  })(RulerDateFormat || (_exports.RulerDateFormat = RulerDateFormat = {}));

  let TimeRangeTimeFormat;
  _exports.TimeRangeTimeFormat = TimeRangeTimeFormat;

  (function (TimeRangeTimeFormat) {
    TimeRangeTimeFormat[TimeRangeTimeFormat["short"] = 0] = "short";
    TimeRangeTimeFormat[TimeRangeTimeFormat["full"] = 1] = "full";
  })(TimeRangeTimeFormat || (_exports.TimeRangeTimeFormat = TimeRangeTimeFormat = {}));

  class Slot {
    constructor(from, to) {
      this.from = from;
      this.to = to;
    }

    static generate(from, to) {
      let fromDate = this.timeInterval.floor(from);
      let toDate = this.timeInterval.ceil(to);
      let range = this.timeInterval.range(fromDate, toDate);
      let ids = range.map((date, index) => {
        let next = range[index + 1] || toDate;
        return new this(date, next);
      });
      return ids;
    }

    get key() {
      return `${this.from.getTime()}-${this.to.getTime()}`;
    }

    get parent() {
      let parentGenerator = this.constructor.parentGenerator;

      if (parentGenerator) {
        let parent = parentGenerator();
        let from = parent.timeInterval.floor(this.from);
        let to = parent.timeInterval.ceil(this.to);
        return new parent(from, to);
      }

      return null;
    }

    children() {
      let childGenerator = this.constructor.childGenerator;

      if (childGenerator) {
        return childGenerator().generate(this.from, this.to);
      }

      return [];
    }

    isEqual(slot) {
      return this.from.getTime() === slot.from.getTime() && this.to.getTime() === slot.to.getTime();
    }

  }

  _exports.Slot = Slot;

  _defineProperty(Slot, "timeInterval", void 0);

  _defineProperty(Slot, "parentGenerator", null);

  _defineProperty(Slot, "childGenerator", null);

  class LevelOneSlot extends Slot {}

  _exports.LevelOneSlot = LevelOneSlot;

  _defineProperty(LevelOneSlot, "timeInterval", _d3Time.timeSecond.every(30));

  _defineProperty(LevelOneSlot, "childGenerator", null);

  _defineProperty(LevelOneSlot, "parentGenerator", () => LevelTwoSlot);

  class LevelTwoSlot extends Slot {}

  _exports.LevelTwoSlot = LevelTwoSlot;

  _defineProperty(LevelTwoSlot, "timeInterval", _d3Time.timeMinute.every(1));

  _defineProperty(LevelTwoSlot, "childGenerator", () => LevelOneSlot);

  _defineProperty(LevelTwoSlot, "parentGenerator", () => LevelThreeSlot);

  class LevelThreeSlot extends Slot {}

  _exports.LevelThreeSlot = LevelThreeSlot;

  _defineProperty(LevelThreeSlot, "timeInterval", _d3Time.timeMinute.every(3));

  _defineProperty(LevelThreeSlot, "childGenerator", () => LevelTwoSlot);

  _defineProperty(LevelThreeSlot, "parentGenerator", () => LevelFourSlot);

  class LevelFourSlot extends Slot {}

  _exports.LevelFourSlot = LevelFourSlot;

  _defineProperty(LevelFourSlot, "timeInterval", _d3Time.timeMinute.every(6));

  _defineProperty(LevelFourSlot, "childGenerator", () => LevelThreeSlot);

  _defineProperty(LevelFourSlot, "parentGenerator", () => LevelFiveSlot);

  class LevelFiveSlot extends Slot {}

  _exports.LevelFiveSlot = LevelFiveSlot;

  _defineProperty(LevelFiveSlot, "timeInterval", _d3Time.timeMinute.every(30));

  _defineProperty(LevelFiveSlot, "childGenerator", () => LevelFourSlot);

  _defineProperty(LevelFiveSlot, "parentGenerator", () => LevelSixSlot);

  class LevelSixSlot extends Slot {}

  _exports.LevelSixSlot = LevelSixSlot;

  _defineProperty(LevelSixSlot, "timeInterval", _d3Time.timeHour.every(1));

  _defineProperty(LevelSixSlot, "childGenerator", () => LevelFiveSlot);

  _defineProperty(LevelSixSlot, "parentGenerator", () => LevelSevenSlot);

  class LevelSevenSlot extends Slot {}

  _exports.LevelSevenSlot = LevelSevenSlot;

  _defineProperty(LevelSevenSlot, "timeInterval", _d3Time.timeHour.every(2));

  _defineProperty(LevelSevenSlot, "childGenerator", () => LevelSixSlot);

  _defineProperty(LevelSevenSlot, "parentGenerator", () => LevelEightSlot);

  class LevelEightSlot extends Slot {}

  _exports.LevelEightSlot = LevelEightSlot;

  _defineProperty(LevelEightSlot, "timeInterval", _d3Time.timeHour.every(6));

  _defineProperty(LevelEightSlot, "childGenerator", () => LevelSevenSlot);

  _defineProperty(LevelEightSlot, "parentGenerator", () => LevelNineSlot);

  class LevelNineSlot extends Slot {}

  _exports.LevelNineSlot = LevelNineSlot;

  _defineProperty(LevelNineSlot, "timeInterval", _d3Time.timeDay.every(1));

  _defineProperty(LevelNineSlot, "childGenerator", () => LevelEightSlot);

  _defineProperty(LevelNineSlot, "parentGenerator", () => LevelTenSlot);

  class LevelTenSlot extends Slot {}

  _exports.LevelTenSlot = LevelTenSlot;

  _defineProperty(LevelTenSlot, "timeInterval", _d3Time.timeDay.every(2));

  _defineProperty(LevelTenSlot, "childGenerator", () => LevelNineSlot);

  _defineProperty(LevelTenSlot, "parentGenerator", () => LevelElevenSlot);

  class LevelElevenSlot extends Slot {}

  _exports.LevelElevenSlot = LevelElevenSlot;

  _defineProperty(LevelElevenSlot, "timeInterval", _d3Time.timeDay.every(4));

  _defineProperty(LevelElevenSlot, "childGenerator", () => LevelTenSlot);

  _defineProperty(LevelElevenSlot, "parentGenerator", () => LevelTwelveSlot);

  class LevelTwelveSlot extends Slot {}

  _exports.LevelTwelveSlot = LevelTwelveSlot;

  _defineProperty(LevelTwelveSlot, "timeInterval", _d3Time.timeDay.every(8));

  _defineProperty(LevelTwelveSlot, "childGenerator", () => LevelElevenSlot);

  _defineProperty(LevelTwelveSlot, "parentGenerator", () => LevelThirteenSlot);

  class LevelThirteenSlot extends Slot {}

  _exports.LevelThirteenSlot = LevelThirteenSlot;

  _defineProperty(LevelThirteenSlot, "timeInterval", _d3Time.timeDay.every(16));

  _defineProperty(LevelThirteenSlot, "childGenerator", () => LevelTwelveSlot);

  _defineProperty(LevelThirteenSlot, "parentGenerator", () => LevelFourteenSlot);

  class LevelFourteenSlot extends Slot {}

  _exports.LevelFourteenSlot = LevelFourteenSlot;

  _defineProperty(LevelFourteenSlot, "timeInterval", _d3Time.timeMonth.every(1));

  _defineProperty(LevelFourteenSlot, "childGenerator", () => LevelThirteenSlot);

  _defineProperty(LevelFourteenSlot, "parentGenerator", () => LevelFifteenSlot);

  class LevelFifteenSlot extends Slot {}

  _exports.LevelFifteenSlot = LevelFifteenSlot;

  _defineProperty(LevelFifteenSlot, "timeInterval", _d3Time.timeMonth.every(2));

  _defineProperty(LevelFifteenSlot, "childGenerator", () => LevelFourteenSlot);

  _defineProperty(LevelFifteenSlot, "parentGenerator", () => LevelSixteenSlot);

  class LevelSixteenSlot extends Slot {}

  _exports.LevelSixteenSlot = LevelSixteenSlot;

  _defineProperty(LevelSixteenSlot, "timeInterval", _d3Time.timeMonth.every(2));

  _defineProperty(LevelSixteenSlot, "childGenerator", () => LevelFifteenSlot);

  _defineProperty(LevelSixteenSlot, "parentGenerator", () => LevelSeventeenSlot);

  class LevelSeventeenSlot extends Slot {}

  _exports.LevelSeventeenSlot = LevelSeventeenSlot;

  _defineProperty(LevelSeventeenSlot, "timeInterval", _d3Time.timeMonth.every(6));

  _defineProperty(LevelSeventeenSlot, "childGenerator", () => LevelSixteenSlot);

  _defineProperty(LevelSeventeenSlot, "parentGenerator", () => LevelEighteenSlot);

  class LevelEighteenSlot extends Slot {}

  _exports.LevelEighteenSlot = LevelEighteenSlot;

  _defineProperty(LevelEighteenSlot, "timeInterval", _d3Time.timeYear.every(1));

  _defineProperty(LevelEighteenSlot, "childGenerator", () => LevelSeventeenSlot);

  _defineProperty(LevelEighteenSlot, "parentGenerator", () => LevelNineteenSlot);

  class LevelNineteenSlot extends Slot {}

  _exports.LevelNineteenSlot = LevelNineteenSlot;

  _defineProperty(LevelNineteenSlot, "timeInterval", _d3Time.timeYear.every(1));

  _defineProperty(LevelNineteenSlot, "childGenerator", () => LevelEighteenSlot);

  _defineProperty(LevelNineteenSlot, "parentGenerator", () => LevelTwentySlot);

  class LevelTwentySlot extends Slot {}

  _exports.LevelTwentySlot = LevelTwentySlot;

  _defineProperty(LevelTwentySlot, "timeInterval", _d3Time.timeYear.every(1));

  _defineProperty(LevelTwentySlot, "childGenerator", () => LevelNineteenSlot);

  _defineProperty(LevelTwentySlot, "parentGenerator", null);

  var ThreadClusterLimit;

  (function (ThreadClusterLimit) {
    ThreadClusterLimit[ThreadClusterLimit["one"] = 250] = "one";
    ThreadClusterLimit[ThreadClusterLimit["two"] = 500] = "two";
    ThreadClusterLimit[ThreadClusterLimit["three"] = 1000] = "three";
    ThreadClusterLimit[ThreadClusterLimit["four"] = 2000] = "four";
    ThreadClusterLimit[ThreadClusterLimit["five"] = 5000] = "five";
    ThreadClusterLimit[ThreadClusterLimit["six"] = 15000] = "six";
    ThreadClusterLimit[ThreadClusterLimit["seven"] = 30000] = "seven";
    ThreadClusterLimit[ThreadClusterLimit["eight"] = 60000] = "eight";
    ThreadClusterLimit[ThreadClusterLimit["nine"] = 180000] = "nine";
    ThreadClusterLimit[ThreadClusterLimit["ten"] = 900000] = "ten";
    ThreadClusterLimit[ThreadClusterLimit["eleven"] = 1800000] = "eleven";
    ThreadClusterLimit[ThreadClusterLimit["twelve"] = 3600000] = "twelve";
    ThreadClusterLimit[ThreadClusterLimit["thirteen"] = 7200000] = "thirteen";
    ThreadClusterLimit[ThreadClusterLimit["fourteen"] = 21600000] = "fourteen";
    ThreadClusterLimit[ThreadClusterLimit["fifteen"] = 21600000] = "fifteen";
    ThreadClusterLimit[ThreadClusterLimit["sixteen"] = 43200000] = "sixteen";
    ThreadClusterLimit[ThreadClusterLimit["seventeen"] = 86400000] = "seventeen";
    ThreadClusterLimit[ThreadClusterLimit["eighteen"] = 172800000] = "eighteen";
    ThreadClusterLimit[ThreadClusterLimit["nineteen"] = 1296000000] = "nineteen";
    ThreadClusterLimit[ThreadClusterLimit["twenty"] = 1296000000] = "twenty";
  })(ThreadClusterLimit || (ThreadClusterLimit = {}));

  function headlineDaysFromScale(scale, locale) {
    const domain = scale.domain();
    const first = domain[0];
    const last = domain[domain.length - 1];
    return (0, _d3Scale.scaleTime)().domain([(0, _dateFns.startOfDay)(first), (0, _dateFns.startOfDay)(last)]).nice().ticks(_d3Time.timeDay.every(1)).map(labelTick(locale, locale.current.ticks.date.full));
  }

  function headlineYearsFromScale(scale, locale) {
    const domain = scale.domain();
    const first = domain[0];
    const last = domain[domain.length - 1];
    return (0, _d3Scale.scaleTime)().domain([(0, _dateFns.startOfYear)(first), (0, _dateFns.startOfYear)(last)]).nice().ticks(_d3Time.timeYear.every(1)).map(labelTick(locale, locale.current.ticks.tokens.year));
  }

  const noLabelTick = date => ({
    value: date
  });

  const labelTick = (locale, formatString) => date => ({
    value: date,
    label: locale.format(date, formatString).toLocaleUpperCase()
  });

  let halfWeekInterval = (0, _d3Time.timeInterval)(date => {
    date.setHours(0, 0, 0, 0);

    if (date.getDate() >= 15) {
      date.setDate(15);
    } else {
      date.setDate(1);
    }
  }, (date, _step_) => {
    if (date.getDate() >= 15) {
      date.setMonth(date.getMonth() + 1, 1);
    } else {
      date.setDate(15);
    }
  });
  const ONE = {
    value: 1,
    presentDateFormat: PresentTimeFormat.timestamp,
    rulerDateFormat: RulerDateFormat.millisecond,
    threadClusterLimit: ThreadClusterLimit.one,
    timeRangeTimeFormat: TimeRangeTimeFormat.full,
    slot: LevelOneSlot,

    ticks(scale, locale) {
      const allTicks = scale.copy().nice(_d3Time.timeSecond).ticks(_d3Time.timeMillisecond.every(250));
      return {
        headline: headlineDaysFromScale(scale, locale),
        main: allTicks.filter(d => d.getMilliseconds() === 0).map(labelTick(locale, locale.current.ticks.time.full)),
        major: allTicks.filter(d => d.getMilliseconds() % 500 === 0).map(noLabelTick),
        minor: allTicks.map(noLabelTick)
      };
    }

  };
  const TWO = {
    value: 2,
    slot: LevelTwoSlot,
    presentDateFormat: PresentTimeFormat.timestamp,
    rulerDateFormat: RulerDateFormat.millisecond,
    threadClusterLimit: ThreadClusterLimit.two,
    timeRangeTimeFormat: TimeRangeTimeFormat.full,

    ticks(scale, locale) {
      const allTicks = scale.copy().nice(_d3Time.timeSecond).ticks(_d3Time.timeMillisecond.every(500));
      return {
        headline: headlineDaysFromScale(scale, locale),
        main: allTicks.filter(d => d.getMilliseconds() === 0 && d.getSeconds() % 2 === 0).map(labelTick(locale, locale.current.ticks.time.full)),
        major: allTicks.filter(d => d.getMilliseconds() === 0).map(noLabelTick),
        minor: allTicks.map(noLabelTick)
      };
    }

  };
  const THREE = {
    value: 3,
    slot: LevelThreeSlot,
    presentDateFormat: PresentTimeFormat.timestamp,
    rulerDateFormat: RulerDateFormat.seconds,
    threadClusterLimit: ThreadClusterLimit.three,
    timeRangeTimeFormat: TimeRangeTimeFormat.full,

    ticks(scale, locale) {
      const allTicks = scale.copy().nice(_d3Time.timeSecond).ticks(_d3Time.timeSecond.every(1));
      return {
        headline: headlineDaysFromScale(scale, locale),
        main: allTicks.filter(d => d.getSeconds() % 5 === 0).map(labelTick(locale, locale.current.ticks.time.full)),
        major: [],
        minor: allTicks.map(noLabelTick)
      };
    }

  };
  const FOUR = {
    value: 4,
    slot: LevelFourSlot,
    presentDateFormat: PresentTimeFormat.timestamp,
    rulerDateFormat: RulerDateFormat.seconds,
    threadClusterLimit: ThreadClusterLimit.four,
    timeRangeTimeFormat: TimeRangeTimeFormat.full,

    ticks(scale, locale) {
      const allTicks = scale.copy().nice(_d3Time.timeSecond).ticks(_d3Time.timeSecond.every(2));
      return {
        headline: headlineDaysFromScale(scale, locale),
        main: allTicks.filter(d => d.getSeconds() % 10 === 0).map(labelTick(locale, locale.current.ticks.time.full)),
        major: [],
        minor: allTicks.map(noLabelTick)
      };
    }

  };
  const FIVE = {
    value: 5,
    slot: LevelFiveSlot,
    presentDateFormat: PresentTimeFormat.timestamp,
    rulerDateFormat: RulerDateFormat.seconds,
    threadClusterLimit: ThreadClusterLimit.five,
    timeRangeTimeFormat: TimeRangeTimeFormat.full,

    ticks(scale, locale) {
      const allTicks = scale.copy().nice(_d3Time.timeSecond).ticks(_d3Time.timeSecond.every(5));
      return {
        headline: headlineDaysFromScale(scale, locale),
        main: allTicks.filter(d => d.getSeconds() % 30 === 0).map(labelTick(locale, locale.current.ticks.time.full)),
        major: allTicks.filter(d => d.getSeconds() % 10 === 0).map(noLabelTick),
        minor: allTicks.map(noLabelTick)
      };
    }

  };
  const SIX = {
    value: 6,
    slot: LevelSixSlot,
    presentDateFormat: PresentTimeFormat.timestamp,
    rulerDateFormat: RulerDateFormat.seconds,
    threadClusterLimit: ThreadClusterLimit.six,
    timeRangeTimeFormat: TimeRangeTimeFormat.full,

    ticks(scale, locale) {
      const allTicks = scale.copy().nice(_d3Time.timeMinute).ticks(_d3Time.timeSecond.every(15));
      return {
        headline: headlineDaysFromScale(scale, locale),
        main: allTicks.filter(d => d.getSeconds() === 0).map(labelTick(locale, locale.current.ticks.time.short)),
        major: allTicks.filter(d => d.getSeconds() % 30 === 0).map(noLabelTick),
        minor: allTicks.map(noLabelTick)
      };
    }

  };
  const SEVEN = {
    value: 7,
    slot: LevelSevenSlot,
    presentDateFormat: PresentTimeFormat.timestamp,
    rulerDateFormat: RulerDateFormat.seconds,
    threadClusterLimit: ThreadClusterLimit.seven,
    timeRangeTimeFormat: TimeRangeTimeFormat.full,

    ticks(scale, locale) {
      const allTicks = scale.copy().nice(_d3Time.timeMinute).ticks(_d3Time.timeSecond.every(30));
      return {
        headline: headlineDaysFromScale(scale, locale),
        main: allTicks.filter(d => d.getSeconds() === 0 && d.getMinutes() % 2 === 0).map(labelTick(locale, locale.current.ticks.time.short)),
        major: allTicks.filter(d => d.getSeconds() === 0).map(noLabelTick),
        minor: allTicks.map(noLabelTick)
      };
    }

  };
  const EIGHT = {
    value: 8,
    slot: LevelEightSlot,
    presentDateFormat: PresentTimeFormat.timestamp,
    rulerDateFormat: RulerDateFormat.minutes,
    threadClusterLimit: ThreadClusterLimit.eight,
    timeRangeTimeFormat: TimeRangeTimeFormat.short,

    ticks(scale, locale) {
      const allTicks = scale.copy().nice(_d3Time.timeMinute).ticks(_d3Time.timeMinute.every(1));
      return {
        headline: headlineDaysFromScale(scale, locale),
        main: allTicks.filter(d => d.getMinutes() % 5 === 0).map(labelTick(locale, locale.current.ticks.time.short)),
        major: [],
        minor: allTicks.map(noLabelTick)
      };
    }

  };
  const NINE = {
    value: 9,
    slot: LevelNineSlot,
    presentDateFormat: PresentTimeFormat.timestamp,
    rulerDateFormat: RulerDateFormat.minutes,
    threadClusterLimit: ThreadClusterLimit.nine,
    timeRangeTimeFormat: TimeRangeTimeFormat.short,

    ticks(scale, locale) {
      const allTicks = scale.copy().nice(_d3Time.timeMinute).ticks(_d3Time.timeMinute.every(3));
      return {
        headline: headlineDaysFromScale(scale, locale),
        main: allTicks.filter(d => d.getMinutes() % 15 === 0).map(labelTick(locale, locale.current.ticks.time.short)),
        major: [],
        minor: allTicks.map(noLabelTick)
      };
    }

  };
  const TEN = {
    value: 10,
    slot: LevelTenSlot,
    presentDateFormat: PresentTimeFormat.timestamp,
    rulerDateFormat: RulerDateFormat.minutes,
    threadClusterLimit: ThreadClusterLimit.ten,
    timeRangeTimeFormat: TimeRangeTimeFormat.short,

    ticks(scale, locale) {
      const allTicks = scale.copy().nice(_d3Time.timeMinute).ticks(_d3Time.timeMinute.every(15));
      return {
        headline: headlineDaysFromScale(scale, locale),
        main: allTicks.filter(d => d.getMinutes() === 0).map(labelTick(locale, locale.current.ticks.time.short)),
        major: allTicks.filter(d => d.getMinutes() % 30 === 0).map(noLabelTick),
        minor: allTicks.map(noLabelTick)
      };
    }

  };
  const ELEVEN = {
    value: 11,
    slot: LevelElevenSlot,
    presentDateFormat: PresentTimeFormat.timestamp,
    rulerDateFormat: RulerDateFormat.minutes,
    threadClusterLimit: ThreadClusterLimit.eleven,
    timeRangeTimeFormat: TimeRangeTimeFormat.short,

    ticks(scale, locale) {
      const allTicks = scale.copy().nice(_d3Time.timeHour).ticks(_d3Time.timeMinute.every(30));
      return {
        headline: headlineDaysFromScale(scale, locale),
        main: allTicks.filter(d => d.getMinutes() === 0 && d.getHours() % 2 === 0).map(labelTick(locale, locale.current.ticks.time.short)),
        major: allTicks.filter(d => d.getMinutes() === 0).map(noLabelTick),
        minor: allTicks.map(noLabelTick)
      };
    }

  };
  const TWELVE = {
    value: 12,
    slot: LevelTwelveSlot,
    presentDateFormat: PresentTimeFormat.timestamp,
    rulerDateFormat: RulerDateFormat.minutes,
    threadClusterLimit: ThreadClusterLimit.twelve,
    timeRangeTimeFormat: TimeRangeTimeFormat.short,

    ticks(scale, locale) {
      const allTicks = scale.copy().nice(_d3Time.timeHour).ticks(_d3Time.timeHour.every(1));
      return {
        headline: headlineDaysFromScale(scale, locale),
        main: allTicks.filter(d => d.getHours() % 4 === 0).map(labelTick(locale, locale.current.ticks.time.short)),
        major: allTicks.filter(d => d.getHours() % 2 === 0).map(noLabelTick),
        minor: allTicks.map(noLabelTick)
      };
    }

  };
  const THIRTEEN = {
    value: 13,
    slot: LevelThirteenSlot,
    presentDateFormat: PresentTimeFormat.timestamp,
    rulerDateFormat: RulerDateFormat.dateTime,
    threadClusterLimit: ThreadClusterLimit.thirteen,
    timeRangeTimeFormat: TimeRangeTimeFormat.short,

    ticks(scale, locale) {
      const allTicks = scale.copy().nice(_d3Time.timeHour).ticks(_d3Time.timeHour.every(2));
      return {
        headline: headlineDaysFromScale(scale, locale),
        main: allTicks.filter(d => d.getHours() % 8 === 0).map(labelTick(locale, locale.current.ticks.time.short)),
        major: allTicks.filter(d => d.getHours() % 4 === 0).map(noLabelTick),
        minor: allTicks.map(noLabelTick)
      };
    }

  };
  const FOURTEEN = {
    value: 14,
    slot: LevelFourteenSlot,
    presentDateFormat: PresentTimeFormat.timestamp,
    rulerDateFormat: RulerDateFormat.dateTime,
    threadClusterLimit: ThreadClusterLimit.fourteen,
    timeRangeTimeFormat: TimeRangeTimeFormat.short,

    ticks(scale, locale) {
      const allTicks = scale.copy().nice(_d3Time.timeHour).ticks(_d3Time.timeHour.every(6));
      return {
        headline: headlineYearsFromScale(scale, locale),
        main: allTicks.filter(d => d.getHours() === 0).map(labelTick(locale, locale.current.ticks.date.medium)),
        major: allTicks.filter(d => d.getHours() % 12 === 0).map(noLabelTick),
        minor: allTicks.map(noLabelTick)
      };
    }

  };
  const FIFTEEN = {
    value: 15,
    slot: LevelFifteenSlot,
    presentDateFormat: PresentTimeFormat.timestamp,
    rulerDateFormat: RulerDateFormat.date,
    threadClusterLimit: ThreadClusterLimit.fifteen,
    timeRangeTimeFormat: TimeRangeTimeFormat.short,

    ticks(scale, locale) {
      const allTicks = scale.copy().nice(_d3Time.timeHour).ticks(_d3Time.timeHour.every(6));
      return {
        headline: headlineYearsFromScale(scale, locale),
        main: allTicks.filter(d => d.getHours() === 0).map(labelTick(locale, locale.current.ticks.date.short)),
        major: allTicks.filter(d => d.getHours() % 12 === 0).map(noLabelTick),
        minor: allTicks.map(noLabelTick)
      };
    }

  };
  const SIXTEEN = {
    value: 16,
    slot: LevelSixteenSlot,
    presentDateFormat: PresentTimeFormat.timestamp,
    rulerDateFormat: RulerDateFormat.date,
    threadClusterLimit: ThreadClusterLimit.sixteen,
    timeRangeTimeFormat: TimeRangeTimeFormat.short,

    ticks(scale, locale) {
      const allTicks = scale.copy().nice(_d3Time.timeHour).ticks(_d3Time.timeHour.every(12));
      return {
        headline: headlineYearsFromScale(scale, locale),
        main: allTicks.filter(d => d.getHours() === 0 && (0, _dateFns.getDayOfYear)(d) % 2 === 0).map(labelTick(locale, locale.current.ticks.date.short)),
        major: allTicks.filter(d => d.getHours() === 0).map(noLabelTick),
        minor: allTicks.map(noLabelTick)
      };
    }

  };
  const SEVENTEEN = {
    value: 17,
    slot: LevelSeventeenSlot,
    presentDateFormat: PresentTimeFormat.timestamp,
    rulerDateFormat: RulerDateFormat.date,
    threadClusterLimit: ThreadClusterLimit.seventeen,
    timeRangeTimeFormat: TimeRangeTimeFormat.short,

    ticks(scale, locale) {
      const allTicks = scale.copy().nice(_d3Time.timeDay).ticks(_d3Time.timeDay.every(1));
      return {
        headline: headlineYearsFromScale(scale, locale),
        main: allTicks.filter(d => (0, _dateFns.getDayOfYear)(d) % 4 === 0).map(labelTick(locale, locale.current.ticks.date.short)),
        major: allTicks.filter(d => (0, _dateFns.getDayOfYear)(d) % 2 === 0).map(noLabelTick),
        minor: allTicks.map(noLabelTick)
      };
    }

  };
  const EIGHTEEN = {
    value: 18,
    slot: LevelEighteenSlot,
    presentDateFormat: PresentTimeFormat.date,
    rulerDateFormat: RulerDateFormat.date,
    threadClusterLimit: ThreadClusterLimit.eighteen,
    timeRangeTimeFormat: TimeRangeTimeFormat.short,

    ticks(scale, locale) {
      const allTicks = scale.copy().nice(_d3Time.timeDay).ticks(_d3Time.timeDay.every(1));
      return {
        headline: headlineYearsFromScale(scale, locale),
        main: allTicks.filter(d => (0, _dateFns.getDayOfYear)(d) % 8 === 0).map(labelTick(locale, locale.current.ticks.date.short)),
        major: allTicks.filter(d => (0, _dateFns.getDayOfYear)(d) % 4 === 0).map(noLabelTick),
        minor: allTicks.map(noLabelTick)
      };
    }

  };
  const NINETEEN = {
    value: 19,
    slot: LevelNineteenSlot,
    presentDateFormat: PresentTimeFormat.date,
    rulerDateFormat: RulerDateFormat.date,
    threadClusterLimit: ThreadClusterLimit.nineteen,
    timeRangeTimeFormat: TimeRangeTimeFormat.short,

    ticks(scale, locale) {
      const allTicks = scale.copy().nice(_d3Time.timeDay).ticks(_d3Time.timeDay.every(1));
      return {
        headline: headlineYearsFromScale(scale, locale),
        main: allTicks.filter(d => d.getDate() === 1).map(labelTick(locale, locale.current.ticks.tokens.month)),
        major: allTicks.filter(d => d.getDate() === 1 || d.getDate() === 15).map(noLabelTick),
        minor: allTicks.map(noLabelTick)
      };
    }

  };
  const TWENTY = {
    value: 20,
    slot: LevelTwentySlot,
    presentDateFormat: PresentTimeFormat.date,
    rulerDateFormat: RulerDateFormat.date,
    threadClusterLimit: ThreadClusterLimit.nineteen,
    timeRangeTimeFormat: TimeRangeTimeFormat.short,

    ticks(scale, locale) {
      const allTicks = scale.copy().nice(_d3Time.timeDay).ticks(halfWeekInterval);
      return {
        headline: headlineYearsFromScale(scale, locale),
        main: allTicks.filter(d => d.getDate() === 1).map(labelTick(locale, locale.current.ticks.tokens.month)),
        major: allTicks.map(noLabelTick),
        minor: []
      };
    }

  };
  const TWENTY_ONE = {
    value: 21,
    slot: LevelTwentySlot,
    presentDateFormat: PresentTimeFormat.date,
    rulerDateFormat: RulerDateFormat.date,
    threadClusterLimit: ThreadClusterLimit.twenty,
    timeRangeTimeFormat: TimeRangeTimeFormat.short,

    ticks(scale, locale) {
      const allTicks = scale.copy().nice(_d3Time.timeDay).ticks(_d3Time.timeMonth);
      return {
        headline: headlineYearsFromScale(scale, locale),
        main: allTicks.map(labelTick(locale, locale.current.ticks.tokens.shortMonth)),
        major: [],
        minor: []
      };
    }

  };
  const TWENTY_TWO = {
    value: 21,
    slot: LevelTwentySlot,
    presentDateFormat: PresentTimeFormat.date,
    rulerDateFormat: RulerDateFormat.date,
    threadClusterLimit: ThreadClusterLimit.twenty,
    timeRangeTimeFormat: TimeRangeTimeFormat.short,

    ticks(scale, locale) {
      const allTicks = scale.copy().nice(_d3Time.timeDay).ticks(_d3Time.timeMonth.every(2));
      return {
        headline: headlineYearsFromScale(scale, locale),
        main: allTicks.map(labelTick(locale, locale.current.ticks.tokens.shortMonth)),
        major: [],
        minor: []
      };
    }

  };

  function zoomLevelForDomain(width, from, to) {
    const pixelsPerSecond = width / (0, _dateFns.differenceInSeconds)(to, from);
    return zoomLevelForPixelsPerSecond(pixelsPerSecond);
  }

  function zoomLevelForPixelsPerSecond(pixelsPerSecond) {
    if (pixelsPerSecond >= 112) {
      return ONE;
    } else if (pixelsPerSecond >= 112 / 2) {
      return TWO;
    } else if (pixelsPerSecond >= 140 / 5) {
      return THREE;
    } else if (pixelsPerSecond >= 118 / 10) {
      return FOUR;
    } else if (pixelsPerSecond >= 140 / 30) {
      return FIVE;
    } else if (pixelsPerSecond >= 84 / 60) {
      return SIX;
    } else if (pixelsPerSecond >= 84 / 120) {
      return SEVEN;
    } else if (pixelsPerSecond >= 84 / 300) {
      return EIGHT;
    } else if (pixelsPerSecond >= 84 / 900) {
      return NINE;
    } else if (pixelsPerSecond >= 84 / 3600) {
      return TEN;
    } else if (pixelsPerSecond >= 84 / 7200) {
      return ELEVEN;
    } else if (pixelsPerSecond >= 84 / 14400) {
      return TWELVE;
    } else if (pixelsPerSecond >= 96 / 28800) {
      return THIRTEEN;
    } else if (pixelsPerSecond >= 132 / 86400) {
      return FOURTEEN;
    } else if (pixelsPerSecond >= 84 / 84600) {
      return FIFTEEN;
    } else if (pixelsPerSecond >= 84 / 172800) {
      return SIXTEEN;
    } else if (pixelsPerSecond >= 84 / 345600) {
      return SEVENTEEN;
    } else if (pixelsPerSecond >= 84 / 691200) {
      return EIGHTEEN;
    } else if (pixelsPerSecond >= 94 / 1618000) {
      return NINETEEN;
    } else if (pixelsPerSecond >= 94 / 2592000) {
      return TWENTY;
    } else if (pixelsPerSecond >= 50 / 2592000) {
      return TWENTY_ONE;
    } else {
      return TWENTY_TWO;
    }
  }

  const DEFAULT_ZOOM = ELEVEN;
  _exports.DEFAULT_ZOOM = DEFAULT_ZOOM;
});