define("clarify/utilities/rpc", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.execute = execute;
  _exports.RPCError = _exports.RPCErrorCode = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  let RPCErrorCode;
  _exports.RPCErrorCode = RPCErrorCode;

  (function (RPCErrorCode) {
    RPCErrorCode[RPCErrorCode["parseError"] = -32700] = "parseError";
    RPCErrorCode[RPCErrorCode["invalidRequest"] = -32600] = "invalidRequest";
    RPCErrorCode[RPCErrorCode["methodNotFound"] = -32601] = "methodNotFound";
    RPCErrorCode[RPCErrorCode["invalidParams"] = -32602] = "invalidParams";
    RPCErrorCode[RPCErrorCode["internalError"] = -32603] = "internalError";
    RPCErrorCode[RPCErrorCode["serverError"] = -32000] = "serverError";
    RPCErrorCode[RPCErrorCode["conflict"] = -32009] = "conflict";
    RPCErrorCode[RPCErrorCode["operationNotPermitted"] = -32003] = "operationNotPermitted";
  })(RPCErrorCode || (_exports.RPCErrorCode = RPCErrorCode = {}));

  class RPCError extends Error {
    constructor(rpcError) {
      super(rpcError.message);

      _defineProperty(this, "code", void 0);

      _defineProperty(this, "data", void 0);

      Object.setPrototypeOf(this, RPCError.prototype);
      this.name = 'RPCError';
      this.code = rpcError.code;
      this.data = rpcError.data;
    }

    get type() {
      switch (this.code) {
        case RPCErrorCode.conflict:
          return 'conflict';

        case RPCErrorCode.parseError:
          return 'parseError';

        case RPCErrorCode.methodNotFound:
          return 'methodNotFound';

        case RPCErrorCode.invalidParams:
          return 'invalidParams';

        case RPCErrorCode.internalError:
          return 'internalError';

        case RPCErrorCode.serverError:
          return 'serverError';

        case RPCErrorCode.conflict:
          return 'conflict';

        case RPCErrorCode.operationNotPermitted:
          return 'operationNotPermitted';

        default:
          return 'unknown';
      }
    }

  }

  _exports.RPCError = RPCError;

  async function execute(input, init, ...requests) {
    let payload = requests.map(request => {
      return {
        jsonrpc: '2.0',
        method: request.method,
        params: request.parameters,
        id: request.id
      };
    });
    let ids = new Map();
    requests.forEach((request, index) => {
      if (request.id) {
        ids.set(request.id, index);
      }
    });
    let headers = new Headers(init.headers);
    headers.append('Content-Type', 'application/json');
    headers.append('X-API-Version', requests[0].version);
    let requestInit = { ...init,
      body: JSON.stringify(payload),
      method: 'POST',
      headers: headers
    };

    try {
      let response = await fetch(input, requestInit);

      if (response.status === 200) {
        let data = await response.json();
        return data.reduce((result, response) => {
          if (!response.id) {
            throw new Error('RPC Error, didn’t include id');
          }

          let index = ids.get(response.id);

          if (index === undefined) {
            throw new Error('RPC Error, unknown id');
          }

          if (response.result) {
            result[index] = {
              type: 'result',
              result: response.result
            };
          } else if (response.error) {
            result[index] = {
              type: 'error',
              error: new RPCError(response.error)
            };
          }

          return result;
        }, new Array(data.length));
      } else {
        return Promise.reject(new Error('wtf'));
      }
    } catch (error) {
      console.log('Failed: ', error);
      return Promise.reject(error);
    }
  }
});