define("clarify/design-library/tests/app.lint-test", [], function () {
  "use strict";

  QUnit.module('ESLint | app');
  QUnit.test('app/components/a-button-tertiary/group.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/a-button-tertiary/group.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/a-button-tertiary/index.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/a-button-tertiary/index.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/a-text/-private.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/a-text/-private.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/a-text/copy.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/a-text/copy.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/a-text/index.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/a-text/index.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/a-text/link.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/a-text/link.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/actions-menu/index.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/actions-menu/index.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/dialog-container/index.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/dialog-container/index.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/dialog/header.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/dialog/header.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/dialog/index.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/dialog/index.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/divider/index.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/divider/index.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/dropdown-menu/header.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/dropdown-menu/header.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/dropdown-menu/index.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/dropdown-menu/index.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/dropdown-menu/item.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/dropdown-menu/item.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/dropdown-menu/option.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/dropdown-menu/option.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/dropdown-menu/options.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/dropdown-menu/options.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/dropdown-menu/separator.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/dropdown-menu/separator.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/fieldset/index.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/fieldset/index.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/filter/index.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/filter/index.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/flat-button/index.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/flat-button/index.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/flat-button/link.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/flat-button/link.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/header-contextual/index.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/header-contextual/index.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/initial-state/index.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/initial-state/index.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/input-combobox/index.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/input-combobox/index.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/input-search/index.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/input-search/index.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/input-select/index.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/input-select/index.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/input-text-area/index.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/input-text-area/index.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/input-text/index.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/input-text/index.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/input-toggle/index.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/input-toggle/index.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/label/index.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/label/index.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/menu-button/index.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/menu-button/index.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/navigation/divider.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/navigation/divider.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/navigation/header.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/navigation/header.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/navigation/hyperlink.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/navigation/hyperlink.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/navigation/index.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/navigation/index.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/non-ideal-state/index.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/non-ideal-state/index.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/page-layout/complex/index.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/page-layout/complex/index.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/page-layout/header.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/page-layout/header.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/page-layout/simple/index.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/page-layout/simple/index.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/popover-layout/index.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/popover-layout/index.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/popover-layout/item.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/popover-layout/item.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/select-button/index.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/select-button/index.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/side-pane/content.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/side-pane/content.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/side-pane/index.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/side-pane/index.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/side-pane/menu.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/side-pane/menu.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/text-button/index.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/text-button/index.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/text-tag/index.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/text-tag/index.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/wizard-dialog/index.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/wizard-dialog/index.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/wizard-dialog/progress.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/wizard-dialog/progress.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/wizard-dialog/step.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/wizard-dialog/step.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/wizard-dialog/text-content.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/wizard-dialog/text-content.js should pass ESLint\n\n');
  });
  QUnit.test('app/helpers/filter-by.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/helpers/filter-by.js should pass ESLint\n\n');
  });
  QUnit.test('app/modifiers/auto-resize.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/modifiers/auto-resize.js should pass ESLint\n\n');
  });
  QUnit.test('app/modifiers/setup-menu-button.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/modifiers/setup-menu-button.js should pass ESLint\n\n');
  });
  QUnit.test('app/modifiers/setup-menu.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/modifiers/setup-menu.js should pass ESLint\n\n');
  });
});