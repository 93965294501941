define("clarify/utilities/promise", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.delay = delay;

  function delay(duration) {
    return new Ember.RSVP.Promise(resolve => setTimeout(resolve, duration));
  }
});