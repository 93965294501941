define("clarify/errors/authentication-error", ["exports", "clarify/errors/localizable-error"], function (_exports, _localizableError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AuthenticationError extends _localizableError.default {
    constructor(payload, source) {
      super(`Authentication Error`);
      this.payload = payload;
      this.source = source;
      Object.setPrototypeOf(this, AuthenticationError.prototype);
    }

    get description() {
      switch (this.source.type) {
        case 'passwordless':
          return this.intl.t('callback.passwordless', {
            error: this.payload.error,
            retryURL: this.retryURL,
            htmlSafe: true
          });

        case 'login':
          return this.intl.t('callback.description', {
            error: this.payload.error
          });
      }
    }

    get retryURL() {
      if (this.source.type === 'passwordless') {
        let {
          email,
          invitation,
          price
        } = this.source;
        let queryParams = new URLSearchParams();
        queryParams.append('email', email);

        if (invitation) {
          queryParams.append('invitation', invitation);
        }

        if (price) {
          queryParams.append('price', price);
        }

        return `/signup/resend?${queryParams.toString()}`;
      }

      return null;
    }

  }

  _exports.default = AuthenticationError;
});