define("clarify/components/thread-pane/header", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="thread-header">
    <span class="thread-header-icon"></span>
    <span class="thread-header-title">
      {{this.title}}
    </span>
    <Button
      class="thread-header-button"
      @rightIcon={{svg-jar "#settings-header-icon" width=20 height=20}}
      @size="normal"
      @color={{if (eq @theme "light") "blue" "white"}}
      @style="icon"
      @action={{action @close}}
      title={{t "thread-feed.tooltip.close-thread"}}
    />
  </div>
  */
  {"id":"9zaPucjx","block":"{\"symbols\":[\"@theme\",\"@close\"],\"statements\":[[10,\"div\"],[14,0,\"thread-header\"],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"thread-header-icon\"],[12],[13],[2,\"\\n  \"],[10,\"span\"],[14,0,\"thread-header-title\"],[12],[2,\"\\n    \"],[1,[32,0,[\"title\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[8,\"button\",[[24,0,\"thread-header-button\"],[16,\"title\",[30,[36,4],[\"thread-feed.tooltip.close-thread\"],null]]],[[\"@rightIcon\",\"@size\",\"@color\",\"@style\",\"@action\"],[[30,[36,0],[\"#settings-header-icon\"],[[\"width\",\"height\"],[20,20]]],\"normal\",[30,[36,2],[[30,[36,1],[[32,1],\"light\"],null],\"blue\",\"white\"],null],\"icon\",[30,[36,3],[[32,0],[32,2]],null]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"svg-jar\",\"eq\",\"if\",\"action\",\"t\"]}","meta":{"moduleName":"clarify/components/thread-pane/header.hbs"}});

  let ThreadHeaderComponent = (_dec = Ember.inject.service, (_class = (_temp = class ThreadHeaderComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "locale", _descriptor, this);
    }

    get title() {
      return this.locale.format(this.args.timestamp, this.locale.current.threads.header);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "locale", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ThreadHeaderComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ThreadHeaderComponent);
});