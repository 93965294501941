define("clarify/templates/organizations/show/timelines/show/activity/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cmMGxq0v",
    "block": "{\"symbols\":[],\"statements\":[[8,\"thread-feed\",[],[[\"@close\",\"@showThread\",\"@sort\",\"@currentSort\",\"@threads\",\"@loadMore\",\"@loading\",\"@hasMoreContent\"],[[30,[36,0],[[32,0],\"close\"],null],[30,[36,0],[[32,0],\"showThread\"],null],[30,[36,0],[[32,0],\"sort\"],null],[32,0,[\"sortByText\"]],[32,0,[\"threads\"]],[30,[36,0],[[32,0],\"loadMore\"],null],[32,0,[\"loading\"]],[32,0,[\"hasMoreContent\"]]]],null]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "clarify/templates/organizations/show/timelines/show/activity/index.hbs"
    }
  });

  _exports.default = _default;
});