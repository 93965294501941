define("clarify/serializers/layout", ["exports", "clarify/serializers/rest-layer"], function (_exports, _restLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Layout extends _restLayer.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "root", 'layouts');

      _defineProperty(this, "attrs", {
        visualizations: {
          serialize: 'records',
          deserialize: 'records'
        }
      });
    }

    serialize(snapshot, options) {
      const superRes = super.serialize(snapshot, options);
      return {
        type: superRes.type,
        visualizations: superRes.visualizations
      };
    }

  }

  _exports.default = Layout;
});