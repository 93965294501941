define("clarify/components/visualization-settings/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="visualization-settings-header">
    <div class="visualization-settings-header-title-wrapper">
      <InlineEditable
        class="visualization-settings-header-title"
        @value={{@title}}
        @placeholder={{t "visualization-settings.untitled"}}
        @onChange={{action @visualizationNameDidChange @visualization}}
      />
    </div>
    <div class="visualization-settings-header-actions">
      <a
        class="visualization-settings-header-action"
        alt="Delete"
        role="button"
        onclick={{action (delete-visualization-alert visualization=@visualization action=(action @deleteVisualization))}}
      >
        {{svg-jar "#visualization-settings-header-delete" width=20 height=20}}
      </a>
    </div>
  </div>
  */
  {"id":"lwVmoj0h","block":"{\"symbols\":[\"@title\",\"@visualization\",\"@visualizationNameDidChange\",\"@deleteVisualization\"],\"statements\":[[10,\"div\"],[14,0,\"visualization-settings-header\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"visualization-settings-header-title-wrapper\"],[12],[2,\"\\n    \"],[8,\"inline-editable\",[[24,0,\"visualization-settings-header-title\"]],[[\"@value\",\"@placeholder\",\"@onChange\"],[[32,1],[30,[36,0],[\"visualization-settings.untitled\"],null],[30,[36,1],[[32,0],[32,3],[32,2]],null]]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"visualization-settings-header-actions\"],[12],[2,\"\\n    \"],[10,\"a\"],[14,0,\"visualization-settings-header-action\"],[14,\"alt\",\"Delete\"],[14,\"role\",\"button\"],[15,\"onclick\",[30,[36,1],[[32,0],[30,[36,2],null,[[\"visualization\",\"action\"],[[32,2],[30,[36,1],[[32,0],[32,4]],null]]]]],null]],[12],[2,\"\\n      \"],[1,[30,[36,3],[\"#visualization-settings-header-delete\"],[[\"width\",\"height\"],[20,20]]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"delete-visualization-alert\",\"svg-jar\"]}","meta":{"moduleName":"clarify/components/visualization-settings/header.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});