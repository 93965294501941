define("clarify/models/permission", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ItemPermissionType = _exports.PermissionType = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let {
    Model,
    attr
  } = _emberData.default;
  let PermissionType;
  _exports.PermissionType = PermissionType;

  (function (PermissionType) {
    PermissionType["owner"] = "timelines:owner";
    PermissionType["collaborator"] = "timelines:collaborator";
    PermissionType["guest"] = "timelines:guest";
  })(PermissionType || (_exports.PermissionType = PermissionType = {}));

  let ItemPermissionType;
  _exports.ItemPermissionType = ItemPermissionType;

  (function (ItemPermissionType) {
    ItemPermissionType["viewer"] = "items:viewer";
  })(ItemPermissionType || (_exports.ItemPermissionType = ItemPermissionType = {}));

  let Permission = (_dec = attr('string'), (_class = (_temp = class Permission extends Model {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "permission", _descriptor, this);
    }

    get identifier() {
      throw new Error('Abstract implementation');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = Permission;
});