define("clarify/components/timeline-canvas/tooltips/item", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="tooltip-item" data-color={{@tooltip.color}} data-visualization-type={{@tooltip.icon}}>
    <div class="tooltip-item-icon"></div>
    <div class="tooltip-item-text">
      <span class="tooltip-item-value">
        {{format-value item=@tooltip.item value=@tooltip.value includeEngUnit=true showEnumValue=true}}
      </span>
      <span class="tooltip-item-name">
        {{@tooltip.displayName}}
      </span>
    </div>
  </div>
  */
  {"id":"YpkpcJg5","block":"{\"symbols\":[\"@tooltip\"],\"statements\":[[10,\"div\"],[14,0,\"tooltip-item\"],[15,\"data-color\",[32,1,[\"color\"]]],[15,\"data-visualization-type\",[32,1,[\"icon\"]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"tooltip-item-icon\"],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"tooltip-item-text\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"tooltip-item-value\"],[12],[2,\"\\n      \"],[1,[30,[36,0],null,[[\"item\",\"value\",\"includeEngUnit\",\"showEnumValue\"],[[32,1,[\"item\"]],[32,1,[\"value\"]],true,true]]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"span\"],[14,0,\"tooltip-item-name\"],[12],[2,\"\\n      \"],[1,[32,1,[\"displayName\"]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"format-value\"]}","meta":{"moduleName":"clarify/components/timeline-canvas/tooltips/item.hbs"}});

  class TooltipComponent extends _component.default {}

  _exports.default = TooltipComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TooltipComponent);
});