define("clarify/utilities/hit-region", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class HitRegions {
    constructor(context) {
      _defineProperty(this, "regions", []);

      _defineProperty(this, "context", void 0);

      this.context = context;
    }

    addHitRegion(options) {
      this.regions.push(options);
    }

    clearHitRegions() {
      this.regions = [];
    }

    removeHitRegion(id) {
      this.regions = this.regions.filter(region => region.id !== id);
    }

    getRegion(x, y) {
      for (var i = this.regions.length - 1; i >= 0; i--) {
        let region = this.regions[i];

        if (this.context.isPointInPath(region.path, x, y, region.fillRule)) {
          return region.id;
        }
      }

      return null;
    }

  }

  _exports.default = HitRegions;
});