define("clarify/templates/components/item-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u+cnQs/H",
    "block": "{\"symbols\":[\"@item\",\"@onItemAdded\"],\"statements\":[[11,\"div\"],[24,0,\"item-card-header item-card\"],[4,[38,0],null,[[\"model\",\"contextProvider\",\"dragImage\"],[[32,1],\"item\",\"item\"]]],[12],[10,\"div\"],[14,0,\"item-card-header-title\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\" \"],[10,\"div\"],[14,0,\"item-card-header-unit\"],[12],[1,[32,1,[\"engUnit\"]]],[13],[13],[2,\" \"],[10,\"div\"],[14,0,\"item-card-metadata\"],[12],[10,\"div\"],[14,0,\"item-card-data\"],[12],[1,[30,[36,1],[\"#auto-complete-location\"],[[\"class\",\"width\",\"height\"],[\"item-card-data-icon\",16,16]]]],[2,\" \"],[10,\"div\"],[14,0,\"item-card-data-title\"],[12],[1,[32,1,[\"locationString\"]]],[13],[13],[2,\" \"],[10,\"div\"],[14,0,\"item-card-data\"],[12],[1,[30,[36,1],[\"#auto-complete-integration\"],[[\"class\",\"width\",\"height\"],[\"item-card-data-icon\",16,16]]]],[2,\" \"],[10,\"div\"],[14,0,\"item-card-data-title\"],[12],[1,[32,1,[\"dataSource\"]]],[13],[13],[2,\" \"],[10,\"div\"],[14,0,\"item-card-footer\"],[12],[8,\"button\",[[24,0,\"item-card-button\"]],[[\"@color\",\"@size\",\"@style\",\"@leftIcon\",\"@title\",\"@action\"],[\"blue\",\"small\",\"link-underline\",[30,[36,1],[\"#x-button-icon-plus\"],[[\"width\",\"height\"],[7,7]]],[30,[36,2],[\"item-card.add\"],null],[30,[36,3],[[32,2],[32,1]],null]]],null],[13],[13]],\"hasEval\":false,\"upvars\":[\"drag-source\",\"svg-jar\",\"t\",\"fn\"]}",
    "meta": {
      "moduleName": "clarify/templates/components/item-card.hbs"
    }
  });

  _exports.default = _default;
});