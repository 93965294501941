define("clarify/routes/organizations/show/timelines/new", ["exports", "clarify/models/layout", "clarify/models/permission"], function (_exports, _layout, _permission) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TimelinesNew = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = (_temp = class TimelinesNew extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "currentUser", _descriptor, this);

      _initializerDefineProperty(this, "layoutService", _descriptor2, this);

      _defineProperty(this, "queryParams", {
        item: {},
        from: {},
        to: {}
      });
    }

    async model(params) {
      let {
        organization
      } = this.modelFor('organizations/show');
      let permission = this.store.createRecord('permission-user', {
        permission: _permission.PermissionType.owner,
        user: this.currentUser.user
      });
      let visualizations = [];

      if (params && params.item) {
        let item = await this.store.findRecord('item', params.item);
        visualizations = [this.layoutService.addVisualization([item])];
      }

      const timeline = this.store.createRecord('timeline', {
        name: '',
        description: '',
        permissions: [permission],
        organization: organization,
        tags: []
      });
      let layout = this.store.createRecord('layout', {
        type: 'clfy.io/main',
        visualizations
      });
      Ember.set(layout, 'timeline', timeline);
      timeline.layouts.pushObject(layout);
      await (0, _layout.saveLayout)(layout);
      timeline.permissions.removeObject(permission);
      return {
        timeline,
        layout
      };
    }

    redirect(model, transition) {
      let queryParams = {
        signals: true,
        new: true
      };
      let parameters = transition.to.queryParams;

      if (parameters && parameters.from && parameters.to) {
        queryParams = { ...queryParams,
          from: parameters.from,
          to: parameters.to
        };
      }

      this.transitionTo('organizations.show.timelines.show', {
        layout: model.layout,
        timeline: model.timeline
      }, {
        queryParams
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "layoutService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = TimelinesNew;
});