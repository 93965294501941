define("clarify/components/app-sub-header/index", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <header class="app-sub-header" data-style={{@style}}>
    <div class="app-sub-header-link-container">
      <LinkTo
        class="app-sub-header-link"
        title={{t "timelines-index.activity"}}
        data-marketing-landmark="timelines.index.header.activity"
        @route="organizations.show.activities"
      >
        {{t "timelines-index.activity"}}
      </LinkTo>
      <LinkTo
        class="app-sub-header-link"
        title={{t "timelines-index.timeline"}}
        data-marketing-landmark="timelines.index.header.timelines"
        @route="organizations.show.timelines"
      >
        {{t "timelines-index.timeline"}}
      </LinkTo>
      <LinkTo
        class="app-sub-header-link"
        title={{t "timelines-index.items"}}
        data-marketing-landmark="timelines.index.header.items"
        @route="organizations.show.items"
      >
        {{t "timelines-index.items"}}
      </LinkTo>
    </div>
    <div class="app-sub-header-button">
      <ButtonShare
        class="app-sub-header-button-xs"
        data-intercom-target="New timeline"
        data-marketing-landmark="timelines.index.new-timeline"
        @title={{t "timelines-index.new-timeline"}}
        @style="light"
        {{on "click" (fn this.createTimeline)}}
      />
    </div>
  </header>
  */
  {"id":"Tp17YSkW","block":"{\"symbols\":[\"@style\"],\"statements\":[[10,\"header\"],[14,0,\"app-sub-header\"],[15,\"data-style\",[32,1]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"app-sub-header-link-container\"],[12],[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"app-sub-header-link\"],[16,\"title\",[30,[36,0],[\"timelines-index.activity\"],null]],[24,\"data-marketing-landmark\",\"timelines.index.header.activity\"]],[[\"@route\"],[\"organizations.show.activities\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,0],[\"timelines-index.activity\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"app-sub-header-link\"],[16,\"title\",[30,[36,0],[\"timelines-index.timeline\"],null]],[24,\"data-marketing-landmark\",\"timelines.index.header.timelines\"]],[[\"@route\"],[\"organizations.show.timelines\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,0],[\"timelines-index.timeline\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"app-sub-header-link\"],[16,\"title\",[30,[36,0],[\"timelines-index.items\"],null]],[24,\"data-marketing-landmark\",\"timelines.index.header.items\"]],[[\"@route\"],[\"organizations.show.items\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,0],[\"timelines-index.items\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"app-sub-header-button\"],[12],[2,\"\\n    \"],[8,\"button-share\",[[24,0,\"app-sub-header-button-xs\"],[24,\"data-intercom-target\",\"New timeline\"],[24,\"data-marketing-landmark\",\"timelines.index.new-timeline\"],[4,[38,2],[\"click\",[30,[36,1],[[32,0,[\"createTimeline\"]]],null]],null]],[[\"@title\",\"@style\"],[[30,[36,0],[\"timelines-index.new-timeline\"],null],\"light\"]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"fn\",\"on\"]}","meta":{"moduleName":"clarify/components/app-sub-header/index.hbs"}});

  let AppSubHeader = (_dec = Ember.inject.service, _dec2 = Ember._action, (_class = (_temp = class AppSubHeader extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);
    }

    createTimeline() {
      this.router.transitionTo('organizations.show.timelines.new');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "createTimeline", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "createTimeline"), _class.prototype)), _class));
  _exports.default = AppSubHeader;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, AppSubHeader);
});