define("clarify/components/image/index", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.url}}
    <img
      class="image"
      src={{this.url}}
      width={{@width}}
      height={{@height}}
      ...attributes
      {{did-update this.handleSourceDidChange @blob}}
      {{did-update this.handleSourceDidChange @source}}
    />
  {{else}}
    <div
      class="image-placeholder"
      style={{this.style}}
      ...attributes
      {{did-update this.handleSourceDidChange @blob}}
      {{did-update this.handleSourceDidChange @source}}
    ></div>
  {{/if}}
  */
  {"id":"7YHQ08nA","block":"{\"symbols\":[\"&attrs\",\"@blob\",\"@source\",\"@width\",\"@height\"],\"statements\":[[6,[37,1],[[32,0,[\"url\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[11,\"img\"],[24,0,\"image\"],[16,\"src\",[32,0,[\"url\"]]],[16,\"width\",[32,4]],[16,\"height\",[32,5]],[17,1],[4,[38,0],[[32,0,[\"handleSourceDidChange\"]],[32,2]],null],[4,[38,0],[[32,0,[\"handleSourceDidChange\"]],[32,3]],null],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[11,\"div\"],[24,0,\"image-placeholder\"],[16,5,[32,0,[\"style\"]]],[17,1],[4,[38,0],[[32,0,[\"handleSourceDidChange\"]],[32,2]],null],[4,[38,0],[[32,0,[\"handleSourceDidChange\"]],[32,3]],null],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"did-update\",\"if\"]}","meta":{"moduleName":"clarify/components/image/index.hbs"}});

  var Status;

  (function (Status) {
    Status["initial"] = "initial";
    Status["loading"] = "loading";
    Status["failed"] = "failed";
    Status["loaded"] = "loaded";
  })(Status || (Status = {}));

  let Image = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, (_class = (_temp = class Image extends _component.default {
    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "network", _descriptor, this);

      _initializerDefineProperty(this, "status", _descriptor2, this);

      _initializerDefineProperty(this, "url", _descriptor3, this);

      _defineProperty(this, "abortController", new AbortController());

      this.load();
    }

    get style() {
      if (this.args.width && this.args.height) {
        return Ember.String.htmlSafe(`height: ${this.args.height}px; width: ${this.args.width}px;`);
      }

      return null;
    }

    handleSourceDidChange() {
      this.abort();
      this.load();
    }

    async load() {
      if (this.args.blob) {
        this.status = Status.loading;
        this.url = window.URL.createObjectURL(this.args.blob);
        this.status = Status.loaded;
      } else {
        this.status = Status.loading;

        try {
          let response = await this.network.fetch(this.args.source, {
            signal: this.abortController.signal
          });
          let blob = await response.blob();
          this.url = window.URL.createObjectURL(blob);
          this.status = Status.loaded;
        } catch (error) {
          if (error.name !== 'AbortError') {
            this.status = Status.failed;
          }
        }
      }
    }

    abort() {
      this.abortController.abort();
      this.abortController = new AbortController();
    }

    willDestroy() {
      super.willDestroy();
      this.abort();

      if (this.url) {
        window.URL.revokeObjectURL(this.url);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "network", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Status.initial;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "url", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "handleSourceDidChange", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "handleSourceDidChange"), _class.prototype)), _class));
  _exports.default = Image;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Image);
});