define("clarify/components/timeline-card/comment-preview/index", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (eq this.state.state "loaded")}}
    {{#if this.state.thread}}
      <TimelineCard::CommentPreview::Content @thread={{this.state.thread}} />
    {{/if}}
  {{/if}}
  {{#if (eq this.state.state "loading")}}
    <TimelineCard::CommentPreview::Loading />
  {{/if}}
  */
  {"id":"Y3j0d7BR","block":"{\"symbols\":[],\"statements\":[[6,[37,0],[[30,[36,1],[[32,0,[\"state\",\"state\"]],\"loaded\"],null]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[32,0,[\"state\",\"thread\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"timeline-card/comment-preview/content\",[],[[\"@thread\"],[[32,0,[\"state\",\"thread\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[6,[37,0],[[30,[36,1],[[32,0,[\"state\",\"state\"]],\"loading\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"timeline-card/comment-preview/loading\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"eq\"]}","meta":{"moduleName":"clarify/components/timeline-card/comment-preview/index.hbs"}});

  let CommentState = (_dec = Ember._tracked, _dec2 = Ember.inject.service, (_class = (_temp = class CommentState extends _component.default {
    // Properties
    //Services
    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "state", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      this.fetch();
    }

    async fetch() {
      this.state = {
        state: 'loading'
      };

      try {
        let thread = await this.store.query('thread', {
          limit: 1,
          sort: '-updatedAt',
          filter: {
            timeline: this.args.timeline.id
          }
        });
        this.state = {
          thread: thread.toArray()[0],
          state: 'loaded'
        };
      } catch (error) {
        this.state = {
          error,
          state: 'failed'
        };
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "state", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        state: 'loading'
      };
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CommentState;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CommentState);
});