define("clarify/utilities/number-parser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.normalize = normalize;

  function normalize(value, decimalPoint = '.', group = ',') {
    let decimalRegExp = new RegExp(`[${decimalPoint}]`, 'g');
    let groupRegExp = new RegExp(`[${group}]`, 'g');
    return value.trim().replace(/[ ']/g, '').replace(groupRegExp, '').replace(decimalRegExp, '.');
  }
});