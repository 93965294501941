define("clarify/components/export-modal/checkbox-field-set", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="export-modal-items-box">
    <CheckboxFieldset
      class="export-modal-items-box-label"
      @onChange={{fn @handleCheckboxChange "average"}}
      @checked={{@visualizationItemViewModel.average}}
      @size="small"
      @title={{t "export-modal.items.average"}}
      @style="dark"
      @disabled={{not @visualizationItemViewModel.active}}
    />
  </div>
  <div class="export-modal-items-box">
    <CheckboxFieldset
      class="export-modal-items-box-label"
      @onChange={{fn @handleCheckboxChange "maximum"}}
      @checked={{@visualizationItemViewModel.maximum}}
      @disabled={{not @visualizationItemViewModel.active}}
      @size="small"
      @title={{t "export-modal.items.maximum"}}
      @style="dark"
    />
  </div>
  <div class="export-modal-items-box">
    <CheckboxFieldset
      class="export-modal-items-box-label"
      @onChange={{fn @handleCheckboxChange "minimum"}}
      @checked={{@visualizationItemViewModel.minimum}}
      @disabled={{not @visualizationItemViewModel.active}}
      @size="small"
      @title={{t "export-modal.items.minimum"}}
      @style="dark"
    />
  </div>
  <div class="export-modal-items-box">
    <CheckboxFieldset
      class="export-modal-items-box-label"
      @onChange={{fn @handleCheckboxChange "sum"}}
      @checked={{@visualizationItemViewModel.sum}}
      @disabled={{not @visualizationItemViewModel.active}}
      @size="small"
      @title={{t "export-modal.items.sum"}}
      @style="dark"
    />
  </div>
  */
  {"id":"kSODoMOQ","block":"{\"symbols\":[\"@handleCheckboxChange\",\"@visualizationItemViewModel\"],\"statements\":[[10,\"div\"],[14,0,\"export-modal-items-box\"],[12],[2,\"\\n  \"],[8,\"checkbox-fieldset\",[[24,0,\"export-modal-items-box-label\"]],[[\"@onChange\",\"@checked\",\"@size\",\"@title\",\"@style\",\"@disabled\"],[[30,[36,0],[[32,1],\"average\"],null],[32,2,[\"average\"]],\"small\",[30,[36,1],[\"export-modal.items.average\"],null],\"dark\",[30,[36,2],[[32,2,[\"active\"]]],null]]],null],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"export-modal-items-box\"],[12],[2,\"\\n  \"],[8,\"checkbox-fieldset\",[[24,0,\"export-modal-items-box-label\"]],[[\"@onChange\",\"@checked\",\"@disabled\",\"@size\",\"@title\",\"@style\"],[[30,[36,0],[[32,1],\"maximum\"],null],[32,2,[\"maximum\"]],[30,[36,2],[[32,2,[\"active\"]]],null],\"small\",[30,[36,1],[\"export-modal.items.maximum\"],null],\"dark\"]],null],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"export-modal-items-box\"],[12],[2,\"\\n  \"],[8,\"checkbox-fieldset\",[[24,0,\"export-modal-items-box-label\"]],[[\"@onChange\",\"@checked\",\"@disabled\",\"@size\",\"@title\",\"@style\"],[[30,[36,0],[[32,1],\"minimum\"],null],[32,2,[\"minimum\"]],[30,[36,2],[[32,2,[\"active\"]]],null],\"small\",[30,[36,1],[\"export-modal.items.minimum\"],null],\"dark\"]],null],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"export-modal-items-box\"],[12],[2,\"\\n  \"],[8,\"checkbox-fieldset\",[[24,0,\"export-modal-items-box-label\"]],[[\"@onChange\",\"@checked\",\"@disabled\",\"@size\",\"@title\",\"@style\"],[[30,[36,0],[[32,1],\"sum\"],null],[32,2,[\"sum\"]],[30,[36,2],[[32,2,[\"active\"]]],null],\"small\",[30,[36,1],[\"export-modal.items.sum\"],null],\"dark\"]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"t\",\"not\"]}","meta":{"moduleName":"clarify/components/export-modal/checkbox-field-set.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});