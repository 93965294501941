define("clarify/utilities/rpc/keyword-keywords", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.KeywordKeywordsRequestInfo = void 0;

  class KeywordKeywordsRequestInfo {
    static create(id, parameters) {
      return {
        method: 'keyword.Keywords',
        version: '0.6',
        id,
        parameters
      };
    }

  }

  _exports.KeywordKeywordsRequestInfo = KeywordKeywordsRequestInfo;
});