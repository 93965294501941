define("clarify/templates/application-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+rka9lxv",
    "block": "{\"symbols\":[],\"statements\":[[8,\"alert/provider\",[],[[],[]],null],[2,\" \"],[10,\"div\"],[14,0,\"application\"],[12],[10,\"div\"],[14,0,\"main-application-container\"],[12],[8,\"app-header\",[],[[\"@style\"],[\"dark\"]],null],[2,\" \"],[8,\"error\",[],[[\"@title\",\"@description\"],[[30,[36,0],[\"application-error.title\"],null],[30,[36,0],[\"application-error.description\"],null]]],null],[13],[13]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "clarify/templates/application-error.hbs"
    }
  });

  _exports.default = _default;
});