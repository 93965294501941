define("clarify/routes/organizations/show/timelines/index", ["exports", "clarify/utilities/rpc/keyword-keywords"], function (_exports, _keywordKeywords) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TimelinesIndex = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, (_class = (_temp = class TimelinesIndex extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "network", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _initializerDefineProperty(this, "mobileUpsell", _descriptor4, this);
    }

    beforeModel(transition) {
      if (this.mobileUpsell.shouldDisplay(transition)) {
        this.mobileUpsell.transition = transition;
        this.intermediateTransitionTo('application-mobile-upsell');
        transition.abort();
      }
    }

    async model() {
      let {
        organization
      } = this.modelFor('organizations/show');
      let keywords = this.fetchKeywords(organization.id);
      return await Ember.RSVP.hash({
        keywords
      });
    }

    async fetchKeywords(organizationId) {
      let request = _keywordKeywords.KeywordKeywordsRequestInfo.create(organizationId, {
        organization: organizationId,
        resources: ['timelines'],
        selection: null
      });

      return await this.network.perform('meta/rpc', {}, request);
    }

    setupController(controller, model) {
      Ember.set(controller, 'keywords', model.keywords);
      let {
        organization
      } = this.modelFor('organizations/show');
      Ember.set(controller, 'organization', organization);
    }

    titleToken() {
      return this.intl.t('routes.organizations/show/timelines/index');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "network", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "mobileUpsell", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = TimelinesIndex;
});