define("clarify/locales/en-us", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    presentTime: {
      timestamp: 'h:mm aaa',
      date: 'do LLL'
    },
    ruler: {
      millisecond: 'h:mm:ss.SSS aaa',
      seconds: 'h:mm:ss aaa',
      minutes: 'h:mm aaa',
      datetime: 'do LLL h:mm aaa',
      date: 'do LLL'
    },
    threads: {
      header: 'LLLL do, yyyy – hh:mm aaa',
      dateHeader: 'iiii, LLLL do, yyyy',
      avatarHeader: 'hh:mm aaa'
    },
    timeRangeTooltip: {
      date: 'iii LLL d, yyyy',
      time: 'hh:mm aaa',
      timeWithSeconds: 'hh:mm:ss aaa'
    },
    ticks: {
      time: {
        full: 'hh:mm:ss',
        short: 'hh:mm'
      },
      date: {
        full: 'EEE d LLL yyyy',
        medium: 'EEE d LLL',
        short: 'd LLL'
      },
      tokens: {
        month: 'LLLL',
        shortMonth: 'LLL',
        year: 'yyyy'
      }
    },
    numbers: {
      decimalPoint: '.',
      groupIndicator: ','
    }
  };
  _exports.default = _default;
});