define("clarify/transforms/threshold-display-options", ["exports", "clarify/transforms/object-transform"], function (_exports, _objectTransform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _objectTransform.default;
    }
  });
});