define("clarify/utilities/rpc/organization/new", ["exports", "clarify/utilities/uuid"], function (_exports, _uuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.OrganizationNewRequestInfo = void 0;

  class OrganizationNewRequestInfo {
    static create(parameters) {
      let id = (0, _uuid.default)();
      return {
        method: 'organization.New',
        version: '0.6',
        id,
        parameters
      };
    }

  }

  _exports.OrganizationNewRequestInfo = OrganizationNewRequestInfo;
});