define("clarify/utilities/partition-comments", ["exports", "date-fns"], function (_exports, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.partitionComments = partitionComments;

  function partitionComments(comments) {
    if (comments.length === 0) {
      return [];
    }

    let cells = [];
    let comment = comments[0];
    cells.push({
      type: 'header',
      user: comment.createdByUser,
      date: comment.createdAt
    });
    cells.push({
      type: 'comment',
      comment: comment
    });
    comments.slice(1).forEach(c => {
      let sameDay = (0, _dateFns.isSameDay)(c.createdAt, comment.createdAt);

      if (sameDay === false) {
        cells.push({
          type: 'date-header',
          date: (0, _dateFns.startOfDay)(c.createdAt)
        });
      }

      if (c.createdById !== comment.createdById || (0, _dateFns.differenceInMinutes)(c.createdAt, comment.createdAt) > 5 || sameDay === false) {
        cells.push({
          type: 'header',
          user: c.createdByUser,
          date: c.createdAt
        });
      }

      cells.push({
        type: 'comment',
        comment: c
      });
      comment = c;
    });
    return cells;
  }
});