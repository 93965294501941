define("clarify/components/m-visualization-settings/menu/display-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <header class="m-visualization-settings-menu-content">
    <p class="m-visualization-settings-menu-content-title">
      {{t "timeline-visualization-settings.menu.display-type.header"}}
    </p>
  </header>
  <div class="m-visualization-settings-display-type-header-content-container">
    <TabBar
      class="m-visualization-settings-display-type-d-flex"
      @valueDidChange={{fn @visualizationTypeDidChange @visualization}}
      @value={{@visualization.type}} as |TabBarItem|
    >
      <TabBarItem class="m-visualization-settings-display-type-control-item" @value="line-chart">
        <MVisualizationSettings::Menu::TypeContent
          @title={{t "visualization-settings.types.line-chart"}}
          @iconName="#design-system-display-graph-24"
        />
      </TabBarItem>
      <TabBarItem class="m-visualization-settings-display-type-control-item" @value="status">
        <MVisualizationSettings::Menu::TypeContent
          @title={{t "visualization-settings.types.status"}}
          @iconName="#design-system-display-status-24"
        />
      </TabBarItem>
    </TabBar>
  </div>
  */
  {"id":"zNJtZbXW","block":"{\"symbols\":[\"TabBarItem\",\"@visualization\",\"@visualizationTypeDidChange\"],\"statements\":[[10,\"header\"],[14,0,\"m-visualization-settings-menu-content\"],[12],[2,\"\\n  \"],[10,\"p\"],[14,0,\"m-visualization-settings-menu-content-title\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"timeline-visualization-settings.menu.display-type.header\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"m-visualization-settings-display-type-header-content-container\"],[12],[2,\"\\n  \"],[8,\"tab-bar\",[[24,0,\"m-visualization-settings-display-type-d-flex\"]],[[\"@valueDidChange\",\"@value\"],[[30,[36,1],[[32,3],[32,2]],null],[32,2,[\"type\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1],[[24,0,\"m-visualization-settings-display-type-control-item\"]],[[\"@value\"],[\"line-chart\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"m-visualization-settings/menu/type-content\",[],[[\"@title\",\"@iconName\"],[[30,[36,0],[\"visualization-settings.types.line-chart\"],null],\"#design-system-display-graph-24\"]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,[32,1],[[24,0,\"m-visualization-settings-display-type-control-item\"]],[[\"@value\"],[\"status\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"m-visualization-settings/menu/type-content\",[],[[\"@title\",\"@iconName\"],[[30,[36,0],[\"visualization-settings.types.status\"],null],\"#design-system-display-status-24\"]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"fn\"]}","meta":{"moduleName":"clarify/components/m-visualization-settings/menu/display-type.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});