define("clarify/routes/organizations/show/timelines/show/stats/show", ["exports", "clarify/errors/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TimelinesShowStatsShowRoute extends Ember.Route {
    async model({
      visualizationId
    }) {
      let {
        layout
      } = this.modelFor('organizations/show/timelines/show/stats');
      let visualization = layout.visualizations.find(visualization => visualization.id === visualizationId);

      if (visualization) {
        return {
          visualization
        };
      }

      return Promise.reject(new _route.default(_route.RouteErrorType.notFound));
    }

    setupController(controller, model) {
      Ember.set(controller, 'visualization', model.visualization);
    }

  }

  _exports.default = TimelinesShowStatsShowRoute;
});