define("clarify/serializers/category", ["exports", "clarify/serializers/rest-layer"], function (_exports, _restLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Category extends _restLayer.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "root", 'categories');
    }

    normalize(modelClass, resourceHash, prop) {
      const payload = {
        id: resourceHash.key,
        title: resourceHash.title
      };
      return super.normalize(modelClass, payload, prop);
    }

  }

  _exports.default = Category;
});