define("clarify/components/export-modal/signals/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="export-modal-items-signals">
    <VisualizationItemIcon
      class="page-checkbox-icon"
      @visualizationItem={{@visualizationItemViewModel.visualizationItem}}
    />
    <div class="page-checkbox-signals">
      <span>
        {{@visualizationItemViewModel.visualizationItem.item.name}}
      </span>
      <br />
      <small
        title={{t
          "export-modal.items.signal-title"
          dataSource=@visualizationItemViewModel.visualizationItem.item.dataSource
          location=@visualizationItemViewModel.visualizationItem.item.locationValue
        }}
      >
        {{#if
          (and
            @visualizationItemViewModel.visualizationItem.item.dataSource.length
            @visualizationItemViewModel.visualizationItem.item.locationValue.length
          )
        }}
          {{t
            "export-modal.items.signal-title"
            dataSource=@visualizationItemViewModel.visualizationItem.item.dataSource
            location=@visualizationItemViewModel.visualizationItem.item.locationValue
          }}
        {{/if}}
      </small>
    </div>
    <div class="page-checkbox-signals-unit">
      {{@visualizationItemViewModel.visualizationItem.item.engUnit}}
    </div>
  </div>
  */
  {"id":"TDAbRj2S","block":"{\"symbols\":[\"@visualizationItemViewModel\"],\"statements\":[[10,\"div\"],[14,0,\"export-modal-items-signals\"],[12],[2,\"\\n  \"],[8,\"visualization-item-icon\",[[24,0,\"page-checkbox-icon\"]],[[\"@visualizationItem\"],[[32,1,[\"visualizationItem\"]]]],null],[2,\"\\n  \"],[10,\"div\"],[14,0,\"page-checkbox-signals\"],[12],[2,\"\\n    \"],[10,\"span\"],[12],[2,\"\\n      \"],[1,[32,1,[\"visualizationItem\",\"item\",\"name\"]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"br\"],[12],[13],[2,\"\\n    \"],[10,\"small\"],[15,\"title\",[30,[36,0],[\"export-modal.items.signal-title\"],[[\"dataSource\",\"location\"],[[32,1,[\"visualizationItem\",\"item\",\"dataSource\"]],[32,1,[\"visualizationItem\",\"item\",\"locationValue\"]]]]]],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,1,[\"visualizationItem\",\"item\",\"dataSource\",\"length\"]],[32,1,[\"visualizationItem\",\"item\",\"locationValue\",\"length\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,0],[\"export-modal.items.signal-title\"],[[\"dataSource\",\"location\"],[[32,1,[\"visualizationItem\",\"item\",\"dataSource\"]],[32,1,[\"visualizationItem\",\"item\",\"locationValue\"]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"page-checkbox-signals-unit\"],[12],[2,\"\\n    \"],[1,[32,1,[\"visualizationItem\",\"item\",\"engUnit\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"and\",\"if\"]}","meta":{"moduleName":"clarify/components/export-modal/signals/index.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});