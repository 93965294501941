define("clarify/components/organizations/show/items-quest/index", ["exports", "@glimmer/component", "xstate", "ember-animated/motions/move", "ember-animated/easings/cosine", "clarify/components/timeline-filter/state-machine"], function (_exports, _component, _xstate, _move, _cosine, _stateMachine) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.isLoading}}
    <Organizations::Show::Timelines::State::LoadingEpilogue @content={{t "items-quest.items-state.loading"}} />
  {{else if this.isFailed}}
    Initial failed
  {{else if this.hasContent}}
    <Organizations::Show::ItemsQuest::Items
      @keywords={{this.keywords}}
      @resource={{this.resource}}
      @keywordFilterTokens={{this.keywordFilterTokens}}
      @updateKeywordFilterTokens={{fn this.updateKeywordFilterTokens}}
      @timeline={{@timeline}}
      @handleKeywordDidChange={{fn this.handleKeywordDidChange}}
      @handleAddItem={{fn this.handleAddItem}}
      @isLoading={{this.isContentLoading}}
      @isFailed={{this.isContentFailed}}
      @isLoaded={{this.isContentLoaded}}
    />
  {{/if}}
  */
  {"id":"1R5USH3g","block":"{\"symbols\":[\"@timeline\"],\"statements\":[[6,[37,1],[[32,0,[\"isLoading\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"organizations/show/timelines/state/loading-epilogue\",[],[[\"@content\"],[[30,[36,2],[\"items-quest.items-state.loading\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[32,0,[\"isFailed\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  Initial failed\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[32,0,[\"hasContent\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"organizations/show/items-quest/items\",[],[[\"@keywords\",\"@resource\",\"@keywordFilterTokens\",\"@updateKeywordFilterTokens\",\"@timeline\",\"@handleKeywordDidChange\",\"@handleAddItem\",\"@isLoading\",\"@isFailed\",\"@isLoaded\"],[[32,0,[\"keywords\"]],[32,0,[\"resource\"]],[32,0,[\"keywordFilterTokens\"]],[30,[36,0],[[32,0,[\"updateKeywordFilterTokens\"]]],null],[32,1],[30,[36,0],[[32,0,[\"handleKeywordDidChange\"]]],null],[30,[36,0],[[32,0,[\"handleAddItem\"]]],null],[32,0,[\"isContentLoading\"]],[32,0,[\"isContentFailed\"]],[32,0,[\"isContentLoaded\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"fn\",\"if\",\"t\"]}","meta":{"moduleName":"clarify/components/organizations/show/items-quest/index.hbs"}});

  let Index = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, (_class = (_temp = class Index extends _component.default {
    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "network", _descriptor, this);

      _initializerDefineProperty(this, "layoutService", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "interpret", _descriptor4, this);

      _initializerDefineProperty(this, "current", _descriptor5, this);

      _initializerDefineProperty(this, "keywordFilterTokens", _descriptor6, this);

      let dymaicMachine = _stateMachine.default.withContext({
        resource: null,
        keywords: null,
        organization: args.organization.id,
        network: this.network,
        store: this.store
      }); // @ts-ignore


      this.interpret = (0, _xstate.interpret)(dymaicMachine);
      this.interpret.onTransition(state => {
        this.current = state;
      });
      this.interpret.start();
      this.interpret.send({
        type: 'FETCH',
        selection: null
      });
    }

    willDestroy() {
      super.willDestroy();
      this.interpret.stop();
    }

    get hasContent() {
      return this.current.matches('content');
    }

    get isContentLoading() {
      return this.current.matches('content.loading');
    }

    get isContentLoaded() {
      return this.current.matches('content.content');
    }

    get isContentFailed() {
      return this.current.matches('content.failed');
    }

    get isLoading() {
      return this.current.matches('loading');
    }

    get isFailed() {
      return this.current.matches('failure');
    }

    get keywords() {
      return this.current.context.keywords;
    }

    get resource() {
      return this.current.context.resource;
    }

    *transition({
      insertedSprites,
      keptSprites,
      removedSprites
    }) {
      for (let sprite of insertedSprites) {
        if (sprite.finalBounds) {
          sprite.startAtPixel({
            x: -sprite.finalBounds.width
          });
        }

        (0, _move.default)(sprite, {
          duration: 150,
          easing: _cosine.easeOut
        });
      }

      for (let sprite of keptSprites) {
        (0, _move.default)(sprite);
      }

      for (let sprite of removedSprites) {
        if (sprite.initialBounds) {
          sprite.endAtPixel({
            x: -sprite.initialBounds.width
          });
        }

        (0, _move.default)(sprite, {
          duration: 150,
          easing: _cosine.easeIn
        });
      }
    }

    async updateKeywordFilterTokens(keywordFilterTokens) {
      this.keywordFilterTokens = keywordFilterTokens;
    }

    handleKeywordDidChange(categories, searchText) {
      this.interpret.send({
        type: 'FETCH',
        selection: {
          categories,
          searchText
        }
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "network", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "layoutService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "interpret", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "current", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "keywordFilterTokens", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new Array();
    }
  }), _applyDecoratedDescriptor(_class.prototype, "updateKeywordFilterTokens", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "updateKeywordFilterTokens"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleKeywordDidChange", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "handleKeywordDidChange"), _class.prototype)), _class));
  _exports.default = Index;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Index);
});