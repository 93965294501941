define("clarify/components/routes/invitations/show/index", ["exports", "@glimmer/component", "ember-concurrency-decorators", "clarify/utilities/rpc/organization/accept-invite"], function (_exports, _component, _emberConcurrencyDecorators, _acceptInvite) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="onboarding-container">
    <div class="onboarding-content">
      <div class="invitations-show">
        <h1 class="invitations-show--title">
          {{t "invitations-show.title" name=@invitation.inviter.name}}
        </h1>
        <p class="invitations-show--description">
          {{t "invitations-show.description" name=@invitation.inviter.name organization=@invitation.organization.name}}
        </p>
        <FlatButton
          class="invitations-show--join-button"
          @size="extra-large"
          @text={{t "invitations-show.join-button"}}
          @loading={{this.acceptInvitation.isRunning}}
          {{on "click" (perform this.acceptInvitation)}}
        />
      </div>
    </div>
  </div>
  */
  {"id":"eyR9tBB0","block":"{\"symbols\":[\"@invitation\"],\"statements\":[[10,\"div\"],[14,0,\"onboarding-container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"onboarding-content\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"invitations-show\"],[12],[2,\"\\n      \"],[10,\"h1\"],[14,0,\"invitations-show--title\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"invitations-show.title\"],[[\"name\"],[[32,1,[\"inviter\",\"name\"]]]]]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"p\"],[14,0,\"invitations-show--description\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"invitations-show.description\"],[[\"name\",\"organization\"],[[32,1,[\"inviter\",\"name\"]],[32,1,[\"organization\",\"name\"]]]]]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[8,\"flat-button\",[[24,0,\"invitations-show--join-button\"],[4,[38,2],[\"click\",[30,[36,1],[[32,0,[\"acceptInvitation\"]]],null]],null]],[[\"@size\",\"@text\",\"@loading\"],[\"extra-large\",[30,[36,0],[\"invitations-show.join-button\"],null],[32,0,[\"acceptInvitation\",\"isRunning\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"perform\",\"on\"]}","meta":{"moduleName":"clarify/components/routes/invitations/show/index.hbs"}});

  let InvitationShow = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, (_class = (_temp = class InvitationShow extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "authentication", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "network", _descriptor3, this);

      _initializerDefineProperty(this, "alert", _descriptor4, this);

      _initializerDefineProperty(this, "intl", _descriptor5, this);
    }

    *acceptInvitation() {
      try {
        let request = _acceptInvite.AcceptInviteRequestInfo.create({
          secret: this.args.invitation.secret
        });

        let data = yield this.network.perform('meta/rpc', {}, request);
        yield this.authentication.checkSession(true);
        this.router.transitionTo('organizations.show.timelines', data.organization.id);
      } catch (error) {
        console.error('InvitationShow#acceptInvitation failed with error: ', error);
        this.alert.show({
          title: this.intl.t('groups-new.alerts.errors.title'),
          message: this.intl.t('groups-new.alerts.errors.message'),
          actions: [{
            title: this.intl.t('groups-new.alerts.errors.dismiss'),
            defaultButton: true,
            action: () => {
              this.alert.dismissAlert();
            }
          }]
        });
      }

      return undefined;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "authentication", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "network", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "alert", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "acceptInvitation", [_emberConcurrencyDecorators.enqueueTask], Object.getOwnPropertyDescriptor(_class.prototype, "acceptInvitation"), _class.prototype)), _class));
  _exports.default = InvitationShow;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, InvitationShow);
});