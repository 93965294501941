define("clarify/adapters/comment", ["exports", "clarify/adapters/meta"], function (_exports, _meta) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Comment extends _meta.default {
    fields(_requestType) {
      return ['id', 'createdAt', 'createdBy', 'updatedAt', 'updatedBy', 'timeline', 'thread', 'organization', 'content', 'attachments', 'contentReferences'];
    }

    urlForQuery(query, _modelName) {
      let path = this.buildURL('thread', query.thread);
      return `${path}/comments`;
    }

    urlForDeleteRecord(id, _modelName, snapshot) {
      let typedSnapshot = snapshot;
      let thread = typedSnapshot.belongsTo('thread', {
        id: true
      });
      let path = this.buildURL('thread', thread);
      return `${path}/comments/${id}`;
    }

    urlForCreateRecord(_modelName, snapshot) {
      let typedSnapshot = snapshot;
      let thread = typedSnapshot.belongsTo('thread', {
        id: true
      });
      return `${this.host}/${this.namespace}/threads/${thread}/comments`;
    }

  }

  _exports.default = Comment;
});