define("clarify/templates/organizations/show/activities-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2M8HQnDZ",
    "block": "{\"symbols\":[],\"statements\":[[8,\"app-header\",[],[[\"@style\"],[\"light\"]],null],[2,\" \"],[8,\"app-sub-header\",[],[[\"@style\"],[\"light\"]],null],[2,\" \"],[8,\"organizations/show/timelines/state/loading-epilogue\",[],[[\"@content\"],[[30,[36,0],[\"activity-feed.state.loading-epilogue\"],null]]],null]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "clarify/templates/organizations/show/activities-loading.hbs"
    }
  });

  _exports.default = _default;
});