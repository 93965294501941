define("clarify/services/layout-service", ["exports", "clarify/utilities/uuid", "clarify/models/display-options", "clarify/models/visualization", "clarify/colors", "clarify/models/item", "clarify/utilities/compact-map"], function (_exports, _uuid, _displayOptions, _visualization, _colors, _item, _compactMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let LayoutService = (_dec = Ember.inject.service, (_class = (_temp = class LayoutService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);
    }

    addVisualization(items) {
      let type = this.visualizationTypeForItems(items);
      let {
        visualizationItems,
        relationships
      } = this.createVisualizationItems(items);
      const payload = {
        data: {
          id: (0, _uuid.default)(),
          type: 'visualization',
          attributes: {
            name: '',
            type: type,
            displayOptions: _displayOptions.default.create({}),
            thresholdOptions: {
              enable: false
            }
          },
          relationships: {
            visualizationItems: {
              data: relationships
            }
          }
        },
        included: visualizationItems
      };
      const visualization = this.store.push(payload);
      return visualization;
    }

    addVisualizationWithVisualizationItems(visualizationItems) {
      let items = visualizationItems.map(visualizationItem => visualizationItem.item);
      let type = this.visualizationTypeForItems(items);
      let visualization = this.addVisualization([]);
      Ember.set(visualization, 'type', type);
      visualization.visualizationItems.setObjects(visualizationItems);
      return visualization;
    }

    addItems(items, visualization) {
      let length = visualization.visualizationItems.toArray().length;
      let {
        visualizationItems
      } = this.createVisualizationItems(items, length);
      let visualizationItemsModels = this.store.push({
        data: visualizationItems
      });
      visualization.visualizationItems.pushObjects(visualizationItemsModels);
    }

    setVisualizationItems(visualizationItems, visualization) {
      visualization.visualizationItems.setObjects(visualizationItems);
    }

    createVisualization(layout, item) {
      let visualization = this.addVisualization([item]);
      layout.visualizations.pushObject(visualization);
    }

    removeItem(_layout, item) {
      item.visualization.visualizationItems.removeObject(item);
    }

    removeVisualization(visualization, layout) {
      layout.visualizations.removeObject(visualization);
    } // Private


    visualizationTypeForItems(items) {
      let accessible = (0, _compactMap.default)(items, item => {
        if (_item.default.isAccessible(item)) {
          return item;
        }

        return null;
      });
      let numericItem = accessible.find(item => item.type === _item.ItemType.numeric);
      let enumItem = accessible.find(item => item.type === _item.ItemType.enum);

      if (enumItem && !numericItem) {
        return _visualization.VisualizationType.status;
      }

      return _visualization.VisualizationType.lineChart;
    }

    createVisualizationItems(items, insertionIndex = 0) {
      let visualizationItems = new Array();
      let relationships = new Array();
      items.forEach((item, index) => {
        let visualizationItemId = (0, _uuid.default)();
        let visualizationItem = {
          id: visualizationItemId,
          type: 'visualization-item',
          attributes: {
            thresholdOptions: {
              enable: false
            },
            displayOptions: _displayOptions.default.create({
              color: _colors.defaultColors[(insertionIndex + index) % _colors.defaultColors.length]
            })
          },
          relationships: {
            item: {
              data: {
                id: item.id,
                type: 'item'
              }
            }
          }
        };
        visualizationItems.push(visualizationItem);
        relationships.push({
          id: visualizationItemId,
          type: 'visualization-item'
        });
      });
      return {
        visualizationItems,
        relationships
      };
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = LayoutService;
});