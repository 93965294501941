define("clarify/components/m-visualization-settings/menu/index", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <VisualizationSettings::FieldsetTabView @tabs={{this.options}} @style="mild" as |value|>
    <div class="m-visualization-settings-menu-container">
      {{#if (eq value "line-chart-display-type")}}
        <MVisualizationSettings::Menu::DisplayType
          @visualization={{@visualization}}
          @visualizationTypeDidChange={{fn @visualizationTypeDidChange}}
        />
      {{/if}}
      {{#if (eq value "line-chart-axis-scaling")}}
        <VisualizationSettings::LineChartRangeFieldset
          @axisScaling={{@visualization.displayOptions.axisScaleScaling}}
          @rawMinValue={{@visualization.displayOptions.axisScaleMin}}
          @rawMaxValue={{@visualization.displayOptions.axisScaleMax}}
          @rangeDidChange={{fn @lineChartRangeDidChange @visualization}}
          @style="light"
        />
      {{/if}}
      {{#if (eq value "line-chart-thresholds")}}
        <Thresholds
          @layout={{@layout}}
          @timeline={{@timeline}}
          @source={{@visualization}}
          @thresholds={{@visualization.thresholds}}
        />
      {{/if}}
    </div>
  </VisualizationSettings::FieldsetTabView>
  */
  {"id":"3wN+O0xE","block":"{\"symbols\":[\"value\",\"@layout\",\"@timeline\",\"@visualization\",\"@lineChartRangeDidChange\",\"@visualizationTypeDidChange\"],\"statements\":[[8,\"visualization-settings/fieldset-tab-view\",[],[[\"@tabs\",\"@style\"],[[32,0,[\"options\"]],\"mild\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"m-visualization-settings-menu-container\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,1],\"line-chart-display-type\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"m-visualization-settings/menu/display-type\",[],[[\"@visualization\",\"@visualizationTypeDidChange\"],[[32,4],[30,[36,0],[[32,6]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,2],[[30,[36,1],[[32,1],\"line-chart-axis-scaling\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"visualization-settings/line-chart-range-fieldset\",[],[[\"@axisScaling\",\"@rawMinValue\",\"@rawMaxValue\",\"@rangeDidChange\",\"@style\"],[[32,4,[\"displayOptions\",\"axisScaleScaling\"]],[32,4,[\"displayOptions\",\"axisScaleMin\"]],[32,4,[\"displayOptions\",\"axisScaleMax\"]],[30,[36,0],[[32,5],[32,4]],null],\"light\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,2],[[30,[36,1],[[32,1],\"line-chart-thresholds\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"thresholds\",[],[[\"@layout\",\"@timeline\",\"@source\",\"@thresholds\"],[[32,2],[32,3],[32,4],[32,4,[\"thresholds\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"fn\",\"eq\",\"if\"]}","meta":{"moduleName":"clarify/components/m-visualization-settings/menu/index.hbs"}});

  class VisualizationSettingsMenu extends _component.default {
    get options() {
      return ['line-chart-display-type', 'line-chart-axis-scaling', 'line-chart-thresholds'];
    }

  }

  _exports.default = VisualizationSettingsMenu;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, VisualizationSettingsMenu);
});