define("clarify/utilities/rpc/integration/publish-signal", ["exports", "clarify/utilities/uuid"], function (_exports, _uuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PublishSignalRequestInfo = void 0;

  class PublishSignalRequestInfo {
    static create(parameters) {
      let id = (0, _uuid.default)();
      return {
        method: 'integration.PublishSignal',
        version: '0.6',
        id,
        parameters
      };
    }

  }

  _exports.PublishSignalRequestInfo = PublishSignalRequestInfo;
});