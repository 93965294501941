define("clarify/components/timeline-canvas/present-ruler-popover/index", ["exports", "@glimmer/component", "clarify/utilities/zoom-levels"], function (_exports, _component, _zoomLevels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="present-ruler-popover" {{transform @left}}>
    <div class="present-ruler-popover-title">
      {{this.title}}
    </div>
    <div class="present-ruler-popover-description">
      {{this.description}}
    </div>
  </div>
  */
  {"id":"/2oMJsqI","block":"{\"symbols\":[\"@left\"],\"statements\":[[11,\"div\"],[24,0,\"present-ruler-popover\"],[4,[38,0],[[32,1]],null],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"present-ruler-popover-title\"],[12],[2,\"\\n    \"],[1,[32,0,[\"title\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"present-ruler-popover-description\"],[12],[2,\"\\n    \"],[1,[32,0,[\"description\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"transform\"]}","meta":{"moduleName":"clarify/components/timeline-canvas/present-ruler-popover/index.hbs"}});

  let PresentRulerPopover = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = (_temp = class PresentRulerPopover extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "locale", _descriptor2, this);
    }

    get title() {
      switch (this.args.format) {
        case _zoomLevels.PresentTimeFormat.date:
          return this.intl.t('present-ruler-popover.today');

        case _zoomLevels.PresentTimeFormat.timestamp:
          return this.intl.t('present-ruler-popover.now');
      }
    }

    get description() {
      switch (this.args.format) {
        case _zoomLevels.PresentTimeFormat.timestamp:
          return this.locale.format(this.args.date, this.locale.current.presentTime.timestamp);

        case _zoomLevels.PresentTimeFormat.date:
          return this.locale.format(this.args.date, this.locale.current.presentTime.date);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "locale", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = PresentRulerPopover;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PresentRulerPopover);
});