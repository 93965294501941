define("clarify/instance-initializers/focus-visible", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function initialize() {
    document.body.addEventListener('mousedown', function () {
      document.body.classList.add('using-mouse');
    });
    document.body.addEventListener('keydown', function () {
      document.body.classList.remove('using-mouse');
    });
  }

  var _default = {
    name: 'focus-visible',
    initialize
  };
  _exports.default = _default;
});