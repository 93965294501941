define("clarify/adapters/organization", ["exports", "clarify/adapters/meta"], function (_exports, _meta) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Organization extends _meta.default {
    fields(_requestType) {
      return ['createdAt', 'createdBy', 'id', 'name', 'updatedAt', 'updatedBy'];
    }

  }

  _exports.default = Organization;
});