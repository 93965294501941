define("clarify/components/timeline-filter/state-machine", ["exports", "xstate", "clarify/utilities/rpc/keyword-keywords", "clarify/utilities/resource"], function (_exports, _xstate, _keywordKeywords, _resource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _xstate.Machine)({
    id: 'fetch',
    initial: 'idle',
    states: {
      idle: {
        on: {
          FETCH: 'loading'
        }
      },
      loading: {
        invoke: {
          id: 'fetch',
          src: fetchTimelines,
          onDone: {
            target: 'content',
            actions: (0, _xstate.assign)({
              resource: (_context, event) => event.data.resource,
              keywords: (_content, event) => event.data.keywords
            })
          },
          onError: 'failure'
        }
      },
      content: {
        initial: 'content',
        states: {
          content: {
            on: {
              FETCH: 'loading'
            }
          },
          failure: {},
          loading: {
            invoke: {
              id: 'fetch',
              src: fetchTimelines,
              onDone: {
                target: 'content',
                actions: (0, _xstate.assign)({
                  resource: (_context, event) => event.data.resource,
                  keywords: (_content, event) => event.data.keywords
                })
              },
              onError: 'failure'
            }
          }
        }
      },
      failure: {
        on: {
          RETRY: {
            target: 'loading'
          }
        }
      }
    }
  });

  _exports.default = _default;

  async function fetchTimelines(context, event) {
    let request = _keywordKeywords.KeywordKeywordsRequestInfo.create(context.organization, {
      organization: context.organization,
      resources: ['items'],
      selection: event.selection
    });

    try {
      let [result] = await context.network.rpc('meta/rpc', {}, request);

      if (result.type === 'result') {
        let filter = {
          filter: result.result.filters.items
        };

        let resource = _resource.default.create({
          store: context.store,
          type: 'item',
          query: { ...filter
          }
        });

        await resource.load();
        return {
          resource,
          keywords: result.result
        };
      } else {
        console.error(result.error);
        return Promise.reject(result.error);
      }
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  }
});