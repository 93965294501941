define("clarify/components/visualization-settings/numeric-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="settings-field">
    <label class="settings-field-label">
      <div class="settings-field-title">
        {{@fieldName}}
      </div>
      <AInputField::-editLabel
        class="settings-field-input"
        data-is-invalid={{@field.isInvalid}}
        placeholder={{t "line-chart-range-fieldset.placeholder"}}
        type="text"
        {{setup-field @field validate="form"}}
      />
    </label>
    {{#if @field.isInvalid}}
      <div class="settings-field-validation-error">
        {{t "line-chart-range-fieldset.invalid"}}
      </div>
    {{/if}}
  </div>
  */
  {"id":"6KVBEGHf","block":"{\"symbols\":[\"@fieldName\",\"@field\"],\"statements\":[[10,\"div\"],[14,0,\"settings-field\"],[12],[2,\"\\n  \"],[10,\"label\"],[14,0,\"settings-field-label\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"settings-field-title\"],[12],[2,\"\\n      \"],[1,[32,1]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[8,\"a-input-field/-edit-label\",[[24,0,\"settings-field-input\"],[16,\"data-is-invalid\",[32,2,[\"isInvalid\"]]],[16,\"placeholder\",[30,[36,0],[\"line-chart-range-fieldset.placeholder\"],null]],[24,4,\"text\"],[4,[38,1],[[32,2]],[[\"validate\"],[\"form\"]]]],[[],[]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,2],[[32,2,[\"isInvalid\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"settings-field-validation-error\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"line-chart-range-fieldset.invalid\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"setup-field\",\"if\"]}","meta":{"moduleName":"clarify/components/visualization-settings/numeric-field.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});