define("clarify/drag-images/visualization", ["exports", "clarify/drag-images/item", "clarify/initializers/images"], function (_exports, _item, _images) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class VisualizationDragImage extends Ember.Object {
    draw(canvasConstructor, models, event) {
      let model = models[0];

      if (!model) {
        return null;
      }

      let clientRect = event.target.getBoundingClientRect();
      let position = {
        x: event.pageX - clientRect.left,
        y: event.pageY - clientRect.top
      };
      let canvas = canvasConstructor(352, 44);
      let context = canvas.getContext('2d');
      (0, _item.roundedRect)(context, 0, 0, 352, 44, 8);
      context.fillStyle = '#384761';
      context.fill();
      context.drawImage(_images.dragImageVisualization, 13, 13);
      let titleTypesetter = (0, _item.createTypesetter)(context, {
        fill: 'white',
        font: 'bold 15px roboto'
      });
      context.save();
      context.translate(42, 0);
      titleTypesetter.write(Ember.get(model, 'name') || 'No name', 192, 44, {
        yAlign: 'center'
      });
      context.restore();
      return {
        canvas,
        x: position.x,
        y: 10
      };
    }

  }

  _exports.default = VisualizationDragImage;
});