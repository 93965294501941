define("clarify/serializers/comment", ["exports", "clarify/serializers/rest-layer"], function (_exports, _restLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Comment extends _restLayer.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "root", 'comments');

      _defineProperty(this, "attrs", {
        timeline: {
          serialize: 'ids'
        },
        thread: {
          serialize: false
        }
      });
    }

    normalize(modelClass, resourceHash, prop) {
      const payload = { ...resourceHash,
        content: null,
        textContent: resourceHash.content,
        attachments: resourceHash.attachments || []
      };
      return super.normalize(modelClass, payload, prop);
    }

    serialize(snapshot, options) {
      const payload = super.serialize(snapshot, options);
      return {
        content: payload.textContent,
        contentReferences: payload.contentReferences,
        timeline: payload.timeline,
        organization: payload.organization,
        attachments: payload.attachments.map(attachment => {
          return {
            name: attachment.name
          };
        })
      };
    }

  }

  _exports.default = Comment;
});