define("clarify/helpers/resize-attachment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.previewSize = previewSize;
  _exports.urlForAttachment = urlForAttachment;
  _exports.default = _exports.ContentMode = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ContentMode;
  _exports.ContentMode = ContentMode;

  (function (ContentMode) {
    ContentMode[ContentMode["cover"] = 0] = "cover";
    ContentMode[ContentMode["contain"] = 1] = "contain";
    ContentMode[ContentMode["scaleDown"] = 2] = "scaleDown";
  })(ContentMode || (_exports.ContentMode = ContentMode = {}));

  function previewSize(imageSize, boundingSize, contentMode = ContentMode.cover) {
    let scaleX = boundingSize.width / imageSize.width;
    let scaleY = boundingSize.height / imageSize.height;
    let scale;

    switch (contentMode) {
      case ContentMode.cover:
        scale = Math.max(scaleX, scaleY);
        break;

      case ContentMode.contain:
        scale = Math.min(scaleX, scaleY);
        break;

      case ContentMode.scaleDown:
        scale = Math.min(scaleX, scaleY, 1);
        break;
    }

    let size = {
      width: Math.round(imageSize.width * scale),
      height: Math.round(imageSize.height * scale)
    };
    let offset = {
      x: (boundingSize.width - size.width) * 0.5,
      y: (boundingSize.height - size.height) * 0.5
    };
    return {
      size,
      offset
    };
  }

  function urlForAttachment(attachment, imageSize, contentMode) {
    let {
      size
    } = previewSize(attachment.dimensions, imageSize, contentMode);
    return `${attachment.previewURL}?preview&width=${size.width}&height=${size.height}`;
  }

  let ResizeHelper = (_dec = Ember.inject.service, (_class = (_temp = class ResizeHelper extends Ember.Helper {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "screen", _descriptor, this);
    }

    compute([attachment], {
      width,
      height
    }) {
      let size = {
        width: Math.floor(this.screen.devicePixelRatio * width),
        height: Math.floor(this.screen.devicePixelRatio * height)
      };
      return urlForAttachment(attachment, size, ContentMode.scaleDown);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "screen", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ResizeHelper;
});