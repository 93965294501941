define("clarify/templates/organizations/show/timelines/show/threads/show-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VLJT32WO",
    "block": "{\"symbols\":[],\"statements\":[[8,\"thread-error\",[],[[\"@title\"],[[30,[36,0],[\"thread.errors.deleted\"],null]]],null]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "clarify/templates/organizations/show/timelines/show/threads/show-error.hbs"
    }
  });

  _exports.default = _default;
});