define("clarify/instance-initializers/scroll-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'scroll-bar',
    initialize: async function (application) {
      let screen = application.lookup('service:screen');
      document.documentElement.style.setProperty('--env-scrollbar-width', `${screen.scrollBarWidth}px`);
    }
  };
  _exports.default = _default;
});