define("clarify/components/export-modal/items", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="export-modal-items-container">
    {{#if @visualizationViewModel.visualization.name}}
      <p>
        {{@visualizationViewModel.visualization.name}}
      </p>
    {{else}}
      <p>
        {{t "export-modal.items.empty-title"}}
      </p>
    {{/if}}
    {{#each @visualizationViewModel.visualizationItemViewModels as |visualizationItemViewModel|}}
      {{#if (is-visualization-item-accessible visualizationItemViewModel.visualizationItem)}}
        <ExportModal::VisualizationItem
          @visualizationItemViewModel={{visualizationItemViewModel}}
          @updateVisualizationItemViewModelMethod={{action @updateVisualizationItemViewModelMethod}}
        />
      {{/if}}
    {{else}}
      <div class="export-modal-items-empty-signal">
        {{t "export-modal.items.empty-signal"}}
      </div>
    {{/each}}
  </div>
  */
  {"id":"BHCjn9mm","block":"{\"symbols\":[\"visualizationItemViewModel\",\"@updateVisualizationItemViewModelMethod\",\"@visualizationViewModel\"],\"statements\":[[10,\"div\"],[14,0,\"export-modal-items-container\"],[12],[2,\"\\n\"],[6,[37,3],[[32,3,[\"visualization\",\"name\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[12],[2,\"\\n      \"],[1,[32,3,[\"visualization\",\"name\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"p\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"export-modal.items.empty-title\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,3,[\"visualizationItemViewModels\"]]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[32,1,[\"visualizationItem\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"export-modal/visualization-item\",[],[[\"@visualizationItemViewModel\",\"@updateVisualizationItemViewModelMethod\"],[[32,1],[30,[36,1],[[32,0],[32,2]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]},{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"export-modal-items-empty-signal\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"export-modal.items.empty-signal\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"is-visualization-item-accessible\",\"if\",\"-track-array\",\"each\"]}","meta":{"moduleName":"clarify/components/export-modal/items.hbs"}});

  class ExportModalItems extends _component.default {}

  _exports.default = ExportModalItems;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ExportModalItems);
});