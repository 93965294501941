define("clarify/routes/callback", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CallbackRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = (_temp = class CallbackRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "authentication", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "analytics", _descriptor3, this);
    }

    async beforeModel() {
      var _query$get, _query$get2;

      let query = new URLSearchParams(location.search);
      let invitation = (_query$get = query.get('invitation')) !== null && _query$get !== void 0 ? _query$get : undefined;
      let price = (_query$get2 = query.get('price')) !== null && _query$get2 !== void 0 ? _query$get2 : undefined;
      let email = query.get('passwordless');
      let source = query.get('utm_source');
      let campaign = query.get('utm_campaign');
      let medium = query.get('utm_medium');
      let term = query.get('utm_term');
      let content = query.get('utm_content');

      if (source && campaign && medium) {
        this.analytics.storeCampaign({
          source,
          campaign,
          medium,
          term,
          content
        });
      }

      const previousTransition = this.authentication.getAndResetTransition();
      await this.authentication.handleAuthentication(email ? {
        type: 'passwordless',
        email,
        invitation,
        price
      } : {
        type: 'login'
      });
      await this.authentication.checkSession();
      /** We allow users to login without logging out, that means the user might not be the user our
       * Analytics tool think it is. And we don't identify them until they have name and an organization.
       * To prevent incorrect tagging, we reset the analytics tool if the user isn't the one they think
       * it is. */

      let analyticsUserId = this.analytics.userId();

      if (analyticsUserId && analyticsUserId !== this.authentication.userId) {
        this.analytics.logout();
      }

      if (invitation) {
        this.router.transitionTo('invitations.show', invitation);
      } else if (!this.authentication.hasOrganizations()) {
        let price = query.get('price');
        let createOrganizationQueryParameters = new URLSearchParams();

        if (price) {
          createOrganizationQueryParameters.append('price', price);
        } // HACK: Use URL instead of route name, to make sure the ProtectedRoute saves the transition,
        // if transitioning to the setup route. Ember doesn’t easily expose the URL of a transition, so
        // storing the actual URL is a bit harder than necassary. As this is the only place we need this,
        // I opted for a simple solution – Bjarne.


        this.router.transitionTo(`/organizations/new?${createOrganizationQueryParameters.toString()}`);
      } else {
        this.router.transitionTo(previousTransition !== null && previousTransition !== void 0 ? previousTransition : 'index');
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "authentication", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "analytics", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CallbackRoute;
});