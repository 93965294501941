define("clarify/templates/organizations/show/timelines/show/settings/index-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9NkUlynx",
    "block": "{\"symbols\":[],\"statements\":[[8,\"settings-error\",[],[[\"@title\",\"@description\"],[[32,0,[\"errorMessage\",\"title\"]],[32,0,[\"errorMessage\",\"description\"]]]],null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "clarify/templates/organizations/show/timelines/show/settings/index-error.hbs"
    }
  });

  _exports.default = _default;
});