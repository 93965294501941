define("clarify/transforms/attachments", ["exports", "clarify/models/attachment", "clarify/transforms/ember-objects"], function (_exports, _attachment, _emberObjects) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _emberObjects.default)(_attachment.default);

  _exports.default = _default;
});