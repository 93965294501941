define("clarify/components/timeline-canvas/header/tick-bar", ["exports", "canvas/components/layer", "ui/helpers/design-system-particles"], function (_exports, _layer, _designSystemParticles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const backgroundColor = _designSystemParticles.darkSystemCanvasBgPrimary;
  const headlineFont = '12px/14px roboto';
  const headlineVerticalOffset = 11;
  const headlineTickWidth = 1;
  const headlineTickMargin = 6;
  const headlineTickMarginPinned = 16;
  const headlineTickTopMargin = 11;
  const headlineTickHeight = 14;
  const headlineTickColor = _designSystemParticles.darkNeutralTxtNeutralMedium;
  const headlineColor = _designSystemParticles.darkNeutralTxtNeutralMedium;
  const headlineMinimumSpacing = 20;
  const mainTickColor = _designSystemParticles.darkNeutralTxtNeutralFaded1up;
  const mainTickFont = '12px/14px roboto';
  const mainTickVerticalNegativeOffset = 32;

  class TickBar extends _layer.default {
    render() {
      let context = this.context;
      context.fillStyle = backgroundColor;
      context.fillRect(0, 0, this.args.width, this.args.height);
      context.strokeStyle = mainTickColor;
      this.paintTicks(context, this.args.scale, this.args.headlineTicks, this.args.mainTicks);
    }

    paintTicks(context, scale, headlineTicks, mainTicks) {
      context.textBaseline = 'top';
      mainTicks.forEach(tick => {
        context.beginPath();
        const position = scale(tick.value);
        context.font = mainTickFont;
        context.fillStyle = mainTickColor;
        const label = tick.label;
        const labelWidth = context.measureText(label).width;
        const labelPosition = Math.round(position - labelWidth / 2);
        context.fillText(label, labelPosition, mainTickVerticalNegativeOffset);
      });
      const maxWidth = this.args.width;
      context.beginPath();
      headlineTicks.forEach(headline => {
        const headlinePosition = scale(headline.value);
        context.strokeStyle = headlineTickColor;
        context.lineWidth = headlineTickWidth;
        context.moveTo(Math.floor(headlinePosition), headlineTickTopMargin);
        context.lineTo(Math.floor(headlinePosition), headlineTickTopMargin + headlineTickHeight);
      });
      context.stroke();
      context.font = headlineFont;
      context.fillStyle = headlineColor;

      for (let index = headlineTicks.length - 1; index >= 0; index--) {
        let headline = headlineTicks[index];
        let headlinePosition = scale(headline.value);
        let headlineLabel = headline.label;

        if (headlinePosition + headlineTickMargin < headlineTickMarginPinned) {
          const nextPos = headlineTicks.length > index + 1 ? scale(headlineTicks[index + 1].value) : maxWidth;
          const headlineWidth = context.measureText(headlineLabel).width;

          if (headlineWidth + headlineMinimumSpacing >= nextPos) {
            context.fillText(headlineLabel, nextPos - headlineWidth - headlineTickMargin, headlineVerticalOffset);
          } else {
            context.fillText(headlineLabel, headlineTickMarginPinned, headlineVerticalOffset);
          }

          break;
        }

        context.fillText(headlineLabel, headlinePosition + headlineTickMargin, headlineVerticalOffset);
      }
    }

  }

  _exports.default = TickBar;
});