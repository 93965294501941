define("clarify/components/m-visualization-item-settings/menu", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <VisualizationSettings::FieldsetTabView @tabs={{this.options}} @style="mild" as |value|>
    {{#if (eq value "item-info")}}
      <AItemCard class="m-item-settings-card" @style="dark" @item={{@item}} @displaySignalLink={{true}} />
    {{else if (eq value "line-chart-thresholds")}}
      <Thresholds @layout={{@layout}} @timeline={{@timeline}} @source={{@source}} @thresholds={{@thresholds}} />
    {{/if}}
  </VisualizationSettings::FieldsetTabView>
  */
  {"id":"ZUuTyE+E","block":"{\"symbols\":[\"value\",\"@layout\",\"@timeline\",\"@source\",\"@thresholds\",\"@item\"],\"statements\":[[8,\"visualization-settings/fieldset-tab-view\",[],[[\"@tabs\",\"@style\"],[[32,0,[\"options\"]],\"mild\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[32,1],\"item-info\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"a-item-card\",[[24,0,\"m-item-settings-card\"]],[[\"@style\",\"@item\",\"@displaySignalLink\"],[\"dark\",[32,6],true]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,0],[[32,1],\"line-chart-thresholds\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"thresholds\",[],[[\"@layout\",\"@timeline\",\"@source\",\"@thresholds\"],[[32,2],[32,3],[32,4],[32,5]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\"]}","meta":{"moduleName":"clarify/components/m-visualization-item-settings/menu.hbs"}});

  class VisualizationHeader extends _component.default {
    get options() {
      return ['item-info', 'line-chart-thresholds'];
    }

  }

  _exports.default = VisualizationHeader;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, VisualizationHeader);
});