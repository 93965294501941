define("clarify/models/timeline", ["exports", "ember-data", "clarify/models/permission", "clarify/models/permission-group", "clarify/models/permission-user"], function (_exports, _emberData, _permission, _permissionGroup, _permissionUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let {
    attr,
    hasMany,
    belongsTo
  } = _emberData.default;
  let Timeline = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = attr('string'), _dec4 = attr('string'), _dec5 = attr('date'), _dec6 = belongsTo('user', {
    inverse: null
  }), _dec7 = attr('date'), _dec8 = attr('string'), _dec9 = hasMany('permission', {
    polymorphic: true,
    async: false,
    inverse: null
  }), _dec10 = hasMany('layout'), _dec11 = belongsTo('organization', {
    async: false,
    inverse: null
  }), _dec12 = attr('timeline-annotations'), _dec13 = hasMany('tag', {
    inverse: null,
    async: false
  }), _dec14 = hasMany('threshold'), _dec15 = Ember.computed('tags.[]'), _dec16 = Ember.computed('isDeleted', 'hasDirtyAttributes', 'isSaving'), _dec17 = Ember.computed.filter('permissions', permission => {
    if (permission instanceof _permissionGroup.default && permission.optionalGroup) {
      return true;
    } else if (permission instanceof _permissionUser.default) {
      return true;
    }

    return false;
  }), _dec18 = Ember.computed('permissions.@each.permission'), (_class = (_temp = class Timeline extends _emberData.default.Model {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "currentUser", _descriptor2, this);

      _initializerDefineProperty(this, "name", _descriptor3, this);

      _initializerDefineProperty(this, "description", _descriptor4, this);

      _initializerDefineProperty(this, "createdAt", _descriptor5, this);

      _initializerDefineProperty(this, "createdBy", _descriptor6, this);

      _initializerDefineProperty(this, "updatedAt", _descriptor7, this);

      _initializerDefineProperty(this, "updatedBy", _descriptor8, this);

      _initializerDefineProperty(this, "permissions", _descriptor9, this);

      _initializerDefineProperty(this, "layouts", _descriptor10, this);

      _initializerDefineProperty(this, "organization", _descriptor11, this);

      _initializerDefineProperty(this, "annotations", _descriptor12, this);

      _initializerDefineProperty(this, "tags", _descriptor13, this);

      _initializerDefineProperty(this, "thresholds", _descriptor14, this);

      _initializerDefineProperty(this, "validPermissions", _descriptor15, this);
    }

    get validTags() {
      return this.tags.filter(tag => tag.isCategoryValid).sortBy('category.title', 'tag');
    }

    get isDeletedOnAPI() {
      return this.isDeleted && Ember.get(this, 'hasDirtyAttributes') === false && Ember.get(this, 'isSaving') === false;
    }

    // A user can have multiple permissions and the most significant permission wins.
    // The most likely situation where this can happen, member is member of a group
    // that has edit / view rights to the timeline, and got admin / edit rights as either
    // via another group or via a direct user perimission.
    get mostSignificantPermissionType() {
      if (this.currentUser.user) {
        let canManage = false;
        let canEdit = false;
        let canView = false;

        function apply(permission) {
          switch (permission) {
            case _permission.PermissionType.owner:
              canManage = true;
              break;

            case _permission.PermissionType.collaborator:
              canEdit = true;
              break;

            case _permission.PermissionType.guest:
              canView = true;
              break;
          }
        }

        for (let permission of this.validPermissions.toArray()) {
          if (permission instanceof _permissionGroup.default) {
            if (permission.group.loadedUsers.includes(this.currentUser.user)) {
              apply(permission.permission);
            }
          } else if (permission instanceof _permissionUser.default) {
            if (permission.user === this.currentUser.user) {
              apply(permission.permission);
            }
          }
        }

        if (canManage) {
          return _permission.PermissionType.owner;
        } else if (canEdit) {
          return _permission.PermissionType.collaborator;
        } else if (canView) {
          return _permission.PermissionType.guest;
        }
      }

      return null;
    }

    get isPublic() {
      let permission = this.validPermissions.find(permission => {
        if (permission instanceof _permissionGroup.default) {
          return permission.group.isPrimaryGroup;
        }

        return false;
      });
      return permission != null;
    }

    get isPrivate() {
      return this.validPermissions.length === 1;
    }

    get members() {
      let users = new Set();

      for (let permission of this.validPermissions) {
        if (permission instanceof _permissionUser.default) {
          users.add(permission.belongsTo('user').id());
        } else if (permission instanceof _permissionGroup.default) {
          let hasMany = permission.group.hasMany('members');

          for (let id of hasMany.ids()) {
            users.add(id);
          }
        }
      }

      return new Array(...users);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "createdAt", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "createdBy", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "updatedAt", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "updatedBy", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "permissions", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "layouts", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "organization", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "annotations", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "tags", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "thresholds", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "validTags", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "validTags"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isDeletedOnAPI", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "isDeletedOnAPI"), _class.prototype), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "validPermissions", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "mostSignificantPermissionType", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "mostSignificantPermissionType"), _class.prototype)), _class));
  _exports.default = Timeline;
});