define("clarify/serializers/timeline", ["exports", "clarify/serializers/rest-layer", "clarify/serializers/permission", "clarify/models/permission", "clarify/environment"], function (_exports, _restLayer, _permission, _permission2, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class TimelineSerializer extends _restLayer.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "root", 'timelines');

      _defineProperty(this, "attrs", {
        tags: {
          serialize: 'records',
          deserialize: 'records'
        },
        permissions: {
          serialize: 'records',
          deserialize: 'records'
        }
      });
    }

    normalize(modelClass, resourceHash, prop) {
      let labels = resourceHash.labels || {};
      let tags = [];
      let permissions = resourceHash.permissions ? _permission.default.fromProprietaryFormat(resourceHash.id, resourceHash.permissions) : undefined;

      for (let [key, values] of Object.entries(labels)) {
        for (let id of values) {
          tags.push({
            id: `${key}||${id}`,
            tag: id,
            category: key
          });
        }
      }

      let payload = { ...resourceHash,
        tags: tags,
        permissions,
        displayOptions: resourceHash.displayOptions || {}
      };
      let parsedPayload = super.normalize(modelClass, payload, prop);
      let queryParameters = new URLSearchParams([['filter', `{timeline: "${resourceHash.id}"}`]]);
      parsedPayload.data.relationships.thresholds = {
        links: {
          related: `${_environment.default.APP.API_HOST}/api/meta/thresholds?${queryParameters.toString()}`
        }
      };
      return parsedPayload;
    }

    serialize(snapshot, options) {
      const superRes = super.serialize(snapshot, options);
      let labels = {};
      let tags = superRes.tags;
      tags.forEach(tag => {
        let tagIds = labels[tag.category] || [];
        tagIds.push(tag.tag);
        labels[tag.category] = tagIds;
      });
      let permissions = Object.assign({
        [_permission2.PermissionType.collaborator]: [],
        [_permission2.PermissionType.owner]: [],
        [_permission2.PermissionType.guest]: []
      }, superRes.permissions);
      return {
        name: superRes.name,
        organization: superRes.organization,
        permissions,
        labels
      };
    }

    serializeHasMany(snapshot, json, relationship) {
      let typedSnapshot = snapshot;
      let key = relationship.key;

      switch (key) {
        case 'permissions':
          let permissions = typedSnapshot.hasMany('permissions'); // @ts-ignore

          json.permissions = _permission.default.toProprietaryFormat(permissions);
          break;

        default:
          super.serializeHasMany(snapshot, json, relationship);
      }

      return;
    }

  }

  _exports.default = TimelineSerializer;
});