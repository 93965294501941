define("clarify/utilities/rpc/label-item-labels", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.LabelItemLablesRequestInfo = void 0;

  class LabelItemLablesRequestInfo {
    static create(id, parameters) {
      return {
        method: 'label.ItemLabels',
        version: '0.6',
        id,
        parameters
      };
    }

  }

  _exports.LabelItemLablesRequestInfo = LabelItemLablesRequestInfo;
});