define("clarify/services/m3-schema", ["exports", "clarify-data-layer/services/m3-schema"], function (_exports, _m3Schema) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _m3Schema.default;
    }
  });
});