define("clarify/components/comment/header", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="comment-header" ...attributes>
    <Avatar class="comment-header-avatar" @user={{@user}} width="28" height="28" />
    <div class="comment-header-content">
      <div class="comment-header-name">
        {{@user.name}}
      </div>
      <div class="comment-header-timestamp">
        {{this.formatted}}
      </div>
    </div>
  </div>
  */
  {"id":"+/xVjiXZ","block":"{\"symbols\":[\"&attrs\",\"@user\"],\"statements\":[[11,\"div\"],[24,0,\"comment-header\"],[17,1],[12],[2,\"\\n  \"],[8,\"avatar\",[[24,0,\"comment-header-avatar\"],[24,\"width\",\"28\"],[24,\"height\",\"28\"]],[[\"@user\"],[[32,2]]],null],[2,\"\\n  \"],[10,\"div\"],[14,0,\"comment-header-content\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"comment-header-name\"],[12],[2,\"\\n      \"],[1,[32,2,[\"name\"]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"comment-header-timestamp\"],[12],[2,\"\\n      \"],[1,[32,0,[\"formatted\"]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}","meta":{"moduleName":"clarify/components/comment/header.hbs"}});

  let CommentHeader = (_dec = Ember.inject.service, (_class = (_temp = class CommentHeader extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "locale", _descriptor, this);
    }

    get formatted() {
      return this.locale.format(this.args.date, this.locale.current.threads.avatarHeader);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "locale", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CommentHeader;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CommentHeader);
});